import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import {
  isEmailValid,
  subscribeUserEmail,
  isAppInWhiteLabelMode,
  isAppInViewMode,
  getQueryParams
} from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import Logo from "components/labels/AppLogo";
import EmailInput from "components/inputs/EmailInput";
import { connect } from "react-redux";
import { routes, websiteUrls, queryParams } from "routes";
import ClickableLink from "components/labels/ClickableLink";
import { FBWrapperTrackCustomEvent } from "utilities/FBWrapper";
import { GAWrapperEvent } from "utilities/GAWrapper";
import { ANALYTICS } from "analytics";

import SignUpThanks from "components/headsup/SignUpThanks";

const isWhiteLabelledApp = isAppInWhiteLabelMode();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  height: 100%;
  background: ${props => props.theme.mobileSignInBackground};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmailForm = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-direction: column;
  margin-top: 50px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  white-space: pre-wrap;
`;

const Description = styled.div`
  margin-top: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  white-space: pre-wrap;
`;

const EmailField = styled(EmailInput)`
  height: 50px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
`;

const SubmitButton = styled(PrimaryButton)`
  margin: 0;
  padding: 0;
  margin-top: 20px;
  min-width: 144px;
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
`;

const ThankYouMessage = styled.div`
  margin-top: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 130%;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  white-space: pre-wrap;
`;

const HomePageLink = styled(ClickableLink)`
  margin-top: 30px;
`;

const ViewModeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: rgb(252, 252, 252);
  height: 100%;
`;

const ViewModeForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 30px 30px 30px;
`;

const ViewModeTitle = styled.div`
  margin-top: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const ViewModeDescription = styled.div`
  margin-top: 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  white-space: pre-line;
`;

const KnowKuberaLink = styled.a`
  margin-top: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 130%;
  text-decoration-line: underline;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: #333333;
`;

const SignInLink = styled(ClickableLink)`
  font-size: 13px;
  line-height: 130%;
  text-decoration-line: underline;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: #333333;
  margin-bottom: 44px;
`;

const BackToHome = styled.a`
  font-size: 13px;
  line-height: 130%;
  color: #333333;
  text-decoration-line: underline;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const AppLogo = styled(Logo)`
  margin-top: 30px;
`;

const InviteMessageBlock = styled.div`
  margin-top: 32px;
`;

const InviteMessageTitle = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.39px;
  margin-bottom: 27px;
`;

const InviteMessageDesc = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.27px;
`;

class MobileNotSupportedComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      errorMessage: null,
      shouldCollectEmail: this.shouldCollectEmail(),
      isEmailSubmitted: false,
      isRequestPending: false
    };
    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  shouldCollectEmail() {
    const pathname = window.location.pathname;
    if (pathname === routes.SIGNUP || pathname === routes.HOME) {
      return true;
    }
    return false;
  }

  handleEmailInput(e) {
    this.setState({ ...this.state, email: e.target.value.trim(), errorMessage: null });
  }

  handleSubmitClick() {
    const email = this.state.email;
    let errorMessage = null;

    if (!email) {
      errorMessage = i18n.t("emptyEmail");
    } else if (isEmailValid(email) === false) {
      errorMessage = i18n.t("wrongEmailFormat");
    }

    if (!errorMessage === true) {
      this.saveEmail(email);
    } else {
      this.setState({
        ...this.state,
        errorMessage
      });
    }
  }

  saveEmail(email) {
    this.setState({ ...this.state, isRequestPending: true });

    this.props.subscribeUserEmail(
      email,
      "mobile_not_supported",
      () => {
        this.setState({ ...this.state, isEmailSubmitted: true, isRequestPending: false });
      },
      error => {
        this.setState({ ...this.state, isRequestPending: false, errorMessage: error.errorMessage });
      }
    );

    FBWrapperTrackCustomEvent("Lead");

    GAWrapperEvent({
      category: ANALYTICS.CATEGORY.ALL,
      action: ANALYTICS.ACTION.EMAIL_SUBMITTED
    });
  }

  isInviteUrl() {
    const params = getQueryParams(window.location);
    if ((params[queryParams.INVITATION_ID] || "").startsWith("family-")) {
      return true;
    }
    return false;
  }

  render() {
    if (isAppInViewMode() === true) {
      return (
        <ViewModeContainer>
          <ContentContainer>
            <ViewModeForm>
              <AppLogo hasNoLink={isWhiteLabelledApp} forceDefault />
              <ViewModeTitle>{i18n.t("mobileNotSupportedComponent.sorry")}</ViewModeTitle>
              <ViewModeDescription>{i18n.t("mobileNotSupportedComponent.viewModeDescription")}</ViewModeDescription>
              {isWhiteLabelledApp === false && (
                <KnowKuberaLink target="_blank" href={websiteUrls.WEB_HOMEPAGE}>
                  {i18n.t("knowMoreKubera")}
                </KnowKuberaLink>
              )}
            </ViewModeForm>
          </ContentContainer>
        </ViewModeContainer>
      );
    }

    if (isWhiteLabelledApp === true) {
      const webSiteUrl = `${window.location.protocol}//${window.location.host}`;

      return (
        <Container>
          <ContentContainer>
            <AppLogo hasNoLink={isWhiteLabelledApp} forceDefault />
            <EmailForm>
              <Title>{i18n.t("mobileNotSupportedComponent.sorry")}</Title>
              <ThankYouMessage
                dangerouslySetInnerHTML={{
                  __html: i18n
                    .t("mobileNotSupportedComponent.wlSorryDescription")
                    .replace("%s1%", webSiteUrl)
                    .replace("%s2%", webSiteUrl)
                }}
              />
            </EmailForm>
          </ContentContainer>
        </Container>
      );
    }

    if (this.isInviteUrl()) {
      return (
        <Container>
          <ContentContainer>
            <AppLogo hasNoLink={isWhiteLabelledApp} forceDefault />
            <InviteMessageBlock>
              <InviteMessageTitle>{`${i18n.t("sorry")}!`}</InviteMessageTitle>
              <InviteMessageDesc>{i18n.t("mobileFamilyInviteMessage")}</InviteMessageDesc>
            </InviteMessageBlock>
          </ContentContainer>
        </Container>
      );
    }

    const shouldCollectEmail = this.state.shouldCollectEmail;
    const email = this.state.email;
    const errorMessage = this.state.errorMessage;
    const isEmailSubmitted = this.state.isEmailSubmitted;
    const isRequestPending = this.state.isRequestPending;

    if (shouldCollectEmail === false) {
      return (
        <Container>
          <ContentContainer>
            <AppLogo hasNoLink={isWhiteLabelledApp} forceDefault />
            <EmailForm>
              <Title>{i18n.t("mobileNotSupportedComponent.sorry")}</Title>
              <ThankYouMessage
                dangerouslySetInnerHTML={{
                  __html: i18n.t("mobileNotSupportedComponent.sorryDescription")
                }}
              />
              <HomePageLink href={websiteUrls.CONTACT}>{i18n.t("contactUs")}</HomePageLink>
              <HomePageLink href={websiteUrls.WEB_HOMEPAGE}>
                {i18n.t("mobileNotSupportedComponent.homepageLink")}
              </HomePageLink>
            </EmailForm>
          </ContentContainer>
        </Container>
      );
    }

    if (isEmailSubmitted === false) {
      return (
        <Container>
          <ContentContainer>
            <AppLogo hasNoLink={isWhiteLabelledApp} forceDefault />
            <EmailForm>
              <Title>{i18n.t("mobileNotSupportedComponent.title")}</Title>
              <Description>{i18n.t("mobileNotSupportedComponent.description")}</Description>
              <EmailField
                placeholder={i18n.t("email")}
                inputError={!errorMessage === false}
                errorMessage={errorMessage}
                value={email}
                onChange={this.handleEmailInput}
                autoFocus={true}
              />
              <SubmitButton title={i18n.t("submit")} onClick={this.handleSubmitClick} isLoading={isRequestPending} />
              <SignInLink to={routes.SIGNIN}>{i18n.t("signInLink")}</SignInLink>
              <BackToHome href="https://www.kubera.com/?r">
                {i18n.t("mobileNotSupportedComponent.homepageLink")}
              </BackToHome>
            </EmailForm>
          </ContentContainer>
        </Container>
      );
    }

    return <SignUpThanks />;
  }
}

const mapDispatchToProps = {
  subscribeUserEmail: subscribeUserEmail
};

export default connect(
  null,
  mapDispatchToProps
)(MobileNotSupportedComponent);
