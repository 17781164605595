import React, { useEffect } from "react";
import styled from "styled-components";
import i18n from "i18next";
import Spinner from "components/loader/Spinner";
import PrimaryButton from "components/button/PrimaryButton";
import { ReactComponent as KuberaLogo } from "assets/images/kubera_logo.svg";
import { isAppInWhiteLabelMode, isMobile } from "@kubera/common";
import { useErrorBoundary } from "react-error-boundary";

const isMobileDevice = isMobile();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: ${props => (isMobileDevice ? props.theme.mobileBackground : props.theme.initialLoaderBG)};
`;

const SpinIt = styled(Spinner)`
  width: 20px;
  height: 20px;
`;

const ErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${props => props.theme.mobileTxtColor};
`;

const RefreshButton = styled(PrimaryButton)`
  height: 44px;
  margin-top: 15px;
`;

const Logo = styled(KuberaLogo)`
  margin-bottom: 11px;
  width: 97px;
  height: 24px;
  path {
    fill: ${props => props.theme.mobileTxtColor};
  }
`;

let timeoutId = null;
function InitialLoader({
  className = "",
  errorMessage,
  hideLogo,
  disableRefresh,
  overrideGenericError,
  throwExceptionIfTimeout = false,
  onRefresh = () => null
}) {
  const isWhiteLabelledApp = isAppInWhiteLabelMode();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    if (throwExceptionIfTimeout) {
      timeoutId = setTimeout(() => {
        showBoundary("InitialLoader timeout error");
      }, 15000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showBoundary, throwExceptionIfTimeout]);

  if (errorMessage) {
    return (
      <Container className={className}>
        {isWhiteLabelledApp === false && !hideLogo === true && <Logo />}
        <ErrorMessage
          dangerouslySetInnerHTML={{
            __html: !overrideGenericError === false ? errorMessage : i18n.t("genericError")
          }}
        ></ErrorMessage>
        {!disableRefresh === true && <RefreshButton title={i18n.t("refresh")} onClick={onRefresh} />}
      </Container>
    );
  } else {
    return (
      <Container className={className}>
        {isWhiteLabelledApp === false && !hideLogo === true && <Logo />}
        <SpinIt darkTheme={true} isHorizontalLoader />
      </Container>
    );
  }
}

export default InitialLoader;
