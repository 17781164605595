import React from "react";
import styled from "styled-components";

import GridHeaderCell from "./GridHeaderCell";

const Header = styled.div`
  display: flex;
  flex: 1;
  height: 40px;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 9px;
  background-color: ${props => props.theme.gridSectionHeaderBackgroundColor};
  color: ${props => props.theme.gridSectionHeaderColor};
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
`;

class GridHeaderRow extends React.Component {
  constructor(props) {
    super(props);

    this.sortPreference = {
      [this.props.sectionId]: {}
    };
  }

  render() {
    this.sortPreference[this.props.sectionId] = this.props.columnSortKey
      ? {
          [this.props.columnSortKey]: this.props.columnSortOrder
        }
      : {};

    return (
      <Header>
        {this.props.row.cells.map((cell, index) => {
          return (
            <GridHeaderCell
              key={index}
              index={index}
              isHeader={true}
              cellType={cell.type}
              cell={this.props.row.cells[index]}
              cellIndex={index}
              cellIndexToStretch={this.props.row.cellIndexToStretch}
              isLastCell={index === this.props.row.cells.length - 1}
              isLastRow={this.props.isLastRow}
              onMoveToNextCell={this.props.onMoveToNextCell}
              sortoption={this.sortPreference[this.props.sectionId][cell.name]}
              shouldSortIconBeShownOnRightSide={
                index === this.props.row.cellIndexToStretch || cell.textAlignment === "left"
              }
              sortPreference={this.sortPreference}
              {...this.props}
            />
          );
        })}
      </Header>
    );
  }
}

export default GridHeaderRow;
