import createWhitelistFilter from "redux-persist-transform-filter";
import {
  FETCH_CUSTODIAN_DETAILS,
  FETCH_CUSTODIAN_DETAILS_SUCCESS,
  FETCH_CUSTODIAN_DETAILS_HISTORY_SUCCESS,
  FETCH_CUSTODIAN_DETAILS_ERROR,
  RESET_CUSTODIAN_DETAILS,
  UPDATE_CUSTODIAN_DETAILS,
  DELETE_CUSTODIAN_HISTORY,
  UPDATE_CUSTODIAN_HISTORY,
  REFRESH_CUSTODIAN_HISTORY,
  UPDATE_CUSTODIAN_CASHFLOW,
  DELETE_CUSTODIAN_CASHFLOW,
  ADD_PENDING_IRR_REQUEST,
  REMOVE_PENDING_IRR_REQUEST
} from "../actions/CustodianDetailsActions";
import { custodianTaxTypes } from "../actions";

const initialState = {
  details: null,
  fetchDetailsPending: false,
  fetchDetailsError: false,
  pendingIrrRequests: []
};

export const custodianDetailsPersistTransform = () => {
  return createWhitelistFilter("custodian_details", []);
};

export function custodianDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CUSTODIAN_DETAILS:
      return {
        ...state,
        details: state.details || null,
        fetchDetailsPending: true,
        fetchDetailsError: null
      };
    case FETCH_CUSTODIAN_DETAILS_SUCCESS:
      return {
        ...state,
        fetchDetailsPending: false,
        details: action.details,
        fetchDetailsError: null
      };
    case FETCH_CUSTODIAN_DETAILS_HISTORY_SUCCESS:
      return {
        ...state,
        fetchDetailsPending: false,
        details: {
          ...state.details,
          history: action.isCostHistory
            ? state.details.history
            : action.clearPreviousHistory === true
            ? action.history
            : [...state.details.history, ...action.history],
          costHistory: action.isCostHistory
            ? action.clearPreviousHistory === true
              ? action.history
              : [...state.details.costHistory, ...action.history]
            : state.details.costHistory,
          isHistoryLoaded: action.isCostHistory ? state.details.isHistoryLoaded : action.history.length === 0,
          isCostHistoryLoaded: action.isCostHistory ? action.history.length === 0 : state.details.isCostHistoryLoaded
        },
        fetchDetailsError: null
      };
    case FETCH_CUSTODIAN_DETAILS_ERROR:
      return {
        ...state,
        fetchDetailsPending: false,
        details: null,
        fetchDetailsError: action.error
      };
    case UPDATE_CUSTODIAN_DETAILS:
      return {
        ...state,
        details: Object.assign({}, action.updatedDetails)
      };
    case ADD_PENDING_IRR_REQUEST:
      return {
        ...state,
        pendingIrrRequests: [...state.pendingIrrRequests, action.requestId]
      };
    case REMOVE_PENDING_IRR_REQUEST:
      return {
        ...state,
        pendingIrrRequests: state.pendingIrrRequests.filter(
          item => !action.requestId === false && item !== action.requestId
        )
      };
    case DELETE_CUSTODIAN_HISTORY: {
      const newState = { ...state };
      const details = newState.details || {};
      const { history = [] } = details;
      const historyItems = action.isCostHistory ? details.costHistory : history;
      const historyIndex = (historyItems || []).findIndex(entry => entry.id === action.historyId);
      if (historyIndex !== -1) {
        historyItems.splice(historyIndex, 1);
      }
      return newState;
    }
    case UPDATE_CUSTODIAN_HISTORY: {
      const newState = { ...state };
      const details = newState.details || {};
      const { history = [] } = details;
      const historyItems = action.isCostHistory ? details.costHistory : history;
      const historyIndex = (historyItems || []).findIndex(entry => entry.id === action.history.id);
      if (historyIndex !== -1) {
        historyItems[historyIndex] = action.history;
      } else {
        historyItems.push(action.history);
      }
      return newState;
    }
    case REFRESH_CUSTODIAN_HISTORY:
      return {
        ...state,
        details: {
          ...state.details,
          isHistoryRefreshing: action.isRefreshing
        }
      };
    case UPDATE_CUSTODIAN_CASHFLOW: {
      const newState = { ...state };
      const details = newState.details;

      if (!details === true) {
        return newState;
      }

      if (!details.cashFlow === true) {
        details.cashFlow = [];
      }

      const cashflowIndex = details.cashFlow.findIndex(entry => entry.id === action.cashflow.id);
      if (cashflowIndex !== -1) {
        details.cashFlow[cashflowIndex] = action.cashflow;
      } else {
        details.cashFlow.push(action.cashflow);
      }

      details.cashFlow = [...details.cashFlow];
      return newState;
    }
    case DELETE_CUSTODIAN_CASHFLOW: {
      const newState = { ...state };
      const details = newState.details;
      const cashflowIndex = details.cashFlow.findIndex(entry => entry.id === action.cashflowId);
      if (cashflowIndex !== -1) {
        details.cashFlow.splice(cashflowIndex, 1);
      }
      return newState;
    }
    case RESET_CUSTODIAN_DETAILS:
      return initialState;
    default:
      return state;
  }
}

export const custodianDetailsIsPendingSelector = state => state.custodian_details.fetchDetailsPending;

export const isCustodianHistoryRefreshingSelector = state => state.custodian_details.details.isHistoryRefreshing;

export const custodianDetailsErrorSelector = state => state.custodian_details.fetchDetailsError;

export const custodianDetailsSelector = state => state.custodian_details.details;

export const isIrrUpdatingSelector = state => state.custodian_details.pendingIrrRequests.length !== 0;

export const taxOnUnrealizedGainsSelector = state => {
  const custodianDetails = custodianDetailsSelector(state);
  const detailsInfo = custodianDetails && custodianDetails.info;
  return detailsInfo && detailsInfo.taxDetails ? JSON.parse(detailsInfo.taxDetails) : null;
};

export const getTaxableTypeForCustodian = state => {
  const taxOnUnrealizedGains = taxOnUnrealizedGainsSelector(state);
  return taxOnUnrealizedGains ? taxOnUnrealizedGains.taxableAssetType : custodianTaxTypes.TAXABLE;
};
