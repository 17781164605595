import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import {
  signInWithGoogle,
  isEmailValid,
  signInWithEmailPassword,
  resendSignupCode,
  confirmSignup,
  isAppInWhiteLabelMode,
  createPassword,
  getQueryParams,
  siteConfigSelector,
  store,
  setSignInWithGoogleFlowAction,
  ApiClient,
  getUuid,
  setMultiuserInvitationData,
  setSignInRedirectPathAction
} from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import AppLogo from "components/labels/AppLogo";
import signInWithGoogleImage from "assets/images/signin_with_google.png";
import EmailInput from "components/inputs/EmailInput";
import PasswordInput from "components/inputs/PasswordInput";
import ClickableLink from "components/labels/ClickableLink";
import { routes, queryParams, queryParamValues, websiteUrls } from "routes";
import TextInput from "components/inputs/TextInput";
import { withRouter } from "@kubera/common";
import VerifySignIn from "components/twoFA/VerifySignIn";
import KuberaPoweredLogo from "components/labels/KuberaPoweredLogo";
import Checkbox from "components/inputs/Checkbox";

const isWhiteLabelledApp = isAppInWhiteLabelMode();

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 50px);
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 423px;
  min-width: 423px;
  height: fit-content;
  background: #ffffff;
`;

const SignInForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 582px;
  margin-top: 8px;
  padding: 50px;
  border: 1px solid #000000;
  box-sizing: border-box;
`;

const Title = styled.div`
  margin-bottom: ${props => (props.isWhiteLabelledApp === true ? "33px" : "0px")};
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  white-space: pre-wrap;
`;

const GoogleSignInButton = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  background-color: transparent;
  background-image: url(${signInWithGoogleImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 321px 50px;
  margin-top: 14px;
  cursor: pointer;
`;

const OrLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  font-feature-settings: "ss01" on;
  margin-top: 7px;
  margin-bottom: 7px;
`;

const ErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.errorCLR};
  margin-top: 7px;
  margin-bottom: 7px;
`;

const EmailField = styled(EmailInput)`
  height: 50px;
`;

const PasswordField = styled(PasswordInput)`
  height: 50px;
  margin-top: 15px;
`;

const SignInButton = styled(PrimaryButton)`
  margin-top: 24px;
  min-width: 137px;
`;

const TermsAndConditionsText = styled.div`
  margin-top: 13px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  font-feature-settings: "ss01" on;
`;

const TermsAndConditionsLink = styled(ClickableLink)``;

const ForgotPasswordLink = styled(ClickableLink)`
  margin-top: 20px;
`;

const SignUpLink = styled(ClickableLink)`
  margin-top: 11px;
`;

const UnconfirmedUserDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  margin-top: 7px;
  margin-bottom: 7px;
`;

const VerificationCodeField = styled(TextInput)`
  height: 50px;
  padding-left: 13px;
  padding-right: 13px;
  outline: 0;
  border: ${props => (props.inputError === true ? "1px solid rgba(255, 0, 0, 0.4)" : "1px solid rgba(0, 0, 0, 0.4)")};
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  color: black;

  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const GoBackLink = styled(ClickableLink)`
  margin-top: 22px;
`;

const PoweredByKuberaLogo = styled(KuberaPoweredLogo)`
  position: absolute;
  right: 50px;
  bottom: 50px;
`;

const RememberMe = styled.div`
  margin-top: 24px;
  display: flex;
  verical-align: center;
  justify-content: flex-start;
  font-size: 14px;
  cursor: pointer;
  padding: 6px 0;
`;

class SignInComponent extends React.Component {
  constructor(props) {
    super(props);

    const params = getQueryParams(window.location);
    this.invitationId = params[queryParams.INVITATION_ID];
    this.invitationEmail = params[queryParams.EMAIL_ID];
    this.invitationPortfolioId = params[queryParams.PORTFOLIO_ID];
    this.redirectPath = params[queryParams.REDIRECT_PATH];

    const email = params && params[queryParams.EMAIL_ID] ? params[queryParams.EMAIL_ID] : null;
    const errorParam = params && params[queryParams.SIGNIN_ERROR] ? params[queryParams.SIGNIN_ERROR] : null;
    const siteName = siteConfigSelector(store.getState()) ? siteConfigSelector(store.getState()).name : "";
    const archivedUserMessage = i18n.t("userArchivedError").replace("%s1%", siteName);
    const errorMessage = errorParam && errorParam === queryParamValues.ARCHIVED_USER_ERROR ? archivedUserMessage : null;

    this.state = {
      isEmailError: false,
      isPasswordError: false,
      errorMessage: errorMessage,
      email: email,
      password: null,
      verificationCode: null,
      isVerificationCodeError: false,
      isUnconfirmedUser: false,
      isSignInPending: false,
      verifyMFA: false,
      newPassword: null,
      isCreatePasswordError: false,
      showCreatePasswordScreen: false,
      createPasswordUserData: null,
      isCreatePasswordPending: false,
      rememberMe: true
    };
    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handlePasswordInput = this.handlePasswordInput.bind(this);
    this.handleCreatePasswordInput = this.handleCreatePasswordInput.bind(this);
    this.handleVerificationCodeInput = this.handleVerificationCodeInput.bind(this);
    this.handleSignInWithGoogle = this.handleSignInWithGoogle.bind(this);
    this.handleSignInClick = this.handleSignInClick.bind(this);
    this.showSignInScreen = this.showSignInScreen.bind(this);
    this.handleSignInVerification = this.handleSignInVerification.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.setVerifyMFA = this.setVerifyMFA.bind(this);
    this.handleSuccessfulSignIn = this.handleSuccessfulSignIn.bind(this);
    this.goBackFromVerify = this.goBackFromVerify.bind(this);
    this.signIn = this.signIn.bind(this);
    this.handleCreatePassword = this.handleCreatePassword.bind(this);
    this.handleRememberMe = this.handleRememberMe.bind(this);

    store.dispatch(setSignInWithGoogleFlowAction(false));

    // Trigger sign out API to clear any cookies that might have been retained
    // even after signout as some requests might come back after the signout API
    ApiClient.signOut(getUuid())
      .then(() => {})
      .catch(() => {});
  }

  componentDidMount() {
    if (!this.invitationId === false) {
      this.props.setMultiuserInvitationData(this.invitationEmail, this.invitationId, this.invitationPortfolioId);
      this.props.setSignInRedirectPathAction({ pathname: routes.FAMILY_INVITATION, search: window.location.search });
    } else if (!this.redirectPath === false) {
      this.props.setSignInRedirectPathAction({
        pathname: this.redirectPath,
        search: window.location.search,
        hash: window.location.hash
      });
    } else {
      this.props.setSignInRedirectPathAction(null);
    }
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      if (this.state.isUnconfirmedUser === true) {
        this.handleSignInVerification();
      } else {
        this.handleSignInClick();
      }
    }
  }

  handleEmailInput(e) {
    this.setState({ ...this.state, email: e.target.value.trim(), isEmailError: false });
  }

  handlePasswordInput(e) {
    this.setState({ ...this.state, password: e.target.value, isPasswordError: false });
  }

  handleVerificationCodeInput(e) {
    this.setState({
      ...this.state,
      verificationCode: e.target.value.replace(/\s/g, ""),
      isVerificationCodeError: false
    });
  }

  handleCreatePasswordInput(e) {
    this.setState({ ...this.state, newPassword: e.target.value, isCreatePasswordError: false });
  }

  handleSignInClick() {
    const { email, password } = this.state;
    let isEmailError = false;
    let isPasswordError = false;
    let errorMessage = "";

    if (!email) {
      errorMessage = i18n.t("emptyEmail");
      isEmailError = true;
    }
    if (!password) {
      errorMessage += errorMessage.length > 0 ? ` ${i18n.t("emptyPassword")}` : i18n.t("emptyPassword");
      isPasswordError = true;
    }

    if (!errorMessage && isEmailValid(email) === false) {
      errorMessage = i18n.t("wrongEmailFormat");
      isEmailError = true;
    }

    if (isEmailError === false && isPasswordError === false) {
      this.handleRememberMe();
      this.signIn();
    } else {
      this.setState({
        ...this.state,
        isEmailError,
        isPasswordError,
        errorMessage
      });
    }
  }

  handleRememberMe() {
    const { rememberMe } = this.state;

    if (rememberMe) {
      localStorage.removeItem("remember_me");
    } else {
      localStorage.setItem("remember_me", 0);
    }
  }

  signIn() {
    this.setState({ ...this.state, isSignInPending: true });

    const email = this.state.email;
    const password = this.state.password;

    signInWithEmailPassword(
      email,
      password,
      data => {
        if (data.challengeName === "SMS_MFA" || data.challengeName === "SOFTWARE_TOKEN_MFA") {
          this.setVerifyMFA(data);
        } else if (data.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.showCreatePasswordScreen(data);
        } else {
          this.handleSuccessfulSignIn();
        }
      },
      error => {
        if (error.code === "UserNotConfirmedException") {
          resendSignupCode(
            email,
            () => {
              this.setState({ ...this.state, isSignInPending: false });
              this.showUnconfirmedUserScreen();
            },
            error => {
              this.setState({ ...this.state, isSignInPending: false, errorMessage: error.message });
            }
          );
        } else if (error.code === "UserNotFoundException") {
          this.setState({
            ...this.state,
            isSignInPending: false,
            errorMessage: i18n.t("genericUserNotFoundException")
          });
        } else if (error.code === "TooManyRequestsException") {
          this.setState({
            ...this.state,
            isSignInPending: false,
            errorMessage: i18n.t("genericError").replace(" ", "\n")
          });
        } else {
          this.setState({ ...this.state, isSignInPending: false, errorMessage: error.message });
        }
      }
    );
  }

  setVerifyMFA(user) {
    this.setState({
      verifyMFA: user
    });
  }

  handleSignInVerification() {
    const verificationCode = this.state.verificationCode;

    if (!verificationCode) {
      this.setState({ ...this.state, isVerificationCodeError: true, errorMessage: i18n.t("emptyVerificationCode") });
    } else {
      this.setState({ ...this.state, isSignInPending: true });
      confirmSignup(
        this.state.email,
        verificationCode,
        data => {
          this.signIn();
        },
        error => {
          if (error.code === "CodeMismatchException") {
            error.message = i18n.t("wrongVerificationCode");
          } else if (error.code?.length > 0) {
            error.message = i18n.t("somethingWentWrong");
          }
          this.setState({ ...this.state, isSignInPending: false, errorMessage: error.message });
        }
      );
    }
  }

  showCreatePasswordScreen(userData) {
    this.setState({ showCreatePasswordScreen: true, createPasswordUserData: userData, errorMessage: null });
  }

  navigateToPasswordCreation() {
    this.props.history.replace(routes.SIGNIN_CALLBACK);
  }

  handleSuccessfulSignIn() {
    this.props.history.replace(routes.SIGNIN_CALLBACK);
  }

  showUnconfirmedUserScreen() {
    this.setState({ ...this.state, isVerificationCodeError: false, errorMessage: null, isUnconfirmedUser: true });
  }

  showSignInScreen() {
    this.setState({
      ...this.state,
      isUnconfirmedUser: false,
      verificationCode: null,
      isVerificationCodeError: false
    });
  }

  handleSignInWithGoogle() {
    this.handleRememberMe();

    store.dispatch(setSignInWithGoogleFlowAction(true));
    signInWithGoogle();
  }

  handleCreatePassword() {
    const newPassword = this.state.newPassword;

    if (!newPassword === true || newPassword.trim().length === 0) {
      this.setState({ isCreatePasswordError: true, errorMessage: i18n.t("emptyPassword") });
      return;
    }
    if (newPassword.length < PasswordInput.minPasswordLength) {
      this.setState({ isCreatePasswordError: true, errorMessage: i18n.t("passwordTooShort") });
      return;
    }

    this.setState({ isCreatePasswordPending: true });

    createPassword(
      this.state.createPasswordUserData,
      newPassword,
      () => {
        this.handleSuccessfulSignIn();
      },
      error => {
        if (error.code === "TooManyRequestsException") {
          this.setState({
            isCreatePasswordPending: false,
            errorMessage: i18n.t("genericError").replace(" ", "\n")
          });
        } else {
          this.setState({ isCreatePasswordPending: false, errorMessage: error.message });
        }
      }
    );
  }

  goBackFromVerify() {
    this.setVerifyMFA(false);
    this.setState({
      isSignInPending: false
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
  }

  rememberMeToggle = isChecked => {
    this.setState({
      rememberMe: isChecked
    });
  };

  render() {
    const verifyMFA = this.state.verifyMFA;
    const isUnconfirmedUser = this.state.isUnconfirmedUser;
    const isSignInPending = this.state.isSignInPending;
    const showCreatePasswordScreen = this.state.showCreatePasswordScreen;

    if (verifyMFA) {
      return (
        <VerifySignIn
          user={verifyMFA}
          onSuccess={this.handleSuccessfulSignIn}
          onGoBack={this.goBackFromVerify}
          onResend={this.signIn}
          email={this.state.email}
          password={this.state.password}
        />
      );
    }

    if (isUnconfirmedUser === true) {
      const verificationCode = this.state.verificationCode;
      const isVerificationCodeError = this.state.isVerificationCodeError;
      const errorMessage = this.state.errorMessage;

      return (
        <Container>
          <ContentContainer>
            <AppLogo hasNoLink={isWhiteLabelledApp} />
            <SignInForm onSubmit={this.onFormSubmit}>
              <Title>{i18n.t("signIn")}</Title>
              {!errorMessage === false && <ErrorMessage data-cy="errorMessage">{errorMessage}</ErrorMessage>}
              {!errorMessage === true && (
                <UnconfirmedUserDescription>{i18n.t("verificationCodeEmailed")}</UnconfirmedUserDescription>
              )}
              <VerificationCodeField
                placeholder={i18n.t("verificationCodeHint")}
                inputError={isVerificationCodeError}
                value={verificationCode}
                onChange={this.handleVerificationCodeInput}
                onKeyDown={this.handleKeyDown}
                data-cy="verificationCode"
                autoComplete="one-time-code"
              />
              <SignInButton
                title={i18n.t("signIn")}
                onClick={this.handleSignInVerification}
                isLoading={isSignInPending}
                data-cy="signIn"
              />
              <GoBackLink onClick={this.showSignInScreen}>{i18n.t("goBackLink")}</GoBackLink>
            </SignInForm>
          </ContentContainer>
        </Container>
      );
    }

    if (showCreatePasswordScreen === true) {
      const password = this.state.newPassword;
      const isPasswordError = this.state.isCreatePasswordError;
      const isCreatePasswordPending = this.state.isCreatePasswordPending;
      const errorMessage = this.state.errorMessage;

      return (
        <Container>
          <ContentContainer>
            <AppLogo hasNoLink={isWhiteLabelledApp} />
            <SignInForm onSubmit={this.onFormSubmit}>
              <Title>{i18n.t("createNewPasswordScreen.title")}</Title>
              {!errorMessage === false && <ErrorMessage data-cy="errorMessage">{errorMessage}</ErrorMessage>}
              <PasswordField
                id="password"
                name="password"
                data-cy="password"
                placeholder={i18n.t("newPassword")}
                inputError={isPasswordError}
                value={password}
                onChange={this.handleCreatePasswordInput}
                onKeyDown={this.handleKeyDown}
                autoComplete="new-password"
                required
                aria-autocomplete="list"
              />
              <SignInButton
                title={i18n.t("create")}
                onClick={this.handleCreatePassword}
                isLoading={isCreatePasswordPending}
              />
              {isWhiteLabelledApp === true && <PoweredByKuberaLogo />}
            </SignInForm>
          </ContentContainer>
        </Container>
      );
    }

    const isEmailError = this.state.isEmailError;
    const isPasswordError = this.state.isPasswordError;
    const errorMessage = this.state.errorMessage;
    const email = this.state.email;
    const password = this.state.password;
    const historyState = this.props.location.state;
    const socialLoginError = historyState && historyState.socialLoginError;

    return (
      <Container>
        <ContentContainer>
          <AppLogo hasNoLink={isWhiteLabelledApp} />
          <SignInForm onSubmit={this.onFormSubmit}>
            <Title isWhiteLabelledApp={isWhiteLabelledApp}>{i18n.t("signIn")}</Title>
            {isWhiteLabelledApp === false && (
              <GoogleSignInButton tabIndex={"0"} onClick={this.handleSignInWithGoogle} data-cy="googleSignIn" />
            )}
            {isWhiteLabelledApp === false && !errorMessage === true && !socialLoginError && (
              <OrLabel>{i18n.t("or")}</OrLabel>
            )}
            {!errorMessage === false && <ErrorMessage data-cy="errorMessage">{errorMessage}</ErrorMessage>}
            {socialLoginError && <ErrorMessage data-cy="errorMessage">{i18n.t("signInSocialLoginError")}</ErrorMessage>}
            <EmailField
              id="email"
              name="email"
              data-cy="email"
              placeholder={i18n.t("email")}
              inputError={isEmailError}
              value={email}
              onChange={this.handleEmailInput}
              onKeyDown={this.handleKeyDown}
              autoComplete="username"
            />
            <PasswordField
              id="password"
              name="password"
              data-cy="password"
              placeholder={i18n.t("password")}
              inputError={isPasswordError}
              value={password}
              onChange={this.handlePasswordInput}
              hidePasswordStrength={true}
              onKeyDown={this.handleKeyDown}
              autoComplete="current-password"
              required
              aria-autocomplete="list"
            />
            <RememberMe
              onClick={e => {
                this.rememberMeToggle(!this.state.rememberMe);
              }}
            >
              <Checkbox
                checked={this.state.rememberMe}
                onChange={(checked, e) => {
                  this.rememberMeToggle(checked);
                }}
                onClick={e => {
                  e.stopPropagation();
                }}
              />
              {i18n.t("rememberMe")}
            </RememberMe>
            <SignInButton
              id="signin"
              data-cy="signin"
              title={i18n.t("signIn")}
              onClick={this.handleSignInClick}
              isLoading={isSignInPending}
            />
            {isWhiteLabelledApp === true && (
              <TermsAndConditionsText>{i18n.t("signInTermsText")}</TermsAndConditionsText>
            )}
            {isWhiteLabelledApp === true && (
              <TermsAndConditionsLink href={websiteUrls.WL_LEGAL} target={"_blank"}>
                {i18n.t("signInTermsLinkText")}
              </TermsAndConditionsLink>
            )}
            <ForgotPasswordLink to={routes.FORGOT_PASSWORD}>{i18n.t("forgotPasswordLink")}</ForgotPasswordLink>
            {isWhiteLabelledApp === false && (
              <SignUpLink to={routes.SIGNUP} data-cy="signUpLink">
                {i18n.t("signUpLink")}
              </SignUpLink>
            )}
            {isWhiteLabelledApp === true && <PoweredByKuberaLogo />}
          </SignInForm>
        </ContentContainer>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  setMultiuserInvitationData,
  setSignInRedirectPathAction
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(SignInComponent));
