import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { Navigate as Redirect, Routes, Route } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import { ANALYTICS } from "analytics";
import { GAWrapperEvent, GAWrapperAppLoadTime } from "utilities/GAWrapper";
import { FBWrapperTrackCustomEvent } from "utilities/FBWrapper";
import AssetsComponent from "components/assets/AssetsComponent";
import DebtsComponent from "components/debts/DebtsComponent";
import InsuranceComponent from "components/insurance/InsuranceComponent";
import RecapComponent from "components/recap/RecapComponent";
import PlanningComponent from "components/planning/PlanningComponent";
import BeneficiaryComponent from "components/beneficiary/BeneficiaryComponent";
import UserPhoneComponent from "components/beneficiary/UserPhoneComponent";
import HeadsUpComponent from "components/headsup/HeadsUpComponent";
import { routes, websiteUrls, queryParams, hashParams, modalValues } from "routes";
import { configureNavigator } from "routes/ConfigureNavigator";
import { connect } from "react-redux";
import { configureLogger, setupClarity } from "logger/ConfigureLogger";
import {
  fetchPortfolioPendingSelector,
  fetchPortfolioErrorSelector,
  portfoliosSelector,
  currentPortfolioSelector,
  fetchPortfolios,
  SyncComponent,
  store,
  getQueryParams,
  getHashParams,
  updateCurrentPortfolioAction,
  userPreferencesSelector,
  updateUserPreferences,
  userSelector,
  getAppVersion,
  prefetchPopularProviders,
  latestWhatsNewTipVersion,
  refreshAllConnectedCustodiansForPortfolio,
  accountSubscriptionIsSubscribedSelector,
  isSyncQueueStuckSelector,
  isAppInWhiteLabelMode,
  siteConfigSelector,
  capitalizeStringWithSpaces,
  wlClientContextSelector,
  fetchNetWorthDataForPortfolio,
  accountPlanSelector,
  accountIsFreshUserSelector,
  overrideInitialCCSetupSelector,
  showRefreshingSelector,
  isAppInViewMode,
  isMobile,
  hasAccountAddedAnyCardSelector,
  getTokenForViewMode,
  pageReloadingSelector,
  showAppUpdateToastIfNeeded,
  currentSlideDirectionSelector,
  isInternalUserSelector,
  accountTrialStartTsSelector,
  isAccountSuspendedSelector,
  getUpdatedIrr,
  fetchPortfolioChangeData,
  appMaintenanceModeSelector,
  redirectPostSignInSelector,
  setSignInRedirectPathAction,
  accountIsSubscriptionIsRequiredSelector,
  getUuid,
  insertCustodianAtEndOfSection,
  isPortfolioReadySelector,
  accountSubscriptionIsActiveSelector,
  isSharedPortfolioUserSelector,
  getMultiuserList,
  fetchPortfolioChangeDataSuccessSelector,
  getConnectivityCenterData,
  isAmcUser,
  whiteLabelUserInfoSelector,
  userTypes,
  setWlClientContext,
  withRouter,
  showBreakingChangesSelector,
  apiErrorCodes,
  isPwaApp,
  isPortfolioEmpty
} from "@kubera/common";
import PortfolioNameComponent from "components/portfolio/PortfolioNameComponent";
import DashboardHeaderComponent from "./DashboardHeaderComponent";
import InitialLoader from "components/loader/InitialLoader";
import ToastComponent from "components/toast/ToastComponent";
import LinkAccountComponent from "components/link_account/LinkAccountComponent";
import SafeDepositBoxComponent from "components/safe_deposit_box/SafeDepositBoxComponent";
import ArchivedComponent from "components/archived/ArchivedComponent";
import PortfolioDownloadDialog from "components/portfolio/PortfolioDownloadDialog";
import DocumentsDownloadDialog from "components/safe_deposit_box/DocumentsDownloadDialog";
import SidebarComponent from "components/dashboard/SidebarComponent";
import AccountSettingsComponent, { accountSettingsTabs } from "components/account_settings/AccountSettingsComponent";
import SheetUpdateFrequencyDialog from "components/grid/SheetUpdateFrequencyDialog";
import ContextMenu, { contextMenuItemType } from "components/contextmenu/ContextMenu";
import Spinner from "components/loader/Spinner";
import UploadTooLargeDialog from "components/documents/UploadTooLargeDialog";
import StorageFullDialog from "components/documents/StorageFullDialog";
import PickLinkAccountCountry from "components/link_account/PickLinkAccountCountry";
import LinkAccountProviderPageListener from "components/link_account/LinkAccountProviderPageListener";
import GenericMessageDialog from "components/dialog/GenericMessageDialog";
import NetWorthComponent from "components/net_worth/NetWorthComponent";
import ConnectComponentModal from "components/link_account/ConnectComponentModal";
import ExtrasComponentModal from "components/link_account/ExtrasComponentModal";
import PortfoliosConnectComponent from "components/link_account/PortfoliosConnectComponent";
import WhiteLabelCollaborationSettingsDialog from "components/dashboard/WhiteLabelCollaborationSettingsDialog";
import AccountInitialSubscription from "components/account_settings/AccountInitialSubscription";
import ShareROLinkComponent from "components/multiuser/ShareROLinkComponent";
import MobileAccountPaymentBlock from "components/account_settings/MobileAccountPaymentBlock";
import ZaboReconnectDialog from "components/link_account/ZaboReconnectDialog";
import SubscriptionBanner from "components/Banner/SubscriptionBanner";
import MobileNavComponent from "components/navigation/MobileNavComponent";
import MobileHeader from "components/header/MobileHeader";
import lrConditionalCall from "utilities/lrConditionalCall";
import MaintenanceModeComponent from "components/dashboard/MaintenanceModeComponent";
import MobileAccountNotificationsComponent from "components/account_settings/MobileAccountNotificationsComponent";
import SearchPortfolioComponent from "components/dashboard/SearchPortfolioComponent";
import { DialogOverlay } from "components/dialog/DialogOverlay";
import { ScrollBarHorizontal } from "theme";
import PortfolioTotalChangeDialog from "components/portfolio/PortfolioTotalChangeDialog";
import Anniversary from "components/dialog/Anniversary";
import ChartsModalComponent from "components/recap/ChartsModalComponent";
import AccountCancelledSubscription from "components/account_settings/AccountCancelledSubscription";
import EmptyPortfolio from "components/headsup/EmptyPortfolio";
import WLKMobileAccountPaymentBlock from "components/account_settings/billing/MobileAccountPaymentBlock";
import PluginWrapper from "components/plugin/PluginWrapper";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import MobilePWAInstallPopup from "components/Banner/MobilePWAInstallPopup";
import BlackPaywalls from "components/blocker_paywall/BlackPaywalls";
import BlockerPaywall from "components/blocker_paywall";
import CollaboratePortfolioComponent from "components/multiuser/CollaboratePortfolioComponent";

export const MOBILE_PWA_POPUP_BYPASS_KEY = "MOBILE_PWA_POPUP_BYPASS_KEY";

export const category = {
  ASSET: "Asset",
  DEBT: "Debt",
  INSURANCE: "Insurance",
  ALL: "All"
};

const Container = styled.div`
  display: flex;
  height: 100%;
  color: ${props => props.theme.dashboardComponentContainerCLR};

  text {
    fill: ${props => props.theme.dashboardComponentContainerCLR};
  }

  ::placeholder {
    color: ${props => (props.theme.mode === "dark" ? props.theme.placeholderColor : null)};
  }
`;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100%;
  overflow-y: auto;
  font-feature-settings: "ss01" on;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;

  * {
    box-sizing: border-box;
  }
`;

const DashboardContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  overflow-y: ${props => (props.allowScroll === true ? "scroll" : "hidden")};
  background-color: ${props => props.theme.dashboardBackgroundColor};

  ${ScrollBarHorizontal}
`;

const Refresher = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.backgroundDetailsOverlay};
  z-index: 1000;
  transform: translate3d(0, 0, 0);
  contain: layout;
`;

const SpinIt = styled(Spinner)`
  width: 20px;
  height: 20px;
`;

const DashboardHeader = styled(DashboardHeaderComponent)`
  background-color: ${props => props.theme.dashboardBackgroundColor};
`;

const DashboardContentContainer = styled.div`
  min-width: ${props => props.minWidth + "px"};
`;

const DashboardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  padding-right: ${props => props.sidePadding + "px"};
  padding-left: ${props => props.sidePadding + "px"};
  width: 100%;
  box-sizing: border-box;
`;

const Sidebar = styled(SidebarComponent)``;

const Dashboard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: ${props => props.minWidth + "px"};
  max-width: 1000px;
`;

const HelpMenuButton = styled.div`
  position: absolute;
  right: 23px;
  bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center
  background: ${props => props.theme.backgroundHelpMenuButton};
  border: ${props => `1px solid ${props => props.theme.borderColorHelpMenuButton}`};
  border-radius: 50%;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 27px;
  color: ${props => props.theme.colorHelpMenuButton};
  cursor: pointer;
`;

const HelpMenuFooter = styled.div`
  padding-bottom: 10px;
  cursor: pointer;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.contextMenuItemDescriptionColor};
`;

const HelpMenuFooterTitle = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: flex-end;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.4);
`;

const HelpMenuFooterItem = styled.a`
  padding-top: 6px;
  padding-bottom: 0px;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 11px;
  text-decoration: none;
  display: inline-block;
  color: ${props => props.theme.contextMenuItemDescriptionColor};

  &: hover {
    text-decoration: underline;
  }
`;

const AppVersion = styled.div`
  font-size: 10px;
  color: ${props => props.theme.contextMenuItemDescriptionColor};
  padding-top: 4px;
  overflow-x: hidden;
`;

const isMobileDevice = isMobile();
const isInViewMode = isAppInViewMode();

class DashboardComponent extends React.Component {
  get showMobileHeadsUp() {
    const { userPreferences } = this.props;

    return (
      isMobileDevice &&
      this.props.user &&
      !isInViewMode &&
      this.props.currentPortfolio &&
      this.props.currentPortfolio.details &&
      (!userPreferences || !userPreferences.isHeadsUpShown)
    );
  }

  get showMobileEmptyPortfolioScreen() {
    if (isAppInWhiteLabelMode()) {
      return (
        isMobileDevice &&
        this.props.user &&
        this.props.currentPortfolio &&
        isPortfolioEmpty(this.props.currentPortfolio) === true
      );
    }
    return isMobileDevice && this.props.user && !isInViewMode && this.props.isPortfolioReady === false;
  }

  get isSubscriptionRequired() {
    return this.props.user && this.props.accountIsSubscriptionIsRequired;
  }

  get showPWAInstallBannerOnSignIn() {
    return (
      this.mobilePwaPopupBypass === false &&
      !this.props.isInternalUser &&
      isInViewMode === false &&
      isMobileDevice &&
      !isPwaApp()
    );
  }

  static showPortfolio = (history, location, portfolioId) => {
    history.push({
      ...location,
      hash: "",
      search: `${queryParams.PORTFOLIO_ID}=${portfolioId}`
    });
  };

  static getCurrentCategory = () => {
    const pathName = window.location.pathname;
    if (pathName.includes(routes.ASSETS)) {
      return category.ASSET;
    } else if (pathName.includes(routes.DEBTS)) {
      return category.DEBT;
    } else if (pathName.includes(routes.INSURANCE)) {
      return category.INSURANCE;
    }
    return null;
  };

  static getPathnameForCategory = categoryName => {
    if (categoryName === category.ASSET) {
      return routes.ASSETS;
    } else if (categoryName === category.DEBT) {
      return routes.DEBTS;
    } else if (categoryName === category.INSURANCE) {
      return routes.INSURANCE;
    }
    return null;
  };

  static trackSession = (userId, user, sessionURL = window.location.href) => {
    if (isAppInViewMode() === false) {
      window.Beacon("identify", {
        name: user.name,
        email: user.email,
        user_id: user.id,
        created_at: user.tsCreated,
        sessionURL: sessionURL,
        appVersion: getAppVersion()
      });
    }

    Sentry.configureScope(function(scope) {
      scope.setUser({
        id: userId
      });
      scope.setExtra("sessionURL", sessionURL);
      scope.setExtra("appVersion", getAppVersion());
    });
  };

  constructor(props) {
    super(props);

    configureNavigator(this.props.history);

    this.helpContextMenuRef = React.createRef();
    this.lastAppUpdateCheckTs = new Date().getTime();
    this.isPaywallModalShown = React.createRef(false);

    this.handleHelpMenuButtonClick = this.handleHelpMenuButtonClick.bind(this);
    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleLoaderRefreshClick = this.handleLoaderRefreshClick.bind(this);
    this.lastFetchTs = new Date().getTime();
    this.mobilePwaPopupBypass = sessionStorage.getItem(MOBILE_PWA_POPUP_BYPASS_KEY) === "true";
  }

  componentDidMount() {
    GAWrapperAppLoadTime("componentDidMount", "start");

    const urlPortfolioId = getQueryParams(this.props.location)[queryParams.PORTFOLIO_ID];
    if (urlPortfolioId) {
      this.props.updateCurrentlySelectedPortfolio(urlPortfolioId);
    }

    if (!getQueryParams(this.props.location)[queryParams.NO_DATA_FETCH] === true) {
      this.fetchData();
    } else {
      this.props.history.replace({
        ...this.props.location,
        search: `${queryParams.PORTFOLIO_ID}=${urlPortfolioId}`
      });

      GAWrapperAppLoadTime("no_data_fetch", "ignore");
    }

    window.addEventListener("click", function() {
      window.Beacon("close");
    });

    document.addEventListener("visibilitychange", () => {
      if (document.hidden === false) {
        if (
          !this.props.wlClientContext === false &&
          !window.wlClientContext === false &&
          window.wlClientContext.userId !== this.props.wlClientContext.userId
        ) {
          this.props.setWlClientContext(window.wlClientContext);
        }

        if (!this.props.currentPortfolio === false) {
          this.props.refreshAllConnectedCustodiansForPortfolio(this.props.currentPortfolio.id);
        }

        // Check for app updates in mobile whenever app if focused so that
        // PWA users have a chance to see the latest updates
        if (isMobileDevice) {
          if (new Date().getTime() - this.lastAppUpdateCheckTs > 15 * 60 * 1000) {
            this.lastAppUpdateCheckTs = new Date().getTime();
            this.props.showAppUpdateToastIfNeeded();
          }
        }

        // when tab / window changes, fetch portfolios if last fetch was more than 60 seconds ago
        if (!this.lastFetchTs || (this.lastFetchTs && Date.now() - this.lastFetchTs > 60 * 1000)) {
          this.props.fetchPortfolios(true);
          this.lastFetchTs = new Date().getTime();
        }
      }
    });

    if (isAppInWhiteLabelMode() === false) {
      this.props.getMultiuserList();
    }

    this.props.showAppUpdateToastIfNeeded();
    this.createLinkingCustodianIdIfNeeded(getHashParams(this.props.location));
  }

  createLinkingCustodianIdIfNeeded(urlHashParams) {
    const modalValue = urlHashParams[hashParams.MODAL];
    const id = urlHashParams[hashParams.ID];

    if (modalValue === modalValues.LINK_ACCOUNT && !id === true) {
      this.linkingCustodianId = getUuid();
      const sheet = this.props.currentPortfolio.details.sheet.find(
        item => item.category === urlHashParams[hashParams.LINK_CATEGORY]
      );
      const sheetId = urlHashParams[hashParams.SHEET_ID] || sheet.id;
      const section = this.props.currentPortfolio.details.section.find(item => item.sheetId === sheetId);
      const sectionId = urlHashParams[hashParams.SECTION_ID] || section.id;
      this.props.insertCustodianAtEndOfSection(this.props.currentPortfolio.id, sectionId, this.linkingCustodianId, 1);
    }
  }

  fetchData() {
    this.props.fetchPortfolios();
    this.setupReporting(this.props.user);

    setTimeout(() => {
      this.props.prefetchPopularProviders([category.ASSET, category.DEBT]);
    }, 5000);
  }

  componentDidUpdate(oldProps) {
    const oldUrlPortfolioId = getQueryParams(oldProps.location)[queryParams.PORTFOLIO_ID];
    const urlPortfolioId = getQueryParams(this.props.location)[queryParams.PORTFOLIO_ID];
    if (urlPortfolioId !== oldUrlPortfolioId) {
      if (!urlPortfolioId === true || !oldUrlPortfolioId === true) {
        return;
      }
      this.props.updateCurrentlySelectedPortfolio(urlPortfolioId);
      if (this.props.fetchPortfolioPending === false) {
        this.props.fetchPortfolioChangeData(urlPortfolioId);
        this.props.refreshAllConnectedCustodiansForPortfolio(urlPortfolioId);
      }
      this.props.getUpdatedIrr(urlPortfolioId);
      this.props.fetchNetWorthData(urlPortfolioId);
      this.props.getConnectivityCenterData(urlPortfolioId);
    }

    if (
      !isInViewMode &&
      oldProps.fetchPortfolioChangeDataSuccess === false &&
      this.props.fetchPortfolioChangeDataSuccess === true
    ) {
      console.log("LOAD SUCCESS");
      GAWrapperAppLoadTime("dashboard", "end");
    }

    if (oldProps.currentPortfolio === null && this.props.currentPortfolio !== null) {
      this.handleSignIn(this.props.user);

      if (!isMobileDevice && this.props.isPortfolioReady === false && this.props.location.pathname !== routes.ASSETS) {
        this.props.history.replace({
          ...this.props.location,
          pathname: routes.ASSETS
        });
      }
    } else if (this.props.user === null && oldProps.user !== null) {
      this.handleSignOut();
    }

    if (oldProps.fetchPortfolioPending === true && this.props.fetchPortfolioPending === false) {
      if (!this.props.redirectPostSignIn === false && this.props.isAmcUser === false) {
        this.props.setSignInRedirectPathAction(null);
        const redirectParts = {};
        if (!this.props.redirectPostSignIn.pathname === false) {
          redirectParts.pathname = this.props.redirectPostSignIn.pathname;
        }
        if (!this.props.redirectPostSignIn.search === false) {
          redirectParts.search = this.props.redirectPostSignIn.search;
        }
        if (!this.props.redirectPostSignIn.hash === false) {
          redirectParts.hash = this.props.redirectPostSignIn.hash;
        }

        this.createLinkingCustodianIdIfNeeded(getHashParams(redirectParts));
        setTimeout(() => {
          this.props.history.push({ ...redirectParts });
        }, 100);
      }
    }

    if (oldProps.accountSubscriptionIsSubscribed === false && this.props.accountSubscriptionIsSubscribed === true) {
      if (!this.props.currentPortfolio === false) {
        this.props.refreshAllConnectedCustodiansForPortfolio(this.props.currentPortfolio.id, 0);
      }
    }

    if (oldProps.hasAccountAddedAnyCard === false && this.props.hasAccountAddedAnyCard === true) {
      this.setupReporting(this.props.user);
    }

    this.setDocumentTitle();
  }

  setIsPaywallModalShown = () => {
    this.isPaywallModalShown.current = true;
  };

  setDocumentTitle() {
    if (!this.props.location.pathname === true) {
      return;
    }

    const pathParts = this.props.location.pathname.split("/");
    const pathname = `/${pathParts[pathParts.length - 1]}`;

    var titlePrefix = capitalizeStringWithSpaces(
      pathname
        .replace("/", "")
        .split("_")
        .join(" ")
        .toLowerCase()
    );

    if (this.props.location.pathname === routes.NET_WORTH) {
      titlePrefix = i18n.t("networth");
    }
    if (this.props.location.pathname === routes.PLANNING) {
      titlePrefix = i18n.t("fastForward");
    }

    if (this.props.location.pathname === routes.PLUGIN) {
      titlePrefix = i18n.t("plugin");
    }

    const siteName = localStorage.getItem("wl_page_title");
    const title = isMobileDevice ? `${siteName}` : `${titlePrefix} • ${siteName}`;

    if (document.title !== title) {
      document.title = title;
    }
  }

  handleSignIn(user) {
    if (!user === true) {
      return;
    }

    this.setupReporting(this.props.user);
    window.ire("identify", { customerId: user.id, customerEmail: "" });
    const userAge = Math.floor(new Date().getTime() / 1000) - user.tsCreated;
    if (userAge < 5 * 60 && this.props.userPreferences.isFirstSessionRegistered === false) {
      this.handleSignUp(user);
    } else {
      GAWrapperAppLoadTime("handleSignIn", "start");
    }
  }

  handleSignUp(user) {
    // We don't want to show whats new tip to newly
    // signed up users
    this.props.updateUserPreferences({
      isFirstSessionRegistered: true,
      lastShownWhatsNewTipVersion: latestWhatsNewTipVersion
    });

    FBWrapperTrackCustomEvent("SignUp");
    window.ire("identify", { customerId: user.id, customerEmail: "" });
    GAWrapperEvent({
      category: ANALYTICS.CATEGORY.ALL,
      action: ANALYTICS.ACTION.SIGNED_UP
    });

    const { accountTrialStartTs } = this.props;

    if (accountTrialStartTs) {
      console.log("initialCC accountTrialStartTs", user.tsTrialStart);
      FBWrapperTrackCustomEvent("StartTrial");
      // commented out temporarily
      // window.lintrk("track", { conversion_id: 8571129 });
      window.ire(
        "trackConversion",
        26214,
        {
          orderId: `${user.id}_trial`,
          customerId: `${user.id}`,
          customerEmail: ""
        },
        {
          verifySiteDefinitionMatch: true
        }
      );

      GAWrapperEvent({
        category: ANALYTICS.CATEGORY.ALL,
        action: ANALYTICS.ACTION.START_TRIAL
      });
    }

    GAWrapperAppLoadTime("handleSignUp", "ignore");
  }

  setupReporting(user) {
    // Enable LR sessions only for users who are not being shown the
    // subscription paywall
    if (isAppInViewMode() === false && !user === true) {
      return;
    }

    const userId = isAppInViewMode() ? getTokenForViewMode() : user.id;

    configureLogger();

    const isLrExecuted = lrConditionalCall(() => {
      LogRocket.identify(userId, {
        displayName: userId,
        appVersion: getAppVersion()
      });

      LogRocket.getSessionURL(function(sessionURL) {
        DashboardComponent.trackSession(userId, user, sessionURL);
      });
    }, this.props.isInternalUser);

    setupClarity(userId);

    if (!isLrExecuted) {
      DashboardComponent.trackSession(userId, user);
    }
  }

  handleSignOut() {
    lrConditionalCall(() => {
      LogRocket.identify();
    }, this.props.isInternalUser);
    window.Beacon("logout");
    Sentry.configureScope(scope => scope.setUser(null));
  }

  handleLoaderRefreshClick(e) {
    this.fetchData();
  }

  getModalComponent() {
    const urlHashParams = getHashParams(this.props.location);
    const modalValue = urlHashParams[hashParams.MODAL];
    const id = urlHashParams[hashParams.ID];

    switch (modalValue) {
      case modalValues.CREATE_PORTFOLIO:
        return <PortfolioNameComponent />;
      case modalValues.EDIT_PORTFOLIO:
        const portfolio = this.props.portfolios.find(portfolio => {
          return portfolio.id === id;
        });
        return <PortfolioNameComponent portfolio={portfolio} />;
      case modalValues.LINK_ACCOUNT:
        var custodianId = id || this.linkingCustodianId;

        return (
          <LinkAccountComponent
            custodianId={custodianId}
            mode={urlHashParams[hashParams.LINK_MODE]}
            category={urlHashParams[hashParams.LINK_CATEGORY]}
            regionCode={urlHashParams[hashParams.LINK_REGION]}
            linkingService={urlHashParams[hashParams.LINK_SERVICE]}
            linkProviderId={urlHashParams[hashParams.LINK_PROVIDER]}
            inline={!urlHashParams[hashParams.LINK_INLINE] === false}
            isReconnectedFromConnectivityCenter={urlHashParams[hashParams.IS_RECONNECTED_FROM_CONNECTIVITY_CENTER]}
          />
        );
      case modalValues.MODIFY_PHONE:
        return <UserPhoneComponent />;
      case modalValues.ARCHIVED:
        return <ArchivedComponent category={DashboardComponent.getCurrentCategory()} />;
      case modalValues.DOWNLOAD_PORTFOLIO:
        return <PortfolioDownloadDialog />;
      case modalValues.DOWNLOAD_DOCUMENTS:
        return <DocumentsDownloadDialog />;
      case modalValues.ACCOUNT_SETTINGS:
        const tabVal = urlHashParams[hashParams.TAB];
        const connectivityCenterPortfolioId = urlHashParams[hashParams.SELECTED_PORTFOLIO_ID];

        if (isMobileDevice) {
          if (this.props.isSharedPortfolioAccountUser === false && tabVal === accountSettingsTabs.SUBSCRIPTION) {
            return !isAppInWhiteLabelMode() ? <MobileAccountPaymentBlock /> : <WLKMobileAccountPaymentBlock />;
          } else if (tabVal === accountSettingsTabs.NOTIFICATIONS) {
            return <MobileAccountNotificationsComponent />;
          } else {
            return null;
          }
        }
        return <AccountSettingsComponent selectedTab={tabVal} selectedPortfolioId={connectivityCenterPortfolioId} />;
      case modalValues.SHEET_FREQUENCY:
        return <SheetUpdateFrequencyDialog sheetId={id} />;
      case modalValues.UPLOAD_TOO_LARGE:
        return <UploadTooLargeDialog />;
      case modalValues.STORAGE_FULL:
        return <StorageFullDialog />;
      case modalValues.PICK_LINK_COUNTRY:
        return <PickLinkAccountCountry custodianId={id} />;
      case modalValues.CONNECT_STOCK:
      case modalValues.CONNECT_METAL:
      case modalValues.CONNECT_CRYPTO:
        return <ConnectComponentModal custodianId={id} modalValue={modalValue} />;
      case modalValues.CONNECT_EXTRAS:
      case modalValues.CONNECT_HOMES:
      case modalValues.CONNECT_CARS:
      case modalValues.CONNECT_DOMAINS:
        return <ExtrasComponentModal custodianId={id} modalValue={modalValue} />;
      case modalValues.CONNECT_PORTFOLIOS:
        return <PortfoliosConnectComponent custodianId={id} />;
      case modalValues.WL_COLLABORATE:
        return <WhiteLabelCollaborationSettingsDialog />;
      case modalValues.SHARE_PORTFOLIO_RO_LINK:
        return <ShareROLinkComponent />;
      case modalValues.COLLABORATE_PORTFOLIO:
        return <CollaboratePortfolioComponent />;

      case modalValues.COLLABORATE_PORTFOLIO_HEADS_UP:
        return (
          <GenericMessageDialog
            title={i18n.t("collaboratePortfolio.headsupDialog.title")}
            description={i18n
              .t("collaboratePortfolio.headsupDialog.description")
              .replace("%s%", `${process.env.PUBLIC_URL}#modal=account_settings&tab=multi_user`)}
            positiveButtonTitle={i18n.t("collaboratePortfolio.headsupDialog.positiveButtonTitle")}
            negativeButtonTitle={i18n.t("collaboratePortfolio.headsupDialog.negativeButtonTitle")}
            onNegativeButtonClick={() => {
              window.kuberaOpen("https://help.kubera.com/article/130-portfolio-sharing-in-kubera");
            }}
            dialogWidth="665px"
          />
        );
      case modalValues.ZABO_RECONNECT:
        return <ZaboReconnectDialog supported={urlHashParams[hashParams.SUPPORTED] === "true"} />;
      case modalValues.GENERIC_ERROR:
        return (
          <GenericMessageDialog title={i18n.t("genericExceptionTitle")} description={i18n.t("genericDialogDesc")} />
        );
      case modalValues.SEARCH:
        return <SearchPortfolioComponent portfolioId={this.props.currentPortfolio?.id} />;
      case modalValues.CUSTODIAN_DETAILS:
        return isMobileDevice ? null : <DialogOverlay />;
      case modalValues.GRID_CHANGE:
        return (
          <PortfolioTotalChangeDialog
            category={urlHashParams[hashParams.CATEGORY] || DashboardComponent.getCurrentCategory()}
            currency={this.props.currentPortfolio?.currency}
            changeData={this.props.currentPortfolio?.details.changeData}
            interval={urlHashParams[hashParams.TAB]}
          />
        );
      case modalValues.RECAP_CHARTS:
        return (
          <ChartsModalComponent
            reportId={urlHashParams[hashParams.REPORT_ID]}
            tabIndex={urlHashParams[hashParams.TAB_INDEX]}
          />
        );
      default:
        return null;
    }
  }

  handleHelpMenuButtonClick(e) {
    window.Beacon("close");
    if (this.helpContextMenuRef.current.isVisible() === true) {
      this.helpContextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = e.target.getBoundingClientRect();
    const isWhiteLabelledApp = isAppInWhiteLabelMode();

    var helpItems = [];
    if (isWhiteLabelledApp === true) {
      const siteConfig = this.props.siteConfig;
      var contactAmcItem = contextMenuItemType.HELP_MENU_WL_SUPPORT_EMAIL;
      contactAmcItem.label = `Contact ${capitalizeStringWithSpaces(siteConfig.shortName)}`;
      contactAmcItem.description = siteConfig.supportEmail;

      helpItems = [[contactAmcItem]];

      if (this.props.siteConfig.supportEntity === "kubera") {
        helpItems[0].unshift(contextMenuItemType.HELP_MENU_WL_CHAT);
      }
    } else {
      helpItems = [
        [
          contextMenuItemType.HELP_MENU_CHAT,
          contextMenuItemType.HELP_MENU_HELP_CENTER,
          contextMenuItemType.HELP_MENU_TWITTER,
          contextMenuItemType.HELP_MENU_HOME,
          contextMenuItemType.HELP_MENU_REFER_FRIEND
        ]
      ];
    }

    const customFooterItem = contextMenuItemType.HELP_MENU_FOOTER;
    customFooterItem.onRender = () => {
      return (
        <HelpMenuFooter>
          {isWhiteLabelledApp === true && (
            <HelpMenuFooterTitle target="_blank" href={websiteUrls.WEB_HOMEPAGE}>
              {i18n.t("poweredByKubera")}
            </HelpMenuFooterTitle>
          )}
          <HelpMenuFooterItem target="_blank" href={`${websiteUrls.TERMS_OF_SERVICE}`}>
            Terms
          </HelpMenuFooterItem>
          ,&nbsp;
          <HelpMenuFooterItem target="_blank" href={`${websiteUrls.PRIVACY_POLICY}`}>
            Privacy
          </HelpMenuFooterItem>
          ,&nbsp;
          <HelpMenuFooterItem target="_blank" href={`${websiteUrls.SECURITY}`}>
            Security
          </HelpMenuFooterItem>
          <AppVersion>{`v${getAppVersion()}`}</AppVersion>
        </HelpMenuFooter>
      );
    };
    helpItems[0].push(customFooterItem);

    this.helpContextMenuRef.current.show(
      helpItems,
      targetPosition.left + targetPosition.width / 2 + 5,
      targetPosition.top - targetPosition.height / 2 + 60,
      false,
      e.target
    );
  }

  handleContextMenuSelection(item) {
    if (item.id === contextMenuItemType.HELP_MENU_CHAT.id || item.id === contextMenuItemType.HELP_MENU_WL_CHAT.id) {
      window.Beacon("open");
    } else if (item.id === contextMenuItemType.HELP_MENU_TWITTER.id) {
      window.kuberaOpen(websiteUrls.TWITTER);
    } else if (item.id === contextMenuItemType.HELP_MENU_BLOG.id) {
      window.kuberaOpen(websiteUrls.BLOG);
    } else if (item.id === contextMenuItemType.HELP_MENU_HOME.id) {
      window.kuberaOpen(websiteUrls.WEB_HOMEPAGE);
    } else if (item.id === contextMenuItemType.HELP_MENU_WL_SUPPORT_EMAIL.id) {
      window.kuberaOpen(`mailto:${item.description}`);
    } else if (item.id === contextMenuItemType.HELP_MENU_REFER_FRIEND.id) {
      window.kuberaOpen("https://www.kubera.com/referral", "_blank");
    } else if (item.id === contextMenuItemType.HELP_MENU_HELP_CENTER.id) {
      window.kuberaOpen(websiteUrls.HELP_CENTER);
    }
  }

  getDashboardMinWidth() {
    const pathName = window.location.pathname;
    if (pathName.includes(routes.NET_WORTH)) {
      return 930;
    }
    return 640;
  }

  getDashboardSidePadding() {
    const pathName = window.location.pathname;
    if (pathName.includes(routes.NET_WORTH)) {
      return 5;
    }
    return 20;
  }

  render() {
    if (this.showPWAInstallBannerOnSignIn) {
      return <MobilePWAInstallPopup />;
    }

    window.renderlog("Render: DashboardComponent");

    const portfolios = this.props.portfolios;
    const isInitialLoad =
      (portfolios === null || portfolios.length === 0) &&
      (!this.props.fetchPortfolioError === true ||
        this.props.fetchPortfolioError.errorCode !== apiErrorCodes.FETCH_PORTFOLIOS);
    const currentPortfolio = this.props.currentPortfolio;
    const isRefreshing = this.props.showRefreshing;
    const modalComponent = this.getModalComponent();
    const isSyncQueueStuck = this.props.isSyncQueueStuck;
    const dashboardMinWidth = this.getDashboardMinWidth();
    const isPaywallToBeShown =
      this.props.user &&
      (!this.props.accountSubscriptionIsActive || (this.isPaywallModalShown.current && !currentPortfolio));

    if (this.props.isAppInMaintenanceMode) {
      return <MaintenanceModeComponent />;
    }

    if (this.props.showBreakingChanges) {
      return (
        <ConfirmationDialog
          canUserDismiss={false}
          title={i18n.t("breakingchanges.title")}
          description={i18n.t("breakingchanges.description")}
          positiveButtonTitle={i18n.t("update")}
          handlePositiveButtonClick={e => window.location.reload()}
        />
      );
    }

    if (this.props.isAccountSuspended) {
      return (
        <InitialLoader
          errorMessage={i18n.t("accountSuspendedError")}
          overrideGenericError={true}
          disableRefresh={true}
        />
      );
    }

    if (this.props.isAmcUser && !this.props.wlClientContext === true) {
      return <Redirect to={{ ...this.props.location, pathname: routes.WL_DASHBOARD }} />;
    }

    if (isInitialLoad === true && this.props.fetchPortfolioError) {
      const error = this.props.fetchPortfolioError;
      return <InitialLoader errorMessage={error.errorMessage} onRefresh={this.handleLoaderRefreshClick} />;
    }

    if (isInitialLoad === true) {
      if (this.props.isSharedPortfolioAccountUser && this.props.portfolios && this.props.portfolios.length === 0) {
        return <BlockerPaywall forceShowPaywall={true} />;
      } else {
        return <InitialLoader throwExceptionIfTimeout />;
      }
    }

    if (this.isSubscriptionRequired) {
      return isMobileDevice ? (
        <>
          <MobileHeader isInViewMode={isInViewMode} />
          <EmptyPortfolio user={this.props.user} />
          <SyncComponent store={store} />
        </>
      ) : (
        <>
          <AccountInitialSubscription />
          <SyncComponent store={store} />
        </>
      );
    }

    if (!isPaywallToBeShown) {
      if (this.showMobileEmptyPortfolioScreen) {
        return (
          <>
            <MobileHeader isInViewMode={isInViewMode} />
            <EmptyPortfolio user={this.props.user} />
            <SyncComponent store={store} />
          </>
        );
      }
      if (this.showMobileHeadsUp) {
        return (
          <>
            <MobileHeader isInViewMode={isInViewMode} />
            <HeadsUpComponent user={this.props.user} />
            <SyncComponent store={store} />
          </>
        );
      }

      if (this.props.whiteLabelUserInfo?.type === userTypes.ADMIN && !this.props.wlClientContext) {
        return <Redirect to={{ ...this.props.location, pathname: routes.WL_DASHBOARD }} />;
      }

      const urlPortfolioId = getQueryParams(this.props.location)[queryParams.PORTFOLIO_ID];
      if (urlPortfolioId === undefined) {
        var search = this.props.location.search;
        search =
          search + (!search === false ? "&" : "") + `${queryParams.PORTFOLIO_ID}=${this.props.currentPortfolio.id}`;
        return <Redirect to={{ ...this.props.location, search: search }} />;
      }

      if (
        !this.props.wlClientContext === false &&
        this.props.wlClientContext.collaborateDocument === 0 &&
        this.props.location.pathname === routes.SAFE_DEPOSIT_BOX
      ) {
        return <Redirect to={{ ...this.props.location, pathname: routes.ASSETS }} />;
      }

      if (this.props.siteConfig.showPlanning === 0 && this.props.location.pathname === routes.PLANNING) {
        return <Redirect to={{ ...this.props.location, pathname: routes.ASSETS }} />;
      }

      if (this.props.siteConfig.showPlugin === 0 && this.props.location.pathname === routes.PLUGIN) {
        return <Redirect to={{ ...this.props.location, pathname: routes.ASSETS }} />;
      }
    }

    return (
      <>
        {isMobileDevice ? (
          <MobileContainer className="mobile-dashboard-content">
            <SyncComponent store={store} />
            <ToastComponent />
            {!isInViewMode && <Anniversary />}
            {isPaywallToBeShown ? (
              <AccountCancelledSubscription onMount={this.setIsPaywallModalShown} />
            ) : (
              <>
                <MobileHeader isInViewMode={isInViewMode} />
                <Routes>
                  <Route
                    path={routes.MOBILENAV}
                    element={<MobileNavComponent direction={this.props.currentSlideDirection} />}
                  />
                  <Route
                    path={routes.ASSETS}
                    element={
                      <AssetsComponent
                        portfolio={this.props.currentPortfolio}
                        direction={this.props.currentSlideDirection}
                        isRefreshing={isRefreshing}
                      />
                    }
                  />
                  <Route
                    path={routes.DEBTS}
                    element={
                      <DebtsComponent
                        portfolio={this.props.currentPortfolio}
                        direction={this.props.currentSlideDirection}
                        isRefreshing={isRefreshing}
                      />
                    }
                  />
                  <Route
                    path={routes.NET_WORTH}
                    element={<NetWorthComponent direction={this.props.currentSlideDirection} />}
                  />
                  <Route path={routes.INSURANCE} element={<Redirect to={routes.ASSETS} />} />
                  <Route path={routes.SAFE_DEPOSIT_BOX} element={<Redirect to={routes.ASSETS} />} />
                  <Route path={routes.BENEFICIARY} element={<Redirect to={routes.ASSETS} />} />
                </Routes>
                <SubscriptionBanner />
              </>
            )}
            {modalComponent}
            {this.props.isPageReloading && (
              <Refresher>
                <SpinIt darkTheme={true} />
              </Refresher>
            )}
          </MobileContainer>
        ) : (
          <Container>
            <SyncComponent store={store} />
            <ToastComponent />
            {!isInViewMode && <Anniversary />}
            {isPaywallToBeShown && <AccountCancelledSubscription onMount={this.setIsPaywallModalShown} />}
            <LinkAccountProviderPageListener />
            {!isPaywallToBeShown && <Sidebar portfolio={currentPortfolio} />}

            <DashboardContainer allowScroll={isRefreshing === false} className="dashboard-container">
              {!isPaywallToBeShown && currentPortfolio != null && <DashboardHeader />}
              {!isPaywallToBeShown && currentPortfolio !== null && (
                <DashboardContentContainer minWidth={dashboardMinWidth + 110}>
                  <DashboardContent className="dashboard-content" sidePadding={this.getDashboardSidePadding()}>
                    <Dashboard minWidth={dashboardMinWidth}>
                      {isRefreshing && (
                        <Refresher>
                          <SpinIt darkTheme={true} />
                        </Refresher>
                      )}
                      <Routes>
                        <Route path={routes.ASSETS} element={<AssetsComponent />} />
                        <Route path={routes.DEBTS} element={<DebtsComponent />} />
                        <Route path={routes.INSURANCE} element={<InsuranceComponent />} />
                        <Route path={routes.REPORTS} element={<RecapComponent />} />
                        <Route path={routes.SAFE_DEPOSIT_BOX} element={<SafeDepositBoxComponent />} />
                        <Route path={routes.BENEFICIARY} element={<BeneficiaryComponent />} />
                        <Route path={routes.NET_WORTH} element={<NetWorthComponent />} />
                        <Route path={routes.PLANNING} element={<PlanningComponent />} />
                        <Route path={routes.PLUGIN} element={<PluginWrapper />} />
                      </Routes>
                    </Dashboard>
                  </DashboardContent>
                </DashboardContentContainer>
              )}
            </DashboardContainer>
            {modalComponent}
            {isInViewMode === false && <HelpMenuButton onClick={this.handleHelpMenuButtonClick}>{"?"}</HelpMenuButton>}
            <ContextMenu ref={this.helpContextMenuRef} width={222} onSelection={this.handleContextMenuSelection} />

            {!isPaywallToBeShown && isSyncQueueStuck === true && (
              <GenericMessageDialog
                canUserDismiss={false}
                title={i18n.t("syncingStuckDialog.title")}
                description={i18n.t("syncingStuckDialog.description")}
                positiveButtonTitle={i18n.t("refresh")}
                onPositiveButtonClick={() => {
                  window.location.reload();
                }}
              />
            )}
            {!isPaywallToBeShown && <SubscriptionBanner />}
          </Container>
        )}
        <BlackPaywalls />
      </>
    );
  }
}

const mapStateToProps = state => ({
  fetchPortfolioPending: fetchPortfolioPendingSelector(state),
  fetchPortfolioChangeDataSuccess: fetchPortfolioChangeDataSuccessSelector(state),
  fetchPortfolioError: fetchPortfolioErrorSelector(state),
  portfolios: portfoliosSelector(state),
  showRefreshing: showRefreshingSelector(state),
  currentPortfolio: currentPortfolioSelector(state),
  userPreferences: userPreferencesSelector(state),
  user: userSelector(state),
  hasAccountAddedAnyCard: hasAccountAddedAnyCardSelector(state),
  accountSubscriptionIsSubscribed: accountSubscriptionIsSubscribedSelector(state),
  isSyncQueueStuck: isSyncQueueStuckSelector(state),
  siteConfig: siteConfigSelector(state),
  wlClientContext: wlClientContextSelector(state),
  whiteLabelUserInfo: whiteLabelUserInfoSelector(state),
  accountPlan: accountPlanSelector(state),
  accountIsFreshUser: accountIsFreshUserSelector(state),
  overrideInitialCCSetup: overrideInitialCCSetupSelector(state),
  isPageReloading: pageReloadingSelector(state),
  currentSlideDirection: currentSlideDirectionSelector(state),
  isInternalUser: isInternalUserSelector(state),
  accountTrialStartTs: accountTrialStartTsSelector(state),
  isAccountSuspended: isAccountSuspendedSelector(state),
  isAppInMaintenanceMode: appMaintenanceModeSelector(state),
  redirectPostSignIn: redirectPostSignInSelector(state),
  accountIsSubscriptionIsRequired: accountIsSubscriptionIsRequiredSelector(state),
  isPortfolioReady: isPortfolioReadySelector(state),
  accountSubscriptionIsActive: accountSubscriptionIsActiveSelector(state),
  isSharedPortfolioAccountUser: isSharedPortfolioUserSelector(state),
  isAmcUser: isAmcUser(state),
  showBreakingChanges: showBreakingChangesSelector(state)
});

const mapDispatchToProps = {
  fetchPortfolios: fetchPortfolios,
  updateCurrentlySelectedPortfolio: updateCurrentPortfolioAction,
  updateUserPreferences: updateUserPreferences,
  prefetchPopularProviders: prefetchPopularProviders,
  refreshAllConnectedCustodiansForPortfolio: refreshAllConnectedCustodiansForPortfolio,
  fetchNetWorthData: fetchNetWorthDataForPortfolio,
  showAppUpdateToastIfNeeded: showAppUpdateToastIfNeeded,
  getUpdatedIrr: getUpdatedIrr,
  fetchPortfolioChangeData: fetchPortfolioChangeData,
  setSignInRedirectPathAction: setSignInRedirectPathAction,
  insertCustodianAtEndOfSection: insertCustodianAtEndOfSection,
  getMultiuserList: getMultiuserList,
  getConnectivityCenterData: getConnectivityCenterData,
  setWlClientContext: setWlClientContext
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardComponent));
