import { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  accountPlanSelector,
  accountSubscriptionStatusSelector,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS
} from "@kubera/common";

const useOptionsRadioButtonSelectionHandler = handleRadioButtonSelection => {
  const accountPlan = useSelector(accountPlanSelector);
  const accountSubscriptionStatus = useSelector(accountSubscriptionStatusSelector);

  useEffect(() => {
    if (accountSubscriptionStatus === SUBSCRIPTION_STATUS.CANCELED) {
      if ([SUBSCRIPTION_PLANS.YEARLY, SUBSCRIPTION_PLANS.YEARLY_BLACK].includes(accountPlan)) {
        handleRadioButtonSelection(accountPlan);
      } else {
        handleRadioButtonSelection(SUBSCRIPTION_PLANS.YEARLY);
      }
    } else if (accountPlan === SUBSCRIPTION_PLANS.YEARLY_BLACK) {
      handleRadioButtonSelection(SUBSCRIPTION_PLANS.YEARLY);
    } else {
      handleRadioButtonSelection(SUBSCRIPTION_PLANS.YEARLY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountPlan]);
};

export default useOptionsRadioButtonSelectionHandler;
