import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import i18n from "i18next";
import {
  getTickerUsingId,
  getUuid,
  tickerTypes,
  fetchTickerDetails,
  showToastTip,
  getSanitizedExchangeRate,
  formatNumberAsCurrency
} from "@kubera/common";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  CurrencyCellData,
  cellType
} from "components/grid/GridDataModel";
import ExchangeRateChangeDialog from "components/grid/ExchangeRateChangeDialog";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const GridContainer = styled.div`
  border-top: 0;
`;

const Grid = styled(GridComponentWrapper)`
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
`;

const ExchangeRateCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  padding: 3px 5px 3px 5px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 11px;
  font-feature-settings: "ss01" on;
  color: rgba(36, 36, 36, 0.6);
  cursor: pointer;
`;

const ExchangeCurrencies = styled.div``;

const ExchangeRate = styled.div``;

class CurrencyInput extends React.Component {
  constructor(props) {
    super(props);

    this.getEmptyRow = this.getEmptyRow.bind(this);

    this.state = {
      gridData: this.getGridData(props.portfolioCurrency),
      showRateCellDialog: false
    };

    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.handleCellInvalidTickerAdded = this.handleCellInvalidTickerAdded.bind(this);
    this.handleRateCellClick = this.handleRateCellClick.bind(this);
    this.handleRateCellDialogOnChange = this.handleRateCellDialogOnChange.bind(this);
    this.handleRateCellDialogOnDismiss = this.handleRateCellDialogOnDismiss.bind(this);
  }

  componentDidUpdate(oldProps) {
    if (this.state.gridData.currency !== this.props.portfolioCurrency || oldProps.value !== this.props.value) {
      this.setState({ gridData: this.getGridData(this.props.portfolioCurrency) });
    }
  }

  handleChange(newGridData) {
    this.setState({ ...this.state, gridData: newGridData });
  }

  getGridData(currency) {
    var rows = [];

    const row = this.getEmptyRow("1");
    row.onUpdateDelay = 3000;
    row.id = getUuid();
    row.cells[0].value = this.props.value;

    if (this.props.tickerId) {
      row.cells[0].currency = getTickerUsingId(this.props.tickerId).shortName;
    }
    if (this.props.exchangeRate) {
      row.cells[0].exchangeRateDetails = this.props.exchangeRate;
    }

    rows.push(row);

    const section = this.getEmptySection(0, "1");
    section.rows = rows;

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;
    gridData.autoFocusFirstCell = !this.props.autoFocus === false;
    return gridData;
  }

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getEmptySection(forIndex, sortKey) {
    const currencyCellColumn = new GridColumnData(this.props.placeholder, true, !this.props.isReadOnly === true, false);
    const sectionData = new GridSectionData(
      getUuid(),
      sortKey,
      "Section " + (forIndex + 1),
      [],
      [currencyCellColumn],
      0,
      0,
      false
    );
    sectionData.showFooter = false;
    sectionData.showHeader = false;
    return sectionData;
  }

  getEmptyRow(sortKey) {
    const currencyCell = new CurrencyCellData(
      cellType.CURRENCY,
      this.props.placeholder,
      null,
      this.props.portfolioCurrency
    );
    currencyCell.isInSingleCellMode = this.props.isInSingleCellMode;
    currencyCell.enterEditModeOnClick = true;
    currencyCell.hideCellFocusHighlight = true;
    currencyCell.useRateFromExchangeRateDetails = true;
    currencyCell.supportedTickerTypes = this.props.supportedTickerTypes || [
      tickerTypes.FIAT,
      tickerTypes.CRYPTO,
      tickerTypes.STOCK,
      tickerTypes.FUND,
      tickerTypes.BOND,
      tickerTypes.DERIVATIVE,
      tickerTypes.INDEX
    ];
    currencyCell.textAlignment = "left";
    currencyCell.isCurrencyInput = true;
    if (!this.props.isInSingleCellMode) {
      currencyCell.width = this.props.width;
    }

    currencyCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const currencyGridCell = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0];

      if (this.props.portfolioCurrency === currencyGridCell.currency || this.props.hideExchangeRateBubble === true) {
        return null;
      }

      const cellData = {
        exchangeRateDate: new Date(),
        fromCurrency: currencyGridCell.currency,
        toCurrency: this.props.portfolioCurrency,
        rate: currencyGridCell.getCellExchangeRate(this.props.portfolioCurrency),
        sanitizedRate: getSanitizedExchangeRate(
          currencyGridCell.getCellExchangeRate(this.props.portfolioCurrency),
          this.props.portfolioCurrency
        ),
        sheetIndex: sheetIndex,
        sectionIndex: sectionIndex,
        rowIndex: rowIndex,
        cellIndex: 0
      };

      return (
        <ExchangeRateCell onClick={e => this.handleRateCellClick(e, cellData)}>
          <ExchangeCurrencies>{`${cellData.fromCurrency.replace(".CC", "")}/${cellData.toCurrency.replace(
            ".CC",
            ""
          )}`}</ExchangeCurrencies>
          <ExchangeRate>{formatNumberAsCurrency(cellData.sanitizedRate, this.props.portfolioCurrency)}</ExchangeRate>
        </ExchangeRateCell>
      );
    };

    const cells = [currencyCell];
    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 0, false, () => {
      return true;
    });
    rowData.showHint = false;
    rowData.getContextMenuItems = (row, rowIndex) => {
      return null;
    };
    return rowData;
  }

  handleRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) {
    this.handleUpdate(updatedRow);
  }

  updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex) {
    const newGridData = this.state.gridData;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = newRow;
    this.setState({ gridData: newGridData });
  }

  handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];

    if (!cell.invalidInputText === false && cell.loading === false) {
      cell.loading = true;
      this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);

      this.props.fetchTickerDetails(
        cell.invalidInputText,
        new Date(),
        result => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];

          cell.loading = false;
          if (!result === true) {
            this.props.showToastTip("TIP", i18n.t("invalidTickerError"), null, 10000);
            this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
            return;
          }

          cell.exchangeRateDetails = result.exchangeRateDetails;
          cell.currency = result.tickerShortName;
          cell.invalidInputText = null;

          const newRow = row.clone();
          this.updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex);
          this.handleRowUpdate(sheetIndex, sectionIndex, rowIndex, newRow, false);
        },
        error => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];
          cell.loading = false;
          this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
          this.props.showToastTip("TIP", i18n.t("tickerFetchFailure"), null, 10000);
        }
      );
    }
  }

  handleRateCellClick(e, cellData) {
    if (!this.props.isReadOnly === false) {
      return;
    }
    this.setState({ showRateCellDialog: true, rateCellDialogData: cellData });
  }

  handleRateCellDialogOnDismiss() {
    this.setState({ showRateCellDialog: false, rateCellDialogData: null });
  }

  handleRateCellDialogOnChange(rate) {
    const rateData = this.state.rateCellDialogData;
    const newGridData = this.state.gridData;

    const row = newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex];
    const cell = row.cells[rateData.cellIndex];
    cell.setExchangeRateDetails(this.props.portfolioCurrency, rate);
    newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex] = row.clone();

    this.setState({ gridData: newGridData });
    this.handleUpdate(row);
  }

  handleUpdate(updatedRow) {
    this.props.onChange({
      value: updatedRow.cells[0].value,
      tickerId: updatedRow.cells[0].getTickerId(),
      exchangeRate: updatedRow.cells[0].exchangeRateDetails
    });
  }

  getGridOptions() {
    if (!this.props.inputStyle === true) {
      return undefined;
    }

    return {
      getRowStyle: (row, rowIndex, sectionIndex) => {
        return this.props.inputStyle;
      },
      getSectionStyle: (section, isLastSection, totalSections) => {
        return { border: 0 };
      }
    };
  }

  render() {
    const { className } = this.props;

    return (
      <Container className={className}>
        <GridContainer>
          <Grid
            gridData={this.state.gridData}
            getEmptyRow={this.getEmptyRow}
            onChange={this.handleChange.bind(this)}
            onRowUpdate={this.handleRowUpdate}
            gridOptions={this.getGridOptions()}
            onCellInvalidTickerAdded={this.handleCellInvalidTickerAdded}
          />
        </GridContainer>
        {!this.state.showRateCellDialog === false && (
          <ExchangeRateChangeDialog
            rateData={this.state.rateCellDialogData}
            onRateChange={this.handleRateCellDialogOnChange}
            onDismiss={this.handleRateCellDialogOnDismiss}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
  fetchTickerDetails: fetchTickerDetails,
  showToastTip: showToastTip
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrencyInput);
