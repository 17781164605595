import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  cellType
} from "components/grid/GridDataModel";
import {
  getUuid,
  convertCustodianHistoryApiDateFormatToUIFormat,
  guessDateInCustodianHistoryUIFormat,
  getTickerUsingId,
  tickerTypes,
  showToastTip,
  getSortKeyBetween,
  fetchTickerDetails,
  updateCustodianCashflow,
  deleteCustodianCashflow,
  getSanitizedExchangeRate,
  deleteAllCustodianCashflows,
  updateCustodianIrrType,
  formatNumberAsCurrency,
  getDateStringForExchangeRateDate,
  updateUserPreferences,
  userPreferencesSelector,
  ApiClient,
  getExchangeRateDetails
} from "@kubera/common";
import { connect } from "react-redux";
import ContextMenu, { contextMenuItemType } from "components/contextmenu/ContextMenu";
import ExchangeRateChangeDialog from "components/grid/ExchangeRateChangeDialog";
import optionsIcon from "assets/images/options.svg";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import ToolTip, { toolTipAlignment } from "components/tooltip/ToolTip";
import { downloadFile } from "utilities/FileUtils";

const GridBatchSize = 10;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const GridHeaderContainer = styled.div`
  display: flex;
  margin-top: 20px;
  height: 34px;
  align-items: center;
  postion: relative;
`;

const GridHeaderLabel = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ss01" on, "calt" off;
  color: ${props => (props.isError === true ? "red" : "black")};
`;

const OptionsContainer = styled.div`
  display: flex;
  position: relative;
`;

const OptionsButton = styled.div`
  text-align: center;
  width: 34px;
  height: 34px;
  outline: 0;
  padding: 0;
  border: 0;
  margin: 0;
  margin-left: 2px;
  margin-bottom: 18px;
  margin-right: -5px;
  cursor: pointer;
  background-color: transparent;
  background-image: url(${optionsIcon});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 14px 16px;
`;

const GridContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;
`;

const Grid = styled(GridComponentWrapper)`
  flex: 1;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
`;

const ExchangeRateCellsContainer = styled.div`
  display: flex;
  position: relative;
`;

const ExchangeRateCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  padding: 3px 5px 3px 5px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 11px;
  font-feature-settings: "ss01" on;
  color: rgba(36, 36, 36, 0.6);
  cursor: pointer;
`;

const ExchangeCurrencies = styled.div``;

const ExchangeRate = styled.div``;

const OpeningBalanceCell = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  margin: 5px;
  padding: 10px 17px 10px 17px;
  background: #f2f2f2;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 11px;
  font-feature-settings: "ss01" on;
  color: rgba(36, 36, 36, 0.6);
`;

const OpeningBalanceCopy = styled.div``;

const OpeningBalanceButton = styled.span`
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
`;

class CustodianAssetCashflowComponent extends React.Component {
  constructor(props) {
    super(props);

    this.getEmptyRow = this.getEmptyRow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.handleAddNewRow = this.handleAddNewRow.bind(this);
    this.handleCellBlur = this.handleCellBlur.bind(this);
    this.handleCellInvalidTickerAdded = this.handleCellInvalidTickerAdded.bind(this);
    this.handleRowContextMenuSelection = this.handleRowContextMenuSelection.bind(this);
    this.handleRateCellClick = this.handleRateCellClick.bind(this);
    this.handleRateCellDialogOnDismiss = this.handleRateCellDialogOnDismiss.bind(this);
    this.handleRateCellDialogOnChange = this.handleRateCellDialogOnChange.bind(this);
    this.handleOpeningBalancePositiveButtonClick = this.handleOpeningBalancePositiveButtonClick.bind(this);
    this.handleOpeningBalanceNegativeButtonClick = this.handleOpeningBalanceNegativeButtonClick.bind(this);
    this.handleOptionsClick = this.handleOptionsClick.bind(this);
    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleDeleteAllDialogNegativeButtonClick = this.handleDeleteAllDialogNegativeButtonClick.bind(this);
    this.handleDeleteAllDialogPositiveButtonClick = this.handleDeleteAllDialogPositiveButtonClick.bind(this);
    this.downloadToolTip = React.createRef();

    props.cashflows.sort((a, b) => a.date.localeCompare(b.date));

    const gridData = this.getGridData(props.currency, props.cashflows);
    this.state = {
      gridData: gridData,
      showRateCellDialog: false,
      rateCellDialogData: null,
      canShowOpeningBalanceCell: false,
      invalidCashflowError: null,
      invalidCashflowRowIds: []
    };

    this.totalCashIn = null;
    this.totalCashOut = null;

    this.contextMenuRef = React.createRef();

    this.checkForCashflowTotalUpdate(gridData);
    setTimeout(() => {
      this.validateGridCashflows(gridData);
    }, 0);

    this.exchangeRateTipRef = React.createRef();
    this.showExchangeRateToolTipIfNeeded();
  }

  componentDidUpdate(oldProps) {
    if (
      this.state.gridData.currency !== this.props.currency ||
      (this.props.cashflows.length === 0 && oldProps.cashflows.length !== 0)
    ) {
      const gridData = this.getGridData(this.props.currency, this.props.cashflows);
      this.setState({ gridData: gridData, canShowOpeningBalanceCell: false });
      this.checkForCashflowTotalUpdate(gridData);
    }

    this.showExchangeRateToolTipIfNeeded();

    if (this.state.gridData.isEmpty() === false) {
      if (this.downloadToolTip.current && this.props.userPreferences.cashflowDownloadToolTipShown === false) {
        this.downloadToolTip.current.show(i18n.t("downloadCashflowTip"), 12, -50);
      }
    }

    if (this.state.gridData.isEmpty() === true) {
      if (this.downloadToolTip.current && this.downloadToolTip.current.isVisible() === true) {
        this.downloadToolTip.current.dismiss();
      }
    }
  }

  getGridData(currency, cashflows) {
    var rows = [];
    let nextSortKey = null;
    for (const [, cashflowEntry] of cashflows.entries()) {
      nextSortKey = getSortKeyBetween(nextSortKey, null);
      const row = this.getEmptyRow(nextSortKey);
      row.id = cashflowEntry.id;
      row.tsModified = cashflowEntry.tsModified;

      row.cells[0].value = convertCustodianHistoryApiDateFormatToUIFormat(cashflowEntry.date);

      row.cells[1].value = cashflowEntry.note;

      row.cells[2].value = cashflowEntry.cashIn;
      if (cashflowEntry.cashInTickerId) {
        row.cells[2].currency = getTickerUsingId(cashflowEntry.cashInTickerId).shortName;
      }
      if (cashflowEntry.cashInExchangeRate) {
        row.cells[2].exchangeRateDetails = cashflowEntry.cashInExchangeRate;
      }
      row.cells[2].exchangeRateDate = new Date(cashflowEntry.date);

      row.cells[3].value = cashflowEntry.cashOut;
      if (cashflowEntry.cashOutTickerId) {
        row.cells[3].currency = getTickerUsingId(cashflowEntry.cashOutTickerId).shortName;
      }
      if (cashflowEntry.cashOutExchangeRate) {
        row.cells[3].exchangeRateDetails = cashflowEntry.cashOutExchangeRate;
      }
      row.cells[3].exchangeRateDate = new Date(cashflowEntry.date);

      rows.push(row);
    }

    rows.push(this.getEmptyRow(`${rows.length + 1}`));

    const section = this.getEmptySection(0, "1");
    section.rows = rows;
    section.onShowPreviousClick = (e, firstVisibleIndex) => {
      const newGridData = this.state.gridData;
      const section = newGridData.sheets[0].sections[0];
      const newVisibleIndex = firstVisibleIndex - GridBatchSize;
      section.hideRowsBeforeRowId = newVisibleIndex <= 0 ? null : section.rows[newVisibleIndex].id;
      this.setState({ gridData: newGridData });
    };

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;

    if (!this.state === true || !this.state.gridData === true) {
      const sectionData = gridData.sheets[0].sections[0];
      if (sectionData.rows.length > GridBatchSize) {
        sectionData.hideRowsBeforeRowId = sectionData.rows[sectionData.rows.length - GridBatchSize].id;
      }
    } else {
      gridData.sheets[0].sections[0].hideRowsBeforeRowId = this.state.gridData.sheets[0].sections[0].hideRowsBeforeRowId;
    }

    return gridData;
  }

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getEmptySection(forIndex, sortKey) {
    const dateColumn = new GridColumnData(
      i18n.t("gridEntry.datePlaceholder"),
      true,
      !this.props.isReadOnly === true,
      false
    );
    const noteColumn = new GridColumnData("", true, !this.props.isReadOnly === true, false);
    const cashInColumn = new GridColumnData(i18n.t("cashIn"), true, !this.props.isReadOnly === true, false);
    const cashOutColumn = new GridColumnData(i18n.t("cashOut"), true, !this.props.isReadOnly === true, false);
    const optionsColumn = new GridColumnData(null, true, false, true);
    const columns = [dateColumn, noteColumn, cashInColumn, cashOutColumn, optionsColumn];

    if (this.props.isReadOnly === true) {
      columns.pop();
    }

    const sectionData = new GridSectionData(
      getUuid(),
      sortKey,
      "Section " + (forIndex + 1),
      [],
      columns,
      undefined,
      3,
      false
    );
    sectionData.showFooter = false;
    return sectionData;
  }

  getEmptyRow(sortKey) {
    const dateCell = new GridCellData(cellType.TEXT, i18n.t("gridEntry.datePlaceholder"), null);
    dateCell.textAlignment = "left";
    const noteCell = new GridCellData(cellType.TEXT, i18n.t("gridEntry.notesPlaceholder"), null);
    noteCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      if (rowIndex === 0 && this.state.canShowOpeningBalanceCell === true) {
        return (
          <OpeningBalanceCell>
            <OpeningBalanceCopy>{i18n.t("openingBalanceCell")}</OpeningBalanceCopy>
            <OpeningBalanceButton onClick={e => this.handleOpeningBalancePositiveButtonClick(e, rowIndex)}>
              {i18n.t("yes")}
            </OpeningBalanceButton>
            <OpeningBalanceButton onClick={e => this.handleOpeningBalanceNegativeButtonClick(e, rowIndex)}>
              {i18n.t("no")}
            </OpeningBalanceButton>
          </OpeningBalanceCell>
        );
      }

      const cashInCell = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[2];
      const cashOutCell = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[3];

      var rateCells = [];
      if (
        this.props.currency !== cashInCell.currency &&
        !cashInCell.exchangeRateDate === false &&
        cashInCell.loading === false
      ) {
        rateCells.push({
          exchangeRateDate: cashInCell.exchangeRateDate,
          fromCurrency: cashInCell.currency,
          toCurrency: this.props.currency,
          rate: cashInCell.getCellExchangeRate(this.props.currency),
          sanitizedRate: getSanitizedExchangeRate(
            cashInCell.getCellExchangeRate(this.props.currency),
            this.props.currency
          ),
          sheetIndex: sheetIndex,
          sectionIndex: sectionIndex,
          rowIndex: rowIndex,
          cellIndex: 2,
          cellId: `${rowIndex}-${cellIndex}-ratecell`
        });
      }
      if (
        this.props.currency !== cashOutCell.currency &&
        !cashOutCell.exchangeRateDate === false &&
        cashOutCell.loading === false
      ) {
        rateCells.push({
          exchangeRateDate: cashOutCell.exchangeRateDate,
          fromCurrency: cashOutCell.currency,
          toCurrency: this.props.currency,
          rate: cashOutCell.getCellExchangeRate(this.props.currency),
          sanitizedRate: getSanitizedExchangeRate(
            cashOutCell.getCellExchangeRate(this.props.currency),
            this.props.currency
          ),
          sheetIndex: sheetIndex,
          sectionIndex: sectionIndex,
          rowIndex: rowIndex,
          cellIndex: 3,
          cellId: `${rowIndex}-${cellIndex}-ratecell`
        });
      }

      if (rateCells.length === 0) {
        return null;
      } else if (!this.firstRateCellId === true) {
        this.firstRateCellId = rateCells[0].cellId;
      }

      return (
        <ExchangeRateCellsContainer>
          {rateCells.map((cellData, index) => (
            <ExchangeRateCell key={index} id={cellData.cellId} onClick={e => this.handleRateCellClick(e, cellData)}>
              <ExchangeCurrencies>{`${cellData.fromCurrency.replace(".CC", "")}/${cellData.toCurrency.replace(
                ".CC",
                ""
              )}`}</ExchangeCurrencies>
              <ExchangeRate>{formatNumberAsCurrency(cellData.sanitizedRate, this.props.currency)}</ExchangeRate>
              {this.firstRateCellId === cellData.cellId && (
                <ToolTip ref={this.exchangeRateTipRef} targetId={this.firstRateCellId} align={toolTipAlignment.LEFT} />
              )}
            </ExchangeRateCell>
          ))}
        </ExchangeRateCellsContainer>
      );
    };

    const cashInCell = new CurrencyCellData(
      cellType.CURRENCY,
      i18n.t("gridEntry.amountPlaceholder"),
      null,
      this.props.currency
    );
    cashInCell.useRateFromExchangeRateDetails = true;
    cashInCell.supportedTickerTypes = [tickerTypes.FIAT, tickerTypes.CRYPTO];
    const cashOutCell = new CurrencyCellData(
      cellType.CURRENCY,
      i18n.t("gridEntry.amountPlaceholder"),
      null,
      this.props.currency
    );
    cashOutCell.useRateFromExchangeRateDetails = true;
    cashOutCell.supportedTickerTypes = [tickerTypes.FIAT, tickerTypes.CRYPTO];
    const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
    optionsCell.toolTip = i18n.t("gridCell.optionsButtonToolTip");

    const cells = [dateCell, noteCell, cashInCell, cashOutCell, optionsCell];
    if (this.props.isReadOnly === true) {
      cells.pop();
    }

    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 1, false, () => {
      const date = guessDateInCustodianHistoryUIFormat(cells[0].value);
      const cashIn = cells[2].value;
      const cashOut = cells[3].value;

      if (
        date.isInvalid === false &&
        ((cashIn !== null && cashIn !== undefined) || (cashOut !== null && cashOut !== undefined))
      ) {
        return true;
      }
      return false;
    });
    rowData.showHint = false;
    rowData.getContextMenuItems = (row, rowIndex) => {
      if (!this.props.isReadOnly === false) {
        return null;
      }
      if (this.state.gridData.sheets[0].sections[0].rows.length > 1) {
        return [[contextMenuItemType.INSERT_ABOVE], [contextMenuItemType.DELETE]];
      }
      return [[contextMenuItemType.INSERT_ABOVE]];
    };
    rowData.onUpdateDelay = 5000;
    return rowData;
  }

  handleRateCellClick(e, cellData) {
    if (this.props.isReadOnly === true) {
      return;
    }
    if (this.exchangeRateTipRef.current && this.exchangeRateTipRef.current.isVisible()) {
      this.exchangeRateTipRef.current.dismiss();
    }
    this.setState({ showRateCellDialog: true, rateCellDialogData: cellData });
  }

  handleChange(newGridData) {
    this.setState({ gridData: newGridData, invalidCashflowError: null });
    this.checkForCashflowTotalUpdate(newGridData);
  }

  handleRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) {
    if (updatedRow.isEmpty() === true) {
      updatedRow.tsModified = null;
      this.updateGridRow(updatedRow, sheetIndex, sectionIndex, rowIndex);
      this.props.deleteCashflow(this.props.custodianId, updatedRow.id);
      return;
    }
    if (updatedRow.isComplete() === false) {
      return;
    }
    this.handleCashflowUpdate(rowIndex, updatedRow);

    const rows = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows;
    if (rowIndex === rows.length - 1) {
      const newGridData = this.state.gridData;
      rows.push(this.getEmptyRow(`${rows.length + 1}`));

      this.setState({ gridData: newGridData });
    }
  }

  checkForCashflowTotalUpdate(gridData) {
    const section = gridData.sheets[0].sections[0];
    const latestTotalCashIn = section.getTotalForColumn(2, this.props.currency);
    const latestTotalCashOut = section.getTotalForColumn(3, this.props.currency);

    if (latestTotalCashIn !== this.totalCashIn && this.props.onTotalCashInChange) {
      this.props.onTotalCashInChange(latestTotalCashIn);
    }
    if (latestTotalCashOut !== this.totalCashOut && this.props.onTotalCashOutChange) {
      this.props.onTotalCashOutChange(latestTotalCashOut);
    }

    this.totalCashIn = latestTotalCashIn;
    this.totalCashOut = latestTotalCashOut;
  }

  isDateEntryAlreadyPresent(rowIndex, dateEntry) {
    const guessedDate = guessDateInCustodianHistoryUIFormat(dateEntry);
    if (guessedDate.isInvalid === true) {
      return false;
    }
    const existingRowIndex = this.state.gridData.sheets[0].sections[0].rows.findIndex(
      row => row.cells[0].value === guessedDate.dateString
    );
    return existingRowIndex !== -1 && existingRowIndex !== rowIndex;
  }

  handleCashflowUpdate(rowIndex, updatedRow) {
    if (this.isDateEntryAlreadyPresent(rowIndex, updatedRow.cells[0].value)) {
      return;
    }

    this.validateGridCashflows(this.state.gridData);
    setTimeout(() => {
      if (this.state.invalidCashflowIds.includes(updatedRow.id)) {
        return;
      }

      const guessedDate = guessDateInCustodianHistoryUIFormat(updatedRow.cells[0].value);
      const cashInCell = this.state.gridData.sheets[0].sections[0].rows[rowIndex].cells[2];
      const cashOutCell = this.state.gridData.sheets[0].sections[0].rows[rowIndex].cells[3];
      var dateMismatch = false;

      if (
        cashInCell.value !== null &&
        cashInCell.currency !== this.props.currency &&
        getDateStringForExchangeRateDate(cashInCell.exchangeRateDate) !==
          getDateStringForExchangeRateDate(guessedDate.date)
      ) {
        cashInCell.invalidInputText = cashInCell.currency;
        cashInCell.exchangeRateDate = guessedDate.date;
        this.handleCellInvalidTickerAdded(0, 0, rowIndex, 2);
        dateMismatch = true;
      }

      if (
        cashOutCell.value !== null &&
        cashOutCell.currency !== this.props.currency &&
        getDateStringForExchangeRateDate(cashOutCell.exchangeRateDate) !==
          getDateStringForExchangeRateDate(guessedDate.date)
      ) {
        cashOutCell.invalidInputText = cashOutCell.currency;
        cashOutCell.exchangeRateDate = guessedDate.date;
        this.handleCellInvalidTickerAdded(0, 0, rowIndex, 3);
        dateMismatch = true;
      }

      if (dateMismatch === true) {
        return;
      }

      const isNewCashflowEntry = !updatedRow.tsModified === true;
      const newGridData = this.state.gridData;
      if (isNewCashflowEntry === true) {
        newGridData.sheets[0].sections[0].rows[rowIndex].tsModified = new Date().getTime() / 1000;
      }

      updatedRow.cells[0].invalidInputText = null;

      const updateCashflow = () => {
        this.props.updateCashflow(isNewCashflowEntry, this.props.custodianId, {
          id: updatedRow.id,
          date: guessedDate.dateString,
          note: updatedRow.cells[1].value,
          cashIn: updatedRow.cells[2].value,
          cashInTickerId: updatedRow.cells[2].getTickerId(),
          cashInExchangeRate: updatedRow.cells[2].exchangeRateDetails,
          cashOut: updatedRow.cells[3].value,
          cashOutTickerId: updatedRow.cells[3].getTickerId(),
          cashOutExchangeRate: updatedRow.cells[3].exchangeRateDetails
        });
      };

      updateCashflow();
    }, 0);
  }

  handleRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, menuItem) {
    if (menuItem.id === contextMenuItemType.DELETE.id) {
      if (row.isComplete()) {
        this.props.deleteCashflow(this.props.custodianId, row.id);

        setTimeout(() => {
          this.validateGridCashflows(this.state.gridData);
        }, 0);
      }

      if (this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows.length === 1) {
        this.setState({ canShowOpeningBalanceCell: false });
      }
    }
  }

  handleAddNewRow(sheetIndex, sectionIndex, rowIndex, row) {}

  handleCellBlur(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    if (cellIndex === 0) {
      const cell = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex];
      const cellValue = cell.value;
      if (!cellValue === true) {
        return;
      }

      const guessedDate = guessDateInCustodianHistoryUIFormat(cellValue);
      const newGridData = this.state.gridData;
      var dateString = guessedDate.dateString;

      if (guessedDate.isInvalid === true) {
        this.props.showToastTip("TIP", i18n.t("invalidDateEntry"), null, 3000);
        if (!dateString === true) {
          dateString = cellValue;
        }
        cell.invalidInputText = cellValue;
      } else if (this.isDateEntryAlreadyPresent(rowIndex, guessedDate.dateString)) {
        this.props.showToastTip(
          "TIP",
          i18n.t("cashflow.duplicateDateEntry"),
          null,
          3000,
          i18n.t("gridCell.detailsButtonToolTip"),
          () => {
            window.kuberaOpen("https://help.kubera.com/article/121-enter-multiple-cash-flow-entries-for-the-same-date");
          }
        );
        cell.invalidInputText = cellValue;
      } else {
        cell.invalidInputText = null;
      }

      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0].value = dateString;

      if (!newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[2].value === true) {
        newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[2].exchangeRateDate =
          guessedDate.date;
      }
      if (!newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[3].value === true) {
        newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[3].exchangeRateDate =
          guessedDate.date;
      }

      this.setState({ gridData: newGridData });
    }
  }

  updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex) {
    const newGridData = this.state.gridData;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = newRow;
    this.setState({ gridData: newGridData });
  }

  handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];

    if (!cell.invalidInputText === false && cell.loading === false) {
      cell.loading = true;
      this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
      cell.isCashflowInvalid = true;

      const date = guessDateInCustodianHistoryUIFormat(row.cells[0].value).date;
      this.props.fetchTickerDetails(
        cell.invalidInputText,
        date,
        result => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];

          cell.loading = false;
          cell.tickerInfo = [];
          if (!result === true) {
            // this.props.showToastTip("TIP", i18n.t("invalidCashflowTickerError"), null, 10000);
            this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
            return;
          }

          const validTickers = result.tickers.filter(ticker => ticker.info.type !== "stock");
          if (validTickers.length > 1) {
            cell.tickerInfo = validTickers;
            this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
          } else if (validTickers.length === 1 && result.tickers.length > 1) {
            const ticker = validTickers[0];
            cell.exchangeRateDetails = ticker.rate ? getExchangeRateDetails(ticker.info.id, ticker.rate) : null;
            cell.currency = ticker.info.shortName;
            cell.invalidInputText = null;

            const newRow = row.clone();
            this.updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex);
            this.checkForCashflowTotalUpdate(this.state.gridData);
            this.handleRowUpdate(sheetIndex, sectionIndex, rowIndex, newRow, false);
          } else {
            cell.exchangeRateDetails = result.exchangeRateDetails;
            cell.currency = result.tickerShortName;
            cell.invalidInputText = null;

            const newRow = row.clone();
            this.updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex);
            this.checkForCashflowTotalUpdate(this.state.gridData);
            this.handleRowUpdate(sheetIndex, sectionIndex, rowIndex, newRow, false);
          }
        },
        error => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];
          cell.loading = false;
          this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
          this.props.showToastTip("TIP", i18n.t("tickerFetchFailure"), null, 10000);
        }
      );
    }
  }

  handleRateCellDialogOnDismiss() {
    this.setState({ showRateCellDialog: false, rateCellDialogData: null });
  }

  handleRateCellDialogOnChange(rate) {
    const rateData = this.state.rateCellDialogData;
    const newGridData = this.state.gridData;

    const row = newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex];
    const cell = row.cells[rateData.cellIndex];
    cell.setExchangeRateDetails(this.props.currency, rate);
    newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex] = row.clone();

    this.setState({ gridData: newGridData });
    this.checkForCashflowTotalUpdate(newGridData);

    this.handleCashflowUpdate(rateData.rowIndex, row);
  }

  handleOpeningBalancePositiveButtonClick(e, rowIndex) {
    const newGridData = this.state.gridData;
    const row = newGridData.sheets[0].sections[0].rows[rowIndex];
    const cell = row.cells[1];
    cell.value = i18n.t("openingBalance");
    newGridData.sheets[0].sections[0].rows[rowIndex] = row.clone();

    this.setState({ gridData: newGridData, canShowOpeningBalanceCell: false });
    this.props.onOpeningBalanceTipDismiss();
    this.handleCashflowUpdate(rowIndex, row);
  }

  handleOpeningBalanceNegativeButtonClick(e, rowIndex) {
    const newGridData = this.state.gridData;
    const row = newGridData.sheets[0].sections[0].rows[rowIndex];
    newGridData.sheets[0].sections[0].rows[rowIndex] = row.clone();

    this.setState({ gridData: newGridData, canShowOpeningBalanceCell: false });
    this.props.onOpeningBalanceTipDismiss();
  }

  handleOptionsClick(e) {
    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }

    const targetPosition = e.target.getBoundingClientRect();
    var menuItems = [[contextMenuItemType.DOWNLOAD_CASHFLOW], [contextMenuItemType.CLEAR_CASHFLOW]];

    this.contextMenuRef.current.show(
      menuItems,
      targetPosition.left + targetPosition.width,
      targetPosition.top,
      false,
      e.target
    );

    if (this.downloadToolTip.current.isVisible() === true) {
      this.downloadToolTip.current.dismiss();
      this.props.updateUserPreferences({ cashflowDownloadToolTipShown: true });
    }
  }

  handleContextMenuSelection(item) {
    if (item.id === contextMenuItemType.DOWNLOAD_CASHFLOW.id) {
      ApiClient.getCashFlowDownloadUrl(this.props.sectionId, this.props.custodianId)
        .then(url => {
          downloadFile(url);
        })
        .catch(apiError => {});
    } else if (item.id === contextMenuItemType.CLEAR_CASHFLOW.id) {
      if (this.state.gridData.isEmpty() === false) {
        this.setState({ showDeleteAllDialog: true });
      }
    }
  }

  handleDeleteAllDialogPositiveButtonClick(e) {
    this.props.deleteAllCashflows(this.props.custodianId);
    this.setState({ showDeleteAllDialog: false });
  }

  handleDeleteAllDialogNegativeButtonClick(e) {
    this.setState({ showDeleteAllDialog: false });
  }

  validateGridCashflows(gridData) {
    var invalidCashflowIds = [];
    var cashflowError = null;
    const oldestRow = this.getOldestRow(gridData);

    for (const [index, row] of gridData.sheets[0].sections[0].rows.entries()) {
      row.cells[2].hasError = false;
      row.cells[3].hasError = false;
      gridData.sheets[0].sections[0].rows[index] = row.clone();

      if (!row.cells[2].value === false && row.cells[2].value <= 0) {
        row.cells[2].hasError = true;
        gridData.sheets[0].sections[0].rows[index] = row.clone();
        cashflowError = i18n.t("invalidCashflowError");
        invalidCashflowIds.push(row.id);
      }
      if (!row.cells[3].value === false && row.cells[3].value <= 0) {
        row.cells[3].hasError = true;
        gridData.sheets[0].sections[0].rows[index] = row.clone();
        cashflowError = i18n.t("invalidCashflowError");
        invalidCashflowIds.push(row.id);
      }

      if (oldestRow && oldestRow.id === row.id) {
        if (
          (!row.cells[2].value === false &&
            !row.cells[3].value === false &&
            row.cells[3].getValueInCurrency(this.props.currency) >
              row.cells[2].getValueInCurrency(this.props.currency)) ||
          (!row.cells[3].value === false && !row.cells[2].value === true)
        ) {
          row.cells[3].hasError = true;
          gridData.sheets[0].sections[0].rows[index] = row.clone();
          invalidCashflowIds.push(row.id);

          if (!cashflowError === true) {
            cashflowError = i18n.t("invalidFirstCashflowError");
          }
        }
      }
    }

    this.setState({ gridData: gridData, invalidCashflowError: cashflowError, invalidCashflowIds: invalidCashflowIds });
  }

  getOldestRow(gridData) {
    var oldestRow = null;
    const rows = gridData.sheets[0].sections[0].rows;

    for (const row of rows) {
      row.cells[3].hasError = false;
      if (row.isComplete()) {
        const guessedDate = guessDateInCustodianHistoryUIFormat(row.cells[0].value).date;

        if (oldestRow === null || guessedDate.getTime() < new Date(oldestRow.cells[0].value).getTime()) {
          oldestRow = row;
        }
      }
    }
    return oldestRow;
  }

  showExchangeRateToolTipIfNeeded() {
    if (this.props.isReadOnly) {
      return;
    }

    setTimeout(() => {
      const gridData = this.state.gridData;
      if (!this.exchangeRateTipRef === true || !this.exchangeRateTipRef.current === true || !gridData === true) {
        return;
      }
      if (this.props.userPreferences.cashflowExchangeRateToolTipShown === true) {
        return;
      }
      this.exchangeRateTipRef.current.show(i18n.t("exchangeRateTip"), -5, 0, () => {
        this.props.updateUserPreferences({ cashflowExchangeRateToolTipShown: true });
      });
    }, 500);
  }

  render() {
    const gridData = this.state.gridData;
    const isCashflowInvalid = !this.state.invalidCashflowError === false;
    const isCashflowInDbEmpty = !this.props.cashflows.length;

    return (
      <Container className={this.props.className}>
        <GridHeaderContainer>
          <GridHeaderLabel isError={isCashflowInvalid === true}>
            {isCashflowInvalid === true ? this.state.invalidCashflowError : i18n.t("cashflow")}
          </GridHeaderLabel>
          <OptionsContainer>
            {this.props.isReadOnly === false && isCashflowInDbEmpty === false && (
              <OptionsButton id="cashflow-option" onClick={this.handleOptionsClick} />
            )}
            {this.props.isReadOnly === false && (
              <ToolTip
                ref={this.downloadToolTip}
                targetId={"cashflow-option"}
                align={toolTipAlignment.LEFT}
                arrowOffset={67}
                onPositiveClick={() => {
                  this.downloadToolTip.current && this.downloadToolTip.current.dismiss();
                  this.props.updateUserPreferences({ cashflowDownloadToolTipShown: true });
                }}
              />
            )}
          </OptionsContainer>
        </GridHeaderContainer>
        <GridContainer>
          <Grid
            title={i18n.t("assetsComponent.title")}
            gridData={gridData}
            getEmptyRow={this.getEmptyRow}
            onChange={this.handleChange}
            onRowUpdate={this.handleRowUpdate}
            onAddNewRow={this.handleAddNewRow}
            onPaste={this.handleCellBlur}
            onCellBlur={this.handleCellBlur}
            onCellInvalidTickerAdded={this.handleCellInvalidTickerAdded}
            onRowContextMenuSelection={this.handleRowContextMenuSelection}
          />
        </GridContainer>
        {!this.state.showRateCellDialog === false && (
          <ExchangeRateChangeDialog
            rateData={this.state.rateCellDialogData}
            onRateChange={this.handleRateCellDialogOnChange}
            onDismiss={this.handleRateCellDialogOnDismiss}
          />
        )}
        <ContextMenu ref={this.contextMenuRef} width={233} onSelection={this.handleContextMenuSelection} />
        {this.state.showDeleteAllDialog === true && (
          <ConfirmationDialog
            canUserDismiss={true}
            title={i18n.t("deleteAllCashflowsDialog.title")}
            description={i18n.t("deleteAllCashflowsDialog.description")}
            positiveButtonTitle={i18n.t("startOver")}
            negativeButtonTitle={i18n.t("cancel")}
            onDismiss={this.handleDeleteAllDialogNegativeButtonClick}
            handlePositiveButtonClick={this.handleDeleteAllDialogPositiveButtonClick}
            handleNegativeButtonClick={this.handleDeleteAllDialogNegativeButtonClick}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  userPreferences: userPreferencesSelector(state)
});

const mapDispatchToProps = {
  showToastTip: showToastTip,
  fetchTickerDetails: fetchTickerDetails,
  updateCashflow: updateCustodianCashflow,
  deleteCashflow: deleteCustodianCashflow,
  deleteAllCashflows: deleteAllCustodianCashflows,
  updateCustodianIrrType: updateCustodianIrrType,
  updateUserPreferences: updateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustodianAssetCashflowComponent);
