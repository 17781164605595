import { ApiClient } from "../../api/ApiClient";
import { getUuid } from "../../utilities/Number";
import { updateTickerDataAction } from "./TickerActions";
import {
  userTypes,
  setSiteConfigAction,
  removeStripeConnectedAccountAction,
  setUserPreferencesAction
} from "./AuthActions";
import { enqueueItem, SyncItem, SyncItemType } from "./SyncActions";
import { resetPortfolioStateAction } from "./PortfolioActions";
import { apiErrorCodes } from "../../api/ApiResponse";
import { store } from "../store";
import {
  wlActiveClientsSelector,
  wlInvitedClientsSelector,
  wlArchivedClientsSelector,
  wlClientContextSelector,
  wlClientEmailSelector,
  wlClientSelector
} from "../reducers/WhiteLabelReducer";

export const FETCH_WL_DASHBOARD_PENDING = "FETCH_WL_DASHBOARD_PENDING";
export const FETCH_WL_DASHBOARD_SUCCESS = "FETCH_WL_DASHBOARD_SUCCESS";
export const FETCH_WL_DASHBOARD_ERROR = "FETCH_WL_DASHBOARD_ERROR";

export const WL_ADD_CLIENT = "WL_ADD_CLIENT";
export const WL_UPDATE_CLIENT = "WL_UPDATE_CLIENT";
export const WL_UPDATE_DASHBOARD_CLIENT = "WL_UPDATE_DASHBOARD_CLIENT";
export const WL_DELETE_CLIENT = "WL_DELETE_CLIENT";

export const WL_ADD_MANAGER = "WL_ADD_MANAGER";
export const WL_UPDATE_MANAGER = "WL_UPDATE_MANAGER";
export const WL_DELETE_MANAGER = "WL_DELETE_MANAGER";

export const WL_ADD_SUBUSER = "WL_ADD_SUBUSER";
export const WL_DELETE_SUBUSER = "WL_DELETE_SUBUSER";

export const WL_SET_CLIENT_CONTEXT = "WL_SET_CLIENT_CONTEXT";

export const WL_SET_ADMIN_LIST_CHARGES = "WL_SET_ADMIN_LIST_CHARGES";

export const wlUserStatus = {
  ADDED: "added",
  INVITED: "invited",
  ACCEPTED: "accepted"
};

export const wlSetupType = {
  ADMIN_WR_CLIENT_WR: "admin_write, client_write",
  ADMIN_WR_CLIENT_RO: "admin_write, client_read"
};

export const fetchWlDashboardPendingAction = isBackgroundRefresh => ({
  type: FETCH_WL_DASHBOARD_PENDING,
  isBackgroundRefresh
});

export const fetchWlDashboardSuccessAction = (dashboard, managers) => ({
  type: FETCH_WL_DASHBOARD_SUCCESS,
  dashboard,
  managers
});

export const fetchWlDashboardErrorAction = error => ({
  type: FETCH_WL_DASHBOARD_ERROR,
  error
});

export const addWlClientAction = client => ({
  type: WL_ADD_CLIENT,
  client
});

export const updateWlClientAction = client => ({
  type: WL_UPDATE_CLIENT,
  client
});

export const updateWlDashboardClientAction = client => ({
  type: WL_UPDATE_DASHBOARD_CLIENT,
  client
});

export const deleteWlClientAction = client => ({
  type: WL_DELETE_CLIENT,
  client
});

export const addWlManagerAction = manager => ({
  type: WL_ADD_MANAGER,
  manager
});

export const updateWlManagerAction = manager => ({
  type: WL_UPDATE_MANAGER,
  manager
});

export const deleteWlManagerAction = manager => ({
  type: WL_DELETE_MANAGER,
  manager
});

export const setWlClientContextAction = client => ({
  type: WL_SET_CLIENT_CONTEXT,
  client
});

export const addWlSubUserAction = (client, subUser) => ({
  type: WL_ADD_SUBUSER,
  client,
  subUser
});

export const deleteWlSubUserAction = (client, subUser) => ({
  type: WL_DELETE_SUBUSER,
  client,
  subUser
});

export const setWlAdminListChargesAction = charges => ({
  type: WL_SET_ADMIN_LIST_CHARGES,
  charges
});

export const fetchWlDashboard = (isBackgroundRefresh = false) => {
  return dispatch => {
    dispatch(fetchWlDashboardPendingAction(isBackgroundRefresh));

    Promise.all([
      ApiClient.getTickerData(getUuid()),
      ApiClient.getWlDashboardMetaInfo(getUuid()),
      ApiClient.getWlManagers(getUuid()),
      ApiClient.getUserPreferences(getUuid())
    ])
      .then(([tickerApiData, dashboardApiData, managersApiData, userPreferencesApiData]) => {
        // Fallback to owner as managerUserId if its not set
        const owner = managersApiData.payload.find(item => item.type === userTypes.OWNER);
        for (const user of dashboardApiData.payload.user) {
          if (!user.managerUserId === true) {
            user.managerUserId = owner.userId;
          } else if (!managersApiData.payload.find(item => item.userId === user.managerUserId) === true) {
            user.managerUserId = owner.userId;
          }
        }

        dispatch(updateTickerDataAction(tickerApiData.payload));
        dispatch(fetchWlDashboardSuccessAction(dashboardApiData.payload, managersApiData.payload));

        if (!userPreferencesApiData === false) {
          dispatch(setUserPreferencesAction(userPreferencesApiData.payload));
        }

        const clientContext = wlClientContextSelector(store.getState());
        if (!clientContext === false) {
          const updatedClient = wlActiveClientsSelector(store.getState()).find(
            client => client.userId === clientContext.userId
          );
          if (!updatedClient === false) {
            dispatch(setWlClientContextAction(updatedClient));
          }
        }

        ApiClient.getWlDashboardUpdatedInfo(getUuid())
          .then(updatedDashboardApiData => {
            dispatch(fetchWlDashboardSuccessAction(updatedDashboardApiData.payload, managersApiData.payload));
          })
          .catch(apiError => {
            dispatch(fetchWlDashboardErrorAction(apiError));
          });
      })
      .catch(apiError => {
        dispatch(fetchWlDashboardErrorAction(apiError));
      });
  };
};

export const inviteWlClient = (name, email, message, inviteClient, onSuccess = null, onError = null) => {
  return dispatch => {
    const client = {
      clientName: name,
      type: userTypes.USER
    };

    if (!email === false) {
      client.email = email.toLowerCase();
    }
    if (!message === false) {
      client.message = message;
    }

    ApiClient.inviteWlUser(getUuid(), client, inviteClient === true ? 1 : 0)
      .then(userApiData => {
        const user = userApiData.payload;
        if (!onSuccess === false) {
          onSuccess(user);
        }
        dispatch(addWlClientAction(user));
      })
      .catch(apiError => {
        if (apiError.errorCode === apiErrorCodes.DUPLICATE_ENTRY) {
          const activeClients = wlActiveClientsSelector(store.getState());
          const invitedClients = wlInvitedClientsSelector(store.getState());
          const archivedClients = wlArchivedClientsSelector(store.getState());

          if (activeClients.findIndex(client => client.email === email) !== -1) {
            apiError.errorMessage = "This client already exists. Please look for them in the 'Active' tab";
          } else if (invitedClients.findIndex(client => client.email === email) !== -1) {
            apiError.errorMessage =
              "A client with this email has already been invited. Go to the 'Invited' tab to invite them again.";
          } else if (archivedClients.findIndex(client => client.email === email) !== -1) {
            apiError.errorMessage =
              "A client with this email has already been archived. Go to the 'Archived' tab to invite them again.";
          }
        } else if (apiError.errorCode === apiErrorCodes.LIMIT_REACHED) {
          apiError.errorMessage =
            "Limit Reached. Please contact <a href='mailto:hello@kubera.com' style='color: #0074fc'>hello@kubera.com</a>";
        }

        if (!onError === false) {
          onError(apiError);
        }
      });
  };
};

export const updateWlClientInvite = (
  name,
  currentEmail,
  email,
  message,
  inviteClient,
  passcode,
  onSuccess,
  onError
) => {
  return dispatch => {
    const client = {
      clientName: name,
      currentEmail: currentEmail.toLowerCase(),
      message: message
    };

    if (!email === false) {
      client.email = email.toLowerCase();
    }

    if (!passcode === false && passcode.trim().length !== 0) {
      client.share = { passcode: passcode };
    }

    ApiClient.updateWlUserInvite(getUuid(), client, inviteClient === true ? 1 : 0)
      .then(userApiData => {
        const user = userApiData.payload;
        if (!onSuccess === false) {
          onSuccess(user);
        }

        const currentUser = wlClientEmailSelector(store.getState(), currentEmail);
        const updatedUser = { ...currentUser, ...user };
        dispatch(updateWlClientAction(updatedUser));
      })
      .catch(apiError => {
        if (!onError === false) {
          onError(apiError);
        }
      });
  };
};

export const archiveWlClient = client => {
  return dispatch => {
    return new Promise(resolve => {
      client.tsArchive = new Date().getTime() / 1000;
      dispatch(updateWlClientAction(client));

      const request = idempotentId => ApiClient.archiveWlUser(idempotentId, client.email);
      const syncItem = new SyncItem(
        SyncItemType.DELETE,
        client.userId,
        request,
        0,
        false,
        () => {
          resolve();
        },
        () => {
          resolve();
        }
      );
      dispatch(enqueueItem(syncItem));
    });
  };
};

export const deleteWlClient = client => {
  return dispatch => {
    dispatch(deleteWlClientAction(client));

    const request = idempotentId => ApiClient.deleteWlUser(idempotentId, client.email);
    const syncItem = new SyncItem(SyncItemType.DELETE, client.userId, request, 0, false, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const reInviteWlClient = client => {
  return dispatch => {
    client.tsArchive = null;
    client.tsReInvite = new Date().getTime() / 1000;
    dispatch(updateWlClientAction(client));

    const request = idempotentId => ApiClient.inviteWlClientAgain(idempotentId, { email: client.email });
    const syncItem = new SyncItem(SyncItemType.UPDATE, client.email, request, 0, false, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const downloadWLClientData = client => {
  return dispatch => {
    const request = idempotentId => ApiClient.downloadWLClientData(idempotentId, { email: client.email });
    const syncItem = new SyncItem(SyncItemType.UPDATE, client.email, request, 0, false, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const addWlManager = (name, email, type, onSuccess = null, onError = null) => {
  return dispatch => {
    const user = {
      name: name,
      email: email,
      type: type
    };

    ApiClient.createWlUser(getUuid(), user)
      .then(userApiData => {
        const manager = userApiData.payload;

        dispatch(addWlManagerAction(manager));
        if (!onSuccess === false) {
          onSuccess(manager);
        }
      })
      .catch(apiError => {
        if (apiError.errorCode === apiErrorCodes.DUPLICATE_ENTRY) {
          const activeClients = wlActiveClientsSelector(store.getState());
          const invitedClients = wlInvitedClientsSelector(store.getState());
          const archivedClients = wlArchivedClientsSelector(store.getState());

          if (
            activeClients.findIndex(client => client.email === email) !== -1 ||
            invitedClients.findIndex(client => client.email === email) !== -1 ||
            archivedClients.findIndex(client => client.email === email) !== -1
          ) {
            apiError.errorMessage = "This email has already been taken up by a client.";
          } else {
            apiError.errorMessage = "A user with this email has already been added for your organization.";
          }
        } else if (apiError.errorCode === apiErrorCodes.LIMIT_REACHED) {
          apiError.errorMessage =
            "Limit Reached. Please contact <a href='mailto:hello@kubera.com' style='color: #0074fc'>hello@kubera.com</a>";
        }

        if (!onError === false) {
          onError(apiError);
        }

        if (!onError === false) {
          onError(apiError);
        }
      });
  };
};

export const deleteWlManager = manager => {
  return dispatch => {
    dispatch(deleteWlManagerAction(manager));

    const request = idempotentId => ApiClient.deleteWlUser(idempotentId, manager.email);
    const syncItem = new SyncItem(SyncItemType.DELETE, manager.userId, request, 0, false, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const updateWlManager = manager => {
  return dispatch => {
    dispatch(updateWlManagerAction(manager));

    const request = idempotentId => ApiClient.updateWlUserRole(idempotentId, manager.email, manager.type);
    const syncItem = new SyncItem(SyncItemType.UPDATE, manager.userId, request, 0, false, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const archiveWlManager = manager => {
  return dispatch => {
    manager.tsArchive = new Date().getTime() / 1000;
    dispatch(updateWlManagerAction(manager));

    const request = idempotentId => ApiClient.archiveWlUser(idempotentId, manager.email);
    const syncItem = new SyncItem(SyncItemType.DELETE, manager.userId, request, 0, false, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const unarchiveWlManager = manager => {
  return dispatch => {
    manager.tsArchive = null;
    dispatch(updateWlManagerAction(manager));

    const request = idempotentId => ApiClient.unarchiveWlUser(idempotentId, manager.email);
    const syncItem = new SyncItem(SyncItemType.DELETE, manager.userId, request, 0, false, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const pingWlClientToUpdatePortfolio = client => {
  return dispatch => {
    const request = idempotentId => ApiClient.pingWlClientToUpdatePortfolio(idempotentId, client.userId);
    const syncItem = new SyncItem(SyncItemType.UPDATE, client.userId, request, 0, false, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const setWlClientContext = client => {
  return dispatch => {
    dispatch(resetPortfolioStateAction());
    dispatch(setWlClientContextAction(client));
  };
};

export const assignManagerToClient = (clientId, managerId) => {
  return dispatch => {
    const client = wlClientSelector(store.getState(), clientId);
    client.managerUserId = managerId;
    dispatch(updateWlClientAction(client));

    const request = idempotentId => ApiClient.setManagerForClient(idempotentId, clientId, managerId);
    const syncItem = new SyncItem(SyncItemType.UPDATE, managerId, request, 0, false, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const createWlSubUser = (client, user) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.createWlSubUser(getUuid(), user)
        .then(userApiData => {
          const user = userApiData.payload;
          dispatch(addWlSubUserAction(client, user));
          resolve(user);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const inviteWlSubUser = (client, user) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.inviteWlSubUser(getUuid(), user)
        .then(userApiData => {
          const user = userApiData.payload;
          dispatch(addWlSubUserAction(client, user));
          resolve(user);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const deleteWlSubUser = (client, user) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.deleteWlSubUser(getUuid(), user)
        .then(() => {
          dispatch(deleteWlSubUserAction(client, user));
          resolve(user);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const connectStripe = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.connectStripe(getUuid())
        .then(data => {
          resolve(data.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const saveConnectedAccount = (authorizationCode, state) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.saveConnectedAccount(getUuid(), {
        authorizationCode,
        state
      })
        .then(data => {
          dispatch(setSiteConfigAction(data.payload));
          resolve(data.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const removeConnectedAccount = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(removeStripeConnectedAccountAction());
      ApiClient.removeConnectedAccount(getUuid())
        .then(data => {
          resolve(data.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const adminChargeClient = ({ userId, amount, description }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.adminChargeClient(getUuid(), {
        id: getUuid(),
        userId,
        amount,
        description
      })
        .then(data => {
          resolve(data.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const adminRemoveChargeClient = (chargeId, userId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.adminRemoveChargeClient(getUuid(), chargeId, userId)
        .then(data => {
          dispatch(getAdminListCharges(userId));
          resolve(data.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const adminScheduleClient = ({ userId, amount, type, status, tsNextDate }) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const client = wlClientSelector(store.getState(), userId);
      ApiClient.adminScheduleClient(getUuid(), {
        userId,
        amount,
        type,
        status,
        tsNextDate
      })
        .then(data => {
          dispatch(
            updateWlClientAction({
              ...client,
              recurringChargeSchedule: data.payload
            })
          );
          resolve(data.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const adminDeleteScheduleClient = userId => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.adminDeleteScheduleClient(getUuid(), userId)
        .then(data => {
          resolve(data.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const getAdminListCharges = listId => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.getAdminListCharges(getUuid(), listId)
        .then(data => {
          dispatch(setWlAdminListChargesAction(data.payload));
          resolve(data.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const getClientListCharges = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.getClientListCharges(getUuid())
        .then(data => {
          dispatch(setWlAdminListChargesAction(data.payload));
          resolve(data.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const adminAddPaymentMethodInStripe = userId => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.adminAddPaymentMethodInStripe(getUuid(), { userId })
        .then(data => {
          resolve(data.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const adminRetryChargeClient = (chargeId, userId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.adminRetryChargeClient(getUuid(), chargeId, userId)
        .then(data => {
          dispatch(getAdminListCharges(userId));
          resolve(data.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const clientRetryChargeClient = chargeId => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.clientRetryChargeClient(getUuid(), chargeId)
        .then(data => {
          resolve(data.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const getClientDashboardData = userId => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.getClientDashboardData(getUuid(), userId)
        .then(data => {
          dispatch(updateWlDashboardClientAction(data.payload));
          resolve(data.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};
