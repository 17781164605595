import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import i18n from "locale/i18n";

import { hashParams, modalValues } from "routes";
import {
  wlClientSelector,
  getHashParams,
  shortFormatNumberWithCurrency,
  wlSiteDefaultCurrencySelector,
  userObjectSelector,
  adminAddPaymentMethodInStripe,
  getClientDashboardData,
  useHistory
} from "@kubera/common";

import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { ReactComponent as Exit } from "assets/images/exit.svg";
import SecondaryButton from "components/button/SecondaryButton";
import { ReactComponent as StripePowered } from "assets/images/stripe_logo.svg";

import AdminStripeSetCard from "./AdminStripeSetCard";
import AdminBillingForm from "./AdminBillingForm";
import PaymentsList from "./PaymentsList";

const AdminBillingDialog = styled(Dialog)`
  position: relative;
  width: 632px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  min-height: 621px;
  justify-content: center;
  box-sizing: border-box;
`;

const Container = styled.div`
  display: flex;
  margin: 60px 60px 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
  margin-bottom: 2px;
`;

const ClientName = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000;
  margin-bottom: 4px;
`;

const ClientEmail = styled.div`
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 20px;
`;

const DashboardValues = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const DashboardValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 30px;
`;

const DashboardValueTitle = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const DashboardValueCurrency = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const AddStripeMethodAction = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 51px;
  display: flex;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 14px;
`;

const AddStripeMethodActionText = styled.div`
  font-family: "Roboto Mono";
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: #000;
  flex: 1;
`;

const ExitIcon = styled(Exit)`
  width: 9px;
  height: 9px;
  padding: 10px;
`;

const Tip = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000;
  margin-bottom: 20px;
`;

const ChangeAction = styled(SecondaryButton)`
  position: absolute;
  top: 50%;
  right: 34px;
  transform: translateY(-50%);
  font-family: "Roboto Mono";
  font-style: normal;
  font-size: 12px;
  line-height: 140%;
  text-align: right;
  text-decoration-line: underline;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: rgba(0, 87, 255, 0.8);
  background: transparent;
  border: 0;
  outline: 0;
  width: 44px;
  min-width: 44px;
  white-space: nowrap;
`;

const StripeDashboardLink = styled(StripePowered)`
  position: absolute;
  top: 60px;
  right: 64px;
  cursor: pointer;
  transform: scale(1.22);
`;

const AdminBillingModal = ({
  dashboardUrlFetchingForUserID,
  userId,
  childStripeWindowRef,
  setDashboardUrlFetchingForUserID = () => null
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const client = useSelector(state => wlClientSelector(state, userId));
  const wlDefaultCurrency = useSelector(wlSiteDefaultCurrencySelector);
  const userObject = useSelector(userObjectSelector);

  const [isPaymentError, setIsPaymentError] = useState(false);
  const [isDashboardLoading, setIsDashboardLoading] = useState(false);

  const paymentMethodId = client.paymentMethod && client.paymentMethod.paymentMethodId;

  const urlHashParams = getHashParams(location);
  const clientCurrentPortfolio = useMemo(
    () =>
      client.portfolio.find(
        eachPortfolio => eachPortfolio.portfolioId === urlHashParams[hashParams.CLIENT_PORTFOLIO_ID]
      ),
    [client, urlHashParams]
  );

  const handleOverlayDismiss = useCallback(() => {
    DialogOverlay.forceDismiss(history, location);
  }, [history, location]);

  const handleChildWindowClose = async () => {
    childStripeWindowRef.current = null;
    setDashboardUrlFetchingForUserID(null);
  };

  const handleChildWindowUnload = () => {
    const closeCheck = setInterval(function() {
      try {
        if (childStripeWindowRef.current == null || childStripeWindowRef.current.closed) {
          clearInterval(closeCheck);
          handleChildWindowClose();
        }
      } catch (ex) {}
    }, 1000);
  };

  const handleAddStripeMethod = async (showLoader = true) => {
    if (showLoader) {
      setIsDashboardLoading(true);
      setDashboardUrlFetchingForUserID(userId);
    }
    const dashboardUrlResponse = await dispatch(adminAddPaymentMethodInStripe(userId));
    setIsDashboardLoading(false);
    childStripeWindowRef.current = window.kuberaOpen(dashboardUrlResponse.url, "_blank");
    childStripeWindowRef.current.addEventListener("unload", handleChildWindowUnload);
  };

  const handlePaymentError = isError => {
    setIsPaymentError(isError);
  };

  useEffect(() => {
    dispatch(getClientDashboardData(userId));
  }, [dispatch, userId]);

  return (
    <DialogOverlay onDismiss={handleOverlayDismiss}>
      <AdminBillingDialog>
        <Container>
          <Title>{i18n.t("adminBillingAndPayments.modalTitle")}</Title>
          <ClientName>{client.clientName}</ClientName>
          <ClientEmail>{client.email}</ClientEmail>
          <DashboardValues>
            <DashboardValue>
              <DashboardValueTitle>
                {i18n.t("assets")}
                <DashboardValueCurrency>
                  {shortFormatNumberWithCurrency(
                    client.portfolio.length > 0 ? clientCurrentPortfolio.value : 0,
                    wlDefaultCurrency,
                    true,
                    true
                  )}
                </DashboardValueCurrency>
              </DashboardValueTitle>
            </DashboardValue>
            <DashboardValue>
              <DashboardValueTitle>
                {i18n.t("investable")}
                <DashboardValueCurrency>
                  {shortFormatNumberWithCurrency(
                    client.portfolio.length > 0 ? clientCurrentPortfolio.investibleTotal : 0,
                    wlDefaultCurrency,
                    true,
                    true
                  )}
                </DashboardValueCurrency>
              </DashboardValueTitle>
            </DashboardValue>
            <DashboardValue>
              <DashboardValueTitle>
                {i18n.t("aum")}
                <DashboardValueCurrency>
                  {shortFormatNumberWithCurrency(
                    client.portfolio.length > 0 ? clientCurrentPortfolio.aumTotal : 0,
                    wlDefaultCurrency,
                    true,
                    true
                  )}
                </DashboardValueCurrency>
              </DashboardValueTitle>
            </DashboardValue>
          </DashboardValues>
          <StripeDashboardLink onClick={handleAddStripeMethod.bind(this, false)} />
          {paymentMethodId ? (
            <>
              <>
                <AdminStripeSetCard
                  client={client}
                  details={client.paymentMethod}
                  isUpdating={isDashboardLoading}
                  onUpdate={handleAddStripeMethod}
                />
                <AdminBillingForm
                  isPaymentError={isPaymentError}
                  client={client}
                  userId={userId}
                  adminUserId={userObject.id}
                />
                <PaymentsList userId={userId} handlePaymentError={handlePaymentError} />
              </>
            </>
          ) : (
            <>
              <AddStripeMethodAction onClick={handleAddStripeMethod}>
                <AddStripeMethodActionText>
                  {i18n.t("adminBillingAndPayments.addMethodInStripeAction")}
                </AddStripeMethodActionText>
                <ChangeAction
                  title=""
                  data-cy="addMethodInStripeAction"
                  isLoading={isDashboardLoading}
                  onClick={() => null}
                />
                <ExitIcon />
              </AddStripeMethodAction>
              <Tip>{i18n.t("adminBillingAndPayments.addMethodInStripeTip")}</Tip>
              <PaymentsList userId={userId} handlePaymentError={handlePaymentError} hideTitleOnLoad />
            </>
          )}
        </Container>
      </AdminBillingDialog>
    </DialogOverlay>
  );
};

AdminBillingModal.show = (history, location, userId = null, clientPortfolioId = null) => {
  var hash = `${hashParams.MODAL}=${modalValues.WL_ADMIN_BILLING}`;
  if (!userId === false) {
    hash += `&${hashParams.ID}=${userId}&${hashParams.CLIENT_PORTFOLIO_ID}=${clientPortfolioId}`;
  }

  history.push({
    ...location,
    hash: hash
  });
};

export default AdminBillingModal;
