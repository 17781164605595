const isValidEnv = () => {
  if (process.env.REACT_APP_ENV === "prod") {
    return true;
  }
  return false;
};

export const FBWrapperTrackCustomEvent = eventName => {
  if (isValidEnv()) {
    window.fbq("trackCustom", eventName);
  }
};
