import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { hashParams, modalValues } from "routes";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { withRouter, getBrowserNameInLowerCase } from "@kubera/common";
import {
  getHashParams,
  custodianSelector,
  userPreferencesSelector,
  updateUserPreferences,
  getAccountLinkingServiceType,
  accountLinkingService,
  userCountryCodeSelector,
  store,
  getPublicTokenForCustodian,
  setPublicTokenForCustodian,
  backgroundLinkFailureDataSelector,
  showToastTip,
  accountCurrentTsSelector,
  accountEndTsSelector,
  accountSubscriptionIsActiveSelector,
  markCustodianAsLinking,
  linkAccountsWithCustodian,
  unmarkCustodianAsLinking,
  custodianPortfolioSelector,
  currentPortfolioSelector,
  custodianLinkNeedsRefresh,
  custodianLinkNeedsReconnect,
  refreshAllConnectedCustodians,
  hasUserLinkedAnyAccounts,
  isZaboMigrationCandidate,
  isZaboReconnectionCandidate,
  isZaboManualEntryCandidate,
  isMobile,
  getProviderDetails,
  custodianSheetSelector,
  qrBasedProviderIds,
  isPlaidSupportedForCountry,
  updateShowLoaderStatusForAConnection
} from "@kubera/common";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import SelectLinkedAccountsComponent from "components/link_account/SelectLinkedAccountsComponent";
import LinkAccountHeadsUpDialog from "components/link_account/LinkAccountHeadsUpDialog";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import DashboardComponent from "components/dashboard/DashboardComponent";
import LinkAccountProviderPageComponent from "components/link_account/LinkAccountProviderPageComponent";
import PrimaryButton from "components/button/PrimaryButton";
import AccountSettingsComponent, { accountSettingsTabs } from "components/account_settings/AccountSettingsComponent";
import ProvidersComponent from "components/link_account/ProvidersComponent";
import ZaboReconnectDialog from "./ZaboReconnectDialog";
import GenericMessageDialog from "components/dialog/GenericMessageDialog";
import CustodianDetailsComponent from "components/custodian_details/CustodianDetailsComponent";
import QrLinkAccountComponent from "./QrLinkAccountComponent";

export const linkAccountMode = {
  LINK: "add",
  EDIT: "edit",
  REFRESH: "refresh",
  SELECT_ACCOUNTS: "select_account",
  RECONNECT: "reconnect",
  MANAGE: "manage"
};

const linkAccountScreen = {
  PROVIDER_LIST: "provider_list",
  ACCOUNT_LIST: "account_list",
  PROVIDER_PAGE: "provider_page",
  CRYPTO_API_PAGE: "crypto_api_page"
};

const reconnectOptions = {
  OUT_OF_SYNC: "out_of_sync",
  WRONG_DATA: "wrong_data",
  VIEW_ACCOUNT_LIST: "view_account_list"
};

const LinkDialogOverlay = styled(DialogOverlay)`
  visibility: ${props => (!props.isHidden === true ? "visible" : "hidden")};
`;

const LinkDialog = styled(Dialog)`
  position: relative;
  width: 612px;
  min-height: 633px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const PayWall = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1;
`;

const PayWallContent = styled.div`
  margin-top: 141px;
  width: 378px;
  padding: 6px;
`;

const PayWallTxt = styled.div`
  background: #fff;
`;

const PayWallTitle = styled.div`
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 5px;
`;

const PayWallDesc = styled.div`
  font-size: 14px;
  margin-bottom: 15px;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 60px 50px 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
  position: relative;
  visibility: ${props => (props.isHidden ? "hidden" : null)};
`;

const PageLoader = styled(Loader)`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: inherit;
`;

const ProviderScreenContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
`;

const HeadsUpDialog = styled(LinkAccountHeadsUpDialog)`
  width: 681px;
`;

const MoreDetailsBtn = styled(PrimaryButton)`
  width: 150px;
  height: 43px;
`;

const ProviderPage = styled(LinkAccountProviderPageComponent)``;

const ReconnectOptionsDialog = styled(Dialog)`
  position: relative;
  width: 414px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const ReconnectContainer = styled.div`
  display: flex;
  margin: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
`;

const ReconnectOptionsTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const ReconnectOptionsDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const ReconnectOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => (props.isFirst === true ? "20px" : "30px")};
  cursor: pointer;
`;

const ReconnectOptionTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const ReconnectOptionDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.7);
`;

const isMetaMaskSupportedBrowser = (function() {
  const name = getBrowserNameInLowerCase();
  switch (name) {
    case "chrome":
    case "firefox":
    case "brave":
    case "edge":
      return true;
    default:
      return false;
  }
})();

class LinkAccountComponent extends React.Component {
  static linkAccount = (
    history,
    location,
    custodianId,
    linkMode = linkAccountMode.LINK,
    shouldReplace = false,
    regionCode = null,
    category = DashboardComponent.getCurrentCategory(),
    isReconnectedFromConnectivityCenter = false
  ) => {
    const regionCodeParam = regionCode ? `${hashParams.LINK_REGION}=${regionCode}` : "";
    if (shouldReplace === true) {
      history.replace({
        ...location,
        hash: `${hashParams.MODAL}=${modalValues.LINK_ACCOUNT}&${hashParams.LINK_CATEGORY}=${category}&${hashParams.ID}=${custodianId}&${hashParams.LINK_MODE}=${linkMode}&${regionCodeParam}&${hashParams.IS_RECONNECTED_FROM_CONNECTIVITY_CENTER}=${isReconnectedFromConnectivityCenter}`
      });
    } else {
      history.push({
        ...location,
        hash: `${hashParams.MODAL}=${modalValues.LINK_ACCOUNT}&${hashParams.LINK_CATEGORY}=${category}&${hashParams.ID}=${custodianId}&${hashParams.LINK_MODE}=${linkMode}&${regionCodeParam}&${hashParams.IS_RECONNECTED_FROM_CONNECTIVITY_CENTER}=${isReconnectedFromConnectivityCenter}`
      });
    }
  };

  static linkAccountWithService = (
    history,
    location,
    custodianId,
    linkMode = linkAccountMode.LINK,
    linkingService = null,
    shouldReplace = false,
    category = DashboardComponent.getCurrentCategory()
  ) => {
    const linkingServiceParam = linkingService ? `${hashParams.LINK_SERVICE}=${linkingService}` : "";
    if (shouldReplace === true) {
      history.replace({
        ...location,
        hash: `${hashParams.MODAL}=${modalValues.LINK_ACCOUNT}&${hashParams.LINK_CATEGORY}=${category}&${hashParams.ID}=${custodianId}&${hashParams.LINK_MODE}=${linkMode}&${linkingServiceParam}`
      });
    } else {
      history.push({
        ...location,
        hash: `${hashParams.MODAL}=${modalValues.LINK_ACCOUNT}&${hashParams.LINK_CATEGORY}=${category}&${hashParams.ID}=${custodianId}&${hashParams.LINK_MODE}=${linkMode}&${linkingServiceParam}`
      });
    }
  };

  static selectAccounts = (history, location, custodianId, category = DashboardComponent.getCurrentCategory()) => {
    const custodian = custodianSelector(store.getState(), custodianId);

    if (custodian && custodian.linkingScreenClosed === true) {
      LinkAccountComponent.linkAccount(
        history,
        location,
        custodianId,
        linkAccountMode.SELECT_ACCOUNTS,
        undefined,
        undefined,
        category
      );
    } else {
      LinkAccountProviderPageComponent.reopen(custodianId);
    }
  };

  static refreshAccountValues = (history, location, custodianId, onSuccess = null, onError = null, flags) => {
    var isRefreshing = false;
    const custodian = custodianSelector(store.getState(), custodianId);

    if (!custodian === true) {
      return;
    }

    const isUneditableConnection = [
      accountLinkingService.CARS,
      accountLinkingService.DOMAINS,
      accountLinkingService.ZILLOW
    ].includes(custodian.linkType);

    if (custodian.statusInfo === "FORCE_RECONNECT") {
      LinkAccountComponent.reconnect(history, location, custodianId);
    } else if (isZaboReconnectionCandidate(custodian.linkType, custodian.linkProviderId)) {
      ZaboReconnectDialog.show(history, location, true);
    } else if (isZaboManualEntryCandidate(custodian.linkType, custodian.linkProviderId)) {
      ZaboReconnectDialog.show(history, location, false);
    } else if (custodianLinkNeedsRefresh(custodianId) === true) {
      if (isUneditableConnection) {
        GenericMessageDialog.show(history, location);
        return;
      }
      LinkAccountComponent.linkAccount(history, location, custodianId, linkAccountMode.REFRESH);
    } else if (custodianLinkNeedsReconnect(custodianId) === true) {
      if (isUneditableConnection) {
        GenericMessageDialog.show(history, location);
        return;
      }
      LinkAccountComponent.linkAccount(history, location, custodianId, linkAccountMode.EDIT);
    } else {
      store.dispatch(refreshAllConnectedCustodians(custodianId, onSuccess, onError, flags));
      isRefreshing = true;
    }
    return isRefreshing;
  };

  static resolveLinkError = (history, location, custodianId, isReconnectedFromConnectivityCenter = false) => {
    const custodian = custodianSelector(store.getState(), custodianId);

    if (
      [accountLinkingService.CARS, accountLinkingService.DOMAINS, accountLinkingService.ZILLOW].includes(
        custodian.linkType
      )
    ) {
      GenericMessageDialog.show(history, location);
      return;
    }

    const custodianSheet = custodianSheetSelector(store.getState(), custodianId);
    const category = custodianSheet ? custodianSheet.category : undefined;

    if (isZaboReconnectionCandidate(custodian.linkType, custodian.linkProviderId)) {
      ZaboReconnectDialog.show(history, location, true);
    } else if (isZaboManualEntryCandidate(custodian.linkType, custodian.linkProviderId)) {
      ZaboReconnectDialog.show(history, location, false);
    } else if (custodian.statusInfo === "FORCE_RECONNECT") {
      LinkAccountComponent.reconnect(history, location, custodianId, false);
    } else if (custodianLinkNeedsRefresh(custodianId) === true) {
      LinkAccountComponent.linkAccount(
        history,
        location,
        custodianId,
        linkAccountMode.REFRESH,
        undefined,
        undefined,
        category,
        isReconnectedFromConnectivityCenter
      );
    } else {
      LinkAccountComponent.linkAccount(
        history,
        location,
        custodianId,
        linkAccountMode.EDIT,
        undefined,
        undefined,
        category,
        isReconnectedFromConnectivityCenter
      );
    }
  };

  static reconnect = (history, location, custodianId, isReconnectedFromConnectivityCenter = false) => {
    LinkAccountComponent.linkAccount(
      history,
      location,
      custodianId,
      linkAccountMode.RECONNECT,
      false,
      null,
      DashboardComponent.getCurrentCategory(),
      isReconnectedFromConnectivityCenter
    );
  };

  constructor(props) {
    super(props);

    const backgroundLinkFailureData = backgroundLinkFailureDataSelector(store.getState(), props.custodianId);
    const linkingAccountsData = (this.props.custodian || {}).linkingAccountsData;
    var linkedAccounts = null;
    var currentScreen = null;

    if (this.props.mode === linkAccountMode.SELECT_ACCOUNTS) {
      currentScreen = linkAccountScreen.ACCOUNT_LIST;
      linkedAccounts = linkingAccountsData.accounts;
    } else if (!backgroundLinkFailureData === false) {
      currentScreen = linkAccountScreen.LINK_FAILURE;
    }

    this.state = {
      isPending: false,
      currentScreen: currentScreen,
      linkedAccounts: linkedAccounts,
      error: null,
      showHeadsUpDialog: false,
      showAbortLinkingDialog: false,
      showAccountAlreadyLinkedDialog: false,
      linkErrorData: backgroundLinkFailureData,
      isCoinsConfirmModalVisible: false,
      isZerionModalOpen: false,
      isMetamaskInstallError: false,
      isMetamaskNotSupported: false,
      isMetaMaskSupportedBrowser: false,
      showQrInstructions: false,
      showReconnectOptionsDialog: false
    };

    this.selectedProvider = null;

    if (this.props.mode === linkAccountMode.SELECT_ACCOUNTS) {
      this.selectedProvider = {
        linkProviderId: linkingAccountsData.providerId,
        name: linkingAccountsData.providerName
      };
    } else if (!backgroundLinkFailureData === false) {
      this.selectedProvider = {
        linkProviderId: backgroundLinkFailureData.providerId,
        name: backgroundLinkFailureData.providerName
      };
    }

    this.regionCode = props.regionCode ? props.regionCode : props.userCountryCode;
    if (this.props.mode === linkAccountMode.SELECT_ACCOUNTS) {
      this.linkingService = linkingAccountsData.linkingService;
    } else if (this.props.mode === linkAccountMode.LINK) {
      this.linkingService = props.linkingService
        ? parseInt(props.linkingService)
        : getAccountLinkingServiceType(props.regionCode);
    } else {
      this.linkingService = this.props.custodian.linkType;
    }

    this.selectedProviderDetails = null;

    this.handleProviderSelection = this.handleProviderSelection.bind(this);
    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleLinkAccount = this.handleLinkAccount.bind(this);
    this.handleHeadsUpDialogButtonClick = this.handleHeadsUpDialogButtonClick.bind(this);
    this.handleLoaderRefreshClick = this.handleLoaderRefreshClick.bind(this);
    this.handleAbortDialogNegativeButtonClick = this.handleAbortDialogNegativeButtonClick.bind(this);
    this.handleAbortDialogPositiveButtonClick = this.handleAbortDialogPositiveButtonClick.bind(this);
    this.showSubscriptionModal = this.showSubscriptionModal.bind(this);
    this.dismissDialog = this.dismissDialog.bind(this);
    this.dismissCoinsConfirmDialog = this.dismissCoinsConfirmDialog.bind(this);
    this.tryPlaidConnection = this.tryPlaidConnection.bind(this);
    this.handleTryCryptoTickerClicked = this.handleTryCryptoTickerClicked.bind(this);
    this.handleReconnectOptionSelection = this.handleReconnectOptionSelection.bind(this);

    this.timer = null;
    this.containerRef = React.createRef();

    this.isDeepLinkFlow = !this.props.linkProviderId === false;
  }

  componentDidMount() {
    // If there is a linking error already don't do anything.
    if (!this.props.custodian) {
      DialogOverlay.forceDismiss(this.props.history, this.props.location);
    }

    if (!this.state.linkErrorData === true) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProp) {
    if (prevProp.accountSubscriptionIsActive !== this.props.accountSubscriptionIsActive) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    if (!this.windowMessageListener === false) {
      window.removeEventListener("message", this.windowMessageListener);
    }
  }

  showSubscriptionModal() {
    AccountSettingsComponent.show(this.props.history, this.props.location, accountSettingsTabs.SUBSCRIPTION);
  }

  fetchData() {
    if (this.props.mode === linkAccountMode.LINK) {
      if (!this.props.linkProviderId === false) {
        this.props.getProviderDetails(
          this.linkingService,
          this.props.linkProviderId,
          providerDetails => {
            if (providerDetails.length === 0) {
              this.dismissDialog();
            } else {
              this.handleProviderSelection(providerDetails[0]);
            }
          },
          apiError => {
            console.log(apiError);
            this.dismissDialog();
          }
        );
      } else if (this.linkingService === accountLinkingService.PLAID && this.props.accountSubscriptionIsActive) {
        this.linkAccount();
      } else {
        this.showProvidersList();
      }
    } else if (this.props.mode === linkAccountMode.REFRESH) {
      this.refreshAccount();
    } else if (this.props.mode === linkAccountMode.EDIT) {
      this.editAccount();
    } else if (this.props.mode === linkAccountMode.RECONNECT) {
      if (
        this.props.isReconnectedFromConnectivityCenter === "true" ||
        (this.props.custodian && this.props.custodian.statusInfo === "FORCE_RECONNECT")
      ) {
        this.reconnectAccount();
      } else {
        this.setState({ showReconnectOptionsDialog: true });
      }
    } else if (this.props.mode === linkAccountMode.MANAGE) {
      this.manageAccount();
    }
  }

  showProvidersList() {
    this.setState({ currentScreen: linkAccountScreen.PROVIDER_LIST });
  }

  getCurrentProviderId() {
    if (this.props.mode === linkAccountMode.LINK) {
      return this.selectedProvider ? this.selectedProvider.linkProviderId : "";
    }
    return this.props.custodian.linkProviderId;
  }

  getCurrentProviderName() {
    if (this.props.mode === linkAccountMode.LINK) {
      return this.selectedProvider ? this.selectedProvider.name : "";
    }
    return this.props.custodian.linkProviderName;
  }

  linkAccount(provider = null, providerAccountId = null) {
    if (
      !this.props.userPreferences.isLinkingHeadsUpDialogShown === true &&
      !this.props.hasUserLinkedAnyAccounts === true &&
      !this.state.showHeadsUpDialog === true
    ) {
      this.setState({ showHeadsUpDialog: true });
      return;
    }

    const custodian = this.props.custodian;
    if (!custodian) {
      DialogOverlay.forceDismiss(this.props.history, this.props.location);
      return;
    }

    this.handleMarkCustodianAsLinking();
    this.openProviderPage(provider, providerAccountId);
  }

  refreshAccount() {
    this.openProviderPage();
    this.handleMarkCustodianAsLinking();
  }

  editAccount() {
    this.openProviderPage();
    this.handleMarkCustodianAsLinking();
  }

  manageAccount() {
    this.openProviderPage();
  }

  reconnectAccount(overrideLinkingMode = null) {
    this.openProviderPage(undefined, undefined, overrideLinkingMode);
    this.handleMarkCustodianAsLinking();
  }

  handleMarkCustodianAsLinking() {
    const providerName = this.getCurrentProviderName();
    this.props.markCustodianAsLinking(
      this.props.portfolio.id,
      this.props.custodian,
      false,
      providerName,
      this.props.category
    );
  }

  openProviderPage = (provider = null, providerAccountId = null, overrideLinkingMode = null) => {
    if (!this.props.portfolio === true) {
      return;
    }

    if (isMobile() && this.isDeepLinkFlow === false) {
      CustodianDetailsComponent.show(this.props.history, this.props.location, this.props.custodian.id, true);
      return;
    }

    if (isMobile() === false && qrBasedProviderIds.includes(this.getCurrentProviderId())) {
      this.setState({ showQrInstructions: true });

      setTimeout(() => {
        this.props.unmarkCustodianAsLinking(
          this.props.portfolio.id,
          this.props.custodian,
          this.getCurrentProviderName(),
          this.props.category,
          false,
          this.props.isReconnectedFromConnectivityCenter
        );
      }, 10);
      return;
    }

    const linkingMode = overrideLinkingMode || this.props.mode;

    if (
      this.linkingService === accountLinkingService.ZABO &&
      isZaboMigrationCandidate(this.props.custodian) === false
    ) {
      this.setState({ currentScreen: linkAccountScreen.PROVIDER_PAGE });
      LinkAccountProviderPageComponent.open(
        this.regionCode,
        linkingMode,
        this.props.category,
        this.props.portfolio.id,
        this.props.custodian,
        this.linkingService,
        this.selectedProviderDetails,
        provider,
        providerAccountId,
        true
      );
    } else {
      if (
        [
          "metamask_ethereum",
          "metamask_bsc",
          "metamask_arbitrum",
          "metamask_avalanche",
          "metamask_optimism",
          "metamask_polygon"
        ].includes((provider || {}).linkProviderId)
      ) {
        if (!isMetaMaskSupportedBrowser) {
          this.setState({
            isMetamaskNotSupported: true
          });

          return;
        } else if (!window.ethereum) {
          this.setState({
            isMetamaskInstallError: true
          });

          return;
        }
      }

      const linkingWindow = LinkAccountProviderPageComponent.open(
        this.regionCode,
        linkingMode,
        this.props.category,
        this.props.portfolio.id,
        this.props.custodian,
        this.linkingService,
        this.selectedProviderDetails,
        provider,
        providerAccountId,
        this.props.inline
      );
      const windowCloseTimer = setInterval(() => {
        if (linkingWindow?.closed === true) {
          try {
            if (!linkingWindow.linkingCompleted === true) {
              this.props.unmarkCustodianAsLinking(
                this.props.portfolio.id,
                this.props.custodian,
                this.getCurrentProviderName(),
                this.props.category,
                false,
                this.props.isReconnectedFromConnectivityCenter
              );
            }
          } catch (e) {
            this.props.unmarkCustodianAsLinking(
              this.props.portfolio.id,
              this.props.custodian,
              this.getCurrentProviderName(),
              this.props.category,
              false,
              this.props.isReconnectedFromConnectivityCenter
            );
          }
          clearInterval(windowCloseTimer);
        } else {
          try {
            linkingWindow.opener = window;
          } catch (e) {
            // Ignore cross domain window access exception.
            // This will be thrown when iframe is not supported by a
            // provider and the connection tab is redirected to  the provider's page
          }
        }
      }, 500);

      this.props.updateUserPreferences({ canShowFirstTimeLinkingDialog: true });

      if (this.isDeepLinkFlow === true) {
        DialogOverlay.forceDismiss(this.props.history, this.props.location);
      } else {
        this.dismissDialog();
      }
    }
  };

  handleHeadsUpDialogButtonClick() {
    this.props.updateUserPreferences({ isLinkingHeadsUpDialogShown: true });
    this.linkAccount(this.selectedProvider);
    this.setState({ showHeadsUpDialog: false });
  }

  dismissDialog() {
    DialogOverlay.dismiss(this.props.history, this.props.location);
  }

  dismissCoinsConfirmDialog() {
    this.setState({
      isCoinsConfirmModalVisible: false
    });
  }

  handleOverlayDismiss() {
    if (this.state.currentScreen === linkAccountScreen.ACCOUNT_LIST || this.state.isPending === true) {
      this.setState({ ...this.state, showAbortLinkingDialog: true });
      return;
    } else if (this.state.currentScreen === linkAccountScreen.ACCOUNT_LIST) {
      if (this.state.linkedAccounts) {
        const unlinkedAccounts = this.state.linkedAccounts.filter(account => account.linkedCustodianId === "");
        if (unlinkedAccounts.length > 0) {
          this.setState({ ...this.state, showAbortLinkingDialog: true });
          return;
        }
      }
    }

    if (this.containerRef.current && this.containerRef.current.querySelector(`[data-exitconfirm="coins"]`)) {
      this.setState({
        isCoinsConfirmModalVisible: true
      });
    } else {
      this.dismissDialog();
    }
  }

  handleZerionDialogDismiss = () => {
    this.setState({
      isZerionModalOpen: false
    });
  };

  handleProviderSelection(providerDetails) {
    const aggregatorOptions = providerDetails.aggregatorOptions;
    const aggregator =
      aggregatorOptions.length === 1
        ? aggregatorOptions[0]
        : aggregatorOptions.find(aggregator => aggregator.plan === "a");
    if (!aggregator === true) {
      return;
    }

    // In case of Plaid provider ensure that the region is set to
    // whatever counry code the aggregator provides instead of the user's current
    // country code as Plaid is only available in limited countries
    if (aggregator.linkType === accountLinkingService.PLAID) {
      this.regionCode = providerDetails.countryCode.toUpperCase();
    }

    this.selectedProviderDetails = providerDetails;
    this.selectedProvider = {
      linkProviderId: aggregator.linkProviderId,
      name: providerDetails.name
    };
    this.linkingService = aggregator.linkType;
    this.linkAccount(this.selectedProvider);
  }

  handleLinkAccount(accounts) {
    if (accounts.length === 0) {
      this.releaseCustodianFromLinking();
      this.dismissDialog();
      return;
    }

    this.props.linkAccountsWithCustodian(
      this.props.portfolio.id,
      this.props.category,
      accounts,
      this.state.linkedAccounts,
      this.props.custodian,
      this.linkingService,
      this.selectedProvider.linkProviderId,
      this.selectedProvider.name,
      false,
      null,
      true,
      true
    );

    if (this.props.currentPortfolio.id !== this.props.portfolio.id) {
      const message = i18n
        .t("linkAccount.accountsAddedInDifferentProtfolio")
        .replace("%s1%", this.selectedProvider.name)
        .replace("%s2%", this.props.portfolio.name);
      this.props.showToastTip(
        "TIP",
        message,
        null,
        -1,
        i18n.t("view"),
        () => {
          DashboardComponent.showPortfolio(this.props.history, this.props.location, this.props.portfolio.id);
        },
        null,
        true,
        true
      );
    }

    this.dismissDialog();
  }

  handleLoaderRefreshClick(e) {
    this.fetchData();
  }

  handleAbortDialogNegativeButtonClick() {
    this.dismissDialog();

    if (this.props.mode === linkAccountMode.SELECT_ACCOUNTS) {
      this.releaseCustodianFromLinking();
    }
  }

  releaseCustodianFromLinking() {
    this.props.unmarkCustodianAsLinking(
      this.props.portfolio.id,
      this.props.custodian,
      this.getCurrentProviderName(),
      this.props.category,
      false,
      this.props.isReconnectedFromConnectivityCenter
    );
  }

  handleAbortDialogPositiveButtonClick() {
    this.setState({ ...this.state, showAbortLinkingDialog: false });
  }

  tryPlaidConnection() {
    this.linkingService = accountLinkingService.PLAID;
    if (isPlaidSupportedForCountry(this.regionCode) === false) {
      this.regionCode = "US";
    }

    this.linkAccount();
  }

  handleTryCryptoTickerClicked() {
    const urlHashParams = getHashParams(this.props.location);
    this.props.history.replace({
      ...this.props.location,
      hash: `${hashParams.MODAL}=${modalValues.CONNECT_CRYPTO}&${hashParams.ID}=${urlHashParams[hashParams.ID]}`
    });
  }

  handleReconnectOptionSelection(selectedOption) {
    if (selectedOption === reconnectOptions.OUT_OF_SYNC || selectedOption === reconnectOptions.WRONG_DATA) {
      this.reconnectAccount();
    } else {
      this.reconnectAccount(linkAccountMode.SELECT_ACCOUNTS);
    }
  }

  shouldShowLoader() {
    if (this.state.currentScreen === linkAccountScreen.ACCOUNT_LIST && this.state.isPending === true) {
      return false;
    }
    if (this.state.currentScreen === linkAccountScreen.ACCOUNT_LIST && !this.state.error === false) {
      return false;
    }
    return this.state.isPending === true || !this.state.error === false;
  }

  dismissMetamaskInstallError = () => {
    this.setState({
      isMetamaskInstallError: false
    });

    this.props.unmarkCustodianAsLinking(
      this.props.portfolio.id,
      this.props.custodian,
      this.getCurrentProviderName(),
      this.props.category,
      false,
      this.props.isReconnectedFromConnectivityCenter
    );
  };

  dismissMetamaskNotSupportedError = () => {
    this.setState({
      isMetamaskNotSupported: false,
      isZerionModalOpen: false
    });

    this.props.unmarkCustodianAsLinking(
      this.props.portfolio.id,
      this.props.custodian,
      this.getCurrentProviderName(),
      this.props.category,
      false,
      this.props.isReconnectedFromConnectivityCenter
    );
  };

  dismissMetamaskTryCatchError = () => {
    this.setState({
      isMetamaskTryCatchError: false
    });

    this.props.unmarkCustodianAsLinking(
      this.props.portfolio.id,
      this.props.custodian,
      this.getCurrentProviderName(),
      this.props.category,
      false,
      this.props.isReconnectedFromConnectivityCenter
    );
  };

  render() {
    const error = this.state.error;
    const isPending = this.state.isPending;
    const shouldShowLoader = this.shouldShowLoader();
    const currentScreen = this.state.currentScreen;
    const showHeadsUpDialog = this.state.showHeadsUpDialog;
    const showAbortLinkingDialog = this.state.showAbortLinkingDialog;
    const showAccountAlreadyLinkedDialog = this.state.showAccountAlreadyLinkedDialog;
    const selectedProvider = this.selectedProvider;

    if (
      this.props.accountSubscriptionIsActive &&
      isPending === false &&
      !error === true &&
      currentScreen === linkAccountScreen.PROVIDER_PAGE
    ) {
      return <ProviderPage custodianId={this.props.custodianId} inline={true} />;
    }

    if (this.state.showQrInstructions === true) {
      return (
        <LinkDialogOverlay onDismiss={this.handleOverlayDismiss}>
          <LinkDialog>
            <Container ref={this.containerRef}>
              <QrLinkAccountComponent
                portfolioId={this.props.currentPortfolio.id}
                linkType={this.linkingService}
                linkProviderName={this.getCurrentProviderName()}
                linkProviderId={this.getCurrentProviderId()}
                category={this.props.category}
                mode={this.props.mode}
                custodianId={this.props.custodianId}
              />
            </Container>
          </LinkDialog>
        </LinkDialogOverlay>
      );
    }

    if (this.state.showReconnectOptionsDialog === true) {
      return (
        <LinkDialogOverlay onDismiss={this.handleOverlayDismiss}>
          <ReconnectOptionsDialog>
            <ReconnectContainer ref={this.containerRef}>
              <ReconnectOptionsTitle>{i18n.t("reconnectAccount.title")}</ReconnectOptionsTitle>
              <ReconnectOptionsDescription>{i18n.t("reconnectAccount.description")}</ReconnectOptionsDescription>
              <ReconnectOptionContainer
                isFirst={true}
                onClick={e => this.handleReconnectOptionSelection(reconnectOptions.OUT_OF_SYNC)}
              >
                <ReconnectOptionTitle>{i18n.t("reconnectAccount.outOfSyncTitle")}</ReconnectOptionTitle>
                <ReconnectOptionDescription>
                  {i18n.t("reconnectAccount.outOfSyncDescription")}
                </ReconnectOptionDescription>
              </ReconnectOptionContainer>
              <ReconnectOptionContainer onClick={e => this.handleReconnectOptionSelection(reconnectOptions.WRONG_DATA)}>
                <ReconnectOptionTitle>{i18n.t("reconnectAccount.wrongDataTitle")}</ReconnectOptionTitle>
                <ReconnectOptionDescription>
                  {i18n.t("reconnectAccount.wrongDataDescription")}
                </ReconnectOptionDescription>
              </ReconnectOptionContainer>
              <ReconnectOptionContainer
                onClick={e => this.handleReconnectOptionSelection(reconnectOptions.VIEW_ACCOUNT_LIST)}
              >
                <ReconnectOptionTitle>{i18n.t("reconnectAccount.accountListTitle")}</ReconnectOptionTitle>
                <ReconnectOptionDescription>
                  {i18n.t("reconnectAccount.accountListDescription")}
                </ReconnectOptionDescription>
              </ReconnectOptionContainer>
            </ReconnectContainer>
          </ReconnectOptionsDialog>
        </LinkDialogOverlay>
      );
    }

    return (
      <LinkDialogOverlay onDismiss={this.handleOverlayDismiss} isHidden={this.isDeepLinkFlow === true}>
        <LinkDialog>
          {!this.props.accountSubscriptionIsActive && (
            <PayWall>
              <PayWallContent>
                <PayWallTxt>
                  <PayWallTitle>{i18n.t("linkAccount.paywallTitle")}</PayWallTitle>
                  <PayWallDesc>{i18n.t("linkAccount.paywallDesc")}</PayWallDesc>
                </PayWallTxt>
                <MoreDetailsBtn title="More Details" data-cy="moreDetailsButton" onClick={this.showSubscriptionModal} />
              </PayWallContent>
            </PayWall>
          )}
          <Container ref={this.containerRef} isHidden={this.state.isZerionModalOpen}>
            {shouldShowLoader === true && (
              <PageLoader
                errorMessage={!error === false ? error.errorMessage : null}
                onRefresh={this.handleLoaderRefreshClick}
              />
            )}
            {isPending === false && !error === true && currentScreen === linkAccountScreen.PROVIDER_LIST && (
              <ProvidersComponent
                custodianId={this.props.custodianId}
                mode={this.props.mode}
                category={this.props.category}
                linkingService={this.linkingService}
                onProviderSelection={this.handleProviderSelection}
                userCountryCode={this.props.userCountryCode}
                onTryPlaidConnection={this.tryPlaidConnection}
                onTryCryptoTicker={this.handleTryCryptoTickerClicked}
              />
            )}
            {currentScreen === linkAccountScreen.ACCOUNT_LIST && (
              <ProviderScreenContainer>
                <SelectLinkedAccountsComponent
                  title={selectedProvider.name}
                  error={error}
                  onRefresh={this.handleLoaderRefreshClick}
                  isPending={isPending}
                  accounts={this.state.linkedAccounts}
                  onAddAccount={this.handleLinkAccount}
                  linkType={this.linkingService}
                  portfolio={this.props.portfolio}
                />
              </ProviderScreenContainer>
            )}
          </Container>
          {showHeadsUpDialog === true && <HeadsUpDialog onButtonClick={this.handleHeadsUpDialogButtonClick} />}
          {showAbortLinkingDialog === true && (
            <ConfirmationDialog
              title={i18n.t("linkAccount.abortLinkingDialogTitle")}
              description={i18n.t("linkAccount.abortLinkingDialogDescription")}
              positiveButtonTitle={i18n.t("stay")}
              negativeButtonTitle={i18n.t("abort")}
              handleNegativeButtonClick={this.handleAbortDialogNegativeButtonClick}
              handlePositiveButtonClick={this.handleAbortDialogPositiveButtonClick}
            />
          )}
          {showAccountAlreadyLinkedDialog === true && (
            <ConfirmationDialog
              title={i18n.t("linkAccount.accountAlreadyLinkedDialogTitle")}
              description={i18n.t("linkAccount.accountAlreadyLinkedDialogDescription")}
              positiveButtonTitle={i18n.t("ok")}
              handlePositiveButtonClick={this.handleOverlayDismiss}
            />
          )}
          {this.state.isCoinsConfirmModalVisible && (
            <ConfirmationDialog
              title={i18n.t("linkAccount.confirmCoinAddTitle")}
              description={i18n.t("linkAccount.confirmCoinAddDesc")}
              positiveButtonTitle={i18n.t("stay")}
              negativeButtonTitle={i18n.t("abort")}
              handleNegativeButtonClick={this.dismissDialog}
              handlePositiveButtonClick={this.dismissCoinsConfirmDialog}
            />
          )}
          {this.state.isMetamaskInstallError && (
            <ConfirmationDialog
              title={i18n.t("linkAccount.metamaskInstallTitle")}
              description={i18n.t("linkAccount.metamaskInstallDesc")}
              positiveButtonTitle={i18n.t("ok")}
              handlePositiveButtonClick={this.dismissMetamaskInstallError}
            />
          )}
          {this.state.isMetamaskNotSupported && (
            <ConfirmationDialog
              title={i18n.t("linkAccount.metamaskNotSupportedTitle")}
              description={i18n.t("linkAccount.metamaskNotSupportedDesc")}
              positiveButtonTitle={i18n.t("ok")}
              handlePositiveButtonClick={this.dismissMetamaskNotSupportedError}
            />
          )}
        </LinkDialog>
      </LinkDialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  custodian: custodianSelector(state, props.custodianId),
  userPreferences: userPreferencesSelector(state),
  accountCurrentTs: accountCurrentTsSelector(state),
  accountEndTs: accountEndTsSelector(state),
  accountSubscriptionIsActive: accountSubscriptionIsActiveSelector(state),
  portfolio: custodianPortfolioSelector(state, props.custodianId),
  currentPortfolio: currentPortfolioSelector(state),
  hasUserLinkedAnyAccounts: hasUserLinkedAnyAccounts(state),
  userCountryCode: userCountryCodeSelector(state)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences,
  getPublicTokenForCustodian: getPublicTokenForCustodian,
  setPublicTokenForCustodian: setPublicTokenForCustodian,
  showToastTip: showToastTip,
  markCustodianAsLinking: markCustodianAsLinking,
  linkAccountsWithCustodian: linkAccountsWithCustodian,
  unmarkCustodianAsLinking: unmarkCustodianAsLinking,
  getProviderDetails: getProviderDetails,
  updateShowLoaderStatusForAConnection: updateShowLoaderStatusForAConnection
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LinkAccountComponent));
