import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  currentPortfolioSelector,
  planningRuleCategories,
  getFilteredRuleObjects,
  getRuleObject,
  planningVariables,
  planningAssetTypes,
  getPortfolioTotalForAssetType,
  getPortfolioTotalForDebtType,
  portfolioCashOnHand,
  getPorfolioTotalForTickerIds,
  getCurrentValueForCustodianItems,
  planningRuleEffect,
  portfolioTotalForCategory,
  getRuleText,
  getRuleNodeKey,
  ruleNodeKeys,
  getCashCumulativeDeltaForRule,
  getNameList,
  getTickerUsingId,
  getExchangeRate,
  formatNumberWithCurrency,
  getQuantityForTickerId,
  getCustodianHistoryFormattedDateString,
  parseKuberaDateString,
  isCryptoCurrency,
  getSplitBreakdownKey,
  splitBreakdownKeys,
  cashEffectRules,
  nonCashEffectRules,
  shortFormatNumberWithCurrency,
  getAssetRules,
  createTaxBlock
} from "@kubera/common";
import CurrencyLabel from "components/labels/CurrencyLabel";
import { ReactComponent as ExpandIcon } from "assets/images/expandable_indicator.svg";
import { category } from "components/dashboard/DashboardComponent";
import ChangeLabel from "components/labels/ChangeLabel";

const BreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BreakdownTable = styled.div`
  margin-top: ${props => (props.showTopMargin === true ? "35px" : "0px")};
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const BreakdownRow = styled.div`
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: 0px 22px 0px 28px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  border-top: ${props => (props.hideBorder === true ? "" : "1px solid rgba(0, 0, 0, 0.1)")};
  cursor: ${props => (props.isExpandable === true ? "pointer" : "auto")};
`;

const BreakdownChildRow = styled(BreakdownRow)`
  background: #f1f1f1;
`;

const TitleContainer = styled.div`
  flex: 1;
  margin-top: 7px;
  margin-bottom: 7px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  flex: 1;
`;

const Description = styled.div`
  margin-top: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.4);
`;

const Value = styled(CurrencyLabel)`
  margin-left: 20px;
  min-width: 100px;
  text-align: right;
`;

const ChangeValue = styled(ChangeLabel)`
  margin-left: 20px;
  min-width: 100px;
  text-align: right;
`;

const ExpandArrow = styled(ExpandIcon)`
  width: 10px;
  height: 10px;
  margin-right: 10px;
  margin-left: -20px;
  transform: ${props => (props.collapsed === 1 ? "rotate(-90deg)" : "")};
  visibility: ${props => (props.expandable === 1 ? "visible" : "hidden")};
`;

const FooterRow = styled(BreakdownRow)`
  font-weight: 700;
`;

const breakdownType = { ASSET: "asset", DEBT: "debt", NETWORTH: "networth" };

class NetworthBreakdownComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assetExpandedKeys: [],
      debtExpandedKeys: []
    };

    this.handleRowClick = this.handleRowClick.bind(this);
  }

  handleRowClick(e, key, debtRules) {
    var expandedKeys = debtRules ? this.state.debtExpandedKeys : this.state.assetExpandedKeys;

    if (expandedKeys.includes(key)) {
      expandedKeys = expandedKeys.filter(item => item !== key);
    } else {
      expandedKeys.push(key);
    }

    if (debtRules) {
      this.setState({ debtExpandedKeys: expandedKeys });
    } else {
      this.setState({ assetExpandedKeys: expandedKeys });
    }
  }

  getDebtRules(rules) {
    return rules.filter(rule => {
      const ruleObject = getRuleObject(rule);
      return !ruleObject === false && ruleObject.category === planningRuleCategories.DEBTS;
    });
  }

  getSelectedDataPoint() {
    return this.props.groupedData.data[this.props.dataIndex];
  }

  getRulesBreakdown(rules, debtRules = false) {
    if (rules.length === 0) {
      return {};
    }

    var breakdown = {};
    const dataPoint = this.getSelectedDataPoint();
    const ruleObjects = getFilteredRuleObjects(rules);

    const getSelfDataFromPCMap = (key, data) => {
      const modifiedData = JSON.parse(JSON.stringify(data));
      const newCd = data.changes?.parentContainerMap?.get(data.id)?.cumulativeDelta || 0;
      modifiedData.changes.cumulativeDelta = newCd;
      modifiedData.nodeKey = key;
      if (data.changes?.subtext) {
        modifiedData.subtext = data.changes.subtext;
      }
      return modifiedData;
    };

    const getCashFlowData = (data, cashflowRuleData) => {
      if (!this.props.dataForScenario.cashBlockId) {
        return cashflowRuleData; // if no cash block, return the default data
      }
      const modifiedData = JSON.parse(JSON.stringify(data));
      modifiedData.changes.cumulativeDelta = 0; // cash block so don't show
      return modifiedData;
    };

    const insertNode = (object, data) => {
      const key = getRuleNodeKey(object);

      if (!breakdown[key] === true) {
        breakdown[key] = { initialValue: 0, rules: [], overriddenLabels: [] };
      }
      if (!object.data === false && !object.data[planningVariables.TICKER_ID] === false) {
        breakdown[key].nodeTickerId = object.data[planningVariables.TICKER_ID].items[0];
      }
      breakdown[key].initialValue = this.getCurrentValue(object);
      breakdown[key].label = this.getRuleNodeLabel(key, debtRules);

      if (!data.overridingRules === false) {
        const overridingRules = JSON.parse(JSON.stringify(data.overridingRules));
        for (const overriddenRule of overridingRules) {
          if (key === ruleNodeKeys.CASH) {
            overriddenRule.changes.cumulativeDelta = -(overriddenRule.changes.initialValue.cashTotal === undefined
              ? overriddenRule.changes.initialValue
              : overriddenRule.changes.initialValue.cashTotal);
          } else {
            overriddenRule.changes.cumulativeDelta = -(overriddenRule.changes.initialValue.total === undefined
              ? overriddenRule.changes.initialValue
              : overriddenRule.changes.initialValue.total);
          }
        }
        breakdown[key].rules.push(...overridingRules);

        for (const overriddenRule of data.overridingRules) {
          const overriddenRuleObject = getRuleObject(
            this.props.dataForScenario.processedRules.find(item => item.id === overriddenRule.id)
          );
          const label = this.getRuleNodeLabel(getRuleNodeKey(overriddenRuleObject), debtRules);

          if (breakdown[key].overriddenLabels.includes(label) === false && !overriddenRule.cashOverride) {
            breakdown[key].overriddenLabels.push(label);
          }
        }
      }

      if (object.childInstanceIds) {
        // adding a child instance id basically in a similar way to overriding rules
        const isCashBlock = object.id === this.props.dataForScenario.cashBlockId;
        const effectToRuleMap = new Map();
        let taxTotal = 0;
        for (const childInstanceId of object.childInstanceIds) {
          const childRule = rules.find(item => item.id === childInstanceId);
          const childRuleChangeObj = dataPoint.rules.find(item => item.id === childInstanceId).changes;
          if (isCashBlock) taxTotal += childRuleChangeObj.cumulativeTax || 0;

          const breakdownObj = {
            type: childRule.type,
            id: childRule.id,
            data: childRule.data,
            changes: {
              cumulativeDelta: childRuleChangeObj.parentContainerMap?.get(object.id)?.cumulativeDelta || 0
            },
            isOverlappingRule: true,
            nodeKey: childRule.nodeKey
          };
          const effect = getRuleObject(childRule).effect;
          const plusKey = getSplitBreakdownKey(object.id, 1, effect);
          if (childRuleChangeObj.parentContainerMap?.has(plusKey)) {
            const nameToVal = {
              [getSplitBreakdownKey("", 1, effect)]:
                childRuleChangeObj.parentContainerMap.get(plusKey)?.cumulativeDelta || 0,
              [getSplitBreakdownKey("", -1, effect)]:
                childRuleChangeObj.parentContainerMap.get(getSplitBreakdownKey(object.id, -1, effect))
                  ?.cumulativeDelta || 0
            };
            if (
              Math.round(nameToVal[splitBreakdownKeys.MAIN_EFFECT] + nameToVal[splitBreakdownKeys.ADJUSTMENT]) ===
              Math.round(breakdownObj.changes.cumulativeDelta)
            ) {
              if (breakdownObj.changes.cumulativeDelta === 0 && isCashBlock) {
                breakdownObj.changes.cumulativeDelta = Number.MIN_VALUE;
              }
              const getCleanNumber = key =>
                shortFormatNumberWithCurrency(Math.abs(nameToVal[key]), this.props.dataForScenario.currency);
              breakdownObj.subtext = `${getCleanNumber(splitBreakdownKeys.MAIN_EFFECT)} - ${getCleanNumber(
                splitBreakdownKeys.ADJUSTMENT
              )} (adjustment)`;
            }
          }
          if (childRuleChangeObj.subtext) {
            breakdownObj.subtext = childRuleChangeObj.subtext;
          }

          // grouping rules by effect
          if (!effectToRuleMap.has(effect)) {
            effectToRuleMap.set(effect, []);
          }
          effectToRuleMap.get(effect).push(breakdownObj);
        }
        const pushEffects = effectArray => {
          for (const effect of effectArray) {
            const currBreakdownObjs = effectToRuleMap.get(effect) || [];
            for (const breakdownObj of currBreakdownObjs) {
              breakdown[key].rules.push(breakdownObj);
            }
          }
        };
        pushEffects(cashEffectRules);
        pushEffects(nonCashEffectRules);
        if (taxTotal) {
          breakdown[key].rules.push(createTaxBlock(taxTotal));
        }
        breakdown[key].hasChildRuleInstance = true;
      }
      if (object.parentContainers) {
        breakdown[key].rules.push(getSelfDataFromPCMap(key, data));
      } else {
        const modifiedData = JSON.parse(JSON.stringify(data));
        breakdown[key].rules.push({ nodeKey: key, ...modifiedData });
      }

      if (!object.data === false && !object.data[planningVariables.NEW_ASSET] === false) {
        breakdown[key].description = i18n.t("newAsset");
      }
      if (!object.data === false && !object.data[planningVariables.NEW_DEBT] === false) {
        breakdown[key].description = i18n.t("newDebt");
      }

      if (
        !object.data[planningVariables.DATE_AGE]?.date === false &&
        (!object.data[planningVariables.NEW_ASSET] === false || !object.data[planningVariables.NEW_DEBT] === false)
      ) {
        breakdown[key].description =
          (breakdown[key].description ? breakdown[key].description + " created on " : "Created on ") +
          getCustodianHistoryFormattedDateString(
            parseKuberaDateString(object.data[planningVariables.DATE_AGE].date).getTime()
          );
        breakdown[key].isNewItem = true;
      }
    };

    const insertCashOutflowNode = (object, data) => {
      const cashflowRuleData = JSON.parse(JSON.stringify(data));
      cashflowRuleData.changes.cumulativeDelta = getCashCumulativeDeltaForRule(data, object.effect);

      const addNode = nodeKey => {
        if (!breakdown[nodeKey] === true) {
          breakdown[nodeKey] = {
            initialValue: 0,
            label: this.getRuleNodeLabel(nodeKey, debtRules),
            rules: []
          };
        }
        breakdown[nodeKey].rules.push(
          object.parentContainers ? getCashFlowData(data, cashflowRuleData) : cashflowRuleData
        );
      };

      if (object.category === planningRuleCategories.EXPENSE) {
        addNode(ruleNodeKeys.EXPENSE);
      } else {
        addNode(ruleNodeKeys.CASH_OUTFLOW);
      }
    };

    const insertCashInflowNode = (object, data) => {
      const cashflowRuleData = JSON.parse(JSON.stringify(data));
      cashflowRuleData.changes.cumulativeDelta = getCashCumulativeDeltaForRule(data, object.effect);

      const addNode = nodeKey => {
        if (!breakdown[nodeKey] === true) {
          breakdown[nodeKey] = {
            initialValue: 0,
            label: this.getRuleNodeLabel(nodeKey, debtRules),
            rules: []
          };
        }
        breakdown[nodeKey].rules.push(
          object.parentContainers ? getCashFlowData(data, cashflowRuleData) : cashflowRuleData
        );
      };

      if (object.category === planningRuleCategories.INCOME) {
        addNode(ruleNodeKeys.INCOME);
      } else {
        addNode(ruleNodeKeys.CASH_INFLOW);
      }
    };

    if (debtRules === false) {
      for (const ruleObject of ruleObjects) {
        const ruleData = dataPoint.rules.find(rule => rule.id === ruleObject.id);

        if (
          ruleObject.effect === planningRuleEffect.CHANGE_ASSET ||
          ruleObject.effect === planningRuleEffect.TRANSFER
        ) {
          insertNode(ruleObject, ruleData);
        } else if (ruleObject.effect === planningRuleEffect.INCREASE_ASSET_DECREASE_CASH) {
          insertNode(ruleObject, ruleData);
          insertCashOutflowNode(ruleObject, ruleData);
        } else if (ruleObject.effect === planningRuleEffect.INCREASE_CASH_DECREASE_ASSET) {
          insertNode(ruleObject, ruleData);
          insertCashInflowNode(ruleObject, ruleData);
        } else if (
          ruleObject.effect === planningRuleEffect.DECREASE_DEBTS_CASH ||
          ruleObject.effect === planningRuleEffect.DECREASE_CASH
        ) {
          insertCashOutflowNode(ruleObject, ruleData);
        } else if (
          ruleObject.effect === planningRuleEffect.INCREASE_DEBTS_CASH ||
          ruleObject.effect === planningRuleEffect.INCREASE_CASH
        ) {
          insertCashInflowNode(ruleObject, ruleData);
        }
      }
    } else {
      for (const ruleObject of ruleObjects) {
        const ruleData = dataPoint.rules.find(rule => rule.id === ruleObject.id);
        insertNode(ruleObject, ruleData);
      }
      /**
       * In debts, showing a specific debt and then all debts at the end will represent that debt twice
       * So, we subtract out the initial node values and hastily transform all debts to other debts
       */
      const allEntryKey = Object.keys(breakdown).find(key => key === planningAssetTypes.all.label);
      if (allEntryKey) {
        // only applies when there is all + other debts
        let otherDebtsTotal = 0;
        let otherCDs = false;
        for (const key of Object.keys(breakdown)) {
          if (key === planningAssetTypes.all.label) {
            continue;
          }
          const netVal =
            breakdown[key].initialValue +
            breakdown[key].rules
              ?.filter(rule => rule.isOverridingRule)
              ?.reduce((total, rule) => rule?.changes?.cumulativeDelta + total, 0); // adding overriding totals(remember, they are negative) to get the actual initial value of the debt
          const ruleCD = breakdown[key].rules
            ?.filter(rule => !rule.isOverridingRule)
            ?.reduce((total, rule) => rule?.changes?.cumulativeDelta + total, 0); // making sure the other node key will actually show up by ensuring it has a non 0 ruleCD
          if (ruleCD !== 0) {
            otherCDs = true;
            otherDebtsTotal += breakdown[key].isNewItem ? 0 : netVal;
          }
        }
        if (otherCDs) {
          const newObj = JSON.parse(JSON.stringify(breakdown[allEntryKey]));
          newObj.initialValue -= otherDebtsTotal;
          newObj.overriddenLabels = [];
          newObj.rules = newObj.rules.filter(rule => !rule.isOverridingRule);
          newObj.label = "Other Debts";
          breakdown[allEntryKey] = newObj;
        }
      }
    }

    const allEntryKey = Object.keys(breakdown).find(key => key === planningAssetTypes.all.label);
    if (!allEntryKey === true) {
      const nonEmptyKeys = Object.keys(breakdown).filter(key => {
        return (
          breakdown[key].rules.filter(rule => !rule.isOverridingRule && rule.changes.cumulativeDelta !== 0).length > 0
        );
      });

      if (nonEmptyKeys.length > 0) {
        const applicableKeys = nonEmptyKeys.filter(key => {
          if (breakdown[key].initialValue === 0) {
            return false;
          }

          for (const testKey of nonEmptyKeys) {
            if (!breakdown[testKey].overriddenLabels === false && breakdown[testKey].overriddenLabels.includes(key)) {
              return false;
            }
          }
          return true;
        });

        const portfolioTotal = debtRules ? this.props.portfolioTotalForDebts : this.props.portfolioTotalForAssets;
        var totalInitialValueForRules = applicableKeys.reduce((total, key) => {
          return total + breakdown[key].initialValue;
        }, 0);

        const othersTotal = portfolioTotal - totalInitialValueForRules;
        if (othersTotal > 0) {
          breakdown[ruleNodeKeys.OTHERS] = {
            initialValue: portfolioTotal - totalInitialValueForRules,
            label:
              portfolioTotal === othersTotal
                ? this.getCurrentValueLabel(this.getRuleNodeLabel(ruleNodeKeys.ALL, debtRules))
                : this.getRuleNodeLabel(ruleNodeKeys.OTHERS, debtRules)
          };
        }
      }
    }

    // Set keys in the order of display
    const moveKeyToEnd = key => {
      if (!breakdown[key] === false) {
        const data = breakdown[key];
        delete breakdown[key];
        breakdown[key] = data;
      }
    };
    moveKeyToEnd(ruleNodeKeys.ALL);
    moveKeyToEnd(ruleNodeKeys.INCOME);
    moveKeyToEnd(ruleNodeKeys.EXPENSE);
    moveKeyToEnd(ruleNodeKeys.CASH_INFLOW);
    moveKeyToEnd(ruleNodeKeys.CASH_OUTFLOW);

    return breakdown;
  }

  getNetworthBreakdown(rawNetworth, inflationCD) {
    return {
      rawNetworth: { initialValue: rawNetworth, label: "Net Worth" },
      inflationCD: {
        initialValue: inflationCD,
        label: "Adjustment for Inflation",
        showColor: true
      }
    };
  }

  getCurrentValue(ruleObject) {
    if (
      ruleObject.category === planningRuleCategories.INCOME ||
      ruleObject.category === planningRuleCategories.EXPENSE
    ) {
      return this.props.portfolioCash;
    }
    if (!ruleObject.data[planningVariables.ASSET_TYPE] === false) {
      return getPortfolioTotalForAssetType(
        this.props.portfolio,
        ruleObject.data[planningVariables.ASSET_TYPE].value
      )[0];
    }
    if (!ruleObject.data[planningVariables.DEBT_TYPE] === false) {
      return getPortfolioTotalForDebtType(this.props.portfolio, ruleObject.data[planningVariables.DEBT_TYPE].value);
    }
    if (!ruleObject.data[planningVariables.TICKER_ID] === false) {
      return getPorfolioTotalForTickerIds(this.props.portfolio, ruleObject.data[planningVariables.TICKER_ID].items);
    }
    if (!ruleObject.data[planningVariables.ASSET_ID] === false) {
      return getCurrentValueForCustodianItems(ruleObject.data[planningVariables.ASSET_ID].items);
    }
    if (!ruleObject.data[planningVariables.DEBT_ID] === false) {
      return getCurrentValueForCustodianItems(ruleObject.data[planningVariables.DEBT_ID].items);
    }
    if (!ruleObject.data[planningVariables.DEBT_IDS] === false) {
      return getCurrentValueForCustodianItems(ruleObject.data[planningVariables.DEBT_IDS].items);
    }
    return 0;
  }

  getRuleNodeLabel(nodeKey, isDebtRule) {
    if (nodeKey === ruleNodeKeys.CASH) {
      return i18n.t("cash");
    }
    if (nodeKey === ruleNodeKeys.ALL) {
      return `${i18n.t("all")} ${isDebtRule ? i18n.t("debts") : i18n.t("assets")}`;
    }
    if (nodeKey === ruleNodeKeys.OTHERS) {
      return `${i18n.t("other")} ${isDebtRule ? i18n.t("debts") : i18n.t("assets")}`;
    }
    return nodeKey;
  }

  getCurrentValueLabel(label, nodeTickerId = null) {
    if (!nodeTickerId === false) {
      const ticker = getTickerUsingId(nodeTickerId);
      const currentQuantity = formatNumberWithCurrency(
        getQuantityForTickerId(this.props.portfolio, nodeTickerId),
        ticker.shortName,
        false,
        4,
        false,
        false,
        false
      );
      const exchangeRate = getExchangeRate(ticker.shortName, this.props.portfolio.currency);
      return `${currentQuantity} ${ticker.code} @ ${formatNumberWithCurrency(
        exchangeRate,
        this.props.portfolio.currency,
        false,
        isCryptoCurrency(this.props.portfolio.currency) ? 4 : 2,
        undefined,
        undefined,
        true
      )} ${i18n.t("asOfToday")}`;
    }
    return `${label} ${i18n.t("asOfToday")}`;
  }

  getRuleData(rule) {
    return this.props.dataForScenario.processedRules.find(item => item.id === rule.id).data;
  }

  getRuleText(rule, breakdown, debtRule) {
    if (rule.id === planningVariables.TAX) return "Tax";
    // patching in nodeKey to return an alternate name if it is being used in asset
    var ruleText = getRuleText(rule.type, this.getRuleData(rule), rule.nodeKey);

    if (rule.isOverridingRule === true) {
      const ruleObject = getRuleObject(this.props.dataForScenario.processedRules.find(item => item.id === rule.id));
      const ruleNodeLabel = this.getRuleNodeLabel(getRuleNodeKey(ruleObject), debtRule);
      ruleText = `${i18n.t("less")} ${rule.cashOverride ? `${rule.containerNodeKey} - ` : ""}${ruleNodeLabel}`;
    }
    return ruleText;
  }

  getRuleDescription(rule, debtRule) {
    if (rule.isOverridingRule === true) {
      const ruleObject = getRuleObject(this.props.dataForScenario.processedRules.find(item => item.id === rule.id));
      return rule.cashOverride
        ? "All cash assets and their changes are shown in the Cash node"
        : i18n.t("adjustDescription").replace("%s1%", this.getRuleNodeLabel(getRuleNodeKey(ruleObject), debtRule));
    }
    return rule.subtext;
  }

  getTableForBreakdown(breakdown, type) {
    const currency = this.props.dataForScenario.currency;
    const dp = this.getSelectedDataPoint();
    const startTotals = {
      [breakdownType.ASSET]: this.props.portfolioTotalForAssets,
      [breakdownType.DEBT]: this.props.portfolioTotalForDebts,
      [breakdownType.NETWORTH]: dp.rawNetworth
    };
    const total = startTotals[type];
    const endTotals = {
      [breakdownType.ASSET]: dp.assetsTotal,
      [breakdownType.DEBT]: dp.debtsTotal,
      [breakdownType.NETWORTH]: dp.networth
    };
    const totalChange = endTotals[type] - total;
    const nonEmptyKeys = Object.keys(breakdown).filter(key => {
      return (
        !breakdown[key].rules === true ||
        breakdown[key].rules.filter(rule => !rule.isOverridingRule && rule.changes.cumulativeDelta !== 0).length > 0
      );
    });
    const debtRules = type === breakdownType.DEBT;
    const totalTitles = {
      [breakdownType.ASSET]: i18n.t("totalAssets"),
      [breakdownType.DEBT]: i18n.t("totalDebts"),
      [breakdownType.NETWORTH]: i18n.t("adjustedNetworth")
    };
    const renderExpandedRow = (
      breakdown,
      key,
      overridingRules,
      overlappingRules,
      nonOverridingRules,
      initialValue,
      overridingRulesTotal,
      overlappingRulesTotal,
      isCashflow,
      label,
      debtRules
    ) => {
      const hasChildRuleInstance = breakdown[key].hasChildRuleInstance && debtRules && key !== ruleNodeKeys.ALL;
      if (!hasChildRuleInstance) {
        return (
          <>
            {isCashflow === false && !initialValue === false && (
              <BreakdownChildRow>
                <TitleContainer>
                  <Title>{this.getCurrentValueLabel(label, breakdown[key].nodeTickerId)}</Title>
                </TitleContainer>
                <Value currency={currency} value={initialValue} roundDown={true} />
              </BreakdownChildRow>
            )}

            {overridingRules.length > 0 &&
              overridingRules.map((rule, index) => {
                if (rule.changes.cumulativeDelta === 0) {
                  return null;
                }

                const description = this.getRuleDescription(rule, debtRules);
                return (
                  <BreakdownChildRow key={index}>
                    <TitleContainer>
                      <Title>{this.getRuleText(rule, breakdown, debtRules)}</Title>
                      {!description === false && <Description>{description}</Description>}
                    </TitleContainer>
                    <ChangeValue
                      currency={currency}
                      disableShortFormat={true}
                      startValue={0}
                      endValue={Math.ceil(rule.changes.cumulativeDelta)} // Math.round would sometimes round down, leading to a more negative value being subtracted
                      preventAnimation={true}
                      disableColor={true}
                      minusMinus={true} // show positive overriding rows(value being subtracted is negative) as - - instead of +
                    />
                  </BreakdownChildRow>
                );
              })}

            {overridingRules.length > 0 && (
              <BreakdownChildRow>
                <TitleContainer>
                  <Title>{`${i18n.t("net")} ${label}`}</Title>
                </TitleContainer>
                <Value currency={currency} value={Math.round(initialValue + overridingRulesTotal)} roundDown={true} />
              </BreakdownChildRow>
            )}

            {overlappingRules.map((rule, index) => {
              if (rule.changes.cumulativeDelta === 0) {
                return null;
              }

              const description = this.getRuleDescription(rule, debtRules);
              return (
                <BreakdownChildRow key={index}>
                  <TitleContainer>
                    <Title>{this.getRuleText(rule, breakdown, debtRules)}</Title>
                    {!description === false && <Description>{description}</Description>}
                  </TitleContainer>
                  <ChangeValue
                    currency={currency}
                    disableShortFormat={true}
                    startValue={0}
                    endValue={Math.round(rule.changes.cumulativeDelta)}
                    preventAnimation={true}
                    disableColor={debtRules === true}
                  />
                </BreakdownChildRow>
              );
            })}

            {nonOverridingRules.map((rule, index) => {
              if (rule.changes.cumulativeDelta === 0) {
                return null;
              }

              const description = this.getRuleDescription(rule, debtRules);
              return (
                <BreakdownChildRow key={index}>
                  <TitleContainer>
                    <Title>{this.getRuleText(rule, breakdown, debtRules)}</Title>
                    {!description === false && <Description>{description}</Description>}
                  </TitleContainer>
                  <ChangeValue
                    currency={currency}
                    disableShortFormat={true}
                    startValue={0}
                    endValue={Math.round(rule.changes.cumulativeDelta)}
                    preventAnimation={true}
                    disableColor={debtRules === true}
                  />
                </BreakdownChildRow>
              );
            })}
          </>
        );
      } else {
        return (
          <>
            {isCashflow === false && !initialValue === false && (
              <BreakdownChildRow>
                <TitleContainer>
                  <Title>{this.getCurrentValueLabel(label, breakdown[key].nodeTickerId)}</Title>
                </TitleContainer>
                <Value currency={currency} value={initialValue} roundDown={true} />
              </BreakdownChildRow>
            )}
            {nonOverridingRules.map((rule, index) => {
              if (rule.changes.cumulativeDelta === 0) {
                return null;
              }

              const description = this.getRuleDescription(rule, debtRules);
              return (
                <BreakdownChildRow key={index}>
                  <TitleContainer>
                    <Title>{this.getRuleText(rule, breakdown, debtRules)}</Title>
                    {!description === false && <Description>{description}</Description>}
                  </TitleContainer>
                  <ChangeValue
                    currency={currency}
                    disableShortFormat={true}
                    startValue={0}
                    endValue={Math.round(rule.changes.cumulativeDelta)}
                    preventAnimation={true}
                    disableColor={debtRules === true}
                  />
                </BreakdownChildRow>
              );
            })}

            {overridingRules.length > 0 &&
              overridingRules.map((rule, index) => {
                if (rule.changes.cumulativeDelta === 0) {
                  return null;
                }

                const description = this.getRuleDescription(rule, debtRules);
                return (
                  <BreakdownChildRow key={index}>
                    <TitleContainer>
                      <Title>{this.getRuleText(rule, breakdown, debtRules)}</Title>
                      {!description === false && <Description>{description}</Description>}
                    </TitleContainer>
                    <ChangeValue
                      currency={currency}
                      disableShortFormat={true}
                      startValue={0}
                      endValue={Math.round(rule.changes.cumulativeDelta)}
                      preventAnimation={true}
                      disableColor={debtRules === true}
                    />
                  </BreakdownChildRow>
                );
              })}

            {overlappingRules.map((rule, index) => {
              if (rule.changes.cumulativeDelta === 0) {
                return null;
              }

              const description = this.getRuleDescription(rule, debtRules);
              return (
                <BreakdownChildRow key={index}>
                  <TitleContainer>
                    <Title>{this.getRuleText(rule, breakdown, debtRules)}</Title>
                    {!description === false && <Description>{description}</Description>}
                  </TitleContainer>
                  <ChangeValue
                    currency={currency}
                    disableShortFormat={true}
                    startValue={0}
                    endValue={Math.round(rule.changes.cumulativeDelta)}
                    preventAnimation={true}
                    disableColor={debtRules === true}
                  />
                </BreakdownChildRow>
              );
            })}

            {/* {(overridingRules.length > 0 || overlappingRules.length > 0) && (
              <BreakdownChildRow>
                <TitleContainer>
                  <Title>{`${i18n.t("net")} ${label}`}</Title>
                </TitleContainer>
                <Value
                  currency={currency}
                  value={Math.round(initialValue + overridingRulesTotal + overlappingRulesTotal)}
                  roundDown={true}
                />
              </BreakdownChildRow>
            )} */}
          </>
        );
      }
    };
    return (
      <BreakdownTable showTopMargin={type !== breakdownType.ASSET}>
        {nonEmptyKeys.map((key, index) => {
          var label = breakdown[key].label;
          const initialValue = breakdown[key].initialValue;
          const isCashflow = [
            ruleNodeKeys.INCOME,
            ruleNodeKeys.EXPENSE,
            ruleNodeKeys.CASH_INFLOW,
            ruleNodeKeys.CASH_OUTFLOW
          ].includes(key);
          const isExpandable =
            initialValue !== 0 || isCashflow || (!breakdown[key].rules === false && breakdown[key].rules.length > 1);
          const isKeyExpanded = debtRules
            ? this.state.debtExpandedKeys.includes(key)
            : this.state.assetExpandedKeys.includes(key);
          const hideCurrentValue = isCashflow === true;

          if (!breakdown[key].rules === true) {
            return (
              <BreakdownRow key={index} hideBorder={index === 0}>
                <TitleContainer>
                  <Title>{label}</Title>
                </TitleContainer>
                {breakdown[key].showColor ? (
                  <ChangeValue
                    currency={currency}
                    disableShortFormat={true}
                    startValue={0}
                    endValue={initialValue}
                    preventAnimation={true}
                    disableColor={false}
                  />
                ) : (
                  <Value currency={currency} value={initialValue} roundDown={true} />
                )}
              </BreakdownRow>
            );
          }

          const totalChange = breakdown[key].rules.reduce((total, rule) => {
            return total + (rule.isOverridingRule === true ? 0 : rule.changes.cumulativeDelta);
          }, 0);
          const overriddenLabels = breakdown[key].overriddenLabels;
          const overridingRules = breakdown[key].rules
            .filter(rule => rule.isOverridingRule === true)
            .sort((a, b) => a.changes.cumulativeDelta - b.changes.cumulativeDelta); // subtractions and then additions
          const overlappingRules = breakdown[key].rules.filter(
            rule => rule.isOverlappingRule === true && rule.changes?.cumulativeDelta
          );
          const overridingRulesTotal = overridingRules.reduce((total, rule) => {
            return total + rule.changes.cumulativeDelta;
          }, 0);
          const overlappingRulesTotal = overlappingRules.reduce((total, rule) => {
            return total + rule.changes.cumulativeDelta;
          }, 0);
          const nonOverridingRules = breakdown[key].rules.filter(
            rule => !rule.isOverridingRule === true && !rule.isOverlappingRule === true
          );

          return (
            <div key={index}>
              <BreakdownRow
                hideBorder={index === 0}
                isExpandable={isExpandable}
                onClick={e => {
                  if (isExpandable === true) {
                    this.handleRowClick(e, key, debtRules);
                  }
                }}
              >
                <ExpandArrow collapsed={isKeyExpanded === false ? 1 : 0} expandable={isExpandable ? 1 : 0} />
                <TitleContainer>
                  <Title>{label}</Title>
                  {!overriddenLabels === false && overriddenLabels.length > 0 && (
                    <Description>{`${i18n.t("less")} ${getNameList(overriddenLabels)}`}</Description>
                  )}
                  {(!overriddenLabels === true || overriddenLabels.length === 0) &&
                    !breakdown[key].description === false && <Description>{breakdown[key].description}</Description>}
                </TitleContainer>
                <ChangeValue
                  currency={currency}
                  disableShortFormat={true}
                  startValue={0}
                  endValue={Math.round(totalChange)}
                  preventAnimation={true}
                  disableColor={debtRules}
                />
                {hideCurrentValue === false && (
                  <Value
                    currency={currency}
                    value={Math.round(initialValue + totalChange + overridingRulesTotal)}
                    roundDown={true}
                  />
                )}
              </BreakdownRow>
              {isKeyExpanded === true &&
                renderExpandedRow(
                  breakdown,
                  key,
                  overridingRules,
                  overlappingRules,
                  nonOverridingRules,
                  initialValue,
                  overridingRulesTotal,
                  overlappingRulesTotal,
                  isCashflow,
                  label,
                  debtRules
                )}
            </div>
          );
        })}
        <FooterRow hideBorder={nonEmptyKeys.length === 0}>
          <TitleContainer>
            <Title>{totalTitles[type]}</Title>
          </TitleContainer>
          {!totalChange === false && type !== breakdownType.NETWORTH && (
            <ChangeValue
              currency={currency}
              disableShortFormat={true}
              startValue={0}
              endValue={Math.round(totalChange)}
              preventAnimation={true}
              disableColor={debtRules}
            />
          )}

          <Value currency={currency} value={Math.round(total + totalChange)} roundDown={true} />
        </FooterRow>
      </BreakdownTable>
    );
  }

  render() {
    const assetsBreakdown = this.getRulesBreakdown(getAssetRules(this.props.dataForScenario.processedRules));
    const debtsBreakdown = this.getRulesBreakdown(
      this.getDebtRules(this.props.dataForScenario.processedRules || []),
      true
    );
    const dp = this.getSelectedDataPoint();
    const { rawNetworth } = dp;
    return (
      <BreakdownContainer>
        {this.getTableForBreakdown(assetsBreakdown, breakdownType.ASSET)}
        {this.getTableForBreakdown(debtsBreakdown, breakdownType.DEBT)}
        {rawNetworth !== undefined &&
          rawNetworth !== dp.networth &&
          this.getTableForBreakdown(
            this.getNetworthBreakdown(
              rawNetworth,
              dp.rules.find(item => item.type === "rule_26")?.changes?.cumulativeDelta || 0
            ),
            breakdownType.NETWORTH
          )}
      </BreakdownContainer>
    );
  }
}

const mapStateToProps = (state, props) => ({
  portfolio: currentPortfolioSelector(state),
  portfolioCash: portfolioCashOnHand(state),
  portfolioTotalForAssets: portfolioTotalForCategory(state, props.portfolio, category.ASSET),
  portfolioTotalForDebts: portfolioTotalForCategory(state, props.portfolio, category.DEBT)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NetworthBreakdownComponent));
