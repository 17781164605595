import React, { useState, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector } from "react-redux";

import {
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS,
  userPriceOptionSelector,
  accountAllPlansSelector,
  accountPlanSelector,
  accountSubscriptionStatusSelector,
  multiuserListSelector,
  isMobile
} from "@kubera/common";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import RadioButton from "components/inputs/RadioButton";
import DeferredPromise from "utilities/DeferredPromise";

import MobileAccountSubscriptionOptions from "./MobileAccountSubscriptionOptions";
import useOptionsRadioButtonSelectionHandler from "./useOptionsRadioButtonSelectionHandler";

const isMobileDevice = isMobile();

const RadioButtonsContainer = styled.div``;

const RadioOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  row-gap: 20px;
`;

const RadioOption = styled(RadioButton)`
  display: flex;
  align-items: center;

  div {
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
  }
`;

const PriceNote = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 3px;
  padding-left: 26px;
  white-space: pre-wrap;

  a {
    color: rgba(0, 0, 0, 0.5);
  }
`;

const SelectPersonalConfirmDialog = styled(ConfirmationDialog)`
  width: 655px;
`;

const AccountSubscriptionOptions = React.forwardRef(({ selectedFrequencyValue, handleRadioButtonSelection }, ref) => {
  const confirmModalDeferred = useRef(new DeferredPromise());

  const userPrice = useSelector(userPriceOptionSelector);
  const accountAllPlans = useSelector(accountAllPlansSelector);
  const accountPlan = useSelector(accountPlanSelector);
  const multiuserList = useSelector(multiuserListSelector);
  const accountSubscriptionStatus = useSelector(accountSubscriptionStatusSelector);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const onPositiveBtnClick = () => {
    setIsModalVisible(false);
    confirmModalDeferred.current.resolve(true);
  };

  const onNegativeBtnClick = () => {
    setIsModalVisible(false);
    confirmModalDeferred.current.resolve(false);
  };

  useImperativeHandle(ref, () => ({
    onSubmitShowModals: () => {
      if (
        accountPlan === "trial" &&
        selectedFrequencyValue === SUBSCRIPTION_PLANS.YEARLY &&
        multiuserList &&
        multiuserList.length > 1
      ) {
        setIsModalVisible(true);
        confirmModalDeferred.current = new DeferredPromise();
        return confirmModalDeferred.current;
      }

      return true;
    }
  }));

  useOptionsRadioButtonSelectionHandler(handleRadioButtonSelection);

  return (
    <>
      <RadioButtonsContainer>
        <RadioOptionContainer>
          {accountAllPlans.map((plan, index) => {
            const subscriptionPlan = SUBSCRIPTION_PLANS[plan.toUpperCase()];
            return (
              <div key={plan}>
                <RadioOption
                  label={i18n.t(`accountSettings.subscriptionOption_${plan}`).replace("%s%", userPrice[plan])}
                  value={subscriptionPlan}
                  checked={selectedFrequencyValue === subscriptionPlan}
                  disabled={
                    accountPlan === subscriptionPlan && accountSubscriptionStatus !== SUBSCRIPTION_STATUS.CANCELED
                  }
                  onChange={handleRadioButtonSelection}
                />
                <PriceNote
                  dangerouslySetInnerHTML={{
                    __html: i18n.t(`accountSettings.subsciptionNote_${plan}`)
                  }}
                />
              </div>
            );
          })}
        </RadioOptionContainer>
      </RadioButtonsContainer>
      {isModalVisible && (
        <SelectPersonalConfirmDialog
          title={i18n.t("accountSettings.dialogUserAccessLoseTitle")}
          description={i18n.t("accountSettings.dialogUserAccessLoseDesc")}
          positiveButtonTitle={i18n.t("accountSettings.dialogUserAccessBtn")}
          negativeButtonTitle={i18n.t("cancel")}
          handleNegativeButtonClick={onNegativeBtnClick}
          handlePositiveButtonClick={onPositiveBtnClick}
        />
      )}
    </>
  );
});

export default isMobileDevice ? MobileAccountSubscriptionOptions : AccountSubscriptionOptions;
