import React, { useRef, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  mergeSortSection,
  updateCustodianBulk,
  getExchangeRate,
  showRefreshingSelector,
  refreshCustodianTimeSelector,
  sectionsUpdatedSelector,
  removeSectionUpdated
} from "@kubera/common";

import GridCell from "./GridCell";

const sortUpdateOnRefreshTimes = {};

const GridHeaderCell = ({ index, sortPreference, ...otherProps }) => {
  const { cell, updateSortPreference, sectionId, sectionRows } = otherProps;

  const dispatch = useDispatch();
  const sortHandleTimeoutId = useRef();
  const prevShowRefreshing = useRef(false);
  const isElementMounted = useRef();
  const axiosCancelToken = useRef();

  const showRefreshing = useSelector(showRefreshingSelector);
  const refreshCustodianTime = useSelector(refreshCustodianTimeSelector);
  const sectionsUpdated = useSelector(sectionsUpdatedSelector);

  const getSortItemValue = item => {
    if (item.type === "currency") {
      return item.value * ((item.ownership || 100) / 100) * getExchangeRate(item.currency, "USD");
    }

    if (item.type === "text") {
      return (item.value || "").toLowerCase();
    }

    return item.value;
  };

  const setSortPreference = async preferenceToSet => {
    if (!updateSortPreference) {
      return;
    }

    if (preference !== preferenceToSet) {
      updateSortPreference(cell.name, preferenceToSet);
    }
    const { hasOrderChanged, idAndSortKeyHash, idAndSortKeyList } = mergeSortSection(
      sectionRows,
      "cells",
      index,
      getSortItemValue,
      preferenceToSet === "descending"
    );

    if (axiosCancelToken.current) {
      axiosCancelToken.current.cancel();
    }
    axiosCancelToken.current = axios.CancelToken.source();
    dispatch(
      updateCustodianBulk(
        { list: idAndSortKeyList, hashMap: idAndSortKeyHash, hasOrderChanged, cancelToken: axiosCancelToken.current },
        void 0
      )
    );
  };

  const timeoutCallSetCall = () => {
    clearTimeout(sortHandleTimeoutId.current);
    sortHandleTimeoutId.current = setTimeout(() => {
      setSortPreference(preference);
    }, 300);
  };

  const onVisibilityChange = useCallback(() => {
    const inputs = ["input", "textarea"];
    const activeElement = document.activeElement;
    if (
      activeElement &&
      inputs.indexOf(activeElement.tagName.toLowerCase()) >= 0 &&
      activeElement.hasAttribute("data-cell-mode")
    ) {
      return;
    }
    if (document.hidden === false && sectionsUpdated[sectionId] === true && preference) {
      timeoutCallSetCall();
    }
  }, [sectionsUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const preference = sortPreference && sortPreference[sectionId] && sortPreference[sectionId][cell.name];
  const switchSortPrefForClick =
    !sortPreference ||
    !sortPreference[sectionId] ||
    !sortPreference[sectionId][cell.name] ||
    sortPreference[sectionId][cell.name] === "ascending"
      ? "descending"
      : "ascending";

  useEffect(() => {
    isElementMounted.current = true;
    if ((!sortUpdateOnRefreshTimes[sectionId] || prevShowRefreshing.current) && preference) {
      sortUpdateOnRefreshTimes[sectionId] = new Date().getTime();
      timeoutCallSetCall();
    }

    prevShowRefreshing.current = showRefreshing;
    return () => {
      isElementMounted.current = false;
      clearTimeout(sortHandleTimeoutId.current);
    };
  }, [preference, showRefreshing]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      if (!isElementMounted.current && sectionsUpdated[sectionId] === true && preference) {
        timeoutCallSetCall();
        dispatch(removeSectionUpdated(sectionId));
      }
    };
  }, [sectionsUpdated, sectionRows]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [onVisibilityChange]);

  useEffect(() => {
    if (
      refreshCustodianTime &&
      (!sortUpdateOnRefreshTimes || sortUpdateOnRefreshTimes[sectionId] < refreshCustodianTime) &&
      preference
    ) {
      timeoutCallSetCall();
      sortUpdateOnRefreshTimes[sectionId] = new Date().getTime();
    }
  }, [refreshCustodianTime]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GridCell
      onClick={() => {
        if (["Cost", "Value", "Balance", "Asset", "Debt", "IRR"].includes(cell.name)) {
          setSortPreference(switchSortPrefForClick);
        }
      }}
      {...otherProps}
    />
  );
};

export default GridHeaderCell;
