import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
  irrTypes,
  getUuid,
  updateCustodianCashflow,
  convertCustodianHistoryApiDateFormatToUIFormat
} from "@kubera/common";
import CostbasisReturnsComponent from "./CostbasisReturnsComponent";
import CashflowReturnsComponent from "./CashflowReturnsComponent";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const CustodianReturnsComponent = ({ className, ...otherProps }) => {
  const dispatch = useDispatch();

  const {
    irrType,
    cashflows: defaultCashflows,
    cost,
    costExchangeRate,
    costTickerId,
    history: costHistory,
    custodianId
  } = otherProps;

  const isCostBasisShowAsFirstEntry = !((defaultCashflows && defaultCashflows.length > 0) || !cost);
  let cashflows = defaultCashflows || [];
  let costBasisRow = null;
  const historyDate = CostbasisReturnsComponent.getNewestCostHistoryDate(costHistory);
  if (isCostBasisShowAsFirstEntry && historyDate) {
    costBasisRow = {
      cashIn: cost,
      cashInExchangeRate: costExchangeRate,
      cashInTickerId: costTickerId,
      date: historyDate,
      id: getUuid(),
      note: "Cost Basis"
    };
    cashflows = [costBasisRow];
  }

  useEffect(() => {
    const getNewestCostHistoryDate = () => {
      return convertCustodianHistoryApiDateFormatToUIFormat(historyDate);
    };

    if (irrType === irrTypes.CASHFLOW && isCostBasisShowAsFirstEntry && historyDate) {
      dispatch(
        updateCustodianCashflow(true, custodianId, {
          ...costBasisRow,
          date: getNewestCostHistoryDate(),
          irrType
        })
      );
    }
  }, [irrType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className={className}>
      {irrType === irrTypes.COSTBASIS && <CostbasisReturnsComponent {...otherProps} cashflows={cashflows} />}
      {irrType === irrTypes.CASHFLOW && <CashflowReturnsComponent {...otherProps} cashflows={cashflows} />}
    </Container>
  );
};

export default CustodianReturnsComponent;
