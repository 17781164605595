import React from "react";
import styled, { css } from "styled-components";
import i18n from "i18next";
import { Link } from "react-router-dom";
import { routes, websiteUrls, hashParams } from "routes";
import { connect } from "react-redux";
import DOMPurify from "dompurify";
import { ReactComponent as Assets } from "assets/images/sidebar/assets.svg";
import { ReactComponent as Debts } from "assets/images/sidebar/debts.svg";
import { ReactComponent as NetWorth } from "assets/images/sidebar/net_worth.svg";
import { ReactComponent as Insurance } from "assets/images/sidebar/insurance.svg";
import { ReactComponent as SafeDeposit } from "assets/images/sidebar/documents.svg";
import { ReactComponent as Beneficiary } from "assets/images/sidebar/beneficiary.svg";
import { ReactComponent as Recap } from "assets/images/sidebar/recap.svg";
import { ReactComponent as Planning } from "assets/images/sidebar/planning.svg";
import { ReactComponent as SubItem } from "assets/images/sidebar/circle.svg";
import { ReactComponent as Plugin } from "assets/images/sidebar/hedge.svg";
import {
  shortFormatNumberWithCurrency,
  portfolioTotalForCategory,
  portfolioCurrencySelector,
  userPreferencesSelector,
  updateUserPreferences,
  currentPortfolioNetWorthDataSelector,
  chartTimeRange,
  wlClientContextSelector,
  isAppInWhiteLabelMode,
  siteConfigSelector,
  isAppInViewMode,
  shareCapabilities,
  getUserNameForViewMode,
  currentPortfolioDocumentsCountSelector,
  currentPortfolioCustodiansCountSelector,
  wlSetupType,
  recapDataSelector,
  getRecapChartOptionForPortfolio,
  RECAP_CATEGORY_TYPE_NETWORTH,
  recapChartTypes,
  isAmcUser,
  wlPaymentMethodSelector,
  isPortfolioReadySelector,
  isPlanningIntroScreenShownForPortfolio,
  userSelector,
  accountCreationTsSelector,
  getHashParams,
  SIDEBAR_CHANGES_TIMESTAMP,
  withRouter,
  showRecapPage,
  currentPortfolioSelector
} from "@kubera/common";
import { category } from "components/dashboard/DashboardComponent";
import hamburgerIcon from "assets/images/sidebar/hamburger.svg";
import AppLogo from "components/labels/AppLogo";
import WhiteLabelCollaborationSettingsDialog from "components/dashboard/WhiteLabelCollaborationSettingsDialog";
import HeadsUpDesktopBanner from "components/headsup/HeadsUpDesktopBanner";
import KuberaHeartsBanner from "components/headsup/KuberaHeartsBanner";
import AccountSettingsComponent, { accountSettingsTabs } from "components/account_settings/AccountSettingsComponent";
import ToolTip, { toolTipAlignment } from "components/tooltip/ToolTip";
const AssetsComponent = styled(Assets)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const DebtsComponent = styled(Debts)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const NetWorthComponent = styled(NetWorth)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const InsuranceComponent = styled(Insurance)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const SafeDepositComponent = styled(SafeDeposit)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const BeneficiaryComponent = styled(Beneficiary)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const RecapComponent = styled(Recap)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const PlanningComponent = styled(Planning)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const SubItemComponent = styled(SubItem)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
  overflow: visible;
`;

const PluginComponent = styled(Plugin)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const sideBarSections = [
  [
    {
      label: "Net Worth",
      icon: <NetWorthComponent />,
      subTitle: "Dashboard",
      route: routes.NET_WORTH,
      labelMargin: 0,
      disabled: false
    }
  ],
  [
    { label: "Assets", icon: <AssetsComponent />, route: routes.ASSETS, labelMargin: 0, disabled: false },
    { label: "Debts", icon: <DebtsComponent />, route: routes.DEBTS, labelMargin: 1, disabled: false }
  ],
  [
    { label: "Recap", icon: <RecapComponent />, route: routes.REPORTS, labelMargin: 0, disabled: false },
    { label: "Fast Forward", icon: <PlanningComponent />, route: routes.PLANNING, labelMargin: 0, disabled: false }
  ]
];

const collapsedSection = {
  top: {
    label: "Beneficiary",
    icon: <InsuranceComponent />,
    route: routes.BENEFICIARY,
    labelMargin: 2,
    disabled: false,
    id: "beneficiary",
    showToolTip: true
  },
  subSections: [
    {
      label: "Documents",
      icon: <SubItemComponent />,
      route: routes.SAFE_DEPOSIT_BOX,
      labelMargin: 0,
      disabled: false
    },
    { label: "Insurance", icon: <SubItemComponent />, route: routes.INSURANCE, labelMargin: 0, disabled: false }
  ]
};

const CollapsedSectionsContainer = styled.div`
  position: relative;
  contain: content;
  overflow: hidden;

  ::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props =>
      props.isSidebarCollapsed === true
        ? props.theme.dashboardBackgroundColor
        : props.theme.mode === "dark"
        ? "#0c0c0c"
        : "#ededed"};
    z-index: 1;
    transform: translateY(0);
    transition: transform 0.3s cubic-bezier(0.05, 0.69, 0.34, 1);
  }

  ${props =>
    props.expandCollapsedSection
      ? css`
          ::before {
            transform: translateY(100%);
          }
        `
      : null}
`;

const Sidebar = styled.div`
  min-width: ${props => (props.collapsed === true ? "50px" : "222px")};
  max-width: ${props => (props.collapsed === true ? "100px" : "222px")};
  transition: width 0.1s ease-out, min-width 0.1s ease-out;
  background: ${props =>
    props.collapsed === true ? props.theme.dashboardBackgroundColor : props.theme.sidebarBackground};
  display: flex;
  flex-direction: column;
`;

const SidebarContentContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SidebarFooterContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  flex-direction: column;
  row-gap: 30px;
  margin-bottom: 30px;
`;

const HamburgerContainer = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 35px;
  left: 20px;
  z-index: 1;
`;

const BetaAppIndicator = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 11px;
  color: ${props => props.theme.sidebarColor};
`;

const HamburgerIcon = styled.div`
  width: 16px;
  height: 30px;
  margin-left: 2px;
  background-color: transparent;
  background-image: url(${hamburgerIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
`;

const HamburgerAppLogo = styled(AppLogo)`
  width: 150px;
`;

const HamburgerLabel = styled(Link)`
  margin-left: 12px;
  cursor: pointer;
  text-decoration: none;
`;

const SidebarSection = styled.div`
  width: ${props => (props.collapsed === true ? "20px" : "100%")};
  margin-top: ${props => (props.isFirst === true ? "70px" : "0px")};
`;

const SectionSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.sidebarSectionSepartorColor};
  margin-top: 5px;
  margin-bottom: 5px;
`;

const StyledLink = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: black;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const SidebarLink = styled.div`
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: ${props => (props.disabled === true ? "" : "pointer")};
`;

const SidebarLinkTitle = styled.div`
  display: flex;
  align-items: center;
  opacity: ${props => (props.collapsed === true ? "0.0" : "1.0")}
  transition: opacity 0.1s ease-out;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  font-weight: ${props => (props.selected ? "bold" : "normal")};
  color: ${props => (props.disabled === true ? props.theme.sidebarDisabledColor : props.theme.sidebarColor)};
  font-feature-settings: "ss01" on;
  margin-left: 12px;
  white-space: nowrap;
  margin-top: ${props => props.marginTop + "px"}
`;

const SidebarLinkDescription = styled.div`
  display: ${props => (props.collapsed === true ? "none" : "flex")};
  align-items: center;
  margin-left: auto;
  margin-top: 2px;
  font-style: normal;
  font-weight: ${props => (props.selected ? "bold" : "normal")};
  font-size: 13px;
  line-height: 13px;
  text-align: right;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.sidebarDescCLR};
`;

const SidebarLinkImage = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;

  & > svg {
    stroke: ${props => (props.selected === true ? props.theme.dashboardComponentContainerCLR : "none")};
    stroke-opacity: ${props => (props.theme.mode === "default" ? 0.5 : null)};
    opacity: ${props => (props.disabled === true ? 0.4 : 1)};
    width: 20px;
  }
`;

const SideBarLinkSubTitle = styled.div`
  margin-left: 32px;
  margin-top: -10px;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.sidebarSubTitleColor};
  cursor: pointer;
`;

const WhiteLabelInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 35px;
  margin-right: 10px;
  cursor: pointer;
`;

const WhiteLabelInfoTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.sidebarInfoCLR};
`;

const WhiteLabelInfoDescription = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.sidebarInfoCLR};
`;

const ViewModeInfoContainer = styled(WhiteLabelInfoContainer)`
  margin-bottom: 30px;
  row-gap: 15px;
  cursor: auto;
`;

const ViewModeSharedBy = styled(WhiteLabelInfoTitle)``;

const ViewModeUser = styled(WhiteLabelInfoDescription)`
  text-transform: capitalize;
`;

const GetKuberaText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.sidebarInfoCLR};
  cursor: pointer;
`;

const GetKuberaDescription = styled.span`
  font-weight: 400;
`;

const Disclaimer = styled.div`
  margin-top: -15px;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 10px;
  color: ${props => props.theme.sidebarInfoCLR};
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  font-feature-settings: "ss01" on;
`;

const PluginImg = styled.div`
  width: 20px;
  height: 20px;
  background-image: url('${props => props.imgUrl}');
  background-size: contain;
`;

class SidebarComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedChartOptions: this.props.getRecapChartOptionForPortfolio,
      expandCollapsedSection: false
    };

    this.setRoute = this.setRoute.bind(this);
    this.handleHamburgerClick = this.handleHamburgerClick.bind(this);
    this.handleWhiteLabelInfoClick = this.handleWhiteLabelInfoClick.bind(this);
    this.handleGetKuberaClick = this.handleGetKuberaClick.bind(this);
    this.toolTipRef = React.createRef();
  }

  componentDidMount() {
    if (
      !this.props.userPreferences.dontShowSidebarCollapseTip &&
      this.props.accountCreationTs < SIDEBAR_CHANGES_TIMESTAMP &&
      this.toolTipRef.current
    ) {
      this.toolTipRef.current.show(i18n.t("sidebar.nesting"), -135, -92, () => {
        this.props.updateUserPreferences({ dontShowSidebarCollapseTip: true });
      });
    }
  }

  handleHamburgerClick(e) {
    this.props.updateUserPreferences({ isSidebarCollapsed: !this.props.userPreferences.isSidebarCollapsed });
  }

  handleWhiteLabelInfoClick(e) {
    WhiteLabelCollaborationSettingsDialog.show(this.props.history, this.props.location);
  }

  handleGetKuberaClick(e) {
    window.kuberaOpen(websiteUrls.WEB_HOMEPAGE);
  }

  setRoute(item) {
    if (item.disabled === true) {
      return;
    }
    this.props.history.push({
      ...this.props.location,
      pathname: item.route,
      hash: `${item.pluginData ? `${hashParams.ID}=${item.pluginData.id}` : ""}`
    });
  }

  getSideBarSections() {
    var sections = [...sideBarSections];
    sections[sections.length - 1] = [...sections[sections.length - 1]];
    if (
      isAppInWhiteLabelMode() ||
      this.props.siteConfig.capability ||
      (this.props.currentPortfolio && this.props.currentPortfolio.write === 0)
    ) {
      sections.push([
        { label: "Insurance", icon: <InsuranceComponent />, route: routes.INSURANCE, labelMargin: 0, disabled: false },
        {
          label: "Documents",
          icon: <SafeDepositComponent />,
          route: routes.SAFE_DEPOSIT_BOX,
          labelMargin: 0,
          disabled: false
        },
        {
          label: "Beneficiary",
          icon: <BeneficiaryComponent />,
          route: routes.BENEFICIARY,
          labelMargin: 2,
          disabled: false
        }
      ]);
    }
    sections[sections.length - 1] = [...sections[sections.length - 1]];

    const routesToRemove = [];
    if (this.props.siteConfig.showInsurance === 0) {
      routesToRemove.push(routes.INSURANCE);
    }
    if (this.props.siteConfig.showDocument === "hide") {
      routesToRemove.push(routes.SAFE_DEPOSIT_BOX);
    }
    if (
      this.props.siteConfig.showBeneficiary === 0 ||
      (this.props.currentPortfolio && this.props.currentPortfolio.write === 0)
    ) {
      routesToRemove.push(routes.BENEFICIARY);
    }
    sections = this.removeRoutesFromSections(sections, routesToRemove);

    if (this.props.siteConfig.showPlugin === 1 && Array.isArray(this.props.siteConfig.plugin)) {
      sections.push([]);
      this.props.siteConfig.plugin.forEach(eachPlugin => {
        sections[sections.length - 1].push({
          label: eachPlugin.name,
          icon: eachPlugin.logo ? <PluginImg imgUrl={eachPlugin.logo} /> : <PluginComponent />,
          route: routes.PLUGIN,
          labelMargin: 0,
          disabled: false,
          pluginData: eachPlugin
        });
      });
    }

    if (isAppInViewMode()) {
      const routesToRemove = [routes.BENEFICIARY];
      const capabilities = JSON.parse(this.props.siteConfig.capability);

      if (
        (capabilities.includes(shareCapabilities.ALL) === false &&
          capabilities.includes(shareCapabilities.INSURANCE) === false) ||
        this.props.insuranceCustodiansCount === 0
      ) {
        routesToRemove.push(routes.INSURANCE);
      }
      if (
        (capabilities.includes(shareCapabilities.ALL) === false &&
          capabilities.includes(shareCapabilities.DOCUMENT) === false) ||
        this.props.safeDepositDocumentsCount === 0
      ) {
        routesToRemove.push(routes.SAFE_DEPOSIT_BOX);
      }
      if (
        (capabilities.includes(shareCapabilities.ALL) === false &&
          capabilities.includes(shareCapabilities.NETWORTH)) === false
      ) {
        routesToRemove.push(routes.NET_WORTH);
      }
      if (
        capabilities.includes(shareCapabilities.ALL) === false &&
        capabilities.includes(shareCapabilities.RECAP) === false
      ) {
        routesToRemove.push(routes.REPORTS);
      }
      if (
        this.props.siteConfig.showPlanning === 0 ||
        (capabilities.includes(shareCapabilities.ALL) === false &&
          capabilities.includes(shareCapabilities.PLANNING) === false)
      ) {
        routesToRemove.push(routes.PLANNING);
      }
      sections = this.removeRoutesFromSections(sections, routesToRemove);
    } else {
      if (!this.props.wlClientContext === false) {
        const documentSideBarItem = sections.flat().find(item => item.route === routes.SAFE_DEPOSIT_BOX) || {};
        documentSideBarItem.disabled = this.props.wlClientContext.collaborateDocument === 0;
      } else {
        const documentSideBarItem =
          collapsedSection.subSections.find(item => item.route === routes.SAFE_DEPOSIT_BOX) || {};
        documentSideBarItem.disabled = this.props.siteConfig.showDocument === "hide";
      }

      if (this.props.siteConfig.showPlanning === 0) {
        sections[2] = sections[2].filter(item => item.route !== routes.PLANNING);
      }
    }
    return sections;
  }

  removeRoutesFromSections(sections, routesToRemove) {
    for (var i = 0; i < sections.length; i++) {
      sections[i] = sections[i].filter(item => routesToRemove.includes(item.route) === false);
    }
    sections = sections.filter(item => item.length > 0);
    return sections;
  }

  isRecapDataEmpty() {
    if (!this.props.recapData === true || !this.props.recapData.data === true) {
      return true;
    }
    return this.checkIfRecapDataIsEmpty();
  }

  checkIfRecapDataIsEmpty = () => {
    return (
      this.props.recapData &&
      this.props.recapData.data &&
      this.props.recapData.data[chartTimeRange.DAILY] &&
      this.props.recapData.data[chartTimeRange.DAILY][this.state.selectedChartOptions] &&
      this.props.recapData.data[chartTimeRange.DAILY][this.state.selectedChartOptions][recapChartTypes.TOTALS] &&
      this.props.recapData.data[chartTimeRange.DAILY][this.state.selectedChartOptions][recapChartTypes.TOTALS][
        RECAP_CATEGORY_TYPE_NETWORTH
      ] &&
      this.props.recapData.data[chartTimeRange.DAILY][this.state.selectedChartOptions][recapChartTypes.TOTALS][
        RECAP_CATEGORY_TYPE_NETWORTH
      ][0].values.length === 0
    );
  };

  handleWhiteLabelBillingInfoClick = () => {
    AccountSettingsComponent.show(this.props.history, this.props.location, accountSettingsTabs.CLIENT_BILLING);
  };

  render() {
    const currentPath = this.props.location.pathname;
    const currentPluginId = getHashParams(this.props.location)[hashParams.ID];
    const collapsed = this.props.userPreferences.isSidebarCollapsed;
    const currency = this.props.portfolioCurrency;
    const assetsTotal = this.props.portfolioTotalForAssets;
    const debtsTotal = this.props.portfolioTotalForDebts;
    const networth = assetsTotal - debtsTotal;
    const isWhiteLabelledApp = isAppInWhiteLabelMode();
    const wlClientContext = this.props.wlClientContext;
    const siteConfig = this.props.siteConfig;
    const sections = this.getSideBarSections();

    const renderLink = (item, index, shouldExpandCollapsedSection = false) => {
      return (
        <StyledLink
          key={index}
          onClick={() => {
            if (shouldExpandCollapsedSection && this.toolTipRef.current && this.toolTipRef.current.isVisible()) {
              this.toolTipRef.current.dismiss();
            }
            this.setState({
              expandCollapsedSection: shouldExpandCollapsedSection
            });
            this.setRoute(item);
          }}
          id={item.id ? item.id : undefined}
        >
          <SidebarLink
            key={index}
            title={item.label}
            data-cy={"menu" + item.label.replace(" ", "")}
            disabled={item.disabled}
          >
            {item.showToolTip && (
              <ToolTip ref={this.toolTipRef} targetId={"beneficiary"} align={toolTipAlignment.TOP_LEFT} />
            )}
            <SidebarLinkImage
              selected={currentPath.startsWith(item.route)}
              collapsed={collapsed}
              disabled={item.disabled}
            >
              {item.icon}
            </SidebarLinkImage>
            <SidebarLinkTitle
              selected={
                currentPath.startsWith(item.route) && (!item.pluginData || currentPluginId === item.pluginData.id)
              }
              collapsed={collapsed}
              marginTop={item.labelMargin}
              disabled={item.disabled}
            >
              {item.label}
            </SidebarLinkTitle>
            {item.route === routes.ASSETS && (
              <SidebarLinkDescription selected={currentPath.startsWith(item.route)} collapsed={collapsed}>
                {shortFormatNumberWithCurrency(assetsTotal, currency, true, true)}
              </SidebarLinkDescription>
            )}
            {item.route === routes.DEBTS && (
              <SidebarLinkDescription selected={currentPath.startsWith(item.route)} collapsed={collapsed}>
                {shortFormatNumberWithCurrency(debtsTotal, currency, true, true)}
              </SidebarLinkDescription>
            )}
            {item.route === routes.NET_WORTH && (
              <SidebarLinkDescription selected={currentPath.startsWith(item.route)} collapsed={collapsed}>
                {shortFormatNumberWithCurrency(networth, currency, true, true)}
              </SidebarLinkDescription>
            )}
          </SidebarLink>
          {!item.subTitle === false && collapsed === false && (
            <SideBarLinkSubTitle>{item.subTitle}</SideBarLinkSubTitle>
          )}
        </StyledLink>
      );
    };

    return (
      <Sidebar className={this.props.className} collapsed={collapsed}>
        {window.location.host === "beta.kubera.com" && <BetaAppIndicator>{i18n.t("beta")}</BetaAppIndicator>}
        <HamburgerContainer>
          <HamburgerIcon onClick={this.handleHamburgerClick} />
          {collapsed === false && (
            <HamburgerLabel
              to={
                isWhiteLabelledApp === true
                  ? routes.ASSETS + this.props.location.search + this.props.location.hash
                  : "#"
              }
            >
              <HamburgerAppLogo hasNoLink={isWhiteLabelledApp === true} regularSize={true} />
            </HamburgerLabel>
          )}
        </HamburgerContainer>
        <SidebarContentContainer>
          {sections.map((section, index) => {
            return (
              <SidebarSection
                key={index}
                isFirst={index === 0}
                isLast={isWhiteLabelledApp && index === sections.length - 1}
                collapsed={collapsed}
              >
                {section.map((item, index) => renderLink(item, index))}
                {(index !== sections.length - 1 || !isWhiteLabelledApp) && <SectionSeparator />}
              </SidebarSection>
            );
          })}

          {!isWhiteLabelledApp &&
            !this.props.siteConfig.capability === true &&
            this.props.currentPortfolio &&
            this.props.currentPortfolio.write !== 0 && (
              <SidebarSection key={sections.length} isFirst={false} isLast={true} collapsed={collapsed}>
                {renderLink(collapsedSection.top, 0, true)}
                <CollapsedSectionsContainer
                  expandCollapsedSection={this.state.expandCollapsedSection}
                  isSidebarCollapsed={collapsed}
                >
                  {collapsedSection.subSections.map((item, index) => renderLink(item, index + 1, true))}
                </CollapsedSectionsContainer>
              </SidebarSection>
            )}
        </SidebarContentContainer>
        {!collapsed && (
          <SidebarFooterContainer>
            <HeadsUpDesktopBanner portfolio={this.props.portfolio} />
            <KuberaHeartsBanner />
          </SidebarFooterContainer>
        )}
        {collapsed === false &&
          isWhiteLabelledApp === true &&
          isAppInViewMode() === false &&
          siteConfig.type !== wlSetupType.ADMIN_WR_CLIENT_RO && (
            <>
              {(!isAmcUser || !wlClientContext === true) && (
                <WhiteLabelInfoContainer onClick={this.handleWhiteLabelInfoClick}>
                  <WhiteLabelInfoTitle>
                    {!wlClientContext === false ? i18n.t("sharedBy") : i18n.t("sharedWith")}
                  </WhiteLabelInfoTitle>
                  <WhiteLabelInfoDescription>
                    {!wlClientContext === false ? wlClientContext.name : siteConfig.name}
                  </WhiteLabelInfoDescription>
                </WhiteLabelInfoContainer>
              )}
              <Disclaimer
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(siteConfig.disclaimer)
                }}
              />
            </>
          )}
        {collapsed === false && isAppInViewMode() === true && (
          <ViewModeInfoContainer>
            {!isAmcUser && (
              <div>
                <ViewModeSharedBy>{i18n.t("sharedBy")}</ViewModeSharedBy>
                <ViewModeUser>
                  {siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO ? siteConfig.name : getUserNameForViewMode()}
                </ViewModeUser>
              </div>
            )}
            {isWhiteLabelledApp === false && (
              <GetKuberaText onClick={this.handleGetKuberaClick}>
                {i18n.t("getKubera") + " - "}
                <GetKuberaDescription>{i18n.t("kuberaDescription")}</GetKuberaDescription>
              </GetKuberaText>
            )}
          </ViewModeInfoContainer>
        )}
      </Sidebar>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: userSelector(state),
  userPreferences: userPreferencesSelector(state),
  currentPortfolio: currentPortfolioSelector(state),
  portfolioCurrency: portfolioCurrencySelector(state, props.portfolio),
  portfolioTotalForAssets: portfolioTotalForCategory(state, props.portfolio, category.ASSET),
  portfolioTotalForDebts: portfolioTotalForCategory(state, props.portfolio, category.DEBT),
  insuranceCustodiansCount: currentPortfolioCustodiansCountSelector(state, category.INSURANCE, true),
  netWorthData: currentPortfolioNetWorthDataSelector(state),
  recapData: recapDataSelector(state),
  wlClientContext: wlClientContextSelector(state),
  siteConfig: siteConfigSelector(state),
  safeDepositDocumentsCount: currentPortfolioDocumentsCountSelector(state),
  isAmcUser: isAmcUser(state),
  wlPaymentMethod: wlPaymentMethodSelector(state),
  isPortfolioReady: isPortfolioReadySelector(state),
  isPlanningIntroScreenShown: isPlanningIntroScreenShownForPortfolio(state, props.portfolio),
  accountCreationTs: accountCreationTsSelector(state),
  getRecapChartOptionForPortfolio: getRecapChartOptionForPortfolio(state),
  shouldShowRecapPage: showRecapPage(state)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SidebarComponent));
