import React from "react";
import styled from "styled-components";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  getLineChartGroupedDataForScenario,
  months,
  portfolioNetWorth,
  parseKuberaDateString,
  updateUserPreferences
} from "@kubera/common";
import LineChartComponent from "components/charts/LineChartComponent";
import NetworthBreakdownDialog from "../breakdown/NetworthBreakdownDialog";

import NetworthChartValueItem from "./NetworthChartValueItem";

const Container = styled.div`
  width: 100%;
  background: ${props => props.theme.gridRowUpdatedBackgroundColor};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
`;

const ValueContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 20px;
`;

const LineChart = styled(LineChartComponent)`
  flex: 1;
  margin-top: 25px;
  cursor: pointer;
`;

class NetworthChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLastDataPointIndex: null,
      preventAnimation: false,
      showBreakdown: false,
      breakdownIndex: null,
      breakdownScenarioIndex: null
    };

    this.handleValueItemClick = this.handleValueItemClick.bind(this);
    this.handleLineChartHoverOverSection = this.handleLineChartHoverOverSection.bind(this);
    this.handleLineChartClickOverSection = this.handleLineChartClickOverSection.bind(this);
    this.handleBreakdownDimiss = this.handleBreakdownDimiss.bind(this);
  }

  handleLineChartClickOverSection(index) {
    this.setState({ breakdownIndex: index, showBreakdown: true });
    this.props.updateUserPreferences({
      planningChartClickTip: true
    });
  }

  handleBreakdownDimiss() {
    this.setState({ breakdownIndex: null, showBreakdown: false, breakdownScenarioIndex: null });
  }

  handleLineChartHoverOverSection(index) {
    this.setState({ currentLastDataPointIndex: index, preventAnimation: true }, () => {
      if (this.state.currentLastDataPointIndex === null) {
        setTimeout(() => {
          this.setState({ preventAnimation: false });
        }, 1000);
      }
    });
  }

  handleValueItemClick(e, groupedData, scenarioIndex) {
    this.setState({
      breakdownScenarioIndex: scenarioIndex,
      showBreakdown: true,
      breakdownIndex: groupedData[scenarioIndex].data.length - 1
    });
  }

  getEndValue(groupedData, scenarioIndex, dataIndex) {
    const dataPoints = groupedData[scenarioIndex].data;

    if (dataPoints.length === 0) {
      return null;
    }

    const dataPointIndex = dataIndex === null ? dataPoints.length - 1 : dataIndex;
    return dataPoints[dataPointIndex].networth;
  }

  getLineChartData(groupedData) {
    var applicableData = [...groupedData].filter(item => this.props.selectedScenarios.includes(item.id));
    if (applicableData.length === 0) {
      return null;
    }

    const data = {
      labels: applicableData[0].data.map((dataPoint, index) => {
        const date = parseKuberaDateString(dataPoint.date);
        return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`.toUpperCase();
      }),
      labelFormat: label => {
        const date = parseKuberaDateString(label);
        return `${months[date.getMonth()]} ${date.getFullYear()}`.toUpperCase();
      },
      datasets: applicableData.map(dataForScenario => {
        return {
          data: dataForScenario.data.map((dataPoint, index) => {
            return Math.kuberaRoundToDecimalPlaces(dataPoint.networth, 2);
          })
        };
      }),
      options: applicableData.map((dataForScenario, index) => {
        return {
          borderColor: this.props.colorPallete[groupedData.findIndex(item => item.id === dataForScenario.id)].color,
          borderWidth: 2
        };
      })
    };
    return data;
  }

  getLineChartGroupedData() {
    return this.props.planningData.map(dataForScenario => {
      return getLineChartGroupedDataForScenario(dataForScenario);
    });
  }

  getDurationLabel(chartData, index) {
    if (index === null) {
      return chartData.labels[chartData.labels.length - 1];
    } else {
      return chartData.labels[index];
    }
  }

  render() {
    if (!this.props.planningData === true) {
      return null;
    }

    const groupedData = this.getLineChartGroupedData();
    const lineChartData = this.getLineChartData(groupedData);

    if (lineChartData === null) {
      return null;
    }

    return (
      <Container>
        <ContentContainer>
          <ValueContainer>
            {this.props.planningData.map((dataForScenario, index) => (
              <NetworthChartValueItem
                key={index}
                index={index}
                dataForScenario={dataForScenario}
                groupedData={groupedData}
                colorPallete={this.props.colorPallete}
                selectedScenarios={this.props.selectedScenarios}
                portfolioNetWorth={this.props.portfolioNetWorth}
                currentLastDataPointIndex={this.state.currentLastDataPointIndex}
                preventAnimation={this.state.preventAnimation}
                getEndValue={this.getEndValue}
                handleValueItemClick={this.handleValueItemClick}
              />
            ))}
          </ValueContainer>
          <LineChart
            data={lineChartData}
            chartHeight={240}
            adjustPageScroll={true}
            chartPadding={{
              left: 0,
              right: 0,
              top: 30,
              bottom: 0
            }}
            chartBgColor={"transparent"}
            onHoverOverSection={this.handleLineChartHoverOverSection}
            onClickOverSection={this.handleLineChartClickOverSection}
            scaleType={"time"}
          />
          {this.state.showBreakdown === true && (
            <NetworthBreakdownDialog
              colorPallete={this.props.colorPallete}
              groupedData={groupedData}
              planningData={this.props.planningData}
              dataIndex={this.state.breakdownIndex}
              selectedScenarios={this.props.selectedScenarios}
              selectedScenarioIndex={this.state.breakdownScenarioIndex}
              getEndValue={this.getEndValue}
              onDismiss={this.handleBreakdownDimiss}
            />
          )}
        </ContentContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  portfolioNetWorth: portfolioNetWorth(state)
});

const mapDispatchToProps = {
  updateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NetworthChartComponent));
