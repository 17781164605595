import React from "react";
import styled, { ThemeProvider } from "styled-components";
import theme from "theme";
import i18n from "i18next";
import { connect } from "react-redux";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  cellType,
  PercentageCellData
} from "components/grid/GridDataModel";
import {
  getUuid,
  getPercentageChange,
  getTickerUsingId,
  accountLinkingService,
  getCustodianLastUpdateDetails,
  tickerTypes,
  expandHoldingsForCustodian,
  isCryptoCurrency,
  isCryptoLinkingService,
  updateCustodianHolding,
  showToastTip,
  fetchTickerDetails,
  accountLinkingContainers,
  refreshCustodian,
  fetchCustodianDetails
} from "@kubera/common";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { category } from "components/dashboard/DashboardComponent";
import GlowingBadgeTip from "components/tooltip/GlowingBadgeTip";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const EmptyHoldingsMessage = styled.div`
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: black;
  white-space: pre;

  a {
    color: ${props => props.theme.linkColor};
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2px;
`;

const LastUpdated = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: ${props => (props.isStale === true ? "rgba(255, 0, 0)" : "rgba(0, 0, 0, 0.4)")};
`;

const HoldingsBadge = styled(GlowingBadgeTip)`
  margin-right: 5px;
`;

const ExpandHoldingsButton = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on, "calt" off;
  cursor: pointer;
`;

const Grid = styled(GridComponentWrapper)`
  margin-left: -1px;
  margin-right: -1px;
  margin-top: 4px;
`;

class CustodianHoldingsComponent extends React.Component {
  constructor(props) {
    super(props);

    const holdings = props.holdings;
    const gridData = this.getGridData(props.currency, holdings);
    this.state = { gridData: gridData, isExpanding: false, showConfirmationDialog: false };

    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.handleExpandHoldings = this.handleExpandHoldings.bind(this);
    this.handleConfirmationDialogPositiveButtonClick = this.handleConfirmationDialogPositiveButtonClick.bind(this);
    this.handleConfirmationDialogNegativeButtonClick = this.handleConfirmationDialogNegativeButtonClick.bind(this);
    this.handleCellInvalidTickerAdded = this.handleCellInvalidTickerAdded.bind(this);

    this.updateHoldingsPromiseList = {};
  }

  componentDidMount() {
    console.log("this.props.isSameAsHoldingsTotal", this.props.isSameAsHoldingsTotal);
    const { refreshCustodian, fetchDetails, custodianId } = this.props;
    if (!this.props.isSameAsHoldingsTotal) {
      refreshCustodian(
        custodianId,
        () => {
          fetchDetails(custodianId);
        },
        undefined,
        undefined,
        undefined,
        { force: 1 }
      );
    }
  }

  componentDidUpdate(oldProps) {
    if (this.props.holdings !== oldProps.holdings) {
      this.setState({
        gridData: this.getGridData(this.props.currency, this.props.holdings)
      });
    }
  }

  componentWillUnmount() {
    const { refreshCustodian, fetchDetails, custodianId } = this.props;
    const promises = Object.values(this.updateHoldingsPromiseList);

    if (promises.length > 0) {
      Promise.all(promises).then(() => {
        refreshCustodian(
          custodianId,
          () => {
            fetchDetails(custodianId);
          },
          undefined,
          undefined,
          undefined,
          { force: 1 }
        );
      });
    }
  }

  getGridData(currency, holdings) {
    var rows = [];
    for (const [index, holding] of holdings.entries()) {
      const row = this.getEmptyRow(`${index + 2}`);
      row.id = holding.id;
      const valueTicker = getTickerUsingId(holding.valueTickerId);
      const showDescription = [accountLinkingService.ZERION, accountLinkingService.IN_HOUSE_CRYPTO_API].includes(
        this.props.linkType
      );

      row.cells[0].symbol = void 0;
      if (!showDescription) {
        row.cells[0].value = holding.name;
      } else {
        row.cells[0].value = holding.name;
        row.cells[0].subValue = holding.description;
      }

      row.cells[2].value = holding.cost ? this.props.valueMultiplier * holding.cost : holding.cost;
      row.cells[2].tickerInfo = holding.costTickerInfo;
      row.cells[3].invalidInputText = holding.costInvalidInputText;
      row.cells[3].ownership = this.props.ownership;
      if (holding.value === null) {
        row.cells[3].value = "Unknown";
        row.cells[3].type = cellType.TEXT;
      } else {
        row.cells[3].value = holding.value ? this.props.valueMultiplier * holding.value : holding.value;
      }

      if (holding.costTickerId) {
        row.cells[2].currency = getTickerUsingId(holding.costTickerId).shortName;
      }
      if (holding.costExchangeRate) {
        row.cells[2].exchangeRateDetails = holding.costExchangeRate;
      }
      if (holding.valueTickerId) {
        row.cells[3].currency = valueTicker.shortName;
      }
      if (holding.valueExchangeRate) {
        row.cells[3].exchangeRateDetails = holding.valueExchangeRate;
      }

      row.cells[2].isEditable = this.props.isReadOnly === false;
      row.cells[3].isEditable =
        [
          accountLinkingService.ZERION,
          accountLinkingService.IN_HOUSE_CRYPTO_OAUTH,
          accountLinkingService.IN_HOUSE_CRYPTO_API
        ].includes(holding.linkType) && holding.linkContainer === accountLinkingContainers.NFT;

      row.cells[1].value = getPercentageChange(
        row.cells[2].getValueInCurrency(currency),
        row.cells[3].getValueInCurrency(currency),
        !isCryptoCurrency(currency)
      );
      rows.push(row);
    }

    const section = this.getEmptySection(0, "1");
    section.rows = rows;

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;
    return gridData;
  }

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getEmptySection(forIndex, sortKey, isViewOnlyMode = false) {
    const allowEditing = isViewOnlyMode === false;
    const nameColumn = new GridColumnData("", true, allowEditing, false);
    const percentageColumn = new GridColumnData(null, false, false, false);
    const costColumn = new GridColumnData("Cost", true, allowEditing, false);
    const valueColumn = new GridColumnData("Value", true, true, false);

    const sectionData = new GridSectionData(
      getUuid(),
      sortKey,
      "Section " + (forIndex + 1),
      [],
      [nameColumn, percentageColumn, costColumn, valueColumn],
      2,
      3,
      false
    );
    sectionData.showAddNewInFooter = false;
    return sectionData;
  }

  getEmptyRow(sortKey) {
    const nameCell = new GridCellData(cellType.TEXT, "Date", null);
    const percentageCell = new PercentageCellData(cellType.PERCENTAGE, "Percentage", null, 2, 3);
    const costCell = new CurrencyCellData(cellType.CURRENCY, "Cost", null, this.props.currency);
    costCell.supportedTickerTypes = [tickerTypes.FIAT, tickerTypes.CRYPTO];
    costCell.useRateFromExchangeRateDetails = true;
    const valueCell = new CurrencyCellData(cellType.CURRENCY, "Value", null, this.props.currency);
    valueCell.supportedTickerTypes = [tickerTypes.FIAT, tickerTypes.CRYPTO];
    var cells = [nameCell, percentageCell, costCell, valueCell];
    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 0, false, () => {
      return true;
    });
    rowData.showHint = false;
    return rowData;
  }

  handleRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) {
    const valueObj = updatedRow.cells[3].isEditable
      ? {
          value: updatedRow.cells[3].value,
          valueTickerId: updatedRow.cells[3].getTickerId(),
          valueExchangeRate: updatedRow.cells[3].exchangeRateDetails,
          valueInvalidInputText: updatedRow.cells[3].invalidInputText,
          valueTickerInfo: updatedRow.cells[3].tickerInfo
        }
      : null;

    const upadateObj = {
      name: updatedRow.cells[0].symbol ? updatedRow.cells[0].subValue : updatedRow.cells[0].value,
      cost: updatedRow.cells[2].value,
      costTickerId: updatedRow.cells[2].getTickerId(),
      costExchangeRate: updatedRow.cells[2].exchangeRateDetails,
      costInvalidInputText: updatedRow.cells[2].invalidInputText,
      costTickerInfo: updatedRow.cells[2].tickerInfo,
      ...valueObj
    };

    const newGridData = this.state.gridData;
    const row = newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const percentageChange = getPercentageChange(
      row.cells[2].getValueInCurrency(this.props.currency),
      row.cells[3].getValueInCurrency(this.props.currency),
      !isCryptoCurrency(this.props.currency)
    );
    row.cells[1].value = percentageChange;

    const holdingsIndex = this.props.holdings.findIndex(item => item.id === updatedRow.id);
    const promise = this.props.updateHolding(this.props.holdings[holdingsIndex], upadateObj);
    this.updateHoldingsPromiseList[row.id] = promise;
    this.setState({ ...this.state, gridData: newGridData });
  }

  handleChange(newGridData) {
    this.setState({ ...this.state, gridData: newGridData });
  }

  handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];

    if (!cell.invalidInputText === false && cell.loading === false) {
      cell.loading = true;
      this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);

      this.props.fetchTickerDetails(
        cell.invalidInputText,
        new Date(),
        result => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];

          cell.loading = false;
          if (!result === true) {
            this.props.showToastTip("TIP", i18n.t("invalidTickerError"), null, 10000);
            this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
            return;
          }

          cell.exchangeRateDetails = result.exchangeRateDetails;
          cell.currency = result.tickerShortName;
          cell.invalidInputText = null;

          const newRow = row.clone();
          this.updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex);
          this.handleRowUpdate(sheetIndex, sectionIndex, rowIndex, newRow, false);
        },
        error => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];
          cell.loading = false;
          this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
          this.props.showToastTip("TIP", i18n.t("tickerFetchFailure"), null, 10000);
        }
      );
    }
  }

  updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex) {
    const newGridData = this.state.gridData;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = newRow;
    this.setState({ gridData: newGridData });
  }

  getEmptyHoldingsMessage() {
    const holdingsTimer = !this.isCryptoHoldings() ? 180 : 3600;
    if (this.props.holdings.length !== 0 && this.state.gridData.sheets[0].sections[0].rows.length === 0) {
      return i18n.t("custodianDetails.noHoldingsMessage");
    } else if (new Date().getTime() / 1000 - this.props.lastUpdateTs < holdingsTimer) {
      return !this.isCryptoHoldings()
        ? i18n.t("custodianDetails.checkingHoldingsMessage")
        : i18n.t("custodianDetails.checkingHoldingsMessageCrypto");
    } else if (!this.isCryptoHoldings() && new Date().getTime() / 1000 - this.props.lastUpdateTs < 2 * 60 * 60) {
      return i18n.t("custodianDetails.stillCheckingForHoldings");
    } else if (this.isCryptoHoldings()) {
      return i18n.t("custodianDetails.noHoldingsCrypto");
    }
    return i18n.t("custodianDetails.noHoldingsMessage");
  }

  isCryptoHoldings() {
    return isCryptoLinkingService(this.props.linkType);
  }

  handleExpandHoldings() {
    this.setState({ showConfirmationDialog: true });
  }

  handleConfirmationDialogPositiveButtonClick(e) {
    this.setState({ isExpanding: true });

    this.props.expandHoldingsForCustodian(
      this.props.custodianId,
      newSectionId => {
        this.props.onHoldingsExpanded();
        this.state.gridData.sheets[0].sections[0].rows.forEach(eachRow => {
          document.flashElement(eachRow.id);
        });
        document.scrollToElement(newSectionId);
      },
      apiError => {
        this.setState({ isExpanding: false });
      },
      true
    );
  }

  handleConfirmationDialogNegativeButtonClick(e) {
    this.setState({ showConfirmationDialog: false });
  }

  render() {
    if (this.props.holdings.length === 0 || this.state.gridData.sheets[0].sections[0].rows.length === 0) {
      return (
        <Container className={this.props.className}>
          <EmptyHoldingsMessage
            dangerouslySetInnerHTML={{
              __html: this.getEmptyHoldingsMessage()
            }}
          />
        </Container>
      );
    }

    const gridData = this.state.gridData;
    const lastUpdateDetails = getCustodianLastUpdateDetails(this.props.custodianId);
    const isExpanding = this.state.isExpanding;
    const showHoldingsBadge = this.props.showHoldingsBadge;

    return (
      <Container className={this.props.className}>
        <DetailsContainer>
          <LastUpdated isStale={lastUpdateDetails.isStale}>
            {lastUpdateDetails.dateString.replace("Last update", "Last Account Sync")}
          </LastUpdated>
          {this.props.holdings.length <= 100 && (
            <>
              {showHoldingsBadge === true && <HoldingsBadge />}
              {!this.props.isReadOnly === true && (
                <ExpandHoldingsButton onClick={this.handleExpandHoldings}>
                  {isExpanding === true ? i18n.t("expandingHoldings") : i18n.t("expandHoldings")}
                </ExpandHoldingsButton>
              )}
            </>
          )}
        </DetailsContainer>
        <ThemeProvider theme={theme}>
          <Grid
            title={i18n.t("assetsComponent.title")}
            gridData={gridData}
            getEmptyRow={this.getEmptyRow}
            onChange={this.handleChange.bind(this)}
            onRowUpdate={this.handleRowUpdate}
            onCellInvalidTickerAdded={this.handleCellInvalidTickerAdded}
            onAddNewRow={this.handleAddNewRow}
          />
        </ThemeProvider>

        {this.state.showConfirmationDialog && (
          <ConfirmationDialog
            canUserDismiss={true}
            title={i18n.t("expandHoldingsDialog.title")}
            description={i18n
              .t("expandHoldingsDialog.description")
              .split("%s1%")
              .join(this.props.category === category.ASSET ? "assets" : "debts")}
            isLoading={this.state.isExpanding}
            positiveButtonTitle={i18n.t("yes")}
            negativeButtonTitle={i18n.t("cancel")}
            onDismiss={this.handleUnlinkAllConnectionsDialogOnDismiss}
            handlePositiveButtonClick={this.handleConfirmationDialogPositiveButtonClick}
            handleNegativeButtonClick={this.handleConfirmationDialogNegativeButtonClick}
          />
        )}
      </Container>
    );
  }
}

CustodianHoldingsComponent.defaultProps = {
  valueMultiplier: 1
};

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
  expandHoldingsForCustodian: expandHoldingsForCustodian,
  updateHolding: updateCustodianHolding,
  showToastTip: showToastTip,
  fetchTickerDetails: fetchTickerDetails,
  refreshCustodian: refreshCustodian,
  fetchDetails: fetchCustodianDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustodianHoldingsComponent);
