import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { useState } from "react";
import { ReactComponent as ClearIcon } from "assets/images/delete_user_icon.svg";
import { planningVariables } from "@kubera/common";
import PercentageInput from "./PercentageInput";
import CurrencyInput from "./CurrencyInput";

const Container = styled.div`
  margin-top: 35px;
`;

const EstimatedTaxLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: #0074fc;
  cursor: pointer;
  width: fit-content;
`;

const TaxTitleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputTitle = styled.div`
  margin-top: 35px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const TaxTitle = styled(InputTitle)`
  margin-top: 0;
  flex: 1;
`;

const HitArea = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

const ClearButton = styled(ClearIcon)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const RateInput = styled(PercentageInput)`
  width: 100%;
  margin-top: 8px;
`;

const Description = styled.p`
  font-size: 11px;
  opacity: 0.5;
  margin-top: 14px;
`;

const AmountInput = styled(CurrencyInput)`
  margin-top: 8px;
`;

const ExpandingTaxInput = ({ tax, taxDeduction, updateParams, portfolioCurrency, tickerId, onGainTitle }) => {
  const [expand, setExpand] = useState(!isNaN(tax) && tax > 0);
  const handleClear = () => {
    setExpand(false);
    updateParams({ [planningVariables.TAX]: 0 });
  };
  return (
    tax !== undefined && (
      <Container>
        {!expand && <EstimatedTaxLabel onClick={() => setExpand(true)}>{i18n.t("estimatedTax")}</EstimatedTaxLabel>}
        {expand && (
          <>
            <>
              <TaxTitleContainer>
                <TaxTitle>{onGainTitle ? i18n.t("taxRateOnGain") : i18n.t("taxRate")}</TaxTitle>
                <HitArea onClick={handleClear}>
                  <ClearButton />
                </HitArea>
              </TaxTitleContainer>
              <RateInput
                value={tax}
                onChange={(e, parsedInput) => updateParams({ [planningVariables.TAX]: parsedInput })}
                callOnChangeOnClear={true}
                inputValidation={value => value < 0}
              />
            </>
            {taxDeduction !== undefined && (
              <>
                <InputTitle>{i18n.t("taxDeduction")}</InputTitle>
                <AmountInput
                  portfolioCurrency={portfolioCurrency}
                  value={taxDeduction.value}
                  tickerId={tickerId}
                  onChange={val => updateParams({ [planningVariables.TAX_DEDUCTION]: val })}
                  placeholder={i18n.t("amount")}
                  inputStyle={{ display: "flex", flex: 1, height: "45px", border: "1px solid rgba(0, 0, 0, 0.4);" }}
                  hideExchangeRateBubble={true}
                  isInSingleCellMode={true}
                  isDisabled={!tax}
                />
                <Description
                  dangerouslySetInnerHTML={{
                    __html: i18n.t("taxDeductionDescription")
                  }}
                />
              </>
            )}
          </>
        )}
      </Container>
    )
  );
};

export default ExpandingTaxInput;
