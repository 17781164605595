import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  sanitizeIrr,
  irrComparisonIndexesSelector,
  updateUserPreferences,
  supportedIndicesSelector,
  isIrrUpdatingSelector,
  irrComparisonTimePeriodSelector,
  supportedIndicesIrrSelector,
  getTickerUsingId,
  updateCustodianIrrType,
  irrTypes,
  showCopiedToast
} from "@kubera/common";
import i18n from "i18next";
import CurrencyLabel from "components/labels/CurrencyLabel";
import PercentageLabel from "components/labels/PercentageLabel";
import CustodianAssetCashflowComponent from "components/custodian_details/CustodianAssetCashflowComponent";
import { ReactComponent as IndexComparisonIcon } from "assets/images/index_comparison_icon.svg";
import IrrSettingsDialog, { timePeriods } from "components/custodian_details/IrrSettingsDialog";
import { ReactComponent as DownArrow } from "assets/images/menu_downarrow.svg";
import ClickableLink from "components/labels/ClickableLink";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
`;

const HeaderFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 30px;
  margin-top: 15px;
  cursor: ${props => (props.isDisabled ? "auto" : "pointer")};
`;

const HeaderFieldTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DropDownArrow = styled(DownArrow)`
  margin-left: 2px;
`;

const HeaderFieldTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const TotalCashflow = styled(CurrencyLabel)`
  margin-top: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: "ss01" on;
`;

const EmptyValue = styled.div`
  margin-top: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: "ss01" on;
`;

const IrrValue = styled(PercentageLabel)`
  margin-top: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: "ss01" on;
  filter: ${props => (props.isUpdating === true ? "opacity(0.3)" : "")};
`;

const CashflowGrid = styled(CustodianAssetCashflowComponent)`
  flex: 1;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

const EmptyGridTipContainer = styled.div`
  margin-bottom: 20px;
`;

const EmptyGridTip = styled.div`
  display: inline;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.7);
  white-space: pre-line;
`;

const LearnMoreLink = styled(ClickableLink)`
  font-size: 13px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.7);
`;

const EnterCostbasisDescription = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: rgba(0, 0, 0, 0.7);
`;

const EnterCostbasisButton = styled.div`
  width: fit-content;
  margin-top: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
  text-transform: capitalize;
`;

const IndexVsIcon = styled(IndexComparisonIcon)``;

class CashflowReturnsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cashInTotal: null,
      cashOutTotal: null,
      showDeleteAllDialog: false,
      selectedIndexes: props.irrComparisonIndexes,
      showSettingsDialog: false,
      selectedTimePeriod: props.irrComparisonTimePeriod || timePeriods.START_DATE
    };

    this.handleTotalCashInChange = this.handleTotalCashInChange.bind(this);
    this.handleTotalCashOutChange = this.handleTotalCashOutChange.bind(this);
    this.handleIrrSettingsButtonClick = this.handleIrrSettingsButtonClick.bind(this);
    this.handleIrrSettingsDialogOnDismiss = this.handleIrrSettingsDialogOnDismiss.bind(this);
    this.handleEnterCostbasisOnClick = this.handleEnterCostbasisOnClick.bind(this);
    this.handleCashTotalClick = this.handleCashTotalClick.bind(this);
  }

  handleTotalCashInChange(total) {
    this.setState({ cashInTotal: total });
  }

  handleTotalCashOutChange(total) {
    this.setState({ cashOutTotal: total });
  }

  handleIrrSettingsButtonClick(e) {
    if (this.props.isReadOnly === true) {
      return;
    }
    this.setState({ showSettingsDialog: true });
  }

  handleCashTotalClick(value) {
    if (!value === true) {
      return;
    }
    navigator.clipboard.writeText(value);
    this.props.showCopiedToast();
  }

  handleIrrSettingsDialogOnDismiss(selectedIndexes, selectedTimePeriod) {
    this.setState({
      showSettingsDialog: false,
      selectedIndexes: selectedIndexes,
      selectedTimePeriod: selectedTimePeriod
    });

    this.props.updateUserPreferences({
      irrComparisonIndexes: selectedIndexes,
      irrComparisonTimePeriod: selectedTimePeriod
    });
  }

  handleEnterCostbasisOnClick(e) {
    this.props.updateCustodianIrrType(this.props.custodianId, irrTypes.COSTBASIS);
  }

  getIrrDetails() {
    if (!this.props.irr === true) {
      return null;
    }

    try {
      const irrDetails = JSON.parse(this.props.irr);
      return irrDetails;
    } catch (e) {
      return null;
    }
  }

  getIndexIrrs(irrDetails) {
    var irrs = [];
    if (!irrDetails === true || !irrDetails.all.market === true) {
      return irrs;
    }

    const valueTickerShortName = getTickerUsingId(this.props.valueTickerId).shortName;
    for (const indexId of this.state.selectedIndexes) {
      const index = this.props.supportedIndexes.find(item => item.id === indexId);

      if (index.id !== valueTickerShortName) {
        irrs.push({ ...index, irr: this.getMarketIrr(indexId, irrDetails) });
      }
    }
    return irrs;
  }

  getMarketIrr(indexId, irrDetails) {
    if (this.state.selectedTimePeriod === timePeriods.START_DATE) {
      return irrDetails.all.market[indexId];
    } else if (this.state.selectedTimePeriod === timePeriods.YEAR) {
      return this.props.supportedIndexesIrr.year[indexId];
    } else if (this.state.selectedTimePeriod === timePeriods.YTD) {
      return this.props.supportedIndexesIrr.ytd[indexId];
    }
    return null;
  }

  getIrr(irrDetails) {
    if (!irrDetails === true || !irrDetails.all === true) {
      return null;
    }
    return sanitizeIrr(irrDetails.all.value);
  }

  render() {
    const irrDetails = this.getIrrDetails();
    const irr = this.getIrr(irrDetails);
    const indexIrrs = this.getIndexIrrs(irrDetails);
    const hasComparisonIrrs = indexIrrs.length > 0 && !irr === false;

    return (
      <Container className={this.props.className}>
        <HeaderContainer>
          <HeaderFieldContainer isDisabled={true} onClick={() => this.handleCashTotalClick(this.state.cashInTotal)}>
            <HeaderFieldTitle>{i18n.t("cashIn")}</HeaderFieldTitle>
            {!this.state.cashInTotal === true && <EmptyValue>{"--"}</EmptyValue>}
            {!this.state.cashInTotal === false && (
              <TotalCashflow
                maxLongFormatValue={99999}
                value={this.state.cashInTotal}
                roundDown={true}
                currency={this.props.currency}
                abbreviate={false}
                preventAnimation
              />
            )}
          </HeaderFieldContainer>
          <HeaderFieldContainer isDisabled={true} onClick={() => this.handleCashTotalClick(this.state.cashOutTotal)}>
            <HeaderFieldTitle>{i18n.t("cashOut")}</HeaderFieldTitle>
            {!this.state.cashOutTotal === true && <EmptyValue>{"--"}</EmptyValue>}
            {!this.state.cashOutTotal === false && (
              <TotalCashflow
                maxLongFormatValue={99999}
                value={this.state.cashOutTotal}
                roundDown={true}
                currency={this.props.currency}
                abbreviate={false}
                preventAnimation
              />
            )}
          </HeaderFieldContainer>
          <HeaderFieldContainer
            onClick={e => {
              if (!irr === true || this.props.isReadOnly === true) {
                return;
              }
              this.handleIrrSettingsButtonClick(e);
            }}
            isDisabled={!irr === true || this.props.isReadOnly === true}
          >
            <HeaderFieldTitleContainer>
              <HeaderFieldTitle>{i18n.t("irr")}</HeaderFieldTitle>
              {!irr === false && this.props.isReadOnly === false && <DropDownArrow />}
            </HeaderFieldTitleContainer>
            {!irr === true && <EmptyValue>{"--"}</EmptyValue>}
            {!irr === false && (
              <IrrValue
                value={irr}
                isUpdating={this.props.isIrrUpdating}
                darkBackground={false}
                updated={true}
                preventAnimation
              />
            )}
          </HeaderFieldContainer>
          {hasComparisonIrrs === true && (
            <HeaderFieldContainer isDisabled={true}>
              <IndexVsIcon />
            </HeaderFieldContainer>
          )}
          {indexIrrs.map((indexIrr, index) => (
            <HeaderFieldContainer
              key={index}
              onClick={this.handleIrrSettingsButtonClick}
              isDisabled={this.props.isReadOnly === true}
            >
              <HeaderFieldTitleContainer>
                <HeaderFieldTitle>{indexIrr.shortName}</HeaderFieldTitle>
                {this.props.isReadOnly === false && <DropDownArrow />}
              </HeaderFieldTitleContainer>
              {!indexIrr.irr === true && <EmptyValue>{"--"}</EmptyValue>}
              {!indexIrr.irr === false && (
                <IrrValue
                  value={sanitizeIrr(indexIrr.irr)}
                  isUpdating={this.props.isIrrUpdating}
                  darkBackground={false}
                  updated={true}
                  preventAnimation
                />
              )}
            </HeaderFieldContainer>
          ))}
        </HeaderContainer>
        <CashflowGrid
          isReadOnly={this.props.isReadOnly}
          currency={this.props.currency}
          custodianId={this.props.custodianId}
          sectionId={this.props.sectionId}
          cashflows={this.props.cashflows}
          onTotalCashInChange={this.handleTotalCashInChange}
          onTotalCashOutChange={this.handleTotalCashOutChange}
          onCustodianUpdate={this.props.onCustodianUpdate}
        />
        <BottomContainer>
          <EmptyGridTipContainer>
            <EmptyGridTip
              dangerouslySetInnerHTML={{
                __html: i18n.t("emptyCashflowTip")
              }}
            />
            <LearnMoreLink href={"https://help.kubera.com/article/79-irr-of-my-investment-in-kubera"} target={"_blank"}>
              {i18n.t("learnMore")}
            </LearnMoreLink>
            <EmptyGridTip
              dangerouslySetInnerHTML={{
                __html: i18n.t("emptyCashflowTip2")
              }}
            />
          </EmptyGridTipContainer>
          {this.props.isReadOnly === false && (
            <EnterCostbasisDescription>{i18n.t("enterCostbasisDescription")}</EnterCostbasisDescription>
          )}
          {this.props.isReadOnly === false && (
            <EnterCostbasisButton onClick={this.handleEnterCostbasisOnClick}>
              {i18n.t("enterCostbasis")}
            </EnterCostbasisButton>
          )}
        </BottomContainer>
        {this.state.showSettingsDialog === true && (
          <IrrSettingsDialog
            irr={irr}
            irrDetails={irrDetails}
            supportedIndexes={this.props.supportedIndexes}
            supportedIndexesIrr={this.props.supportedIndexesIrr}
            selectedTimePeriod={this.state.selectedTimePeriod}
            custodianName={this.props.custodianName}
            selectedIndexes={this.state.selectedIndexes}
            onDismiss={this.handleIrrSettingsDialogOnDismiss}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  supportedIndexes: supportedIndicesSelector(state),
  supportedIndexesIrr: supportedIndicesIrrSelector(state),
  irrComparisonIndexes: irrComparisonIndexesSelector(state),
  isIrrUpdating: isIrrUpdatingSelector(state),
  irrComparisonTimePeriod: irrComparisonTimePeriodSelector(state)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences,
  updateCustodianIrrType: updateCustodianIrrType,
  showCopiedToast: showCopiedToast
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CashflowReturnsComponent);
