import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const Header = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  margin-bottom: 26px;
`;
const Desc = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  a {
    color: black;
  }
`;

class APIAccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Container className={this.props.className}>
        <Header>{i18n.t("accountSettings.apiAccessTitle")}</Header>
        <Desc
          dangerouslySetInnerHTML={{
            __html: i18n.t("accountSetting.apiAccessDesc")
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(APIAccess);
