import uuidv4 from "uuid/v4";
import { format } from "timeago.js";

export const sanitizeIrr = irr => {
  if (!irr === true) {
    return 0;
  }
  return parseFloat(irr.toFixed(2));
};

Math.kuberaFloor = function(value) {
  const roundedDownUsignedValue = Math.floor(Math.abs(value));
  if (roundedDownUsignedValue === 0) {
    return roundedDownUsignedValue;
  }
  return Math.sign(value) * roundedDownUsignedValue;
};

Math.kuberaRoundToDecimalPlaces = function(value, places) {
  return +(Math.round(value + "e+" + places) + "e-" + places);
};

export const isAppVersionLesserThan = version => {
  if (!version === true) {
    return false;
  }
  if (process.env.REACT_APP_ENV === "local") {
    return false;
  }
  const currentAppVersionComponents = getAppVersion().split(".");
  const checkVersionComponents = version.split(".");

  for (const [index, component] of checkVersionComponents.entries()) {
    if (index >= currentAppVersionComponents.length) {
      return true;
    }
    if (parseInt(component) === parseInt(currentAppVersionComponents[index])) {
      continue;
    }
    return parseInt(component) > parseInt(currentAppVersionComponents[index]);
  }
  return false;
};

export const getAppVersion = () => {
  const buildNumber = process.env.REACT_APP_BUILD_NUMBER;
  if (buildNumber) {
    return `${process.env.REACT_APP_VERSION}.${buildNumber}`;
  }
  return `${process.env.REACT_APP_VERSION}.99999999`;
};

export const hasDateChangedSince = timestamp => {
  var hasDateChanged = false;
  if (timestamp) {
    const lastFetchDate = new Date(timestamp * 1000).getDate();
    const currentDate = new Date().getDate();
    hasDateChanged = lastFetchDate !== currentDate;
  }
  return hasDateChanged;
};

export const parseNetWorthDateString = dateString => {
  const components = dateString.split("-");
  return new Date(parseInt(components[0]), parseInt(components[1]) - 1, parseInt(components[2]));
};

export const convertBytesToString = bytes => {
  if (!bytes) {
    return "0 KB";
  }
  if (bytes >= 1024 * 1024 * 1024) {
    return `${parseFloat((bytes / (1024 * 1024 * 1024)).toFixed(2))} GB`;
  } else if (bytes >= 1024 * 1024) {
    return `${parseFloat((bytes / (1024 * 1024)).toFixed(2))} MB`;
  }
  return `${parseFloat((bytes / 1024).toFixed(2))} KB`;
};

export const getCagr = (startValue, endValue, startDate, endDate) => {
  if (startValue === 0) {
    return null;
  }

  var timeInYears = (new Date(endDate).getTime() - new Date(startDate).getTime()) / 1000;
  timeInYears = timeInYears / (365 * 24 * 60 * 60);

  if (timeInYears < 1) {
    return getPercentageChange(startValue, endValue, false, true);
  }
  if (endValue < 0) {
    const originalStartValue = startValue;
    startValue = 2 * startValue - endValue;
    endValue = originalStartValue;
  }
  return sanitizeIrr((Math.pow(endValue / startValue, 1 / timeInYears) - 1) * 100);
};

export const getHomeLTV = (homeLoanAmount, homeValue) => {
  if (homeValue === 0) {
    return null;
  }
  return getPercentageValue(homeLoanAmount, homeValue, false, 2);
};

export const getPercentageValue = (value, total, roundDown = true, decimalPoints = 2) => {
  const percentage = (value * 100) / total;
  if (percentage > 0 && percentage < 1) {
    return parseFloat(percentage.toFixed(decimalPoints));
  }
  return roundDown ? Math.floor(percentage) : parseFloat(percentage.toFixed(decimalPoints));
};

// this is used only in Recap as "actualValue" key, since rounding off the percentage value and calculating the value back from the rounded off percentage causes mismatch of the data
export const getPercentageValueForRecap = (value, total) => {
  const percentage = (value * 100) / total;
  return percentage;
};

export const getActualValueFromPercentage = (percentage, totalValue) => {
  return (percentage * totalValue) / 100;
};

export const getPercentageChange = (cost, value, roundDown = true, showFractions = false) => {
  if (cost === null || value === null) {
    return null;
  }
  if (isNaN(cost) === true || isNaN(value) === true) {
    return null;
  }

  cost = roundDown ? Math.floor(cost) : cost;
  value = roundDown ? Math.floor(value) : value;
  if (cost === 0 || value === 0) {
    return null;
  }

  const percentage = ((value - cost) / Math.abs(cost)) * 100;
  if (Math.abs(percentage) > 0 && Math.abs(percentage) < 1) {
    return parseFloat(percentage.toFixed(2));
  } else if (percentage < 0) {
    return showFractions ? parseFloat(percentage.toFixed(2)) : Math.ceil(percentage);
  } else {
    return showFractions ? parseFloat(percentage.toFixed(2)) : Math.floor(percentage);
  }
};

export const getUuid = () => {
  return uuidv4();
};

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const monthsInFullFormat = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export const getMonthFromDate = date => {
  return months[date.getMonth()];
};

export const getMonthAndYearFromDate = date => {
  return `${months[date.getMonth()]} ${date.getFullYear()}`;
};
const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const daysOfWeekInFullFormat = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const getDayOfWeekFromDate = date => {
  return daysOfWeek[date.getDay()];
};

export const getShareLinkFormattedDateString = timestamp => {
  const date = new Date(timestamp);
  var dateString = ("0" + date.getDate()).slice(-2) + " " + getMonthFromDate(date) + " " + date.getFullYear();
  return dateString;
};

export const getCustodianHistoryFormattedDateString = timestamp => {
  const date = new Date(timestamp);
  var dateString = ("0" + date.getDate()).slice(-2) + " " + getMonthFromDate(date) + " " + date.getFullYear();
  return dateString;
};

export const isInCustodianHistoryFormat = string => {
  const regexOne = new RegExp(`^\\d{1,2} (${[...months, ...monthsInFullFormat].join("|")}) ?(\\d{1,4})?$`, "gi");
  const regexTwo = new RegExp(`^(${[...months, ...monthsInFullFormat].join("|")})? ? ?\\d{1,4}.?$`, "gi");
  const regexThree = new RegExp(`^(${[...months, ...monthsInFullFormat].join("|")})$`, "gi");
  return regexOne.test(string) || regexTwo.test(string) || regexThree.test(string);
};

export const isInFullMonthsFormat = string => {
  return monthsInFullFormat.some(month => month === string);
};

export const isDateString = string => {
  const regex = new RegExp("^\\d{4}-\\d{2}-\\d{2}$", "gi");
  return regex.test(string);
};
export const minAge = 10;
export const dateRange = 100;
export const isInvalidDob = guessedDate => {
  return guessedDate.isInvalid === true || guessedDate.date.getFullYear() >= new Date().getFullYear() - minAge;
};

export const formatDateOfYearToMonth = dateOfYearString => {
  if (!dateOfYearString === true) {
    return "";
  }
  let inpVal = dateOfYearString;
  if (!isDateString(dateOfYearString)) {
    const guessedDate = guessDate(dateOfYearString);
    if (guessedDate.isInvalid === true) {
      return "";
    }
    inpVal = guessedDate.dateString;
  }
  return monthsInFullFormat[inpVal.split("-")[1] - 1];
};

export const getCustodianHistoryApiFormattedDateString = timestamp => {
  const date = new Date(timestamp);
  var dateString =
    date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
  return dateString;
};

export const getKuberaDateString = timestamp => {
  const date = new Date(timestamp);
  var dateString =
    date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
  return dateString;
};

const today = new Date();
export const currentMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
export const nextMonthEnd = new Date(today.getFullYear(), today.getMonth() + 2, 0, 0, 0, 0, 0);
export const defaultDateOfYear = getKuberaDateString(nextMonthEnd.getTime());
export const oneYearFromNowMonthEnd = new Date(today.getFullYear() + 1, today.getMonth() + 1, 0, 0, 0, 0, 0);
export const convertDateOfYearToDate = parentObj => parseKuberaDateString(parentObj.dateOfYear) || nextMonthEnd;

export const parseKuberaDateString = dateString => {
  if (!dateString === true) {
    return null;
  }
  return new Date(new Date(dateString.replaceAll("-", "/")).setHours(0, 0, 0, 0));
};

export const convertCustodianHistoryApiDateFormatToUIFormat = apiDate => {
  const apiDateParts = apiDate.split("-");

  if (apiDateParts.length !== 3) {
    return "";
  }
  if (parseInt(apiDateParts[0]) === 0) {
    return "";
  }

  var dateString =
    ("0" + apiDateParts[2]).slice(-2) + " " + months[parseInt(apiDateParts[1]) - 1] + " " + apiDateParts[0];
  return dateString;
};

const checkIfUserDateLocaleIsUsFormat = () => {
  // toLocaleDateString checks locale and timezone-MDN
  const date = new Date(2021, 5, 7);
  const dateInUserLocale = date.toLocaleDateString(navigator.language);
  return dateInUserLocale === "6/7/2021";
};

const getPartsFromTextBasedDateInput = (datePart, userInput) => {
  /* eslint-disable */
  const regexForDatePart = new RegExp(
    `^([0-9]*|last|previous|next|after)\s?${datePart}s?(ago|after|before|next)?$`,
    "gi"
  );
  const matchedInput = regexForDatePart.exec(userInput);
  return matchedInput;
};
const futureTenseDictionary = ["after", "next", "later"];
const pastTenseDictionary = ["last", "previous", "before"];
const presentTenseDictionary = ["this"];
const isDateInputInFutureTense = text => {
  if (text) {
    return futureTenseDictionary.findIndex(dictionaryItem => dictionaryItem === text) !== -1;
  } else {
    return false;
  }
};

const interpretTextBasedDateInput = (userInput, isFutureDate = false) => {
  const currentYear = parseInt(new Date().getFullYear());
  const currentMonth = parseInt(new Date().getMonth());
  const currentDate = parseInt(new Date().getDate());
  // check if user has entered text containing string day
  if (getPartsFromTextBasedDateInput("day", userInput)) {
    const matchedInput = getPartsFromTextBasedDateInput("day", userInput);
    const matchedDay = matchedInput[1];
    const matchedTense = matchedInput[2];
    if (isNaN(matchedDay) || matchedDay === "") {
      // prefix or suffix would be text
      let computedDay;
      if (userInput.indexOf("day") !== 0) {
        computedDay = isDateInputInFutureTense(matchedDay) || isFutureDate ? currentDate + 1 : currentDate - 1;
      } else {
        computedDay = isDateInputInFutureTense(matchedTense) || isFutureDate ? currentDate + 1 : currentDate - 1;
      }
      return new Date(currentYear, currentMonth, computedDay);
    } else {
      // prefix or suffix would be number
      const computedDay =
        isDateInputInFutureTense(matchedTense) || isFutureDate
          ? currentDate + parseInt(matchedDay)
          : currentDate - parseInt(matchedDay);

      return new Date(currentYear, currentMonth, computedDay);
    }
  } else if (getPartsFromTextBasedDateInput("week", userInput)) {
    const matchedInput = getPartsFromTextBasedDateInput("week", userInput);
    const matchedWeek = matchedInput[1];
    const matchedTense = matchedInput[2];
    if (isNaN(matchedWeek) || matchedWeek === "") {
      // prefix would be text
      let computedDay;
      if (userInput.indexOf("week") !== 0) {
        computedDay = isDateInputInFutureTense(matchedWeek) || isFutureDate ? currentDate + 7 : currentDate - 7;
      } else {
        computedDay = isDateInputInFutureTense(matchedTense) || isFutureDate ? currentDate + 7 : currentDate - 7;
      }
      return new Date(currentYear, currentMonth, computedDay);
    } else {
      // prefix or suffix would be number
      const computedDay =
        isDateInputInFutureTense(matchedTense) || isFutureDate
          ? currentDate + parseInt(matchedWeek) * 7
          : currentDate - parseInt(matchedWeek) * 7;
      return new Date(currentYear, currentMonth, computedDay);
    }
  } else if (getPartsFromTextBasedDateInput("month", userInput)) {
    const matchedInput = getPartsFromTextBasedDateInput("month", userInput);
    const matchedMonth = matchedInput[1];
    const matchedTense = matchedInput[2];
    if (isNaN(matchedMonth) || matchedMonth === "") {
      // prefix would be text
      let computedMonth;
      if (userInput.indexOf("month") !== 0) {
        computedMonth = isDateInputInFutureTense(matchedMonth) || isFutureDate ? currentMonth + 1 : currentMonth - 1;
      } else {
        computedMonth = isDateInputInFutureTense(matchedTense) || isFutureDate ? currentMonth + 1 : currentMonth - 1;
      }
      return new Date(currentYear, computedMonth, currentDate);
    } else {
      // prefix or suffix would be number
      const computedMonth =
        isDateInputInFutureTense(matchedTense) || isFutureDate
          ? currentMonth + parseInt(matchedMonth)
          : currentMonth - parseInt(matchedMonth);
      return new Date(currentYear, computedMonth, currentDate);
    }
  } else if (getPartsFromTextBasedDateInput("year", userInput)) {
    const matchedInput = getPartsFromTextBasedDateInput("year", userInput);
    const matchedYear = matchedInput[1];
    const matchedTense = matchedInput[2];
    if (isNaN(matchedYear) || matchedYear === "") {
      // prefix would be text
      let computedYear;
      if (userInput.indexOf("year") !== 0) {
        computedYear = isDateInputInFutureTense(matchedYear) || isFutureDate ? currentYear + 1 : currentYear - 1;
      } else {
        computedYear = isDateInputInFutureTense(matchedTense) || isFutureDate ? currentYear + 1 : currentYear - 1;
      }
      return new Date(computedYear, currentMonth, currentDate);
    } else {
      // prefix or suffix would be number
      const computedYear =
        isDateInputInFutureTense(matchedTense) || isFutureDate
          ? currentYear + parseInt(matchedYear)
          : currentYear - parseInt(matchedYear);
      return new Date(computedYear, currentMonth, currentDate);
    }
  }
  const lowercaseInput = userInput.toLowerCase().replace(/\s/g, "");
  if (lowercaseInput.length > 2) {
    const matchedMonths = monthsInFullFormat.filter(month => month.toLowerCase().startsWith(lowercaseInput));
    if (matchedMonths.length === 1) {
      const monthIndex = monthsInFullFormat.indexOf(matchedMonths[0]);
      return new Date((isFutureDate && monthIndex < currentMonth ? 1 : 0) + currentYear, monthIndex, 1);
    }
  }
};

export const isInYearRange = year => {
  const currentYear = parseInt(new Date().getFullYear());
  return year >= currentYear - dateRange && year <= currentYear + dateRange;
};

export const guessDateFromUserInput = (userInput, isFutureDate = false) => {
  const currentDate = parseInt(new Date().getDate());
  const currentYear = parseInt(new Date().getFullYear());
  let currentMonth = parseInt(new Date().getMonth());
  const currentDay = new Date().getDay();
  if (!userInput) {
    return null;
  }
  if (
    userInput &&
    userInput
      .replaceAll(" ", "")
      .toLowerCase()
      .trim() === "today"
  ) {
    return new Date(currentYear, currentMonth, currentDate);
  }
  if (
    userInput &&
    userInput
      .replaceAll(" ", "")
      .toLowerCase()
      .trim() === "yesterday"
  ) {
    return new Date(currentYear, currentMonth, currentDate - 1);
  }
  if (
    userInput &&
    userInput
      .replaceAll(" ", "")
      .toLowerCase()
      .trim() === "tomorrow"
  ) {
    return new Date(currentYear, currentMonth, currentDate + 1);
  }

  const interpretedDate = interpretTextBasedDateInput(
    userInput
      .replaceAll(" ", "")
      .toLowerCase()
      .trim(),
    isFutureDate
  );
  if (interpretedDate) {
    return interpretedDate;
  } else {
    var dateParts = null;
    let hasUserEnteredDay = null;
    let userEnteredDay;
    let previousWeekDateForTheEnteredDay = null;
    let nextWeekDateForTheEnteredDay = null;
    //  normalise any user input to date seperated by space
    userInput = userInput.replaceAll(/[*|/.,_-]/g, " ").trim();
    if (userInput.split(" ").length >= 2) {
      dateParts = userInput.split(" ").filter(datePart => {
        return datePart !== " " && datePart !== "";
      });
    }
    if (dateParts === null) {
      // user has not entered a proper date format
      if (!isNaN(userInput)) {
        // user has entered a number
        if (userInput > 31) {
          //assuming DDMMYYYY or MMDDYYYY
          let userMonth = parseInt(
            checkIfUserDateLocaleIsUsFormat() ? userInput.substring(0, 2) : userInput.substring(2, 4)
          );
          let userDay = parseInt(
            checkIfUserDateLocaleIsUsFormat() ? userInput.substring(2, 4) : userInput.substring(0, 2)
          );
          let userYear = userInput % 10000;
          if (
            userInput.length >= 8 &&
            (userMonth == 0 || userMonth > 12 || userDay == 0 || userDay > 31 || !isInYearRange(userYear))
          ) {
            //YYYYMMDD
            userYear = parseInt(userInput.substring(0, 4));
            userMonth = parseInt(userInput.substring(4, 6));
            userDay = parseInt(userInput.substring(6, 8));
          }

          if (userInput.length <= 6) {
            if (isInYearRange(userYear) || userInput.length <= 4) {
              //MMYYYY, MYYYY, YYYY
              userDay = 0;
              userMonth = parseInt(userInput.substring(0, userInput.length - 4));
              userMonth = isNaN(userMonth) ? 1 : userMonth + 1;
            } else {
              //(MMDDYY / DDMMYY);
              let userYearLastTwo = userYear % 100;
              userYear =
                userYearLastTwo > currentYear % 100 && !isFutureDate ? 1900 + userYearLastTwo : 2000 + userYearLastTwo;
            }
          }
          return new Date(userInput.length <= 4 ? userYear + 1 : userYear, userMonth - 1, userDay);
        }
        const userEnteredDate = parseInt(userInput);
        currentMonth = parseInt(new Date().getMonth());
        return new Date(
          currentYear,
          userEnteredDate > currentDate
            ? isFutureDate
              ? currentMonth
              : currentMonth - 1
            : isFutureDate
            ? currentMonth + 1
            : currentMonth,
          userEnteredDate
        );
      } else {
        // user has entered a text
        if (userInput.length === 3) {
          userEnteredDay = daysOfWeek.findIndex(dayEntry => dayEntry.toLowerCase() === userInput.toLowerCase());
        } else {
          userEnteredDay = daysOfWeekInFullFormat.findIndex(
            dayEntry => dayEntry.toLowerCase() === userInput.toLowerCase()
          );
        }
        hasUserEnteredDay = userEnteredDay !== -1;
        if (hasUserEnteredDay) {
          // the entered text can be a day. eg : mon, tuesday
          currentMonth = parseInt(new Date().getMonth());
          previousWeekDateForTheEnteredDay =
            currentDate -
            (currentDay - userEnteredDay >= 0 ? currentDay - userEnteredDay : 7 + (currentDay - userEnteredDay));
          nextWeekDateForTheEnteredDay =
            currentDate +
            (userEnteredDay - currentDay > 0 ? userEnteredDay - currentDay : 7 + (userEnteredDay - currentDay));
          return new Date(
            currentYear,
            currentMonth,
            isFutureDate ? nextWeekDateForTheEnteredDay : previousWeekDateForTheEnteredDay
          );
        } else {
          // the entered text can be just month: eg - june, Aug
          let userEnteredMonth;
          if (userInput.length === 3) {
            userEnteredMonth = months.findIndex(month => month.toLowerCase() === userInput.toLowerCase());
          } else {
            userEnteredMonth = monthsInFullFormat.findIndex(month => month.toLowerCase() === userInput.toLowerCase());
          }
          const hasUserEnteredMonth = userEnteredMonth !== -1;
          if (hasUserEnteredMonth) {
            // user has just entered month
            return new Date(
              userEnteredMonth > currentMonth
                ? isFutureDate
                  ? currentYear
                  : currentYear - 1
                : isFutureDate
                ? currentYear + 1
                : currentYear,
              userEnteredMonth + 1,
              0
            );
          } else {
            // if the entered text is not a day or a month string, return null

            return null;
          }
        }
      }
    } else {
      // date parts are present
      if (
        daysOfWeek.findIndex(day => dateParts.find(datePart => datePart.toLowerCase() === day.toLowerCase())) !== -1
      ) {
        // last mon, previous tue
        const enteredDay = daysOfWeek.findIndex(day =>
          dateParts.find(datePart => datePart.toLowerCase() === day.toLowerCase())
        );
        currentMonth = parseInt(new Date().getMonth());
        if (isDateInputInFutureTense(dateParts[0]) || isFutureDate) {
          nextWeekDateForTheEnteredDay =
            currentDate + (enteredDay - currentDay > 0 ? enteredDay - currentDay : 7 + (enteredDay - currentDay));
          return new Date(currentYear, currentMonth, nextWeekDateForTheEnteredDay);
        } else {
          previousWeekDateForTheEnteredDay =
            currentDate - (currentDay - enteredDay > 0 ? currentDay - enteredDay : 7 + (currentDay - enteredDay));
          return new Date(currentYear, currentMonth, previousWeekDateForTheEnteredDay);
        }
      } else if (
        daysOfWeekInFullFormat.findIndex(day =>
          dateParts.find(datePart => datePart.toLowerCase() === day.toLowerCase())
        ) !== -1
      ) {
        // last monday, previous tuesday
        const enteredDay = daysOfWeekInFullFormat.findIndex(day =>
          dateParts.find(datePart => datePart.toLowerCase() === day.toLowerCase())
        );
        currentMonth = parseInt(new Date().getMonth());
        if (isDateInputInFutureTense(dateParts[0]) || isFutureDate) {
          nextWeekDateForTheEnteredDay =
            currentDate + (enteredDay - currentDay > 0 ? enteredDay - currentDay : 7 + (enteredDay - currentDay));
          return new Date(currentYear, currentMonth, nextWeekDateForTheEnteredDay);
        } else {
          previousWeekDateForTheEnteredDay =
            currentDate - (currentDay - enteredDay > 0 ? currentDay - enteredDay : 7 + (currentDay - enteredDay));
          return new Date(currentYear, currentMonth, previousWeekDateForTheEnteredDay);
        }
      }
      // user has entered date in proper date format (dd/mm/yyyy, DD MMM YYYY, DD MMM, MMM DD etc)
      let isMonthFirstFormat = dateParts[1].length === 4 && !isNaN(parseInt(dateParts[1])); //second entry is year
      const isYearFirstFormat = dateParts[0].length === 4 && !isNaN(parseInt(dateParts[0])); //first entry is year
      // check if user has entered in month first format
      if (dateParts[1].length === 3) {
        isMonthFirstFormat =
          isMonthFirstFormat ||
          (checkIfUserDateLocaleIsUsFormat() &&
            months.findIndex(monthEntry => monthEntry.toLowerCase() === dateParts[1].toLowerCase()) === -1);
      } else {
        isMonthFirstFormat =
          isMonthFirstFormat ||
          (checkIfUserDateLocaleIsUsFormat() &&
            monthsInFullFormat.findIndex(monthEntry => monthEntry.toLowerCase() === dateParts[1].toLowerCase()) === -1);
      }
      const isMonthEnteredInAbbrevatedFormat =
        months.findIndex(
          monthEntry =>
            monthEntry.toLowerCase() ===
            (isMonthFirstFormat || isNaN(dateParts[0]) ? dateParts[0].toLowerCase() : dateParts[1].toLowerCase())
        ) !== -1;
      const monthsArray = isMonthEnteredInAbbrevatedFormat ? [...months] : [...monthsInFullFormat];

      let date = parseInt(
        isYearFirstFormat
          ? dateParts[2]
          : isMonthFirstFormat === false && !isNaN(dateParts[0])
          ? dateParts[0]
          : dateParts[1] > 31
          ? 0
          : dateParts[1]
      );
      let month = isYearFirstFormat
        ? dateParts[1]
        : isMonthFirstFormat === true || isNaN(dateParts[0])
        ? dateParts[0]
        : dateParts[1];
      var yearPart =
        dateParts.length > 2
          ? isYearFirstFormat
            ? dateParts[0]
            : dateParts[2]
          : dateParts[1] > 31
          ? dateParts[1]
          : "";
      if (yearPart.length === 3) {
        yearPart = `2${yearPart}`;
      } else if (yearPart.length === 2) {
        yearPart = `20${yearPart}` > currentYear && !isFutureDate ? `19${yearPart}` : `20${yearPart}`;
      } else if (yearPart.length === 1) {
        yearPart = `200${yearPart}`;
      }
      if (date > 31) {
        return null;
      }
      if (isMonthFirstFormat && !isNaN(month) && parseInt(month) > 12 && date <= 12 && date >= 1) {
        // invalid month and date could be a month, try swapping
        const tmp = parseInt(month);
        month = date;
        date = tmp;
      }
      if (
        (isNaN(month) === false && parseInt(month) > 12) ||
        (isNaN(month) === true &&
          monthsArray.findIndex(monthEntry => monthEntry.toLowerCase() === month.toLowerCase()) === -1)
      ) {
        return null;
      }

      const dateNumber = date;
      const isMonthString = isNaN(month) === true;
      const monthNumber =
        isMonthString === true
          ? monthsArray.findIndex(monthEntry => monthEntry.toLowerCase() === month.toLowerCase())
          : parseInt(month) - 1;

      var year = parseInt(
        !yearPart === false
          ? yearPart
          : monthNumber > currentMonth
          ? isFutureDate
            ? currentYear
            : currentYear - 1
          : isFutureDate
          ? currentYear + 1
          : currentYear
      );

      if (!year) {
        return null;
      }
      const yearNumber = year;
      var guessedDate = new Date(yearNumber, dateNumber == 0 ? monthNumber + 1 : monthNumber, dateNumber);
      if (guessedDate instanceof Date === false || isNaN(guessedDate)) {
        return null;
      }
      return guessedDate;
    }
  }
};

export const guessDateInCustodianHistoryUIFormat = userInput => {
  const date = guessDateFromUserInput(userInput);
  return {
    dateString: !date === true ? "" : getCustodianHistoryFormattedDateString(date.getTime()),
    isInvalid:
      !date === true
        ? true
        : date.getTime() > new Date().getTime() ||
          date.getTime() < new Date(1970, 0, 1).getTime() ||
          !isInYearRange(date.getFullYear()),
    date: date
  };
};

export const guessFutureDate = userInput => {
  const date = guessDateFromUserInput(userInput, true);
  return {
    dateString: !date === true ? "" : getCustodianHistoryApiFormattedDateString(date.getTime()),
    isInvalid: !date === true ? true : date.getTime() <= new Date().getTime() || !isInYearRange(date.getFullYear()),
    date: date
  };
};

export const guessPastDate = userInput => {
  const date = guessDateFromUserInput(userInput);
  return {
    dateString: !date === true ? "" : getCustodianHistoryApiFormattedDateString(date.getTime()),
    isInvalid: !date === true ? true : date.getTime() >= new Date().getTime() || !isInYearRange(date.getFullYear()),
    date: date
  };
};

export const guessDate = userInput => {
  const date = guessDateFromUserInput(userInput);
  return {
    dateString: !date === true ? "" : getCustodianHistoryApiFormattedDateString(date.getTime()),
    isInvalid: !date === true || !isInYearRange(date.getFullYear()),
    date: date
  };
};

export const converUIDateFormatToCustodianHistoryApiDateFormat = uiDate => {
  if (!uiDate) {
    return null;
  }

  const uiDateParts = uiDate.split(" ");

  if (uiDateParts.length !== 3) {
    return null;
  }
  const year = uiDateParts[2];
  if (year.length !== 4) {
    return null;
  }
  const month = months.findIndex(monthEntry => monthEntry.toLowerCase() === uiDateParts[1].toLowerCase());
  if (month === -1) {
    return null;
  }
  let day = uiDateParts[0];
  if (isNaN(uiDateParts[0])) {
    return null;
  } else if (day === 0) {
    day = 1;
  }

  var dateString = `${year}-${("0" + (month + 1)).slice(-2)}-${("0" + day).slice(-2)}`;
  return dateString;
};

export const getStringFromBytes = bytes => {
  if (bytes > 1024 * 1024 * 1024) {
    return Math.floor(bytes / (1024 * 1024 * 1024)) + " GB";
  } else if (bytes > 1024 * 1024) {
    return Math.floor(bytes / (1024 * 1024)) + " MB";
  } else if (bytes > 1024) {
    return Math.floor(bytes / 1024) + " KB";
  } else {
    return bytes + " B";
  }
};

export const getSortKeyBetween = (before, after) => {
  if (!before) {
    before = "0";
  }
  if (!after) {
    after = "".padEnd(before.length, "9");
  }
  if (before >= after) {
    return before;
  }
  before = before.padEnd(after.length, "0");
  after = after.padEnd(before.length, "0");

  const len = before.length;
  let newIndex = "";
  for (let i = 0; i < len; i++) {
    const bChar = before[i];
    const aChar = after[i];
    if (bChar === aChar) {
      newIndex += bChar;
    } else {
      const bInt = parseInt(bChar, 10);
      const aInt = parseInt(aChar, 10);
      const halfStep = Math.floor((aInt - bInt) / 2);
      if (halfStep !== 0) {
        // Enough room to put an index between those 2
        newIndex += `${halfStep + bInt}`;
      } else if (i === len - 1) {
        // Last character, adding 5
        newIndex += `${bChar}5`;
      } else {
        let nines = 0;
        let j = i + 1;
        let found = false;
        for (; j < len; j++) {
          if (before[j] === "9" && after[j] === "0") {
            nines++;
          } else {
            found = true;
            break;
          }
        }
        if (!found) {
          newIndex += bChar;
          newIndex = newIndex.padEnd(nines + newIndex.length, "9");
          newIndex += "5";
        } else {
          const newBInt = parseInt(before[j], 10);
          const newAInt = parseInt(after[j], 10);
          const mean = Math.floor((newAInt - (10 - newBInt)) / 2);

          if (mean >= 0) {
            newIndex += aChar;
            newIndex = newIndex.padEnd(nines + newIndex.length, "0");
            newIndex += mean;
          } else {
            newIndex += bChar;
            newIndex = newIndex.padEnd(nines + newIndex.length, "9");
            newIndex += 10 + mean;
          }
        }
        // Example: 197, 204. A good index would be 200 or 201, but 199 is ok too
      }
      break;
    }
  }
  if (newIndex >= after || newIndex <= before) {
    throw new Error(`Failed to generate new index between ${before} and ${after}`);
  }
  return newIndex;
};

export function getFriendlyDateString(dateObjc) {
  if (!dateObjc === true) {
    return "";
  }
  return format(dateObjc);
}

export const monthsBetweenDates = (startDate, endDate) => {
  if (!startDate === true || !endDate === true || endDate.getTime() <= startDate.getTime()) {
    return 0;
  }
  return (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
};

export function getFriendlyDateStringForFutureDate(futureDate, suffix = "from now") {
  if (!futureDate === true) {
    return "";
  }
  futureDate = new Date(futureDate.setHours(0, 0, 0, 0));

  if (futureDate.getTime() <= new Date().getTime()) {
    return getFriendlyDateString(futureDate);
  }

  var dateString = "";
  const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
  const months = monthsBetweenDates(currentDate, futureDate);
  if (months >= 12) {
    const years = Math.floor(months / 12);
    dateString += `${years} year${years > 1 ? "s" : ""}`;

    const remainingMonths = months % 12;
    if (remainingMonths > 0) {
      dateString += ` and ${remainingMonths} month${remainingMonths > 1 ? "s" : ""}`;
    }
  } else if (months >= 1) {
    dateString += `${months} month${months > 1 ? "s" : ""}`;
  } else {
    const days = Math.floor((futureDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));
    dateString += `${days} day${days > 1 ? "s" : ""}`;
  }
  dateString += ` ${suffix}`;
  return dateString;
}

export function getPastDateString(date) {
  const currenDate = new Date();

  if (currenDate.getFullYear() === date.getFullYear()) {
    return `${getMonthFromDate(date)} ${date.getDate()}`;
  }
  return `${getMonthFromDate(date)} ${date.getDate()}, ${date.getFullYear()}`;
}

export function getLastSeenDateString(dateTs) {
  const currenTs = new Date().getTime();
  const timeElapsed = currenTs - dateTs;

  if (timeElapsed < 24 * 60 * 60 * 1000) {
    return "Today";
  } else if (timeElapsed < 2 * 24 * 60 * 60 * 1000) {
    return "Yesterday";
  } else if (timeElapsed < 7 * 24 * 60 * 60 * 1000) {
    return `${Math.floor(timeElapsed / (24 * 60 * 60 * 1000))} days`;
  } else if (timeElapsed < 30 * 24 * 60 * 60 * 1000) {
    const weeksPassed = Math.floor(timeElapsed / (7 * 24 * 60 * 60 * 1000));
    return `${weeksPassed} ${weeksPassed === 1 ? "week" : "weeks"}`;
  } else if (timeElapsed < 365 * 24 * 60 * 60 * 1000) {
    const monthsPassed = Math.floor(timeElapsed / (30 * 24 * 60 * 60 * 1000));
    return `${monthsPassed} ${monthsPassed === 1 ? "month" : "months"}`;
  } else {
    const yearsPassed = Math.floor(timeElapsed / (365 * 24 * 60 * 60 * 1000));
    return `${yearsPassed} ${yearsPassed === 1 ? "year" : "years"}`;
  }
}

export function getDateForPdfFileName() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  return `${day}-${month}-${year}`;
}

export function getDateForPdfFooter() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const day = currentDate.getDate();
  const month = monthsInFullFormat[currentDate.getMonth()];
  return `${day} ${month} ${year}`;
}

export function formatDateForLastLogin(dateTsInSeconds) {
  const date = new Date(dateTsInSeconds * 1000);
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}, ${date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  })}`;
}

export function getDateInKuberaFormat(date) {
  const localTimeZoneOffsetInMills = new Date(date).getTimezoneOffset() * 60 * 1000;
  return new Date(new Date(date).getTime() + localTimeZoneOffsetInMills);
}

export function setSortKey(array, k) {
  return getSortKeyBetween(array[k - 1] ? array[k - 1].sortKey : null, array[k + 1] ? array[k + 1].sortKey : null);
}

export function getOrdinalNumberString(number) {
  var suffix = "";

  if (number > 3 && number < 21) {
    suffix = "th";
  }
  switch (number % 10) {
    case 1:
      suffix = "st";
      break;
    case 2:
      suffix = "nd";
      break;
    case 3:
      suffix = "rd";
      break;
    default:
      suffix = "th";
  }
  return `${number}${suffix}`;
}

// Helper function to get month abbreviation
export function getMonthAbbr(monthIndex) {
  const monthAbbr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return monthAbbr[monthIndex];
}

export function getDateInDDMMMYYYY(dateObj) {
  const day = String(dateObj.getDate()).padStart(2, "0"); // Ensures two digits
  const month = getMonthAbbr(dateObj.getMonth());
  const year = dateObj.getFullYear();

  // Combine into desired format
  return `${day} ${month} ${year}`;
}
