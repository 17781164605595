import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  getGroupedDataForScenario,
  planningGroupByPeriod,
  months,
  parseKuberaDateString,
  getRulesCashBreakdown
} from "@kubera/common";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import VerticalBarChartComponent from "components/charts/VerticalBarChartComponent";
import CashflowBreakdownDialog from "../breakdown/CashflowBreakdownDialog";

const MIN_BARS = 7;
const MAX_BARS = 15;

const Container = styled.div`
  width: 100%;
  background: ${props => props.theme.gridRowUpdatedBackgroundColor};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
`;

const ValueContainer = styled.div`
  display: flex;
`;

const ValueItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 30px;
  padding-left: 8px;
  border-left: ${props => `6px solid ${props.color}`};
  cursor: pointer;
`;

const ValueName = styled.div`
  margin-bottom: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  margin-top: -2px;
`;

const ValueLabel = styled(CurrencyHeaderLabel)`
  margin-top: -4px;
  height: 31px;
`;

const BarChart = styled(VerticalBarChartComponent)`
  margin-top: 5px;
`;

const DurationLabel = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-left: 30px;
  margin-top: 20px;
`;

class CashflowChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLastDataPointIndex: null,
      preventAnimation: false,
      showBreakdown: false,
      breakdownIndex: null
    };

    this.handleBarChartHoverOverSection = this.handleBarChartHoverOverSection.bind(this);
    this.handleBarChartClickOverSection = this.handleBarChartClickOverSection.bind(this);
    this.handleTotalClick = this.handleTotalClick.bind(this);
    this.handleBreakdownDimiss = this.handleBreakdownDimiss.bind(this);
  }

  handleBarChartHoverOverSection(index) {
    this.setState({ currentLastDataPointIndex: index, preventAnimation: true }, () => {
      if (this.state.currentLastDataPointIndex === null) {
        setTimeout(() => {
          this.setState({ preventAnimation: false });
        }, 1000);
      }
    });
  }

  handleBarChartClickOverSection(index, outflowClicked = false) {
    this.setState({ breakdownIndex: index, showBreakdown: true, showOutflow: outflowClicked });
  }

  handleBreakdownDimiss() {
    this.setState({ showBreakdown: false, breakdownIndex: null });
  }

  handleTotalClick(outflowClicked = false) {
    this.setState({ breakdownIndex: null, showBreakdown: true, showOutflow: outflowClicked });
  }

  getDataForScenario() {
    return this.props.planningData[this.props.selectedScenarioIndex];
  }

  getColorPalette() {
    return this.props.colorPallete[this.props.selectedScenarioIndex];
  }

  getLabelForGroupedDataPoint(groupBy, dataPoint) {
    const startDate = parseKuberaDateString(dataPoint.startData.date);
    const endDate = parseKuberaDateString(dataPoint.endData.date);
    switch (groupBy) {
      case planningGroupByPeriod.MONTH: {
        return `${months[endDate.getMonth()]} ${endDate.getFullYear()}`;
      }
      case planningGroupByPeriod.QUARTER: {
        if (startDate.getFullYear() === endDate.getFullYear()) {
          return `${months[startDate.getMonth()]} - ${months[endDate.getMonth()]} ${endDate.getFullYear()}`;
        } else {
          return `${months[startDate.getMonth()]} ${startDate.getFullYear()} - ${
            months[endDate.getMonth()]
          } ${endDate.getFullYear()}`;
        }
      }
      case planningGroupByPeriod.YEAR:
        return `${months[startDate.getMonth()]} ${startDate.getFullYear()} - ${endDate.getFullYear()}`;
      case planningGroupByPeriod.HALF_DECADE:
      case planningGroupByPeriod.DECADE:
        return `${startDate.getFullYear()} - ${endDate.getFullYear()}`;
      default:
        return `${months[startDate.getMonth()]} ${startDate.getFullYear()} - ${
          months[endDate.getMonth()]
        } ${endDate.getFullYear()}`;
    }
  }

  getBarChartData(breakdownData) {
    const emptyColor = this.getColorPalette().barchartDefaultColor;

    const data = {
      labels: breakdownData.map(dataPoint => dataPoint.label),
      datasets: [],
      options: [
        { minBarLength: 10 },
        {
          minBarLength: 10
        }
      ]
    };
    const inflowDataSet = {
      backgroundColor: breakdownData.map(dataPoint =>
        dataPoint.inflow.total ? this.getColorPalette().color : emptyColor
      ),
      hoverBackgroundColor: breakdownData.map(dataPoint =>
        dataPoint.inflow.total ? this.getColorPalette().color : emptyColor
      ),
      data: breakdownData.map(dataPoint => dataPoint.inflow.total),
      barPercentage: 1
    };
    const outflowDataSet = {
      backgroundColor: breakdownData.map(dataPoint =>
        dataPoint.outflow.total ? this.getColorPalette().barChartSecondaryColor : emptyColor
      ),
      hoverBackgroundColor: breakdownData.map(dataPoint =>
        dataPoint.outflow.total ? this.getColorPalette().barChartSecondaryColor : emptyColor
      ),
      data: breakdownData.map(dataPoint => dataPoint.outflow.total),
      barPercentage: 1
    };

    data.datasets.push(inflowDataSet);
    data.datasets.push(outflowDataSet);
    return data;
  }

  isChartEmpty(barChartData) {
    return (
      barChartData.datasets[0].data.filter(item => item !== 0).length === 0 &&
      barChartData.datasets[1].data.filter(item => item !== 0).length === 0
    );
  }

  getDurationLabel(chartData, index) {
    if (index === null) {
      return chartData.labels[chartData.labels.length - 1];
    } else {
      return chartData.labels[index];
    }
  }

  getBreakdowns(groupedData, rules) {
    // gets the cash breakdown and splits it up into inflow and outflow
    const retVar = [];
    let startBreakdown = null;

    for (const dp of groupedData.data) {
      const ruleIdToCD = new Map(); // combining inflow and outflow for the same rule and only showing in one place

      const inflow = { breakdown: {}, total: 0 };
      const outflow = { breakdown: {}, total: 0 };

      const endBreakdown = getRulesCashBreakdown(rules, dp.endData);
      const keys = Object.keys(endBreakdown);
      for (const key of keys) {
        const inflowKeyObj = { ...endBreakdown[key], rules: [], total: 0 };
        const outflowKeyObj = { ...endBreakdown[key], rules: [], total: 0 };

        for (const endRule of endBreakdown[key].rules) {
          const { id } = endRule;
          const netRule = window.kbStructuredClone(endRule);
          const startRule = startBreakdown && startBreakdown[key]?.rules.find(s => s.id === id);
          const isOutflow = endRule.changes.cumulativeDelta < 0;
          let delta =
            (isOutflow ? -1 : 1) * (endRule.changes.cumulativeDelta - (startRule?.changes.cumulativeDelta || 0));
          const destinationObj = isOutflow ? outflowKeyObj : inflowKeyObj;

          if (ruleIdToCD.has(id)) {
            // rule has a pre existing inflow or outflow, need to combine
            const otherChange = ruleIdToCD.get(id);
            const sourceObj = otherChange.isOutflow ? outflow : inflow;
            sourceObj.total -= otherChange.delta;

            const combinedChange = (otherChange.isOutflow ? -1 : 1) * otherChange.delta + (isOutflow ? -1 : 1) * delta;
            const finalObj = combinedChange < 0 ? outflow : inflow;
            const absChange = Math.abs(combinedChange);

            const sourceKeyObj = sourceObj.breakdown[otherChange.key];
            if (sourceObj === finalObj && absChange) {
              const sourceRule = sourceKeyObj.rules.find(r => r.id === id);
              sourceRule.changes.cumulativeDelta = absChange;
              sourceObj.total += absChange;
              continue;
            } else {
              delta = absChange;
              sourceKeyObj.rules = sourceKeyObj.rules.filter(r => r.id !== id);
            }
          } else if (delta) {
            ruleIdToCD.set(endRule.id, { isOutflow, key, delta });
          }

          netRule.changes.cumulativeDelta = delta;
          destinationObj.total += delta;
          destinationObj.rules.push(netRule);
        }

        const addKeyObjToObj = (keyObj, obj) => {
          obj.breakdown[key] = keyObj;
          obj.total += keyObj.total;
          delete keyObj.total; // not needed anymore
        };

        addKeyObjToObj(inflowKeyObj, inflow);
        addKeyObjToObj(outflowKeyObj, outflow);
      }
      retVar.push({ inflow, outflow, label: this.getLabelForGroupedDataPoint(groupedData.groupBy, dp).toUpperCase() });
      startBreakdown = endBreakdown; // updating end point of previous month
    }
    return retVar;
  }

  getInflowTotal(breakdownData, index) {
    if (index === null) {
      return breakdownData[breakdownData.length - 1].inflow.total;
    } else {
      return breakdownData[index].inflow.total;
    }
  }

  getOutflowTotal(breakdownData, index) {
    if (index === null) {
      return breakdownData[breakdownData.length - 1].outflow.total;
    } else {
      return breakdownData[index].outflow.total;
    }
  }

  render() {
    const dataForScenario = this.getDataForScenario();
    const colorPallete = this.getColorPalette();

    if (!this.props.planningData === true || !dataForScenario === true || dataForScenario.data.length === 0) {
      return null;
    }

    const groupedData = getGroupedDataForScenario(dataForScenario, MIN_BARS, MAX_BARS, true);
    const breakdownData = this.getBreakdowns(groupedData, dataForScenario.processedRules);
    const barChartData = this.getBarChartData(breakdownData);

    return (
      <Container>
        <ContentContainer>
          <DurationLabel>{this.getDurationLabel(barChartData, this.state.currentLastDataPointIndex)}</DurationLabel>
          <ValueContainer>
            <ValueItem color={colorPallete.color} onClick={() => this.handleTotalClick(false)}>
              <ValueName>{i18n.t("inflow")}</ValueName>
              <ValueLabel
                currency={dataForScenario.currency}
                value={this.getInflowTotal(breakdownData, this.state.currentLastDataPointIndex)}
                currencyFontSize={16}
                valueFontSize={30}
                fontWeight={400}
                height={"40px"}
                showZero={true}
                preventAnimation={this.state.preventAnimation === true}
              />
            </ValueItem>
            <ValueItem color={colorPallete.barChartSecondaryColor} onClick={() => this.handleTotalClick(true)}>
              <ValueName>{i18n.t("outflow")}</ValueName>
              <ValueLabel
                currency={dataForScenario.currency}
                value={this.getOutflowTotal(breakdownData, this.state.currentLastDataPointIndex)}
                currencyFontSize={16}
                valueFontSize={30}
                fontWeight={400}
                height={"40px"}
                showZero={true}
                preventAnimation={this.state.preventAnimation === true}
              />
            </ValueItem>
          </ValueContainer>
          {this.isChartEmpty(barChartData) === false && (
            <BarChart
              data={barChartData}
              chartHeight={82}
              adjustPageScroll={true}
              chartPadding={{
                left: 10,
                right: 10,
                top: 20,
                bottom: 0
              }}
              maxBars={MAX_BARS}
              onHoverOverSection={this.handleBarChartHoverOverSection}
              onClickOverSection={this.handleBarChartClickOverSection}
            />
          )}
          {this.state.showBreakdown === true && (
            <CashflowBreakdownDialog
              currency={groupedData.currency}
              colorPallete={colorPallete}
              getInflowTotal={this.getInflowTotal}
              getOutflowTotal={this.getOutflowTotal}
              scenarioRules={dataForScenario.processedRules}
              breakdownData={breakdownData}
              dataIndex={this.state.breakdownIndex}
              timeRange={this.getDurationLabel(barChartData, this.state.breakdownIndex)}
              onDismiss={this.handleBreakdownDimiss}
              showOutflow={this.state.showOutflow}
            />
          )}
        </ContentContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CashflowChartComponent));
