import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import * as Sentry from "@sentry/browser";

import {
  wlAdminListChargesSelector,
  wlPaymentMethodSelector,
  siteConfigSelector,
  userSelector,
  SUBSCRIPTION_ERROR
} from "@kubera/common";

import AccountCardPayment from "./AccountCardPayment";
import AccountSubscribedCard from "./AccountSubscribedCard";
import PaymentsList from "./PaymentsList";
import AccountDeclinedCardModal from "./AccountDeclinedCardModal";

const AccountPaymentBlock = () => {
  const stripe = useStripe();

  const paymentMethod = useSelector(wlPaymentMethodSelector);
  const user = useSelector(userSelector);
  const wlAdminListCharges = useSelector(wlAdminListChargesSelector);

  const [updateMode, setUpdateMode] = useState(false);
  const [isPaymentError, setIsPaymentError] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isDeclinedError, setIsDeclinedError] = useState(false);
  const [paymentErrorMsg, setPaymentErrorMsg] = useState(void 0);

  const captureError = useCallback((errorState, reason) => {
    Sentry.withScope(scope => {
      scope.setExtras({
        plan: "monthly",
        reason
      });
      Sentry.captureMessage(errorState);
    });
  }, []);

  const handleSubscribedCardSubmit = () => {
    if (paymentMethod.paymentMethodId) {
      setUpdateMode(false);
    }
  };

  const handlePaymentError = isError => {
    setIsPaymentError(isError);
  };

  const onDeclinedModalDismiss = () => {
    setPaymentErrorMsg(void 0);
    setIsDeclinedError(false);
  };

  const renderPaymentBlock = () => {
    if (!!paymentMethod.paymentMethodId && !updateMode) {
      return (
        <AccountSubscribedCard
          setUpdateMode={setUpdateMode}
          isAuthenticating={isAuthenticating}
          isPaymentError={isPaymentError}
        />
      );
    }

    return <AccountCardPayment setIsSubmitted={handleSubscribedCardSubmit} captureError={captureError} />;
  };

  const handleAuthenticationRequired = async accountPaymentAuth => {
    if (
      accountPaymentAuth &&
      (accountPaymentAuth.intentStatus === "requires_action" ||
        accountPaymentAuth.intentStatus === "requires_payment_method")
    ) {
      setIsAuthenticating(true);
      return stripe
        .confirmCardPayment(accountPaymentAuth.clientSecret, { payment_method: paymentMethod.paymentMethodId })
        .then(async result => {
          if (result.error) {
            captureError(SUBSCRIPTION_ERROR.CONFIRMPAYMENT_ERROR, result.error);
            setPaymentErrorMsg(result.error.message);
            setIsDeclinedError(true);
          } else if (result.paymentIntent.status === "succeeded") {
          } else {
            captureError(SUBSCRIPTION_ERROR.CONFIRMPAYMENT_STATUSERROR, result.paymentIntent.last_payment_error);
            setPaymentErrorMsg(result.paymentIntent.last_payment_error);
            setIsDeclinedError(true);
          }
        })
        .catch(error => null);
    }

    return Promise.resolve();
  };

  useEffect(() => {
    setIsAuthenticating(false);
  }, [wlAdminListCharges]);

  return (
    <>
      {renderPaymentBlock()}
      <PaymentsList
        userId={user.id}
        handlePaymentError={handlePaymentError}
        handleAuthenticationRequired={handleAuthenticationRequired}
        hideTitleOnLoad={!paymentMethod.paymentMethodId || updateMode}
      />
      <AccountDeclinedCardModal isOpen={isDeclinedError} message={paymentErrorMsg} onDismiss={onDeclinedModalDismiss} />
    </>
  );
};

let stripePromise = null;
const handleStripeLoad = async stripeAccount => {
  if (stripePromise === null) {
    stripePromise = await loadStripe(`${process.env.REACT_APP_STRIPE_PK}`, { stripeAccount });
  }

  return stripePromise;
};

const ElementsWrapper = props => {
  const siteConfig = useSelector(siteConfigSelector);

  const [isStripeLoaded, setIsStripeLoaded] = useState(false);

  useEffect(() => {
    const loadStripe = async () => {
      await handleStripeLoad(siteConfig.stripeConnectedAccount.id);
      setIsStripeLoaded(true);
    };
    if (siteConfig.stripeConnectedAccount && !isStripeLoaded) {
      loadStripe();
    }
  }, [siteConfig.stripeConnectedAccount, isStripeLoaded]);

  if (!isStripeLoaded) {
    return null;
  }

  return (
    <Elements stripe={stripePromise}>
      <AccountPaymentBlock {...props} />
    </Elements>
  );
};

export default ElementsWrapper;
