import React from "react";
import styled from "styled-components";
import { planningRuleEffect, withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  store,
  portfolioNetWorth,
  getKuberaDateString,
  getRuleObject,
  planningRuleCategories,
  userAgeAtDate,
  isAppInViewMode,
  timePeriods,
  getDateForTimePeriod,
  currentPortfolioSelector
} from "@kubera/common";
import i18n from "i18next";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import ChangeLabel from "components/labels/ChangeLabel";
import NetworthBreakdownDialog from "./breakdown/NetworthBreakdownDialog";
import DobDialog from "./DobDialog";
import ToolTip, { toolTipAlignment } from "components/tooltip/ToolTip";
import { userPreferencesSelector, updateUserPreferences } from "@kubera/common";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const CardRow = styled.div`
  display: flex;
  gap: 5px;
`;

const Card = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const TimePeriodContainer = styled.div`
  display: flex;
  padding-left: 10px;
  margin-bottom: ${props => (props.showYear === true ? "-4px" : "-1px")};
`;

const TimePeriod = styled.div`
  opacity: 0.7;
  leading-trim: both;
  text-edge: cap;
  font-variant-numeric: slashed-zero;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -2.56px;
  text-transform: uppercase;
`;

const TimePeriodYear = styled.div`
  display: flex;
  margin-left: 8px;
  opacity: 0.5;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto Condensed;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 42px;
  letter-spacing: -2.4px;
  text-transform: uppercase;
`;

const TimePeriodAge = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 9px;
  margin-left: 5px;
  opacity: 0.5;
  font-family: Roboto Condensed;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-transform: uppercase;
  white-space: pre;
  z-index: 100;
`;

const AddAgeButton = styled(TimePeriodAge)`
  opacity: 1;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
  text-decoration-line: underline;
`;

const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -7px;
  padding: ${props => (props.showYear === true ? "25px 45px 25px 30px" : "25px 45px 5px 30px")};
  border: ${props => `1px solid ${props.theme.gridSectionBorderColor}`};
  background: ${props => props.theme.gridRowUpdatedBackgroundColor};
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ValueTitle = styled.div`
  font-feature-settings: "ss01" on, "calt" off;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const AFIBadge = styled.div`
  font-feature-settings: "ss01" on, "calt" off;
  font-family: "Roboto Condensed";
  font-size: 13px;
  font-weight: 400;
  opacity: 0.5;
  margin-left: 4px;
`;

const NetworthValue = styled(CurrencyHeaderLabel)``;

const NetworthChange = styled(ChangeLabel)`
  margin-top: -2px;
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const NetworthChangePlaceholder = styled.div`
  min-height: 21.5px;
`;

const BreakdownContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

const Breakdown = styled.div`
  display: flex;
  flex-direction: row;
`;

const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`;

const BreakdownValue = styled(CurrencyHeaderLabel)`
  margin-top: 1px;
  margin-bottom: 20px;
`;

class PlanningTimestampsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showBreakdownTimePeriod: null, showDobDialog: false };

    this.handleShowBreakdownClick = this.handleShowBreakdownClick.bind(this);
    this.handleBreakdownDimiss = this.handleBreakdownDimiss.bind(this);
    this.handleAddAgeButtonClick = this.handleAddAgeButtonClick.bind(this);
    this.handleDobDialogDimiss = this.handleDobDialogDimiss.bind(this);

    this.breakdownRef = React.createRef();
  }

  componentDidMount() {
    if (!this.props.preferences.timestampsClickTip && this.breakdownRef.current) {
      this.breakdownRef.current.show(i18n.t("timestampBreakdownTip"), 20, 45, () => {
        this.props.updateUserPreferences({ timestampsClickTip: true });
      });
    }
  }

  isReadOnly() {
    return isAppInViewMode() === true || this.props.portfolio.write === 0;
  }

  handleDobDialogDimiss() {
    this.setState({ showDobDialog: false });
  }

  handleShowBreakdownClick(e, timePeriod) {
    this.setState({ showBreakdownTimePeriod: timePeriod });
  }

  handleBreakdownDimiss() {
    this.setState({ showBreakdownTimePeriod: null });
  }

  handleAddAgeButtonClick(e) {
    this.setState({ showDobDialog: true });
    e.stopPropagation();
  }

  getCardForTimePeriod(timePeriod) {
    const date = getDateForTimePeriod(timePeriod);
    const dateString = getKuberaDateString(date);
    const userAgeAtTimePeriod = userAgeAtDate(store.getState(), date);
    const showYear = [timePeriods.HALF_DECADE, timePeriods.DECADE, timePeriods.TWO_DECADES].includes(timePeriod);
    const showBreakdown = [timePeriods.MONTH, timePeriods.YEAR].includes(timePeriod);
    const dataForScenario = this.props.planningData[this.props.selectedScenarioIndex];
    const dataPointForToday = dataForScenario.data[0];
    const dataPoint = dataForScenario.data.find(dataPoint => dataPoint.date === dateString);

    const rules = dataPoint.rules.map(rule => getRuleObject(rule));
    const income = rules.reduce((total, rule) => {
      return total + (rule.category === planningRuleCategories.INCOME ? rule.changes.cumulativeDelta : 0);
    }, 0);
    const expenses = rules.reduce((total, rule) => {
      return (
        total +
        (rule.category === planningRuleCategories.EXPENSE && rule.effect !== planningRuleEffect.CHANGE_NET_WORTH
          ? Math.abs(rule.changes.cumulativeDelta)
          : rule.changes.cumulativeInterestPayment || 0)
      );
    }, 0);
    const tax = -rules.reduce((total, rule) => total + (rule.changes.cumulativeTax || 0), 0);

    return (
      <Card onClick={e => this.handleShowBreakdownClick(e, timePeriod)}>
        <TimePeriodContainer showYear={showYear}>
          <TimePeriod>{this.getTimePeriodDescription(timePeriod)}</TimePeriod>
          {showYear === true && <TimePeriodYear>{date.getFullYear()}</TimePeriodYear>}
          {showYear === true && userAgeAtTimePeriod !== null && (
            <TimePeriodAge>{i18n.t("userAgeAtDate").replace("%s1%", userAgeAtTimePeriod)}</TimePeriodAge>
          )}
          {showYear === true && userAgeAtTimePeriod === null && !this.isReadOnly() && (
            <AddAgeButton onClick={this.handleAddAgeButtonClick}>{i18n.t("yourAge")}</AddAgeButton>
          )}
        </TimePeriodContainer>
        <CardDetails showYear={showYear}>
          <TitleContainer id={`card-${timePeriod}`}>
            <ValueTitle>{i18n.t("networth")}</ValueTitle>
            {dataPoint.rawNetworth !== undefined && dataPoint.rawNetworth !== dataPoint.networth && (
              <AFIBadge>{i18n.t("AFI")}</AFIBadge>
            )}
            {timePeriod === timePeriods.YEAR && (
              <ToolTip
                ref={this.breakdownRef}
                targetId={`card-${timePeriods.YEAR}`}
                align={toolTipAlignment.TOP_RIGHT}
                noArrow={true}
              />
            )}
          </TitleContainer>

          <NetworthValue
            currency={dataForScenario.currency}
            value={dataPoint.networth}
            currencyFontSize={18}
            valueFontSize={36}
            fontWeight={400}
            height={"40px"}
            showZero={true}
            letterSpacing="-1.86px"
          />
          {dataPointForToday.networth !== dataPoint.networth && (
            <NetworthChange
              currency={dataForScenario.currency}
              startValue={dataPointForToday.networth}
              endValue={dataPoint.networth}
            />
          )}
          {dataPointForToday.networth === dataPoint.networth && <NetworthChangePlaceholder />}
          {showBreakdown === true && (
            <BreakdownContainer>
              <Breakdown>
                <NumberContainer>
                  <ValueTitle>{i18n.t("assets")}</ValueTitle>
                  <BreakdownValue
                    currency={dataForScenario.currency}
                    value={dataPoint.assetsTotal}
                    currencyFontSize={14}
                    valueFontSize={24}
                    fontWeight={400}
                    height={"28px"}
                    showZero={true}
                    letterSpacing="-1.2px"
                  />
                </NumberContainer>
                <NumberContainer>
                  <ValueTitle>{i18n.t("debts")}</ValueTitle>
                  <BreakdownValue
                    currency={dataForScenario.currency}
                    value={dataPoint.debtsTotal}
                    currencyFontSize={14}
                    valueFontSize={24}
                    fontWeight={400}
                    height={"28px"}
                    showZero={true}
                    letterSpacing="-1.2px"
                  />
                </NumberContainer>
              </Breakdown>
              <Breakdown>
                <NumberContainer>
                  <ValueTitle>{i18n.t("income")}</ValueTitle>
                  <BreakdownValue
                    currency={dataForScenario.currency}
                    value={income}
                    currencyFontSize={14}
                    valueFontSize={24}
                    fontWeight={400}
                    height={"28px"}
                    showZero={true}
                    letterSpacing="-1.2px"
                  />
                </NumberContainer>
                <NumberContainer>
                  <ValueTitle>{i18n.t("expenses")}</ValueTitle>
                  <BreakdownValue
                    currency={dataForScenario.currency}
                    value={expenses}
                    currencyFontSize={14}
                    valueFontSize={24}
                    fontWeight={400}
                    height={"28px"}
                    showZero={true}
                    letterSpacing="-1.2px"
                  />
                </NumberContainer>
                <NumberContainer>
                  <ValueTitle>{i18n.t("estimatedTax")}</ValueTitle>
                  <BreakdownValue
                    currency={dataForScenario.currency}
                    value={tax}
                    currencyFontSize={14}
                    valueFontSize={24}
                    fontWeight={400}
                    height={"28px"}
                    showZero={true}
                    letterSpacing="-1.2px"
                  />
                </NumberContainer>
              </Breakdown>
            </BreakdownContainer>
          )}
        </CardDetails>
      </Card>
    );
  }

  getTimePeriodDescription(timePeriod) {
    switch (timePeriod) {
      case timePeriods.MONTH:
        return `01 ${i18n.t("month")}`;
      case timePeriods.YEAR:
        return `01 ${i18n.t("year")}`;
      case timePeriods.HALF_DECADE:
        return "05Y";
      case timePeriods.DECADE:
        return "10Y";
      case timePeriods.TWO_DECADES:
        return "20Y";
      default:
        return timePeriod;
    }
  }

  getGroupedDataForTimePeriod(timePeriod) {
    const date = getDateForTimePeriod(timePeriod);
    const dateString = getKuberaDateString(date);

    return this.props.planningData.map(dataForScenario => {
      const dataPoint = dataForScenario.data.find(dataPoint => dataPoint.date === dateString);

      return {
        id: dataForScenario.scenario.id,
        currency: dataForScenario.currency,
        data: [dataPoint]
      };
    });
  }

  getEndValue(groupedData, scenarioIndex) {
    const dataPoints = groupedData[scenarioIndex].data;

    if (dataPoints.length === 0) {
      return null;
    }
    return dataPoints[dataPoints.length - 1].networth;
  }

  render() {
    if (!this.props.planningData === true || !this.props.planningData[this.props.selectedScenarioIndex] === true) {
      return null;
    }

    return (
      <Container>
        <ContentContainer>
          <CardsContainer>
            <CardRow>
              {this.getCardForTimePeriod(timePeriods.MONTH)}
              {this.getCardForTimePeriod(timePeriods.YEAR)}
            </CardRow>
            <CardRow>
              {this.getCardForTimePeriod(timePeriods.HALF_DECADE)}
              {this.getCardForTimePeriod(timePeriods.DECADE)}
              {this.getCardForTimePeriod(timePeriods.TWO_DECADES)}
            </CardRow>
          </CardsContainer>
          {this.state.showBreakdownTimePeriod !== null && (
            <NetworthBreakdownDialog
              colorPallete={this.props.colorPallete}
              groupedData={this.getGroupedDataForTimePeriod(this.state.showBreakdownTimePeriod)}
              planningData={this.props.planningData}
              dataIndex={0}
              selectedScenarios={this.props.planningData.map(data => data.scenario.id)}
              selectedScenarioIndex={this.props.selectedScenarioIndex}
              getEndValue={this.getEndValue}
              onDismiss={this.handleBreakdownDimiss}
            />
          )}
          {this.state.showDobDialog === true && <DobDialog onDismiss={this.handleDobDialogDimiss} />}
        </ContentContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  portfolioNetWorth: portfolioNetWorth(state),
  portfolio: currentPortfolioSelector(state),
  preferences: userPreferencesSelector(state)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PlanningTimestampsComponent));
