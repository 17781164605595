import createWhitelistFilter from "redux-persist-transform-filter";
import parsePhoneNumber from "libphonenumber-js";
import { createSelector } from "reselect";
import {
  SET_STATUS_CONFIG,
  SET_SITE_CONFIG,
  REMOVE_STRIPE_CONNECTED_ACCOUNT,
  SET_USER,
  SET_USER_ERROR,
  SET_USER_PREFERENCES,
  SIGNOUT_USER,
  SET_SIGNIN_REDIRECT_PATH,
  SUBSCRIPTION_STATUS,
  INITIAL_CARD_SETUP_TIMESTAMP,
  userTypes,
  SET_SIGN_IN_WITH_GOOGLE_FLOW,
  OVERRIDE_INITIAL_CC_SETUP_LIST,
  UPDATE_CREDIT_BALANCE,
  FETCH_CREDIT_BALANCE_PENDING,
  SET_APP_MAINTENANCE_STATUS,
  SHOW_BREAKING_CHANGES,
  SUBSCRIPTION_PLANS,
  FETCH_MULTIUSER_LIST_PENDING,
  SET_MULTIUSER_LIST,
  SET_MULTIUSER_INVITATION_DATA,
  SET_SIGN_UP_WITH_GOOGLE_FLOW,
  SET_RECEIPTS,
  SET_SHARE_MASK,
  BLACK_FEATURE_TRIGGERED,
  API_ACCESS_TIMESTAMP
} from "../actions/AuthActions";
import { getSubscriptionRemainingDays } from "../../utilities";
import { parseFullName } from "parse-full-name";
import { wlClientContextSelector } from "./WhiteLabelReducer";
import { wlSetupType } from "../actions/WhiteLabelActions";
import { currentPortfolioCurrencySelector, custodianSelector, currentPortfolioSelector } from "./PortfolioReducer";
import { apiErrorCodes } from "../../api/ApiResponse";
import { isMobile } from "../../utilities/Location";
import { isAppInViewMode, isAppInWhiteLabelMode, capitalizeStringWithSpaces } from "../../utilities/Auth";
import { getCustodianHistoryFormattedDateString, parseKuberaDateString } from "../../utilities/Number";
import { SIMILAR_FORMAT_CURRENCIES } from "../actions";
import i18n from "i18next";

const isMobileDevice = isMobile();

const countryCodeToCountryMap = {
  AF: "Afghanistan",
  AX: "Aland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua And Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia And Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CD: "Congo, Democratic Republic",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "Cote D'Ivoire",
  HR: "Croatia",
  CU: "Cuba",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island & Mcdonald Islands",
  VA: "Holy See (Vatican City State)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran, Islamic Republic Of",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle Of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KR: "Korea",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libyan Arab Jamahiriya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia, Federated States Of",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  AN: "Netherlands Antilles",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian Territory, Occupied",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  RU: "Russian Federation",
  RW: "Rwanda",
  BL: "Saint Barthelemy",
  SH: "Saint Helena",
  KN: "Saint Kitts And Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin",
  PM: "Saint Pierre And Miquelon",
  VC: "Saint Vincent And Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome And Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia And Sandwich Isl.",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard And Jan Mayen",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad And Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks And Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States of America",
  UM: "United States Outlying Islands",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Viet Nam",
  VG: "Virgin Islands, British",
  VI: "Virgin Islands, U.S.",
  WF: "Wallis And Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe"
};

export const latestWhatsNewTipVersion = "v2";
export const latestWhatsNewTip = "";

export const initialState = {
  statusConfig: null,
  siteConfig: null,
  user: null,
  multiuserListPending: false,
  multiuserListMap: {},
  userPreferences: {
    isFirstSessionRegistered: false,
    isYodleeLinkAlertShown: false,
    isAssetInitialTipShownForPortfolio: [],
    isDebtInitialTipShownForPortfolio: [],
    isInsuranceInitialTipShownForPortfolio: [],
    isSidebarCollapsed: false,
    canShowLinkAccountTip: false,
    isLinkAccountTipShown: false,
    planningChartClickTip: false,
    coinsConnectTipShown: false,
    stocksConnectTipShown: false,
    isMultiCurrencyTipShown: false,
    portfolioTickerShortcutMap: {},
    isAccoutNameTipShown: false,
    canShowAccountNameTip: false,
    isSheetOverflowTipShown: false,
    canShowAddNewButtonTip: false,
    isAddNewButtonTipShown: false,
    lastShownWhatsNewTipVersion: null,
    bannerPreference: null,
    optinEndBannerDismissed: null,
    isFirstTimeLinkingDialogShown: false,
    canShowFirstTimeLinkingDialog: false,
    isHoldingsExpansionTipShownForCustodian: [],
    isHoldingsAvailableTipShownForCustodian: [],
    portfolioNetWorthChartTimeRangeMap: {},
    custodianValueChartTimeRange: null,
    isDoughnutTipShownForPortfolio: [],
    isNetWorthChartTipShownForPortfolio: [],
    userPhoneCountryCode: null,
    isLinkingHeadsUpDialogShown: false,
    showRevertHoldingsTipForCustodianId: null,
    isRevertHoldingsTipShown: false,
    isContributingCustodiansTipShown: false,
    irrComparisonIndexes: null,
    maskAllValues: false,
    maskAllValuesMobile: false,
    irrComparisonTimePeriod: null,
    isRecapIntroScreenShownForPortfolio: [],
    portfolioRecapChartTimeRangeMap: {},
    portfolioRecapChartTypeMap: {},
    portfolioRecapChartOptionsMap: {},
    portfolioRecapPercentageChangeFlagMap: {},
    hasTopInvestmentsRetirmentAcknowledged: false,
    recentSearchesPortfolioMap: {},
    valueHistoryExchangeRateToolTipShown: false,
    cashflowExchangeRateToolTipShown: false,
    networthIrrComparisonTimePeriod: null,
    isInvestableAssetsTipShown: false,
    isCashOnHandTipShown: false,
    isUnfunedTipShown: false,
    isTaxOnUnrealizedGainsTipShown: false,
    isUnfundedCardHiddenForPortfolio: [],
    portfolios: {},
    isChartSettingsTipShown: false,
    isRecapSelectedFromMenu: false,
    isReportChartOptionsToolTipShown: false,
    cashflowDownloadToolTipShown: false,
    userDob: null,
    selectedPlanningChartType: null,
    netWorthStatementBadgeShown: false,
    isPlanningIntroScreenShownForPortfolio: [],
    isUserSubscribedOnce: false,
    dontShowSidebarCollapseTip: false,
    kuberaNumberFormat: "systemDefault",
    isLinkPortfolioTipShown: false,
    isBlackHeadsUpShown: {},
    hideAPIDots: false
  },
  shareMaskValue: true,
  redirectPostSignIn: null,
  signInWithGoogleFlow: false,
  signUpWithGoogleFlow: false,
  creditBalance: {
    amount: null,
    isPending: false
  },
  isAccountSuspended: false,
  isAppInMaintenanceMode: false,
  multiuserInvitationId: null,
  multiuserInvitationEmail: null,
  multiuserInvitationPortfolioId: null,
  receipts: [],
  showBreakingChangesError: false,
  blackFeatureTriggeredPromise: null,
  blackFeatureTriggeredAction: null
};

export const authPersistTransform = () => {
  return createWhitelistFilter("auth", [
    "user",
    "multiuserListMap",
    "userPreferences",
    "redirectPostSignIn",
    "signInWithGoogleFlow",
    "signUpWithGoogleFlow",
    "creditBalance",
    "siteConfig",
    "multiuserInvitationEmail",
    "multiuserInvitationId",
    "multiuserInvitationPortfolioId",
    "shareMaskValue"
  ]);
};

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user:
          action.user && action.user.constructor === Object && Object.keys(action.user).length !== 0
            ? { ...action.user, tsClientNow: Date.now() }
            : null
      };
    case SET_USER_ERROR:
      return {
        ...state,
        isAccountSuspended: action.userError.errorCode === apiErrorCodes.ACCOUNT_SUSPENDED
      };
    case SET_USER_PREFERENCES:
      return {
        ...state,
        userPreferences:
          Object.keys(action.userPreferences).length === 0
            ? state.userPreferences
            : { ...initialState.userPreferences, ...action.userPreferences }
      };
    case SET_SIGNIN_REDIRECT_PATH:
      return {
        ...state,
        redirectPostSignIn: action.path
      };
    case SET_SHARE_MASK:
      return {
        ...state,
        shareMaskValue: action.shareMaskValue
      };
    case SET_SITE_CONFIG:
      return {
        ...state,
        siteConfig: action.config
      };
    case REMOVE_STRIPE_CONNECTED_ACCOUNT: {
      const newState = { ...state };
      delete newState.siteConfig.stripeConnectedAccount;
      return newState;
    }
    case SET_STATUS_CONFIG:
      return {
        ...state,
        statusConfig: action.config
      };
    case SET_SIGN_IN_WITH_GOOGLE_FLOW:
      return {
        ...state,
        signInWithGoogleFlow: action.isSignInWithGoogleFlow
      };
    case SET_SIGN_UP_WITH_GOOGLE_FLOW:
      return {
        ...state,
        signUpWithGoogleFlow: action.isSignUpWithGoogleFlow
      };
    case FETCH_CREDIT_BALANCE_PENDING:
      return {
        ...state,
        creditBalance: { amount: state.creditBalance.amount, isPending: action.isPending }
      };
    case UPDATE_CREDIT_BALANCE:
      return {
        ...state,
        creditBalance: { amount: action.balanceInCents, isPending: state.creditBalance.isPending }
      };
    case SET_APP_MAINTENANCE_STATUS:
      return {
        ...state,
        isAppInMaintenanceMode: action.isAppInMaintenanceMode
      };
    case SHOW_BREAKING_CHANGES:
      return {
        ...state,
        showBreakingChangesError: true
      };
    case FETCH_MULTIUSER_LIST_PENDING:
      return {
        ...state,
        multiuserListPending: action.isPending
      };
    case SET_MULTIUSER_LIST: {
      const newState = { ...state };
      newState.multiuserListPending = false;
      newState.multiuserListMap[getPortfolioSessionUserId()] = action.list;
      return newState;
    }
    case SET_MULTIUSER_INVITATION_DATA:
      return {
        ...state,
        multiuserInvitationEmail: action.email,
        multiuserInvitationId: action.invitationId,
        multiuserInvitationPortfolioId: action.portfolioId
      };
    case SET_RECEIPTS:
      return {
        ...state,
        receipts: action.receipts
      };
    case BLACK_FEATURE_TRIGGERED:
      return {
        ...state,
        blackFeatureTriggeredPromise: action.promise,
        blackFeatureTriggeredAction: action.action
      };
    default:
      return state;
  }
}

export const userReceiptsSelector = state => {
  return state.auth.receipts;
};

export const siteConfigSelector = state => {
  return state.auth.siteConfig;
};

export const statusConfigSelector = state => {
  return state.auth.statusConfig;
};

export const userSelector = state => state.auth.user;
export const isAccountSuspendedSelector = state => state.auth.isAccountSuspended;
export const blackFeatureTriggeredPromiseSelector = state => state.auth.blackFeatureTriggeredPromise;
export const blackFeatureTriggeredActionSelector = state => state.auth.blackFeatureTriggeredAction;

export const isReadOnlyWlClient = state => {
  const siteConfig = siteConfigSelector(state);
  return siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO;
};

export const isWlAdvisorModeOn = state => {
  const siteConfig = siteConfigSelector(state);
  if (!siteConfig === true) {
    return false;
  }
  return siteConfig.advisorMode === 1;
};

export const wlSiteDefaultCurrencySelector = state => {
  const siteConfig = siteConfigSelector(state);
  if (!siteConfig === true || !siteConfig.currency === true) {
    return "USD";
  }
  return siteConfig.currency;
};

export const siteConfigUserPoolIdSelector = state => {
  const siteConfig = siteConfigSelector(state);

  if (!siteConfig === true) {
    return null;
  }
  return siteConfig.userPoolId;
};

export const siteConfigClientIdSelector = state => {
  const siteConfig = siteConfigSelector(state);

  if (!siteConfig === true) {
    return null;
  }
  return siteConfig.clientId;
};

export const extractFirstName = name => {
  const parsedName = parseFullName(name);
  if (parsedName.first && parsedName.first.length >= 3) {
    return parsedName.first;
  } else {
    return name;
  }
};

export const sharedPortfolioUsersSelector = createSelector(
  [userSelector],
  user => {
    if (user) {
      return user.sharedWithMe || [];
    }
    return [];
  }
);

export const sharedPortfolioUserSelector = state => {
  if (isSharedPortfolioUserSelector(state) === false) {
    return null;
  }

  const sessionUserId = getPortfolioSessionUserId();
  const sharedPortfolioUsers = sharedPortfolioUsersSelector(state);
  if (!sessionUserId === false) {
    return sharedPortfolioUsers.find(item => item.id === sessionUserId);
  }
  return null;
};

export const sharedPortfolioAccountTitleSelector = (state, ownerUser, uppercase = false) => {
  if (!ownerUser === true) {
    ownerUser = sharedPortfolioUserSelector(state);
  }
  if (!ownerUser === true) {
    return null;
  }
  const user = userSelector(state);
  const userName = capitalizeStringWithSpaces(extractFirstName(userNameSelector(state)));
  if (ownerUser.id === user.id) {
    return userName;
  }

  const ownerName = capitalizeStringWithSpaces(extractFirstName(ownerUser.name));
  return `${uppercase ? userName.toUpperCase() : userName} x ${uppercase ? ownerName.toUpperCase() : ownerName}`;
};

export const sharedPortfolioAccountSubtitleSelector = (state, ownerUser) => {
  if (!ownerUser === true) {
    return null;
  }

  const user = userSelector(state);
  if (ownerUser.id === user.id) {
    return userEmailSelector(state);
  }
  return i18n.t("collabWith").replace("%s%", capitalizeStringWithSpaces(ownerUser.name));
};

export const userFirstNameSelector = state => {
  const name = state.auth.user ? state.auth.user.name : null;

  if (!name === false) {
    return extractFirstName(name);
  }
  return "Default portfolio";
};

export const userEmailSelector = (state, considerWlContext = false) => {
  if (considerWlContext === true) {
    const client = wlClientContextSelector(state);

    if (!client === false) {
      return client.email;
    }
  }
  if (state.auth.user) {
    return state.auth.user.email;
  }
  return "";
};

export const isInternalUserSelector = state => {
  const email = userEmailSelector(state);
  const whiteListedAccounts = [
    "umesh.gopinath@gmail.com",
    "manojmarathayil@gmail.com",
    "me@rohitnadhani.com",
    "testkubera2@gmail.com",
    "test123kubera@gmail.com",
    "testkubera1@gmail.com"
  ];

  if (email && whiteListedAccounts.includes(email)) {
    return true;
  }
  return email && email.endsWith("@kubera.com");
};

export const overrideInitialCCSetupSelector = state => {
  const email = userEmailSelector(state);

  if (process.env.REACT_APP_ENV === "prod" && OVERRIDE_INITIAL_CC_SETUP_LIST.includes(email)) {
    return true;
  }

  return false;
};

export const userObjectSelector = state => {
  if (state.auth.user) {
    return state.auth.user;
  }
  return null;
};

export const userNameSelector = state => {
  if (state.auth.user) {
    return state.auth.user.name;
  }
  return "";
};

export const userDiscountSelector = state => {
  if (state.auth.user) {
    return state.auth.user.discount;
  }
  return 0;
};

export const userTsPasswordSelector = state => {
  if (state.auth.user?.tsPassword) {
    return state.auth.user.tsPassword;
  }
  return 0;
};

export const userOnlyGoogleAuthenticatedSelector = state => {
  let providerType = state.auth.user?.providerType;

  if (!providerType) {
    providerType = "";
  }

  if (providerType === "Google") {
    return 1;
  }
  return 0;
};

export const userThirdPartyAuthenticatedSelector = state => {
  let providerType = state.auth.user?.providerType;

  if (!providerType) {
    providerType = "";
  }

  const providerTypeValues = providerType.split(",");

  if (providerTypeValues.includes("Google") && providerTypeValues.includes("Cognito")) {
    return 1;
  }
  return 0;
};

export const userShowSetPasswordSelector = state => {
  const tsPassword = userTsPasswordSelector(state);
  const userThirdPartyAuthenticated = userThirdPartyAuthenticatedSelector(state);

  if (userThirdPartyAuthenticated === 1 && tsPassword === 0) {
    return 1;
  }
  return 0;
};

export const userDiscountInfoSelector = createSelector(
  [state => state.auth.user],
  user => {
    const NoDiscountInfo = { name: null, url: null };
    if (user) {
      const { discountInfo } = user;
      return discountInfo ? JSON.parse(discountInfo) : NoDiscountInfo;
    }
    return NoDiscountInfo;
  }
);

export const userPhoneSelector = (state, considerWlContext = false) => {
  if (considerWlContext === true) {
    const client = wlClientContextSelector(state);

    if (!client === false) {
      return client.phone;
    }
  }
  if (state.auth.user) {
    return state.auth.user.phone;
  }
  return "";
};

export const userPhoneCountryCodeSelector = (state, considerWlContext = false) => {
  const userPhone = userPhoneSelector(state, considerWlContext);

  if (userPhone) {
    const phoneNumber = parsePhoneNumber(userPhone);
    return phoneNumber.country;
  }
  const userCountryCode = userCountryCodeSelector(state);
  return userCountryCode;
};

export const userCountryNameSelector = state => {
  const countryCode = userCountryCodeSelector(state);
  return countryCodeToCountryMap[countryCode];
};

export const userMaskAllValuesSelector = state => {
  const siteConfig = siteConfigSelector(state);
  if (siteConfig && siteConfig.mask === 1) {
    return state.auth.shareMaskValue;
  }
  return isMobileDevice ? state.auth.userPreferences.maskAllValuesMobile : state.auth.userPreferences.maskAllValues;
};

export const userCountryCodeSelector = state => {
  if (state.auth.user) {
    return state.auth.user.countryCode;
  }
  return "";
};

export const userProfilePictureTokenSelector = state => {
  if (state.auth.user) {
    const profilePicture = state.auth.user.picture;

    if (!profilePicture === true) {
      return "";
    }
    return profilePicture;
  }
  return "";
};

export const userStorageUsage = state => {
  if (state.auth.user) {
    return state.auth.user.storageUsage;
  }
  return 0;
};

export const userStorageLimit = state => {
  if (state.auth.user) {
    return state.auth.user.storageLimit;
  }
  return 0;
};

export const userPriceOptionSelector = state => {
  if (state.auth.user && state.auth.user.price) {
    return state.auth.user.price;
  }
  return {};
};

export const accountTrialStartTsSelector = state => {
  if (isAppInWhiteLabelMode() === false && isSharedPortfolioUserSelector(state)) {
    return sharedPortfolioUserSelector(state).tsTrialStart;
  } else if (state.auth.user) {
    return state.auth.user.tsTrialStart;
  }
  return 0;
};

export const accountCreationTsSelector = state => {
  if (state.auth.user) {
    return state.auth.user.tsCreated;
  }
  return 0;
};

export const isAnniversaryDateSelector = state => {
  const userPreferences = userPreferencesSelector(state);

  const creationTs = accountCreationTsSelector(state) * 1000;

  const today = new Date();

  const created = new Date(creationTs);
  created.setFullYear(today.getFullYear());

  const createdPlus28 = new Date(creationTs);
  createdPlus28.setFullYear(today.getFullYear());
  createdPlus28.setDate(createdPlus28.getDate() + 28);

  return (
    today.getTime() >= created.getTime() &&
    today.getTime() <= createdPlus28.getTime() &&
    today.getFullYear() !== userPreferences.prevAnniversary
  );
};

export const anniversaryCountSelector = state => {
  const creationTs = accountCreationTsSelector(state) * 1000;

  const created = new Date(creationTs);
  const today = new Date();

  return Math.floor(today.getFullYear() - created.getFullYear());
};

export const subscriptionSelector = createSelector(
  [state => userSelector(state), state => sharedPortfolioUsersSelector(state), state => getPortfolioSessionUserId()],
  (user, sharedPortfolioUsers, sessionUserId) => {
    if (!user === true) {
      return null;
    }
    if (!sessionUserId === true || sessionUserId === user.id) {
      return user.subscription;
    }
    return sharedPortfolioUsers.find(item => item.id === sessionUserId).subscription;
  }
);

export const accountAmountPaidSelector = state => {
  const accountSubscriptionStatus = accountSubscriptionStatusSelector(state);
  if (
    accountSubscriptionStatus !== SUBSCRIPTION_STATUS.TRIALING &&
    state.auth.user &&
    subscriptionSelector(state) &&
    subscriptionSelector(state).cost !== null
  ) {
    return subscriptionSelector(state).cost / 100;
  }
  const accountPlan = accountPlanSelector(state);
  const userPriceOption = userPriceOptionSelector(state);
  const userDiscount = userDiscountSelector(state);

  const defaultSubscriptionPrice =
    accountPlan === SUBSCRIPTION_PLANS.MONTHLY ? userPriceOption.monthly : userPriceOption.yearly;
  const discountToApply = accountSubscriptionStatus === SUBSCRIPTION_STATUS.TRIALING ? userDiscount : 0;
  const discount = defaultSubscriptionPrice * (discountToApply / 100);

  return defaultSubscriptionPrice - discount;
};

export const accountStartTsSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    return subscriptionSelector(state).tsStart;
  }
  return 0;
};

export const accountCurrentTsSelector = state => {
  if (state.auth.user) {
    return state.auth.user.tsServerNow;
  }
  return 0;
};

export const accountIsFreshUserSelector = state => {
  const accountCreationTs = accountCreationTsSelector(state);

  return INITIAL_CARD_SETUP_TIMESTAMP - accountCreationTs < 0;
};

export const accountClientCurrentTsSelector = state => {
  if (state.auth.user) {
    return state.auth.user.tsClientNow;
  }
  return 0;
};

export const accountEndTsSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    return subscriptionSelector(state).tsEnd;
  }
  return 0;
};

export const accountGraceTsSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    return subscriptionSelector(state).graceDays;
  }
  return 0;
};

export const accountPlanSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    return subscriptionSelector(state).plan;
  }
  return "trial";
};

export const accountAllPlansSelector = createSelector(
  [userSelector, accountPlanSelector],
  (user, accountPlan) => {
    if (user && accountPlan === SUBSCRIPTION_PLANS.MONTHLY) {
      return [SUBSCRIPTION_PLANS.MONTHLY, ...user.plan];
    } else if (user) {
      return user.plan;
    }
    return [];
  }
);

export const accountRemainingDaysSelector = state => {
  if (state.auth.user && subscriptionSelector(state) && subscriptionSelector(state).remainingDay) {
    return subscriptionSelector(state).remainingDay;
  }
  const accountEndTs = accountEndTsSelector(state);
  const calculatedRemDays = Math.floor((new Date(accountEndTs * 1000).getTime() - new Date().getTime()) / 86400000);
  return calculatedRemDays >= 0 ? calculatedRemDays : 0;
};

export const accountPaymentAuthSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    return subscriptionSelector(state).paymentAuth;
  }
  return null;
};

export const coolingOffDaysSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    return subscriptionSelector(state).coolingOffDays;
  }
  return 0;
};

export const accountIsSubscriptionIsRequiredSelector = state => {
  const isInViewMode = isAppInViewMode();
  const isWhiteLabelSite = isAppInWhiteLabelMode();

  const accountPlan = accountPlanSelector(state);
  const overrideInitialCCSetup = overrideInitialCCSetupSelector(state);
  const accountIsFreshUser = accountIsFreshUserSelector(state);
  const accountTrialStartTs = accountTrialStartTsSelector(state);

  const isTrial = accountPlan === SUBSCRIPTION_PLANS.TRIAL;

  return (
    !isInViewMode &&
    !isWhiteLabelSite &&
    !overrideInitialCCSetup &&
    isTrial &&
    accountIsFreshUser &&
    !accountTrialStartTs
  );
};

export const accountSubscriptionStatusSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    switch (subscriptionSelector(state).status) {
      case SUBSCRIPTION_STATUS.ACTIVE:
      case SUBSCRIPTION_STATUS.CANCELED:
      case SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED:
      case SUBSCRIPTION_STATUS.PAST_DUE:
      case SUBSCRIPTION_STATUS.EXPIRED:
      case SUBSCRIPTION_STATUS.TRIALING:
        return subscriptionSelector(state).status;
      default:
        return SUBSCRIPTION_STATUS.PAST_DUE;
    }
  }

  return SUBSCRIPTION_STATUS.ACTIVE;
};

export const accountRemainingTsSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    let remainingTs = 0;

    const positiveStatus = [
      SUBSCRIPTION_STATUS.ACTIVE,
      SUBSCRIPTION_STATUS.EXPIRED,
      SUBSCRIPTION_STATUS.TRIALING,
      SUBSCRIPTION_STATUS.CANCELED,
      SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED
    ];
    const accountSubscriptionStatus = accountSubscriptionStatusSelector(state);
    const accountCurrentTs = accountCurrentTsSelector(state);
    const accountEndTs = accountEndTsSelector(state);
    const accountStartTs = accountStartTsSelector(state);

    if (positiveStatus.includes(accountSubscriptionStatus)) {
      remainingTs = accountEndTs - accountCurrentTs;
    } else {
      remainingTs = accountStartTs - accountCurrentTs;
    }

    return remainingTs;
  }

  return 0;
};

export const accountRemainingFromTsDatesSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    let remainingDays = 0;

    const positiveStatus = [
      SUBSCRIPTION_STATUS.ACTIVE,
      SUBSCRIPTION_STATUS.EXPIRED,
      SUBSCRIPTION_STATUS.TRIALING,
      SUBSCRIPTION_STATUS.CANCELED,
      SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED
    ];
    const accountSubscriptionStatus = accountSubscriptionStatusSelector(state);
    const accountCurrentTs = accountCurrentTsSelector(state);
    const accountEndTs = accountEndTsSelector(state);
    const accountStartTs = accountStartTsSelector(state);

    if (positiveStatus.includes(accountSubscriptionStatus)) {
      remainingDays = getSubscriptionRemainingDays(accountCurrentTs, accountEndTs);
    } else {
      remainingDays = getSubscriptionRemainingDays(accountCurrentTs, accountStartTs);
    }

    return remainingDays;
  }

  return 0;
};

export const accountSubscriptionIsSubscribedSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    const remainingDays = accountRemainingFromTsDatesSelector(state);
    return remainingDays > 0;
  }

  return false;
};

export const accountSubscriptionIsActiveSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    const remainingDays = accountRemainingFromTsDatesSelector(state);
    const accountGraceTs = accountGraceTsSelector(state);

    return remainingDays + accountGraceTs > 0;
  }

  return false;
};

export const accountCardDetailsSelector = createSelector(
  [subscriptionSelector],
  subscription => {
    if (subscription) {
      return {
        cardLast4: subscription.cardLast4Digits,
        cardBrand: subscription.cardBrand,
        cardExpiryMonth: subscription.cardExpMonth,
        cardExpiryYear: subscription.cardExpYear
      };
    }
    return null;
  }
);

export const hasAccountAddedAnyCardSelector = state => {
  const cardDetails = accountCardDetailsSelector(state);
  return !cardDetails === false && !cardDetails.cardBrand === false;
};

export const accountPaymentMethodSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    return subscriptionSelector(state).paymentMethodId;
  }
  return null;
};

export const isBlackUserSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    return subscriptionSelector(state).allowExtendedShare;
  }
  return 0;
};

export const isBlackOptinAllowedSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    return subscriptionSelector(state).allowOptIn;
  }
  return 0;
};

export const blackRemainingOptinTrialDaysSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    return subscriptionSelector(state).remainingOptInTrialDay;
  }
  return 0;
};

export const isNewUserInTrialSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    const accountPlan = accountPlanSelector(state);

    if (
      accountPlan === SUBSCRIPTION_PLANS.TRIAL &&
      isBlackUserSelector(state) &&
      !userSelector(state).tsExtendedTrialStart
    ) {
      return true;
    }

    return false;
  }

  return true;
};

export const isInOptinTrialSelector = state => {
  if (state.auth.user && blackRemainingOptinTrialDaysSelector(state)) {
    return userSelector(state).tsExtendedTrialStart && blackRemainingOptinTrialDaysSelector(state) > 0;
  }
  return false;
};

export const isOptinTrialFinishedSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    return userSelector(state).tsExtendedTrialStart && blackRemainingOptinTrialDaysSelector(state) === 0;
  }
  return false;
};

export const showBlackPaywalls = state => {
  const isBlackUser = isBlackUserSelector(state);
  const isBlackOptinAllowed = isBlackOptinAllowedSelector(state);
  const isNewUserInTrial = isNewUserInTrialSelector(state);

  return (
    isAppInViewMode() === false &&
    (isAppInWhiteLabelMode() === true
      ? siteConfigSelector(state).allowPortfolioLink === 0
      : !isBlackUser || isNewUserInTrial || isBlackOptinAllowed)
  );
};

export const trialPeriodSelector = state => {
  if (state.auth.user && state.auth.user.trialDay) {
    return state.auth.user.trialDay;
  }
  return 100;
};

export const userPreferencesSelector = state => {
  return state.auth.userPreferences;
};

export const portfolioTickerShortcutsSelector = (state, portfolioId) => {
  const userPreferences = userPreferencesSelector(state);
  if (userPreferences && userPreferences.portfolioTickerShortcutMap) {
    return userPreferences.portfolioTickerShortcutMap[portfolioId];
  }
  return [];
};

export const redirectPostSignInSelector = state => {
  return state.auth.redirectPostSignIn;
};

export const signWithGoogleFlowSelector = state => {
  return state.auth.signInWithGoogleFlow;
};

export const signUpWithGoogleFlowSelector = state => {
  return state.auth.signUpWithGoogleFlow;
};

export const supportedLinkingServiceTypesSelector = state => {
  const user = state.auth.user;

  if (user && user.globalSetting && user.globalSetting.supportedLinkType) {
    try {
      return JSON.parse(user.globalSetting.supportedLinkType);
    } catch (error) {
      return null;
    }
  }
  return null;
};

export const canShowWhatsNewTip = state => {
  const userPreferences = userPreferencesSelector(state);
  return userPreferences.lastShownWhatsNewTipVersion !== latestWhatsNewTipVersion;
};

export const isInitialTipShown = (state, portfolio, category) => {
  if (!portfolio === true) {
    return false;
  }

  const userPreferences = userPreferencesSelector(state);
  if (category === "Asset") {
    if (userPreferences.isAssetInitialTipShownForPortfolio) {
      return userPreferences.isAssetInitialTipShownForPortfolio.includes(portfolio.id);
    }
  } else if (category === "Debt") {
    if (userPreferences.isDebtInitialTipShownForPortfolio) {
      return userPreferences.isDebtInitialTipShownForPortfolio.includes(portfolio.id);
    }
  } else if (category === "Insurance") {
    if (userPreferences.isInsuranceInitialTipShownForPortfolio) {
      return userPreferences.isInsuranceInitialTipShownForPortfolio.includes(portfolio.id);
    }
  }
  return false;
};

export const isDoughnutTipShownForPortfolio = (state, portfolio) => {
  if (!portfolio === true) {
    return true;
  }
  const userPreferences = userPreferencesSelector(state) || {};
  return (
    userPreferences.isDoughnutTipShownForPortfolio &&
    userPreferences.isDoughnutTipShownForPortfolio.includes(portfolio.id)
  );
};

export const isNetWorthChartTipShownForPortfolio = (state, portfolio) => {
  if (!portfolio === true) {
    return true;
  }
  const userPreferences = userPreferencesSelector(state);
  return userPreferences.isNetWorthChartTipShownForPortfolio.includes(portfolio.id);
};

export const isRecapIntroScreenShownForPortfolio = (state, portfolio) => {
  if (!portfolio === true) {
    return true;
  }
  const userPreferences = userPreferencesSelector(state);
  return userPreferences.isRecapIntroScreenShownForPortfolio.includes(portfolio.id);
};

export const isPlanningIntroScreenShownForPortfolio = (state, portfolio) => {
  if (!portfolio === true) {
    return true;
  }
  const userPreferences = userPreferencesSelector(state);
  if (!userPreferences.isPlanningIntroScreenShownForPortfolio === true) {
    return true;
  }
  return userPreferences.isPlanningIntroScreenShownForPortfolio.includes(portfolio.id);
};

export const irrComparisonIndexesSelector = createSelector(
  [state => state],
  state => {
    const userPreferences = userPreferencesSelector(state);
    var indexes = userPreferences.irrComparisonIndexes;
    if (indexes === null) {
      indexes = ["DJI.INDX", "GSPC.INDX"];
    }
    return indexes;
  }
);

export const irrComparisonTimePeriodSelector = state => {
  const userPreferences = userPreferencesSelector(state);
  return userPreferences.irrComparisonTimePeriod;
};

export const netWorthIrrComparisonTimePeriodSelector = state => {
  const userPreferences = userPreferencesSelector(state);
  return userPreferences.networthIrrComparisonTimePeriod;
};

export const recentSearchesSelector = (state, portfolioId) => {
  const userPreferences = userPreferencesSelector(state);
  const recentSearchesMap = userPreferences.recentSearchesPortfolioMap;
  if (!recentSearchesMap === true || !recentSearchesMap[portfolioId] === true) {
    return [];
  }
  return recentSearchesMap[portfolioId];
};

export const whiteLabelUserInfoSelector = state => {
  const user = userSelector(state);
  if (!user === true) {
    return null;
  }
  return userSelector(state).wl;
};

export const isAmcUser = state => {
  const whiteLabelUserInfo = whiteLabelUserInfoSelector(state);
  if (!whiteLabelUserInfo === true) {
    return false;
  }
  return (
    whiteLabelUserInfo.type === userTypes.ADMIN ||
    whiteLabelUserInfo.type === userTypes.OWNER ||
    whiteLabelUserInfo.type === userTypes.MANAGER
  );
};

export const canAmcUserManageOtherUsers = state => {
  const whiteLabelUserInfo = whiteLabelUserInfoSelector(state);
  if (!whiteLabelUserInfo === true) {
    return false;
  }
  return whiteLabelUserInfo.type === userTypes.ADMIN || whiteLabelUserInfo.type === userTypes.OWNER;
};

export const shouldShowHoldingsExpansionTipForCustodian = (state, custodianId) => {
  const custodian = custodianSelector(state, custodianId);
  if (custodian && custodian.holdingsCount > 1) {
    const userPreferences = userPreferencesSelector(state);
    if (userPreferences.isHoldingsExpansionTipShownForCustodian) {
      return userPreferences.isHoldingsExpansionTipShownForCustodian.includes(custodianId) === false;
    }
  }
  return false;
};

export const shouldShowHoldingsAvailableTipForCustodian = (state, custodianId) => {
  // 1605011830 check is to ensure tip is not shown for already linked custodians
  const custodian = custodianSelector(state, custodianId);
  if (
    custodian &&
    custodian.holdingsCount > 0 &&
    custodian.linkAccountTsCreated &&
    custodian.linkAccountTsCreated > 1605011830
  ) {
    const userPreferences = userPreferencesSelector(state);
    if (userPreferences.isHoldingsAvailableTipShownForCustodian) {
      return userPreferences.isHoldingsAvailableTipShownForCustodian.includes(custodianId) === false;
    }
  }
  return false;
};

export const creditBalanceSelector = state => {
  return state.auth.creditBalance;
};

export const appMaintenanceModeSelector = state => {
  return state.auth.isAppInMaintenanceMode;
};

export const showBreakingChangesSelector = state => {
  return state.auth.showBreakingChangesError;
};

export const betaProgramEnabledSelector = (state, programName) => {
  const user = userSelector(state);
  if (!user === true || !user.beta === true) {
    return false;
  }
  try {
    const programs = JSON.parse(user.beta.feature);
    return programs.includes(programName);
  } catch (e) {
    return false;
  }
};

export const getAccountOwnerNameSelector = state => {
  if (isSharedPortfolioUserSelector(state) === false) {
    return userNameSelector(state);
  }
  if (isAppInWhiteLabelMode()) {
    const userList = multiuserListSelector(state);
    if (userList) {
      const owner = userList.find(item => !item.parentId === true);
      if (owner) {
        return owner.name;
      }
    }
    return null;
  }
  const sharedPortfolioUsers = sharedPortfolioUsersSelector(state);
  const ownerUserId = getPortfolioSessionUserId();
  const owner = sharedPortfolioUsers.find(item => item.id === ownerUserId);
  if (owner) {
    return owner.name;
  }
  return null;
};

export const maxMultiuserMemberCountSelector = state => {
  if (state.auth.user && subscriptionSelector(state)) {
    return subscriptionSelector(state).maxFamilyMemberCount;
  }
  return 0;
};

export const accountMultiuserEnabledSelector = state => {
  const maxUsersCount = maxMultiuserMemberCountSelector(state);
  return !maxUsersCount === false;
};

export const isSharedPortfolioUserSelector = state => {
  const user = userSelector(state);
  const sessionUserId = getPortfolioSessionUserId();
  if (isAppInWhiteLabelMode() && user && user.parentId) {
    return true;
  }
  if (!user === false && !sessionUserId === false && sessionUserId !== user.id) {
    return true;
  }
  return false;
};

export const isMultiuserPendingSelector = state => {
  return state.auth.multiuserListPending;
};

export const multiuserListSelector = createSelector(
  [state => state],
  state => {
    return state.auth.multiuserListMap[getPortfolioSessionUserId()] || [];
  }
);

export const multiuserListForCurrentPortfolioSelector = state => {
  const portfolio = currentPortfolioSelector(state);
  const currentUser = userSelector(state);
  if (!portfolio === true || !currentUser === true) {
    return [];
  }

  const userList = multiuserListSelector(state);
  return userList
    .filter(user => user.userId !== currentUser.id && (!user.portfolioId === true || user.portfolioId === portfolio.id))
    .sort((a, b) => a.tsInvite - b.tsInvite);
};

export const currentUserMemberDetailsFromMultiuserListSelector = state => {
  const multiuserList = multiuserListSelector(state);
  const currentUser = userSelector(state);
  return multiuserList.find(item => item.userId === currentUser.id);
};

export const multiuserInvitationDataSelector = state => {
  return {
    email: state.auth.multiuserInvitationEmail,
    id: state.auth.multiuserInvitationId,
    portfolioId: state.auth.multiuserInvitationPortfolioId
  };
};

export const wlSiteConnectedAccountSelector = state => {
  const siteConfig = siteConfigSelector(state);

  if (siteConfig && siteConfig.stripeConnectedAccount) {
    return siteConfig.stripeConnectedAccount;
  }

  return {};
};

export const userDobSelector = state => {
  const userPreferences = userPreferencesSelector(state);
  return userPreferences.userDob;
};

export const formattedUserDobSelector = state => {
  const userDob = userDobSelector(state);
  if (!userDob === true) {
    return null;
  }
  return getCustodianHistoryFormattedDateString(parseKuberaDateString(userDob).getTime());
};

export const dateForUserAge = (state, age) => {
  const userDob = userDobSelector(state);

  if (!userDob === true || !age === true || age < 0) {
    return null;
  }
  const userDobDate = parseKuberaDateString(userDob);
  return new Date(userDobDate.setFullYear(userDobDate.getFullYear() + age));
};

export const userAgeAtDate = (state, futureDate) => {
  const userDob = userDobSelector(state);

  if (!userDob === true || !futureDate === true || futureDate.getTime() < new Date().getTime()) {
    return null;
  }
  const userDobDate = parseKuberaDateString(userDob);
  var age = futureDate.getFullYear() - userDobDate.getFullYear();
  if (
    futureDate.getMonth() < userDobDate.getMonth() ||
    (futureDate.getMonth() === userDobDate.getMonth() && futureDate.getDate() < userDobDate.getDate())
  ) {
    age--;
  }
  return age;
};

export const planningChartTypeSelector = state => {
  const userPreferences = userPreferencesSelector(state);
  return userPreferences.selectedPlanningChartType;
};

export const isUnfundedCardHiddenForAPortfolio = (state, portfolio) => {
  if (!portfolio === true) {
    return true;
  }
  const userPreferences = userPreferencesSelector(state);
  // console.log("***", userPreferences.isUnfundedCardHiddenForPortfolio);
  if (userPreferences.isUnfundedCardHiddenForPortfolio !== undefined) {
    return userPreferences.isUnfundedCardHiddenForPortfolio.includes(portfolio.id);
  }
  return null;
};

export const kuberaNumberFormatSelector = state => {
  const userPreferences = userPreferencesSelector(state);
  if (userPreferences && userPreferences.kuberaNumberFormat) {
    return userPreferences.kuberaNumberFormat === "systemDefault" ? undefined : userPreferences.kuberaNumberFormat;
  } else {
    return undefined;
  }
};

export const kuberaNumberFormatSelectorOverridenForSimilarFormatCurrencies = state => {
  const portfolioCurrency = currentPortfolioCurrencySelector(state);
  const siteConfig = siteConfigSelector(state);
  const kuberaNumberFormatForROMode =
    siteConfig && siteConfig.option ? JSON.parse(siteConfig.option).kuberaNumberFormat : undefined;
  let kuberaNumberFormat;
  if (SIMILAR_FORMAT_CURRENCIES.get("INR").includes(portfolioCurrency)) {
    kuberaNumberFormat = "en-IN";
  } else {
    kuberaNumberFormat = isAppInViewMode() ? kuberaNumberFormatForROMode : kuberaNumberFormatSelector(state);
  }
  return kuberaNumberFormat;
};

var portfolioUserId = null;
export const setPortfolioSessionUserId = userId => {
  portfolioUserId = userId;
  sessionStorage.setItem("portfolioUserId", userId);
};

export const removePortfolioSessionUserId = () => {
  portfolioUserId = null;
  sessionStorage.removeItem("portfolioUserId");
};

export const getPortfolioSessionUserId = () => {
  if (!portfolioUserId === true) {
    portfolioUserId = sessionStorage.getItem("portfolioUserId");
  }
  return portfolioUserId;
};

var lastUsedPortfolioUserId = null;
export const setLastUsedPortfolioUserId = userId => {
  lastUsedPortfolioUserId = userId;
  localStorage.setItem("lastUsedPortfolioUserId", userId);
};

export const getLastUsedPortfolioUserId = () => {
  if (!lastUsedPortfolioUserId === true) {
    lastUsedPortfolioUserId = localStorage.getItem("lastUsedPortfolioUserId");
  }
  return lastUsedPortfolioUserId;
};

export const removeLastUsedPortfolioUserId = () => {
  lastUsedPortfolioUserId = null;
  localStorage.removeItem("lastUsedPortfolioUserId");
};

export const showAPIBadgeTipSelector = state => {
  const userPreferences = userPreferencesSelector(state);
  if (userPreferences?.hideAPIDots === true) return false;

  const accountCreationTs = accountCreationTsSelector(state);
  const user = userSelector(state);
  const userId = getPortfolioSessionUserId();
  return (
    !isAppInWhiteLabelMode() && !isAppInViewMode() && accountCreationTs < API_ACCESS_TIMESTAMP && user?.id === userId
  );
};
