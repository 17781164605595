import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  guessFutureDate,
  guessPastDate,
  updateUserPreferences,
  userDobSelector,
  getKuberaDateString,
  getCustodianHistoryFormattedDateString,
  guessDate,
  parseKuberaDateString,
  wlClientContextSelector,
  isInCustodianHistoryFormat,
  isInvalidDob,
  dateRange
} from "@kubera/common";
import PrimaryButton from "components/button/PrimaryButton";
import NumberInput from "components/inputs/NumberInput";
import DateInput from "components/inputs/DateInput";
import PercentageInput from "components/inputs/PercentageInput";
import { ReactComponent as ClearIcon } from "assets/images/delete_user_icon.svg";
import DropDown from "components/inputs/DropDown";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";

const DateDialog = styled(Dialog)`
  position: relative;
  width: 460px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 50px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const ModeContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const ModeTitle = styled.div`
  color: ${props => (props.isSelected ? "#000000" : "rgba(0, 0, 0, 0.35);")};
  cursor: pointer;
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AgeInput = styled(NumberInput)`
  height: 24px;
  padding: 12px 20px 12px 20px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  outline: none;
  font-weight: 400;
  color: ${props => (!props.hasError ? "inherit" : props.theme.errorCLR)};
  ::placeholder {
    font-weight: 400;
  }
`;

const DateOfBirthTitle = styled(ModeTitle)`
  margin-top: 13px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const DateOfBirthInput = styled(DateInput)`
  margin-top: 10px;
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: ${props => (props.extraExtraMargin ? "28px" : props.extramargin ? "18px" : "5px")};
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  text-align: left;
  letter-spacing: 0.01em;
  color: ${props => (!props.hasError ? "transparent" : props.theme.errorCLR)};
  margin-block-start: 5px !important;
  margin-block-end: 0em !important;
`;

const AddThereafterLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: #0074fc;
  cursor: pointer;
  width: fit-content;
  margin-top: 5px;
`;

const ThereafterTitleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const ThereafterTitles = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
  flex: 1;
`;

const HitArea = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  right: 0;
  top: 0;
`;

const ClearButton = styled(ClearIcon)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const RateInput = styled(PercentageInput)`
  width: 100%;
`;

const IncreasingDropDown = styled(DropDown)`
  background: transparent;
  padding: 0;
  border: 0;
`;

class DateAgeDialog extends React.Component {
  constructor(props) {
    super(props);
    const revisedPercentage = props.data ? props.data.revisedPercentage : null;
    this.state = {
      isAgeMode: false,
      dateString:
        props.data && props.data.date
          ? getCustodianHistoryFormattedDateString(parseKuberaDateString(props.data.date).getTime())
          : null,
      age: props.data ? props.data.age : null,
      dobString: props.userDob,
      invalidAge: false,
      revisedPercentage,
      showThereafter: this.props.enableRevisions && !isNaN(revisedPercentage) && revisedPercentage !== null,
      increasing: props.data?.increasing !== undefined ? props.data.increasing : true
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);
    this.handleDateInputChange = this.handleDateInputChange.bind(this);
    this.handleAgeInputChange = this.handleAgeInputChange.bind(this);
    this.handleDobInputChange = this.handleDobInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.allowPastDate = this.allowPastDate.bind(this);
    this.resolveAge = this.resolveAge.bind(this);
    addKeyboardEventListener(this.handleKeyDown);
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown, true);
  }

  handleModeChange(isAgeMode) {
    if (isAgeMode === this.state.isAgeMode) {
      return;
    }
    this.setState({ isAgeMode: isAgeMode });
  }

  handleDateInputChange(e) {
    this.setState({ dateString: e.target.value });
  }

  handleAgeInputChange(e, value) {
    this.setState({ invalidAge: false });
    this.setState({ age: value });
  }

  resolveAge() {
    const guessedDate = guessPastDate(this.state.dobString);
    if (this.state.age < 0 || this.state.age > dateRange) {
      this.setState({ invalidAge: true });
      return true;
    }
    if (!guessedDate || isInvalidDob(guessedDate) || !this.state.age) {
      this.setState({ invalidAge: false });
      return false;
    }
    const res = guessedDate.date.getFullYear() + this.state.age < new Date().getFullYear();
    this.setState({ invalidAge: res });
    return res;
  }

  handleDobInputChange(newValue = "") {
    this.setState({ dobString: newValue });
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      if (this.state.isAgeMode === true || isInCustodianHistoryFormat(this.state.dateString)) {
        this.handleSaveClick();
      }
    }
  }

  getDropDownData() {
    var data = [
      {
        id: true,
        label: i18n.t("increasingBy"),
        selected: this.state.increasing === true
      },
      {
        id: false,
        label: i18n.t("decreasingBy"),
        selected: this.state.increasing === false
      }
    ];
    return data;
  }

  handleSaveClick(e) {
    const thereafterObj = this.props.enableRevisions
      ? { revisedPercentage: this.state.revisedPercentage, increasing: this.state.increasing }
      : {};
    if (this.state.isAgeMode === true) {
      const isInvalidAge = this.resolveAge();
      const guessedDate = guessPastDate(this.state.dobString);
      if (!isInvalidDob(guessedDate) && !this.state.age === false && this.state.age > 0 && !isInvalidAge) {
        this.props.updateUserPreferences({
          userDob: guessedDate.dateString
        });

        const targetDate = parseKuberaDateString(guessedDate.dateString);
        targetDate.setFullYear(guessedDate.date.getFullYear() + this.state.age);
        const targetDateString = getKuberaDateString(targetDate.getTime());

        this.props.onVariableUpdate({
          ...(this.props.data || {}),
          date: targetDateString,
          age: this.state.age,
          ...thereafterObj
        });
        this.props.onDismiss();
      }
    } else {
      const guessedDate = this.allowPastDate()
        ? guessDate(this.state.dateString)
        : guessFutureDate(this.state.dateString);
      if (guessedDate.isInvalid === false) {
        this.props.onVariableUpdate({ ...(this.props.data || {}), date: guessedDate.dateString, ...thereafterObj });
        this.props.onDismiss();
      }
    }
  }

  allowPastDate() {
    return (
      !this.props.data === false && !this.props.data.props === false && this.props.data.props.allowPastDate === true
    );
  }

  onDateChange(value = "") {
    this.setState({ dateString: value });
  }

  render() {
    const canShowAgeOption = !this.props.wlClientContext === true;

    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <DateDialog>
          <Container>
            <ModeContainer>
              <ModeTitle isSelected={!this.state.isAgeMode} onClick={e => this.handleModeChange(false)}>
                {i18n.t("byDate")}
              </ModeTitle>
              {canShowAgeOption === true && (
                <ModeTitle isSelected={this.state.isAgeMode} onClick={e => this.handleModeChange(true)}>
                  {i18n.t("byAge")}
                </ModeTitle>
              )}
            </ModeContainer>
            {this.state.isAgeMode === false && (
              <DateInput
                value={this.state.dateString}
                allowPastDate={this.allowPastDate}
                onDateChange={this.onDateChange}
                onValidDate={this.handleSaveClick}
                errorMessage={i18n.t("enterFutureDate")}
              />
            )}
            {this.state.isAgeMode === true && (
              <AgeContainer>
                <AgeInput
                  placeholder={i18n.t("age")}
                  autoFocus={true}
                  value={this.state.age}
                  onChange={this.handleAgeInputChange}
                  onKeyDown={this.handleKeyDown}
                  onBlur={this.resolveAge}
                  hasError={this.state.age && this.state.invalidAge}
                />
                <ErrorMessage hasError={this.state.invalidAge}>{i18n.t("enterFutureAge")}</ErrorMessage>
                {!this.props.userDob === true && (
                  <>
                    <DateOfBirthTitle>{`${i18n.t("your")} ${i18n.t("dateOfBirth")}`}</DateOfBirthTitle>{" "}
                    <DateOfBirthInput
                      inputPlaceholder={i18n.t("date")}
                      value={this.state.dobString}
                      onDateChange={this.handleDobInputChange}
                      isBdayPicker={true}
                    />
                  </>
                )}
              </AgeContainer>
            )}
            {this.props.enableRevisions && !this.state.showThereafter && (
              <AddThereafterLabel onClick={() => this.setState({ showThereafter: true })}>
                {i18n.t("addRevisedPercentage")}
              </AddThereafterLabel>
            )}
            {this.state.showThereafter && (
              <>
                <ThereafterTitleContainer>
                  <ThereafterTitles>
                    <div>{i18n.t("thereafter")}</div>
                    <IncreasingDropDown
                      width={360}
                      selectedItemStyle={{ fontWeight: "bold", fontSize: "22px", flex: "inherit" }}
                      items={this.getDropDownData()}
                      onSelection={item => {
                        this.setState({ increasing: item.id });
                      }}
                    />
                  </ThereafterTitles>
                  <HitArea
                    onClick={() => this.setState({ revisedPercentage: null, showThereafter: false, increasing: true })}
                  >
                    <ClearButton />
                  </HitArea>
                </ThereafterTitleContainer>
                <RateInput
                  value={this.state.revisedPercentage}
                  onChange={(e, parsedInput) => this.setState({ revisedPercentage: parsedInput })}
                  callOnChangeOnClear={true}
                  inputValidation={value => value < 0}
                  showZero={true}
                  placeholder={i18n.t("thereafterPercentagePlaceholder")}
                />
              </>
            )}
            <SaveButton
              title={i18n.t("save")}
              onClick={this.handleSaveClick}
              extramargin={!this.props.userDob === true && this.state.isAgeMode === true}
              extraExtraMargin={this.props.enableRevisions}
            />
          </Container>
        </DateDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  userDob: userDobSelector(state),
  wlClientContext: wlClientContextSelector(state)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DateAgeDialog);
