import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  dashboardChartIdsSelector,
  defaultDashboardChartIdsSelector,
  updateDashboardCharts,
  chartKeyParams,
  parseParams,
  createChartIdUsingReportId,
  currentPortfolioSelector,
  getColumnForChart,
  recapDataPortfolioMapUpdateTimestampSelector,
  recapChartOptions,
  recapDataSelector,
  shouldShowLoaderOnPortfolioAndNeworthCurrencyMismatch,
  shortFormatNumberWithCurrency,
  getSheetsAndSectionsSankeyChartDataFromRecap
} from "@kubera/common";
import NetWorthLineChartComponent from "components/net_worth/NetWorthLineChartComponent";
import DiyDashboardRenderCharts from "./DiyDashboardRenderCharts";
import Loader from "components/loader/Loader";
import SankeyChartComponent from "../charts/SankeyChartComponent";

const NetWorthLineChartContainer = styled.div`
  position: ${props => (props.empty === true ? "relative" : "")};
  margin-bottom: 6px;
  width: 100%;
`;
const LoaderOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.popoverBackgroundColor};
  z-index: 10000;
`;

const RefreshLoader = styled(Loader)`
  height: auto;
  margin-top: 160px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const SankeyChart = styled(SankeyChartComponent)`
  margin-bottom: 6px;
  border: ${props => props.theme.netWorthContainerBR};
  background: ${props => props.theme.netWorthContainerBG};
`;

const DiyDashboardChartsComponent = props => {
  const dispatch = useDispatch();

  const { netWorthData, areAllChartsEmpty, isNetWorthChartEmpty, portfolioId } = props;

  const isFetching = netWorthData.isFetching;

  const currentPortfolio = useSelector(currentPortfolioSelector);
  const chartIds = useSelector(dashboardChartIdsSelector);
  const defaultDashboardChartIds = useSelector(defaultDashboardChartIdsSelector) || [];
  const recapData = useSelector(recapDataSelector);
  const recapDataPortfolioMapUpdateTimestamp = useSelector(recapDataPortfolioMapUpdateTimestampSelector);
  const showLoaderOnPortfolioAndNeworthCurrencyMismatch = useSelector(
    shouldShowLoaderOnPortfolioAndNeworthCurrencyMismatch
  );

  let diyDashboardCharts = chartIds;
  let dashboardChartIds = useMemo(
    () => [...chartIds.columns["column1"].chartIds, ...chartIds.columns["column2"].chartIds],
    [chartIds]
  );

  const diyChartsData = useMemo(() => {
    if (!recapDataPortfolioMapUpdateTimestamp)
      return {
        diyDashboardCharts,
        dashboardChartIds
      };

    let diyDashboardChartsTemp = diyDashboardCharts;
    let dashboardChartIdsTemp = dashboardChartIds;

    if (dashboardChartIds.length && recapData) {
      let remainingCharts = defaultDashboardChartIds.filter((chartId, index) => {
        const defaultChartParams = parseParams(chartId);
        return dashboardChartIds.every(id => {
          const dashboardChartParams = parseParams(id);
          if (parseParams(defaultChartParams.report_id).chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id) {
            return (
              parseParams(dashboardChartParams.report_id).report_node_id !==
              parseParams(defaultChartParams.report_id).report_node_id
            );
          }
          return !(
            defaultChartParams[chartKeyParams.IS_DEFAULT_CHART] ===
              dashboardChartParams[chartKeyParams.IS_DEFAULT_CHART] &&
            defaultChartParams.chart_style === dashboardChartParams.chart_style &&
            dashboardChartParams.chart_content === defaultChartParams.chart_content &&
            dashboardChartParams.chart_name === defaultChartParams.chart_name
          );
        });
      });
      const diyDashboardChartsObj = { ...diyDashboardCharts };
      if (remainingCharts.length) {
        remainingCharts.forEach((chart, _) => {
          const columnForChart = getColumnForChart(diyDashboardCharts);
          diyDashboardChartsObj.columns[columnForChart].chartIds = [
            ...diyDashboardChartsObj.columns[columnForChart].chartIds
          ];
          diyDashboardChartsObj.columns[columnForChart].chartIds.push(chart);
        });
        diyDashboardChartsTemp = diyDashboardChartsObj;
        dashboardChartIdsTemp = [
          ...diyDashboardChartsObj.columns["column1"].chartIds,
          ...diyDashboardChartsObj.columns["column2"].chartIds
        ];
      } else if (
        defaultDashboardChartIds.filter(chart => chart).length < dashboardChartIds.filter(chart => chart).length
      ) {
        console.log("some charts have been removed");
        // if any of the default chart is removed like user has removed all crypto assets etc
        const column1Charts = diyDashboardChartsObj.columns.column1.chartIds;
        const column2Charts = diyDashboardChartsObj.columns.column2.chartIds;
        const filteredColumn1Charts = column1Charts.filter(chartId => {
          const chartParams = parseParams(chartId);
          const isDefaultChart = chartParams[chartKeyParams.IS_DEFAULT_CHART] === "true";

          return isDefaultChart
            ? defaultDashboardChartIds.find(id => {
                const parsedId = parseParams(id);
                if (parseParams(parsedId.report_id).chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id) {
                  return (
                    parseParams(chartParams.report_id).report_node_id === parseParams(parsedId.report_id).report_node_id
                  );
                } else {
                  return (
                    chartParams.chart_style === parsedId.chart_style &&
                    chartParams.chart_content === parsedId.chart_content &&
                    chartParams.chart_name === parsedId.chart_name
                  );
                }
              })
            : true;
        });
        const filteredColumn2Charts = column2Charts.filter(chartId => {
          const chartParams = parseParams(chartId);
          const isDefaultChart = chartParams[chartKeyParams.IS_DEFAULT_CHART] === "true";

          return isDefaultChart
            ? defaultDashboardChartIds.find(id => {
                const parsedId = parseParams(id);
                if (parseParams(parsedId.report_id).chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id) {
                  return (
                    parseParams(chartParams.report_id).report_node_id === parseParams(parsedId.report_id).report_node_id
                  );
                } else {
                  return (
                    chartParams.chart_style === parsedId.chart_style &&
                    chartParams.chart_content === parsedId.chart_content &&
                    chartParams.chart_name === parsedId.chart_name
                  );
                }
              })
            : true;
        });
        diyDashboardChartsObj.columns.column1.chartIds = [...filteredColumn1Charts];
        diyDashboardChartsObj.columns.column2.chartIds = [...filteredColumn2Charts];
        diyDashboardChartsTemp = diyDashboardChartsObj;
        dashboardChartIdsTemp = [
          ...diyDashboardChartsObj.columns["column1"].chartIds,
          ...diyDashboardChartsObj.columns["column2"].chartIds
        ];
      }
    }

    return {
      diyDashboardCharts: diyDashboardChartsTemp,
      dashboardChartIds: dashboardChartIdsTemp
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartIds, recapDataPortfolioMapUpdateTimestamp]);

  diyDashboardCharts = diyChartsData.diyDashboardCharts;
  dashboardChartIds = diyChartsData.dashboardChartIds;

  const sankeyChartData = useSelector(getSheetsAndSectionsSankeyChartDataFromRecap);

  const handleSuggestedChartsTabCheckBoxChange = (isChecked, chartId) => {
    const dashboardCharts = { ...diyDashboardCharts };
    const isChartIdNotPresent = diyDashboardCharts.columnOrder.every(column => {
      const chartIndex = diyDashboardCharts.columns[column].chartIds.findIndex(id => {
        const chartParams = parseParams(chartId);
        const chartName = chartParams.chart_name;
        const diyChartParams = parseParams(id);
        const shouldCompareAgainstInvestableAssetsChartParams =
          chartParams.should_compare_with_investable_assets === "undefined"
            ? undefined
            : chartParams.should_compare_with_investable_assets;
        const shouldCompareWithTotalAssetsOrDebtsChartParams =
          chartParams.should_compare_with_total_assets_or_debts === "undefined"
            ? undefined
            : chartParams.should_compare_with_total_assets_or_debts;
        const shouldCompareWithSheetChartParams =
          chartParams.should_compare_with_sheet === "undefined" ? undefined : chartParams.should_compare_with_sheet;

        const shouldCompareAgainstInvestableAssetsDiyChartParams =
          diyChartParams.should_compare_with_investable_assets === "undefined"
            ? undefined
            : diyChartParams.should_compare_with_investable_assets;
        const shouldCompareWithTotalAssetsOrDebtsDiyChartParams =
          diyChartParams.should_compare_with_total_assets_or_debts === "undefined"
            ? undefined
            : diyChartParams.should_compare_with_total_assets_or_debts;
        const shouldCompareWithSheetDiyChartParams =
          diyChartParams.should_compare_with_sheet === "undefined"
            ? undefined
            : diyChartParams.should_compare_with_sheet;

        if (
          parseParams(chartParams.report_id).chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id ||
          parseParams(diyChartParams.report_id).chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id
        ) {
          if (
            chartName === "Assets x Class" ||
            chartName === "Assets x Sheets" ||
            chartName === "Assets x Taxability"
          ) {
            return (
              parseParams(chartParams.report_id).report_node_id ===
                parseParams(diyChartParams.report_id).report_node_id &&
              chartParams.chart_style === diyChartParams.chart_style &&
              chartParams.chart_content === diyChartParams.chart_content &&
              chartParams.chart_name === diyChartParams.chart_name &&
              shouldCompareAgainstInvestableAssetsChartParams === shouldCompareAgainstInvestableAssetsDiyChartParams &&
              shouldCompareWithTotalAssetsOrDebtsChartParams === shouldCompareWithTotalAssetsOrDebtsDiyChartParams &&
              shouldCompareWithSheetChartParams === shouldCompareWithSheetDiyChartParams
            );
          }
          return (
            parseParams(chartParams.report_id).report_node_id ===
              parseParams(diyChartParams.report_id).report_node_id &&
            chartParams.chart_style === diyChartParams.chart_style &&
            chartParams.chart_content === diyChartParams.chart_content &&
            shouldCompareAgainstInvestableAssetsChartParams === shouldCompareAgainstInvestableAssetsDiyChartParams &&
            shouldCompareWithTotalAssetsOrDebtsChartParams === shouldCompareWithTotalAssetsOrDebtsDiyChartParams &&
            shouldCompareWithSheetChartParams === shouldCompareWithSheetDiyChartParams
          );
        } else {
          return (
            chartParams.chart_style === diyChartParams.chart_style &&
            chartParams.chart_content === diyChartParams.chart_content &&
            chartParams.chart_name === diyChartParams.chart_name
          );
        }
      });
      if (chartIndex !== -1) {
        const chartParams = parseParams(chartId);
        chartParams.is_checked = isChecked;
        const reportId = encodeURIComponent(chartParams.report_id);
        const chartContent = chartParams.chart_content;
        const chartStyle = chartParams.chart_style;
        const isDefaultChart = chartParams[chartKeyParams.IS_DEFAULT_CHART];
        const chartName = chartParams.chart_name;
        const shouldBeCheckedByDefault = chartParams.should_be_checked_by_default;
        const shouldCompareWithInvestableAsset = chartParams.should_compare_with_investable_assets;
        const shouldCompareWithTotalAssetsOrDebts = chartParams.should_compare_with_total_assets_or_debts;
        const shouldCompareWithSheet = chartParams.should_compare_with_sheet;
        const updatedChartId = createChartIdUsingReportId(
          reportId,
          chartStyle,
          chartContent,
          isChecked,
          isDefaultChart,
          chartName,
          shouldBeCheckedByDefault,
          shouldCompareWithInvestableAsset,
          shouldCompareWithTotalAssetsOrDebts,
          shouldCompareWithSheet
        );
        if (isDefaultChart === "true") {
          dashboardCharts.columns[column].chartIds.splice(chartIndex, 1);
          const targetColumn = getColumnForChart({ ...dashboardCharts });
          dashboardCharts.columns[targetColumn].chartIds.push(updatedChartId);
        } else {
          dashboardCharts.columns[column].chartIds[chartIndex] = updatedChartId;
        }
        // dashboardCharts.columns[column].chartIds[chartIndex] = updatedChartId;
        dispatch(updateDashboardCharts(diyDashboardCharts));
        return false;
      }
      return true;
    });
    if (isChartIdNotPresent) {
      const chartParams = parseParams(chartId);
      chartParams.is_checked = isChecked;
      const reportId = encodeURIComponent(chartParams.report_id);
      const chartContent = chartParams.chart_content;
      const chartStyle = chartParams.chart_style;
      const isDefaultChart = chartParams[chartKeyParams.IS_DEFAULT_CHART];
      const chartName = chartParams.chart_name;
      const shouldBeCheckedByDefault = chartParams.should_be_checked_by_default;
      const shouldCompareWithInvestableAsset = chartParams.should_compare_with_investable_assets;
      const shouldCompareWithTotalAssetsOrDebts = chartParams.should_compare_with_total_assets_or_debts;
      const shouldCompareWithSheet = chartParams.should_compare_with_sheet;
      const updatedChartId = createChartIdUsingReportId(
        reportId,
        chartStyle,
        chartContent,
        isChecked,
        isDefaultChart,
        chartName,
        shouldBeCheckedByDefault,
        shouldCompareWithInvestableAsset,
        shouldCompareWithTotalAssetsOrDebts,
        shouldCompareWithSheet
      );
      const column = getColumnForChart({ ...dashboardCharts });
      dashboardCharts.columns[column].chartIds.push(updatedChartId);
      dispatch(updateDashboardCharts(diyDashboardCharts));
    }
  };

  const handleResetToDefaultLink = () => {
    const dashboardCharts = { ...diyDashboardCharts };
    diyDashboardCharts.columnOrder.forEach(column => {
      const chartIds = [];
      diyDashboardCharts.columns[column].chartIds.forEach(chartId => {
        const chartParams = parseParams(chartId);
        const shouldBeCheckedByDefault = chartParams.should_be_checked_by_default;
        const reportId = encodeURIComponent(chartParams.report_id);
        const chartContent = chartParams.chart_content;
        const chartStyle = chartParams.chart_style;
        const isDefaultChart = chartParams[chartKeyParams.IS_DEFAULT_CHART];
        const chartName = chartParams.chart_name;
        const shouldCompareWithInvestableAsset = chartParams.should_compare_with_investable_assets;
        const shouldCompareWithTotalAssetsOrDebts = chartParams.should_compare_with_total_assets_or_debts;
        const shouldCompareWithSheet = chartParams.should_compare_with_sheet;
        if (isDefaultChart === "true") {
          const updatedChartId = createChartIdUsingReportId(
            reportId,
            chartStyle,
            chartContent,
            shouldBeCheckedByDefault,
            isDefaultChart,
            chartName,
            shouldBeCheckedByDefault,
            shouldCompareWithInvestableAsset,
            shouldCompareWithTotalAssetsOrDebts,
            shouldCompareWithSheet
          );
          chartIds.push(updatedChartId);
        } else {
          chartIds.push(chartId);
        }
      });
      dashboardCharts.columns[column].chartIds = [...chartIds];
    });
    dispatch(updateDashboardCharts(dashboardCharts));
  };

  useEffect(() => {
    if (!recapDataPortfolioMapUpdateTimestamp) return;
    dispatch(updateDashboardCharts(diyDashboardCharts, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, recapDataPortfolioMapUpdateTimestamp]);

  const filteredDashboardChartIds = useMemo(
    () => dashboardChartIds.filter((id, index) => dashboardChartIds.indexOf(id) === index),
    [dashboardChartIds]
  );

  const areDashboardChartsEmpty = useMemo(
    () => [...chartIds.columns["column1"].chartIds, ...chartIds.columns["column2"].chartIds].length === 0,
    [chartIds.columns]
  );

  return (
    <>
      <NetWorthLineChartContainer empty={areAllChartsEmpty === false && isNetWorthChartEmpty === true}>
        {isNetWorthChartEmpty === false && (
          <Container>
            {showLoaderOnPortfolioAndNeworthCurrencyMismatch && (
              <LoaderOverlay>
                <RefreshLoader />
              </LoaderOverlay>
            )}
            <NetWorthLineChartComponent
              portfolio={currentPortfolio}
              data={netWorthData.data}
              dashboardChartIds={filteredDashboardChartIds}
              handleSuggestedChartsTabCheckBoxChange={handleSuggestedChartsTabCheckBoxChange}
              handleResetToDefaultLink={handleResetToDefaultLink}
            />
            {areDashboardChartsEmpty === false && !sankeyChartData === false && (
              <SankeyChart
                data={sankeyChartData.data}
                nodeAlign={"right"}
                formatValue={value => shortFormatNumberWithCurrency(value, sankeyChartData.currency, true, true)}
                maxLayerDepth={sankeyChartData.maxLayerDepth}
              />
            )}
            <DiyDashboardRenderCharts
              portfolioId={portfolioId}
              chartIds={chartIds}
              diyDashboardCharts={diyDashboardCharts}
            />
          </Container>
        )}
        {isNetWorthChartEmpty === true && (
          <>
            <NetWorthLineChartComponent
              portfolio={currentPortfolio}
              isNetWorthChartEmpty={isNetWorthChartEmpty}
              isLoading={isFetching}
              dashboardChartIds={filteredDashboardChartIds}
              handleSuggestedChartsTabCheckBoxChange={handleSuggestedChartsTabCheckBoxChange}
              handleResetToDefaultLink={handleResetToDefaultLink}
            />
            {areDashboardChartsEmpty === false && !sankeyChartData === false && (
              <SankeyChart
                data={sankeyChartData.data}
                nodeAlign={"right"}
                formatValue={value => shortFormatNumberWithCurrency(value, sankeyChartData.currency, true, true)}
                maxLayerDepth={sankeyChartData.maxLayerDepth}
              />
            )}
            <DiyDashboardRenderCharts
              portfolioId={portfolioId}
              chartIds={chartIds}
              diyDashboardCharts={diyDashboardCharts}
            />
          </>
        )}
      </NetWorthLineChartContainer>
    </>
  );
};

export default DiyDashboardChartsComponent;
