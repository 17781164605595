import React from "react";
import styled from "styled-components";
import i18n from "locale/i18n";

import SecondaryButton from "components/button/SecondaryButton";
import { ReactComponent as Exit } from "assets/images/exit.svg";

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 51px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 11px 20px;
  box-sizing: border-box;
  margin-bottom: 12px;
  overflow: hidden;
`;

const CardDetails = styled.div`
  font-size: 14px;
`;

const CardInfo = styled.div`
  font-family: "Roboto Mono";
  font-style: normal;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: #000;
`;

const ChangeAction = styled(SecondaryButton)`
  position: absolute;
  top: 50%;
  right: 34px;
  transform: translateY(-50%);
  font-family: "Roboto Mono";
  font-style: normal;
  font-size: 12px;
  line-height: 140%;
  text-align: right;
  text-decoration-line: underline;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: rgba(0, 87, 255, 0.8);
  background: transparent;
  border: 0;
  outline: 0;
  width: 44px;
  min-width: 44px;
  white-space: nowrap;
`;

const ExitIcon = styled(Exit)`
  position: absolute;
  top: 50%;
  right: 19px;
  transform: translateY(-50%);
  width: 9px;
  margin-top: 1px;
  cursor: pointer;
`;

const AdminStripeSetCard = ({ client, details = {}, isUpdating = false, onUpdate = () => null }) => {
  const wlRecurringChargeSchedule = client.recurringChargeSchedule || {};

  const handleUpdateCardAction = () => {
    onUpdate();
  };

  return (
    <CardContainer>
      <CardDetails>
        <CardInfo>
          {details.cardBrand} {details.cardLast4Digits} • Exp {details.cardExpMonth}/{details.cardExpYear}
        </CardInfo>
      </CardDetails>
      <ChangeAction
        title={i18n.t(wlRecurringChargeSchedule.status !== "card_error" ? "update" : "addCard")}
        data-cy="removeCardBtn"
        onClick={handleUpdateCardAction}
        isLoading={isUpdating}
      />
      <ExitIcon onClick={handleUpdateCardAction} />
    </CardContainer>
  );
};

export default AdminStripeSetCard;
