import React from "react";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import i18n from "i18next";
import CurrencyLabel from "components/labels/CurrencyLabel";
import ChangeLabel from "components/labels/ChangeLabel";
import { getValueChangeContributionsForNetworth, store, convertCurrency } from "@kubera/common";
import { category } from "components/dashboard/DashboardComponent";
import ValueChangeTable from "components/table/MobileValueChangeTable";
import ClickableLink from "components/labels/DelayLink";
import slideAnimation from "utilities/slideAnimation";

import { ReactComponent as BackArrow } from "assets/images/back_arrow.svg";

export const changeMode = {
  INVESTABLE: "investable",
  NETWORTH: "networth"
};

const Container = styled(DialogOverlay)`
  z-index: 2;
  background: ${props => props.theme.mobileBackground};
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

const ContainerDialog = styled(Dialog)`
  position: relative;
  width: 100%;
  height: 100vh
  border: 0;
  box-shadow: none;
  background: ${props => props.theme.mobileBackground};
  padding-top: 60px;
  animation: ${slideAnimation};
`;

const ChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HistoryBackContainer = styled(ClickableLink)`
  position: absolute;
  left: -1px;
  top: 4px;
  padding: 20px;
  border-radius: 50%;
`;

const HistoryBackBtn = styled(BackArrow)`
  height: 16px;

  path {
    fill: ${props => props.theme.mobileTxtColorHistoryBackBtn};
  }
`;

const CurrentTotal = styled(CurrencyLabel)`
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -2px;
  color: ${props => props.theme.mobileTxtColor};
  margin-left: 20px;
  margin-bottom: 13px;
`;

const ChartTimeRange = styled.div`
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: ${props => props.theme.mobileTxtColor};
  margin-left: 20px;
  margin-bottom: 3px;
  font-weight: bold;
`;

const TotalChange = styled(ChangeLabel)`
  margin-bottom: 30px;
  margin-left: 22px;
  font-size: 22px;

  .child-container {
    justify-content: flex-start;
  }
`;

const TabValueChange = styled(ChangeLabel)`
  margin-bottom: 20px;
  margin-left: 22px;
  font-size: 22px;

  .child-container {
    justify-content: flex-start;
  }
`;

const ChangeTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
`;

const ChangeTabList = styled(TabList)`
  display: ${props => (props.showtabselector === 1 ? "flex" : "none")};
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
  padding-left: 20px;
  margin-bottom: 30px;
`;

const ChangeTab = styled(Tab)`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${props => props.theme.mobileTxtColorLight};
  padding-bottom: 5px;
  margin-right: 25px;
  border: 0;
  cursor: pointer;
  z-index: 100;

  &.is-selected {
    color: ${props => props.theme.mobileTxtColor};
    border-bottom: ${props => `2px solid ${props.theme.mobileTxtColor}`};
  }
`;

const ChangeTabPanel = styled(TabPanel)`
  display: none;
  border-top: ${props => (props.showtabselector === 1 ? props.theme.mobileTxtColorLight : "")};
  box-sizing: border-box;
  flex: 1;

  &.is-selected {
    display: flex;
  }
`;

const PanelChangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

class NetWorthTotalChangeDialog extends React.Component {
  constructor(props) {
    super(props);

    const selectedIndex = 0;
    this.state = { tabIndex: selectedIndex };

    this.setTab = this.setTab.bind(this);
    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
  }

  handleOverlayDismiss() {
    if (this.props.onDismiss) {
      this.props.onDismiss();
    }
  }

  setTab(tabIndex) {
    this.setState({
      tabIndex
    });
  }

  get tabs() {
    var tabs = [];

    if (this.props.mode === changeMode.NETWORTH) {
      tabs.push({
        title: i18n.t("assetsComponent.title"),
        category: category.ASSET,
        panelComponent: this.getTabPanel(this.props.mode, category.ASSET)
      });

      tabs.push({
        title: i18n.t("debtsComponent.title"),
        category: category.DEBT,
        panelComponent: this.getTabPanel(this.props.mode, category.DEBT)
      });
    } else if (this.props.mode === changeMode.INVESTABLE) {
      tabs.push({
        title: i18n.t("assetsComponent.title"),
        category: category.ASSET,
        panelComponent: this.getTabPanel(this.props.mode, category.ASSET)
      });
    }
    return tabs;
  }

  getTabPanel(mode, tabCategory) {
    const currency = this.props.currency;
    const startDataPointCurrency = this.props.startDataPointCurrency;
    // We need to create a deep copy so that changes made to the data point don't get saved
    // to original grid object
    const startDataPoint = JSON.parse(JSON.stringify(this.props.startDataPoint));
    const startValueCustodians = tabCategory === category.DEBT ? startDataPoint.debt : startDataPoint.asset;
    const endDataPoint = JSON.parse(JSON.stringify(this.props.endDataPoint));
    const endValueCustodians = tabCategory === category.DEBT ? endDataPoint.debt : endDataPoint.asset;

    var valueChangeContributions = [];
    var tabTotalStartValue = 0;
    var tabTotalEndValue = 0;

    if (mode === changeMode.NETWORTH) {
      valueChangeContributions = getValueChangeContributionsForNetworth(
        store.getState(),
        currency,
        startDataPointCurrency,
        startValueCustodians,
        endValueCustodians
      );
      tabTotalStartValue = tabCategory === category.DEBT ? startDataPoint.debtTotal : startDataPoint.assetTotal;
      tabTotalEndValue = tabCategory === category.DEBT ? endDataPoint.debtTotal : endDataPoint.assetTotal;

      if (startDataPointCurrency !== this.props.currency) {
        tabTotalStartValue = convertCurrency(tabTotalStartValue, startDataPointCurrency, this.props.currency);
        tabTotalEndValue = convertCurrency(tabTotalEndValue, startDataPointCurrency, this.props.currency);
      }
    } else if (mode === changeMode.INVESTABLE) {
      valueChangeContributions = getValueChangeContributionsForNetworth(
        store.getState(),
        currency,
        startDataPointCurrency,
        startValueCustodians,
        endValueCustodians,
        true
      );
    }

    return (
      <PanelChangeContainer>
        {mode !== changeMode.INVESTABLE && (
          <TabValueChange
            currency={currency}
            startValue={tabTotalStartValue}
            endValue={tabTotalEndValue}
            disableShortFormat={true}
            hidePercentage={true}
            disableColor={tabCategory === category.DEBT}
            alignPosition="left"
          />
        )}
        <ValueChangeTable
          rows={valueChangeContributions}
          currency={currency}
          disableColor={tabCategory === category.DEBT}
          disableRoundDown={true}
        />
      </PanelChangeContainer>
    );
  }

  render() {
    const { currency, total, totalStartValue, totalEndValue, chartTimeRangeText, mode, direction } = this.props;
    const tabs = this.tabs;
    const showtabselector = mode !== changeMode.INVESTABLE ? 1 : 0;

    return (
      <Container onDismiss={this.handleOverlayDismiss}>
        <ContainerDialog className={this.props.className} direction={direction}>
          <HistoryBackContainer onClick={this.handleOverlayDismiss} direction="right">
            <HistoryBackBtn />
          </HistoryBackContainer>
          <ChangeContainer>
            <CurrentTotal
              currency={currency}
              value={total}
              maxLongFormatValue={99999}
              abbreviate={false}
              roundDown={true}
            />
            <ChartTimeRange>{chartTimeRangeText}</ChartTimeRange>
            <TotalChange
              currency={currency}
              startValue={totalStartValue}
              endValue={totalEndValue}
              disableShortFormat={true}
              alignPosition="left"
            />
            <ChangeTabs
              selectedTabClassName="is-selected"
              selectedTabPanelClassName="is-selected"
              selectedIndex={this.state.tabIndex}
              onSelect={this.setTab}
            >
              <ChangeTabList showtabselector={showtabselector}>
                {tabs.map((tab, index) => (
                  <ChangeTab data-cy={"changeTab" + index} key={index}>
                    {tab.title}
                  </ChangeTab>
                ))}
              </ChangeTabList>
              {tabs.map((tab, index) => (
                <ChangeTabPanel key={index} showtabselector={showtabselector}>
                  {tab.panelComponent}
                </ChangeTabPanel>
              ))}
            </ChangeTabs>
          </ChangeContainer>
        </ContainerDialog>
      </Container>
    );
  }
}

export default NetWorthTotalChangeDialog;
