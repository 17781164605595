import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Sentry from "@sentry/browser";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import i18n from "i18next";
import { useLocation } from "react-router-dom";

import {
  accountPlanSelector,
  accountEndTsSelector,
  accountSubscriptionStatusSelector,
  accountCardDetailsSelector,
  accountPaymentAuthSelector,
  accountPaymentMethodSelector,
  coolingOffDaysSelector,
  getSubscriptionState,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS,
  SUBSCRIPTION_ERROR,
  getTrialInfo,
  fetchUser,
  getHashParams,
  isMobile,
  userReceiptsSelector,
  getSubscriptionRemainingDays
} from "@kubera/common";

import AccountCardPayment from "./AccountCardPayment";
import AccountSubscribedCard from "./AccountSubscribedCard";
import AccountCancelledCard from "./AccountCancelledCard";
import AccountCardChange from "./AccountCardChange";
import AccountUpdateSubscription from "./AccountUpdateSubscription";
import AccountDeclinedCardModal from "./AccountDeclinedCardModal";
import AccountThanksModal from "./AccountThanksModal";
import AccountReconnectModal from "./AccountReconnectModal";
import AccountInitialCardSetup from "./AccountInitialCardSetup";
import ExtendTrialBlock from "./ExtendTrialBlock";
import { hashParams, modalValues } from "routes";
import ReceiptsModal from "./ReceiptsModal";

const isMobileDevice = isMobile();

const AccountPaymentBlock = ({ className = "", isInitialSetup = false, addVipPassClick = () => null }) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const location = useLocation();

  const urlHashParams = getHashParams(location);
  const modalValue = urlHashParams[hashParams.ACTION];

  const accountPlan = useSelector(accountPlanSelector);
  const accountSubscriptionStatus = useSelector(accountSubscriptionStatusSelector);
  const accountCardDetails = useSelector(accountCardDetailsSelector);
  const accountPaymentAuth = useSelector(accountPaymentAuthSelector);
  const accountPaymentMethod = useSelector(accountPaymentMethodSelector);
  const accountEndTs = useSelector(accountEndTsSelector);
  const coolingOffDays = useSelector(coolingOffDaysSelector);
  const coolingOfDaysBeforeSub = useRef(0);
  const initialEndTs = useRef(null);
  const prevPlan = useRef(null);
  const prevStatus = useRef(null);
  const invoiceUrl = useRef(null);
  const receipts = useSelector(userReceiptsSelector);

  const accountEndDateDiffWithCooling = getSubscriptionRemainingDays(Date.now() / 1000, accountEndTs) + coolingOffDays;

  const [isThanksModalVisible, setThanksModalVisible] = useState(false);
  const [isReconnectModalVisible, setReconnectModalVisible] = useState(false);
  const [isUpdateMode, setUpdateMode] = useState(modalValue === modalValues.SUBSCRIPTION_EDIT);
  const [isUpdateCardMode, setUpdateCardMode] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDeclinedError, setIsDeclinedError] = useState(false);
  const [paymentErrorMsg, setPaymentErrorMsg] = useState(void 0);
  const [isSubscribedFromPaywall, setIsSubscribedFromPaywall] = useState();

  const reloadAppIfSubscribedFromPaywall = () => {
    if (isSubscribedFromPaywall) {
      setTimeout(() => {
        window.location.reload();
      }, 300);
    }
  };

  const onDismissThanks = () => {
    if (coolingOfDaysBeforeSub.current <= 0) {
      setReconnectModalVisible(true);
      setThanksModalVisible(false);
      return;
    }
    reloadAppIfSubscribedFromPaywall();
    setThanksModalVisible(false);
  };

  const onDismissReconnect = () => {
    reloadAppIfSubscribedFromPaywall();
    setReconnectModalVisible(false);
  };

  const onDeclinedModalDismiss = () => {
    setPaymentErrorMsg(void 0);
    setIsDeclinedError(false);
  };

  const captureError = useCallback(
    (errorState, reason) => {
      Sentry.withScope(scope => {
        scope.setExtras({
          status: accountSubscriptionStatus,
          plan: accountPlan,
          reason
        });
        Sentry.captureMessage(errorState);
      });
    },
    [accountSubscriptionStatus, accountPlan]
  );

  const handleCardPaymentSuccess = () => {
    const isPaywallShown = document.querySelector(".cancelled-with-paywall");
    if (isPaywallShown) {
      setIsSubscribedFromPaywall(true);
    }
    setThanksModalVisible(true);
  };

  useEffect(() => {
    if (accountSubscriptionStatus !== SUBSCRIPTION_STATUS.ACTIVE) {
      initialEndTs.current = accountEndTs;
    }
  }, [accountSubscriptionStatus, accountEndTs]);

  useEffect(() => {
    if (accountSubscriptionStatus !== SUBSCRIPTION_STATUS.ACTIVE) {
      prevPlan.current = accountPlan;
      prevStatus.current = accountSubscriptionStatus;
    }
  }, [accountSubscriptionStatus, accountPlan]);

  useEffect(() => {
    if (accountSubscriptionStatus !== SUBSCRIPTION_STATUS.ACTIVE) {
      coolingOfDaysBeforeSub.current = accountEndDateDiffWithCooling;
    }
  }, [accountSubscriptionStatus, accountEndDateDiffWithCooling]);

  useEffect(() => {
    const getSubscription = async (count = 0) => {
      if (count < 3) {
        const currentState = await dispatch(getSubscriptionState());
        if (currentState.payload.status !== "active") {
          return getSubscription(count + 1);
        } else {
          return currentState;
        }
      }

      return null;
    };

    if (
      isSubmitted &&
      accountPaymentAuth &&
      (accountPaymentAuth.intentStatus === "requires_action" ||
        accountPaymentAuth.intentStatus === "requires_payment_method")
    ) {
      setIsAuthenticating(true);
      stripe
        .confirmCardPayment(accountPaymentAuth.clientSecret, { payment_method: accountPaymentMethod })
        .then(async result => {
          if (result.error) {
            captureError(SUBSCRIPTION_ERROR.CONFIRMPAYMENT_ERROR, result.error);

            if (invoiceUrl.current) {
              setPaymentErrorMsg(
                i18n.t("accountCardDeclined.descriptionWithInvoice").replace("%s%", invoiceUrl.current)
              );
              invoiceUrl.current = null;
            } else {
              setPaymentErrorMsg(result.error.message);
            }

            setIsDeclinedError(true);
          } else if (result.paymentIntent.status === "succeeded") {
            await getSubscription();
            setThanksModalVisible(true);
          } else {
            if (accountPlan === SUBSCRIPTION_PLANS.TRIAL) {
              dispatch(getTrialInfo());
            }
            captureError(SUBSCRIPTION_ERROR.CONFIRMPAYMENT_STATUSERROR, result.paymentIntent.last_payment_error);
            setPaymentErrorMsg(result.paymentIntent.last_payment_error);
            setIsDeclinedError(true);
          }
          setIsAuthenticating(false);
        })
        .catch(error => null);
    }
  }, [dispatch, isSubmitted, stripe, accountPaymentAuth, accountPaymentMethod, captureError, accountPlan]);

  useEffect(() => {
    const handlePaymentOnVisibilityChange = () => {
      if (!document.hidden) {
        const overlay = document.getElementById("kubera-container-overlay");
        overlay.style.pointerEvents = "none";
        dispatch(
          fetchUser(() => {
            overlay.style.pointerEvents = "auto";
          })
        );
      }
    };

    document.addEventListener("visibilitychange", handlePaymentOnVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handlePaymentOnVisibilityChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderPaymentBlock = () => {
    if (isUpdateCardMode) {
      return (
        <>
          <AccountCardChange
            setUpdateCardMode={setUpdateCardMode}
            setIsSubmitted={setIsSubmitted}
            captureError={captureError}
          />
          {!isMobileDevice && <ExtendTrialBlock />}
        </>
      );
    }

    if (isUpdateMode) {
      return (
        <>
          <AccountUpdateSubscription
            setUpdateMode={setUpdateMode}
            setIsSubmitted={setIsSubmitted}
            captureError={captureError}
          />
          {!isMobileDevice && <ExtendTrialBlock />}
        </>
      );
    }

    if (isInitialSetup) {
      return (
        <AccountInitialCardSetup className={className} setIsSubmitted={setIsSubmitted} captureError={captureError} />
      );
    }

    if (
      (accountPlan === SUBSCRIPTION_PLANS.TRIAL && accountSubscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE) ||
      accountSubscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED ||
      ([SUBSCRIPTION_STATUS.CANCELED, SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED].includes(accountSubscriptionStatus) &&
        !accountCardDetails.cardLast4)
    ) {
      return (
        <>
          <AccountCardPayment
            setThanksModalVisible={handleCardPaymentSuccess}
            setIsSubmitted={setIsSubmitted}
            captureError={captureError}
            addVipPassClick={addVipPassClick}
            invoiceUrlRef={invoiceUrl}
          />
          {!isMobileDevice && <ExtendTrialBlock />}
        </>
      );
    }

    if (
      accountSubscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE ||
      accountSubscriptionStatus === SUBSCRIPTION_STATUS.TRIALING ||
      accountSubscriptionStatus === SUBSCRIPTION_STATUS.PAST_DUE
    ) {
      return (
        <>
          <AccountReconnectModal
            isOpen={isReconnectModalVisible}
            onDismiss={onDismissReconnect}
            endDate={initialEndTs.current}
            plan={prevPlan.current}
          />
          <AccountThanksModal
            isOpen={isThanksModalVisible}
            isOpenForSubscriptionStates={
              accountSubscriptionStatus !== SUBSCRIPTION_STATUS.PAST_DUE &&
              ![SUBSCRIPTION_STATUS.PAST_DUE, SUBSCRIPTION_STATUS.CANCELED].includes(prevStatus.current)
            }
            onDismiss={onDismissThanks}
          />
          <AccountSubscribedCard
            setUpdateMode={setUpdateMode}
            setUpdateCardMode={setUpdateCardMode}
            isAuthenticating={isAuthenticating}
            addVipPassClick={addVipPassClick}
          />
          {!isMobileDevice && <ExtendTrialBlock />}
          <AccountDeclinedCardModal
            isOpen={isDeclinedError}
            message={paymentErrorMsg}
            onDismiss={onDeclinedModalDismiss}
          />
        </>
      );
    }

    if ([SUBSCRIPTION_STATUS.CANCELED, SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED].includes(accountSubscriptionStatus)) {
      return (
        <>
          <AccountCancelledCard
            setIsSubmitted={setIsSubmitted}
            captureError={captureError}
            addVipPassClick={addVipPassClick}
            invoiceUrlRef={invoiceUrl}
          />
          {!isMobileDevice && <ExtendTrialBlock />}
        </>
      );
    }

    return null;
  };

  return (
    <>
      {renderPaymentBlock()}
      <ReceiptsModal receipts={receipts} />
    </>
  );
};

let stripePromise = null;
const handleStripeLoad = function() {
  if (stripePromise === null) {
    stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);
  }
};

window.addEventListener("load", handleStripeLoad);

const ElementsWrapper = props => {
  useEffect(() => {
    handleStripeLoad();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <AccountPaymentBlock {...props} />
    </Elements>
  );
};

export default ElementsWrapper;
