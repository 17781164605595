import React from "react";
import i18n from "locale/i18n"; // eslint-disable-line no-unused-vars
import { Provider } from "react-redux";
import { configureStore } from "redux/ConfigureStore";
import { configureEventManager } from "utilities/EventManager";
import { store, persistor, setTokenForViewMode } from "@kubera/common";
import { PersistGate } from "redux-persist/integration/react";
import RootComponent from "components/root/RootComponent";
import { setupCommonLogger } from "logger/ConfigureLogger";
import { configureNavigator } from "routes/ConfigureNavigator";
import "intersection-observer";
import DOMPurify from "dompurify";
import localforage from "localforage";
import TroubleShoot, { captureGlobalError } from "./TroubleShoot";
import { ErrorBoundary } from "react-error-boundary";

// Allow target blank for sanitized html injected
(function() {
  const TEMPORARY_ATTRIBUTE = "data-temp-href-target";

  DOMPurify.addHook("beforeSanitizeAttributes", function(node) {
    if (node.tagName === "A") {
      if (!node.hasAttribute("target")) {
        node.setAttribute("target", "_self");
      }

      if (node.hasAttribute("target")) {
        node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute("target"));
      }
    }
  });

  DOMPurify.addHook("afterSanitizeAttributes", function(node) {
    if (node.tagName === "A" && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
      node.setAttribute("target", node.getAttribute(TEMPORARY_ATTRIBUTE));
      node.removeAttribute(TEMPORARY_ATTRIBUTE);
      if (node.getAttribute("target") === "_blank") {
        node.setAttribute("rel", "noopener");
      }
    }
  });
})();

window.renderlog = log => {
  if (process.env.REACT_APP_ENV === "local") {
    //console.log(log);
  }
};

document.scrollToElement = (id, params = { behavior: "smooth" }) => {
  const element = document.getElementById(id);

  if (!element === false) {
    element.scrollIntoView(params);
  }
};

document.flashElement = (id, ref = null) => {
  const element = ref || document.getElementById(id);

  if (!element === false) {
    const originalBackground = element.style.background;
    element.style.transition = "background 0.3s linear";
    element.style.background = "#FFB800";
    setTimeout(function() {
      element.style.background = originalBackground;
    }, 700);
  }
};

const cleanupOldKeys = () => {
  const keysToRemove = ["RECAP_DATA_STORAGE_KEY", "persist:cuberarootv63"];
  keysToRemove.forEach(key => {
    localforage.removeItem(key);
  });

  // Remove old keys with v63 redux stores for view mode
  localforage.keys().then(keys => {
    keys.forEach(key => {
      if (key.startsWith("persist:cuberarootv63")) {
        localforage.removeItem(key);
      }
    });
  });
};
cleanupOldKeys();

function App() {
  setTokenForViewMode();
  setupCommonLogger();
  configureStore();
  configureEventManager();
  configureNavigator();

  const handleBoundaryError = (error, componentStack) => {
    if (process.env.REACT_APP_ENV !== "local") {
      captureGlobalError(error, componentStack);
    }
  };

  return (
    <ErrorBoundary FallbackComponent={TroubleShoot} onError={handleBoundaryError}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RootComponent />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
