import React, { useState, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector } from "react-redux";

import {
  SUBSCRIPTION_PLANS,
  userPriceOptionSelector,
  accountAllPlansSelector,
  accountPlanSelector,
  multiuserListSelector
} from "@kubera/common";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import RadioButton from "components/inputs/RadioButton";
import DeferredPromise from "utilities/DeferredPromise";

import useOptionsRadioButtonSelectionHandler from "./useOptionsRadioButtonSelectionHandler";

const RadioButtonsContainer = styled.div``;

const RadioOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0;
`;

const RadioOption = styled(RadioButton)`
  display: flex;
  align-items: center;
  height: 55px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  flex-direction: row-reverse;
  padding-left: 5px;
  padding-right: 15px;
  background: #fff;

  div {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
`;

const SelectPersonalConfirmDialog = styled(ConfirmationDialog)`
  width: 100%;
  max-width: 350px;
`;

const AccountSubscriptionOptions = React.forwardRef(({ selectedFrequencyValue, handleRadioButtonSelection }, ref) => {
  const confirmModalDeferred = useRef(new DeferredPromise());

  const userPrice = useSelector(userPriceOptionSelector);
  const accountAllPlans = useSelector(accountAllPlansSelector);
  const accountPlan = useSelector(accountPlanSelector);
  const multiuserList = useSelector(multiuserListSelector);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const onPositiveBtnClick = () => {
    setIsModalVisible(false);
    confirmModalDeferred.current.resolve(true);
  };

  const onNegativeBtnClick = () => {
    setIsModalVisible(false);
    confirmModalDeferred.current.resolve(false);
  };

  useImperativeHandle(ref, () => ({
    onSubmitShowModals: () => {
      if (
        accountPlan === "trial" &&
        selectedFrequencyValue === SUBSCRIPTION_PLANS.YEARLY &&
        multiuserList &&
        multiuserList.length > 1
      ) {
        setIsModalVisible(true);
        confirmModalDeferred.current = new DeferredPromise();
        return confirmModalDeferred.current;
      }

      return true;
    }
  }));

  useOptionsRadioButtonSelectionHandler(handleRadioButtonSelection);

  return (
    <>
      <RadioButtonsContainer>
        <RadioOptionContainer>
          {accountAllPlans.map(plan => {
            const subscriptionPlan = SUBSCRIPTION_PLANS[plan.toUpperCase()];
            return (
              <div key={plan}>
                <RadioOption
                  label={i18n.t(`accountSettings.subscriptionOption_${plan}`).replace("%s%", userPrice[plan])}
                  value={subscriptionPlan}
                  checked={selectedFrequencyValue === subscriptionPlan}
                  disabled={accountPlan === subscriptionPlan}
                  onChange={handleRadioButtonSelection}
                />
              </div>
            );
          })}
        </RadioOptionContainer>
      </RadioButtonsContainer>
      {isModalVisible && (
        <SelectPersonalConfirmDialog
          title={i18n.t("accountSettings.dialogUserAccessLoseTitle")}
          description={i18n.t("accountSettings.dialogUserAccessLoseDesc")}
          positiveButtonTitle={i18n.t("accountSettings.dialogUserAccessBtn")}
          negativeButtonTitle={i18n.t("cancel")}
          handleNegativeButtonClick={onNegativeBtnClick}
          handlePositiveButtonClick={onPositiveBtnClick}
        />
      )}
    </>
  );
});

export default AccountSubscriptionOptions;
