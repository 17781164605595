import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import i18n from "i18next";
import {
  getTickerUsingId,
  irrTypes,
  irrComparisonIndexesSelector,
  updateUserPreferences,
  sanitizeIrr,
  supportedIndicesSelector,
  convertCustodianHistoryApiDateFormatToUIFormat,
  getUuid,
  tickerTypes,
  fetchTickerDetails,
  showToastTip,
  getSanitizedExchangeRate,
  updateCustodianIrrType,
  formatNumberAsCurrency,
  updateCustodianHistoryAction,
  getCustodianHistoryApiFormattedDateString,
  supportedIndicesIrrSelector,
  irrComparisonTimePeriodSelector,
  fetchCustodianDetailsHistory,
  getTaxableTypeForCustodian,
  getExchangeRate,
  getExchangeRateDetails
} from "@kubera/common";
import PercentageLabel from "components/labels/PercentageLabel";
import CustodianAssetCostComponent from "components/custodian_details/CustodianAssetCostComponent";
import { ReactComponent as DownArrow } from "assets/images/menu_downarrow.svg";
import { ReactComponent as IndexComparisonIcon } from "assets/images/index_comparison_icon.svg";
import IrrSettingsDialog, { timePeriods } from "components/custodian_details/IrrSettingsDialog";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  CurrencyCellData,
  cellType
} from "components/grid/GridDataModel";
import ExchangeRateChangeDialog from "components/grid/ExchangeRateChangeDialog";
import ResetAlert from "components/dialog/ResetAlert";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const HeaderContainer = styled.div`
  display: flex;
`;

const HeaderFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 30px;
  margin-top: 15px;
  cursor: ${props => (props.isDisabled ? "auto" : "pointer")};
`;

const HeaderFieldTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DropDownArrow = styled(DownArrow)`
  margin-left: 2px;
`;

const HeaderFieldTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const IrrValue = styled(PercentageLabel)`
  margin-top: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: "ss01" on;
  filter: ${props => (props.isUpdating === true ? "opacity(0.3)" : "")};
`;

const EmptyIrrValue = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: "ss01" on;
`;

const IndexVsIcon = styled(IndexComparisonIcon)`
  circle {
    fill: ${props => props.theme.vsLabelBG};
  }
`;

const GridTitle = styled.div`
  margin-top: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
`;

const LastUpdate = styled.div`
  width: fit-content;
  margin-top: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

const EnterCashflowDescription = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: rgba(0, 0, 0, 0.7);
  white-space: pre-line;
`;

const EnterCashflowButton = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
  text-transform: capitalize;
`;

const GridContainer = styled.div`
  margin-top: 10px;
  border: ${props => (props.theme.mode === "default" ? "1px solid rgba(0, 0, 0, 0.1)" : null)};
  border-top: 0;
`;

const Grid = styled(GridComponentWrapper)`
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
`;

const ExchangeRateCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  padding: 3px 5px 3px 5px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 11px;
  font-feature-settings: "ss01" on;
  color: rgba(36, 36, 36, 0.6);
  cursor: pointer;
`;

const ExchangeCurrencies = styled.div``;

const ExchangeRate = styled.div``;

const ManuallyOverrideContainer = styled.div`
display: flex
flex: 1
font-weight: 400;
font-size: 10px;
line-height: 12px;
margin-top: 12px;
color: rgba(0, 0, 0, 0.5);
`;

const ResetContainer = styled.div`
  display: flex;
`;

const ManuallyOverrideDescription = styled.div``;

const LearnMore = styled.a`
  margin-left: 5px;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;

  &:visited {
    color: #000000;
  }
`;

const Reset = styled(LearnMore)`
  color: rgba(0, 0, 0, 0.5);
`;

class CostbasisReturnsComponent extends React.Component {
  static getNewestCostHistoryDate(history) {
    if (history.length === 0) {
      return null;
    }
    var result = null;
    for (const item of history) {
      if (!result === true) {
        result = item;
      } else if (item.date.localeCompare(result.date) > 0) {
        result = item;
      }
    }
    return result.date;
  }

  constructor(props) {
    super(props);

    this.getEmptyRow = this.getEmptyRow.bind(this);

    this.state = {
      gridData: this.getGridData(props.currency),
      selectedIndexes: props.irrComparisonIndexes,
      showSettingsDialog: false,
      showCostHistoryDialog: false,
      showRateCellDialog: false,
      selectedTimePeriod: props.irrComparisonTimePeriod || timePeriods.START_DATE,
      isCustodianUpdating: false,
      isCostHistoryUpdating: false,
      showCostBasisManuallyOverriddenResetAlert: false
    };

    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.handleEnterCashflowOnClick = this.handleEnterCashflowOnClick.bind(this);
    this.handleIrrSettingsButtonClick = this.handleIrrSettingsButtonClick.bind(this);
    this.handleIrrSettingsDialogOnDismiss = this.handleIrrSettingsDialogOnDismiss.bind(this);
    this.handleLastUpdateClick = this.handleLastUpdateClick.bind(this);
    this.handleCostHistoyDialogOnDismiss = this.handleCostHistoyDialogOnDismiss.bind(this);
    this.handleCellInvalidTickerAdded = this.handleCellInvalidTickerAdded.bind(this);
    this.handleRateCellClick = this.handleRateCellClick.bind(this);
    this.handleRateCellDialogOnChange = this.handleRateCellDialogOnChange.bind(this);
    this.handleRateCellDialogOnDismiss = this.handleRateCellDialogOnDismiss.bind(this);
    this.handleResetManuallyOverridenCostbasis = this.handleResetManuallyOverridenCostbasis.bind(this);
    this.dismissCostBasisResetAlert = this.dismissCostBasisResetAlert.bind(this);
  }

  componentDidUpdate(oldProps) {
    if (
      this.state.gridData.currency !== this.props.currency ||
      this.props.cost !== oldProps.cost ||
      this.props.costExchangeRate !== oldProps.costExchangeRate
    ) {
      this.setState({ gridData: this.getGridData(this.props.currency) });
    }
  }

  handleChange(newGridData) {
    this.setState({ ...this.state, gridData: newGridData });
  }

  getGridData(currency) {
    var rows = [];

    const row = this.getEmptyRow("1");
    row.onUpdateDelay = 3000;
    row.id = this.props.custodianId;
    row.cells[0].value = this.props.cost;

    if (this.props.costTickerId) {
      row.cells[0].currency = getTickerUsingId(this.props.costTickerId).shortName;
    }
    if (this.props.costExchangeRate) {
      row.cells[0].exchangeRateDetails = this.props.costExchangeRate;
    }

    rows.push(row);

    const section = this.getEmptySection(0, "1");
    section.rows = rows;

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;
    return gridData;
  }

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getEmptySection(forIndex, sortKey) {
    const costColumn = new GridColumnData("Cost", true, this.props.isReadOnly === false, false);
    const sectionData = new GridSectionData(
      getUuid(),
      sortKey,
      "Section " + (forIndex + 1),
      [],
      [costColumn],
      0,
      0,
      false
    );
    sectionData.showFooter = false;
    sectionData.showHeader = false;
    return sectionData;
  }

  getEmptyRow(sortKey) {
    const costCell = new CurrencyCellData(cellType.CURRENCY, "Cost", null, this.props.currency);
    costCell.enterEditModeOnClick = true;
    costCell.hideCellFocusHighlight = true;
    costCell.useRateFromExchangeRateDetails = true;
    costCell.supportedTickerTypes = [tickerTypes.FIAT, tickerTypes.CRYPTO];
    costCell.textAlignment = "left";

    costCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const costGridCell = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0];

      if (this.props.currency === costGridCell.currency) {
        return null;
      }

      const cellData = {
        exchangeRateDate: new Date(this.getNewestCostHistoryDate()),
        fromCurrency: costGridCell.currency,
        toCurrency: this.props.currency,
        rate: costGridCell.getCellExchangeRate(this.props.currency),
        sanitizedRate: getSanitizedExchangeRate(
          costGridCell.getCellExchangeRate(this.props.currency),
          this.props.currency
        ),
        sheetIndex: sheetIndex,
        sectionIndex: sectionIndex,
        rowIndex: rowIndex,
        cellIndex: 0
      };

      return (
        <ExchangeRateCell onClick={e => this.handleRateCellClick(e, cellData)}>
          <ExchangeCurrencies>{`${cellData.fromCurrency.replace(".CC", "")}/${cellData.toCurrency.replace(
            ".CC",
            ""
          )}`}</ExchangeCurrencies>
          <ExchangeRate>{formatNumberAsCurrency(cellData.sanitizedRate, this.props.currency)}</ExchangeRate>
        </ExchangeRateCell>
      );
    };

    const cells = [costCell];
    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 0, false, () => {
      return true;
    });
    rowData.showHint = false;
    rowData.getContextMenuItems = (row, rowIndex) => {
      return null;
    };
    return rowData;
  }

  handleRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) {
    const cell = updatedRow.cells[0];
    cell.exchangeRateDate = null;
    cell.setExchangeRateDetails(this.props.currency, getExchangeRate(cell.currency, this.props.currency));
    this.updateCustodianCost(updatedRow);
  }

  updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex) {
    const newGridData = this.state.gridData;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = newRow;
    this.setState({ gridData: newGridData });
  }

  handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];
    cell.isCashflowInvalid = true;

    if (!cell.invalidInputText === false && cell.loading === false) {
      cell.loading = true;
      this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);

      this.props.fetchTickerDetails(
        cell.invalidInputText,
        new Date(),
        result => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];

          cell.loading = false;
          cell.tickerInfo = [];
          if (!result === true) {
            // this.props.showToastTip("TIP", i18n.t("invalidTickerError"), null, 10000);
            this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
            return;
          }

          const validTickers = result.tickers.filter(ticker => ticker.info.type !== "stock");
          if (validTickers.length > 1) {
            cell.tickerInfo = validTickers;
            this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
          } else if (validTickers.length === 1 && result.tickers.length > 1) {
            const ticker = validTickers[0];
            cell.exchangeRateDetails = ticker.rate ? getExchangeRateDetails(ticker.info.id, ticker.rate) : null;
            cell.currency = ticker.info.shortName;
            cell.invalidInputText = null;

            const newRow = row.clone();
            this.updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex);
            this.handleRowUpdate(sheetIndex, sectionIndex, rowIndex, newRow, false);
          } else {
            cell.exchangeRateDetails = result.exchangeRateDetails;
            cell.currency = result.tickerShortName;
            cell.invalidInputText = null;

            const newRow = row.clone();
            this.updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex);
            this.handleRowUpdate(sheetIndex, sectionIndex, rowIndex, newRow, false);
          }
        },
        error => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];
          cell.loading = false;
          this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
          this.props.showToastTip("TIP", i18n.t("tickerFetchFailure"), null, 10000);
        }
      );
    }
  }

  handleEnterCashflowOnClick(e) {
    this.props.updateCustodianIrrType(this.props.custodianId, irrTypes.CASHFLOW);
  }

  handleIrrSettingsButtonClick(e) {
    if (this.props.isReadOnly === true) {
      return;
    }
    this.setState({ showSettingsDialog: true });
  }

  handleIrrSettingsDialogOnDismiss(selectedIndexes, selectedTimePeriod) {
    this.setState({
      showSettingsDialog: false,
      selectedIndexes: selectedIndexes,
      selectedTimePeriod: selectedTimePeriod
    });

    this.props.updateUserPreferences({
      irrComparisonIndexes: selectedIndexes,
      irrComparisonTimePeriod: selectedTimePeriod
    });
  }

  handleLastUpdateClick(e) {
    this.setState({ showCostHistoryDialog: true });
  }

  handleCostHistoyDialogOnDismiss() {
    this.setState({ showCostHistoryDialog: false });
  }

  handleRateCellClick(e, cellData) {
    if (this.props.isReadOnly === true) {
      return;
    }
    this.setState({ showRateCellDialog: true, rateCellDialogData: cellData });
  }

  handleRateCellDialogOnDismiss() {
    this.setState({ showRateCellDialog: false, rateCellDialogData: null });
  }

  handleRateCellDialogOnChange(rate) {
    const rateData = this.state.rateCellDialogData;
    const newGridData = this.state.gridData;

    const row = newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex];
    const cell = row.cells[rateData.cellIndex];
    cell.exchangeRateDate = new Date(this.getNewestCostHistoryDate());
    cell.setExchangeRateDetails(this.props.currency, rate);
    newGridData.sheets[rateData.sheetIndex].sections[rateData.sectionIndex].rows[rateData.rowIndex] = row.clone();

    this.setState({ gridData: newGridData });
    this.updateCustodianCost(row);
  }

  updateCustodianCost(updatedRow) {
    if (!this.props.cost === true && !updatedRow.cells[0].value === false) {
      this.props.updateCustodianIrrType(this.props.custodianId, irrTypes.COSTBASIS, true, false);
    }
    const taxDetailsInfo = {
      dcb: !this.props.detailsInfo.linkType === false && this.props.costType === "auto" ? this.props.cost : null,
      taxableAssetType: this.props.custodianTaxType
    };

    this.props.onCustodianUpdate(
      {
        cost: updatedRow.cells[0].value,
        costTickerId: updatedRow.cells[0].getTickerId(),
        costExchangeRate: updatedRow.cells[0].exchangeRateDetails,
        costType: "manual",
        irrChangeTs: Math.floor(new Date().getTime() / 1000),
        past: 0,
        taxDetails: JSON.stringify(taxDetailsInfo)
      },
      true,
      () => {
        this.setState({
          isCustodianUpdating: false
        });
        this.props.fetchDetailsHistory(this.props.custodianId, new Date().getTime() / 1000, true, true, () => {
          this.setState({ isCostHistoryUpdating: false });
        });
      }
    );

    if (this.props.history.length > 0) {
      const todaysHistory = this.props.history.find(
        item => item.date === getCustodianHistoryApiFormattedDateString(new Date().getTime())
      );

      const history = {
        id: !todaysHistory === true ? getUuid() : todaysHistory.id,
        date: getCustodianHistoryApiFormattedDateString(new Date(this.getNewestCostHistoryDate()).getTime()),
        cost: updatedRow.cells[0].value,
        costTickerId: updatedRow.cells[0].getTickerId(),
        costExchangeRate: updatedRow.cells[0].exchangeRateDetails,
        tsModified: Math.floor(new Date().getTime() / 1000),
        timestamp: Math.floor(new Date().getTime() / 1000)
      };
      this.props.updateLocalCostHistory(history, true);
    }

    this.setState({ isCostHistoryUpdating: true, isCustodianUpdating: true });
  }

  getIrr(irrDetails) {
    if (!irrDetails === true || !irrDetails.all === true) {
      return null;
    }
    return sanitizeIrr(irrDetails.all.value);
  }

  getIrrDetails() {
    if (!this.props.irr === true) {
      return null;
    }

    try {
      const irrDetails = JSON.parse(this.props.irr);
      return irrDetails;
    } catch (e) {
      return null;
    }
  }

  getIndexIrrs(irrDetails) {
    var irrs = [];
    if (!irrDetails === true || !irrDetails.all.market === true) {
      return irrs;
    }

    const valueTickerShortName = getTickerUsingId(this.props.valueTickerId).shortName;
    for (const indexId of this.state.selectedIndexes) {
      const index = this.props.supportedIndexes.find(item => item.id === indexId);

      if (index.id !== valueTickerShortName) {
        irrs.push({ ...index, irr: this.getMarketIrr(indexId, irrDetails) });
      }
    }
    return irrs;
  }

  getMarketIrr(indexId, irrDetails) {
    if (this.state.selectedTimePeriod === timePeriods.START_DATE) {
      return irrDetails.all.market[indexId];
    } else if (this.state.selectedTimePeriod === timePeriods.YEAR) {
      return this.props.supportedIndexesIrr.year[indexId];
    } else if (this.state.selectedTimePeriod === timePeriods.YTD) {
      return this.props.supportedIndexesIrr.ytd[indexId];
    }
    return null;
  }

  getNewestCostHistoryDate() {
    var historyDate = CostbasisReturnsComponent.getNewestCostHistoryDate(this.props.history);
    if (this.props.history.length === 0 && this.props.cost !== null && this.props.cost !== undefined) {
      historyDate = getCustodianHistoryApiFormattedDateString(new Date().getTime());
    }
    return historyDate ? convertCustodianHistoryApiDateFormatToUIFormat(historyDate) : null;
  }

  showCostBasisResetAlert() {
    this.setState({ showCostBasisManuallyOverriddenResetAlert: true });
  }
  dismissCostBasisResetAlert() {
    this.setState({ showCostBasisManuallyOverriddenResetAlert: false });
  }

  handleResetManuallyOverridenCostbasis() {
    this.props.onCustodianUpdate(
      {
        costType: "auto"
      },
      true,
      this.props.onUpdateCustodianSuccess(this.props.detailsInfo.id)
    );

    this.dismissCostBasisResetAlert();
  }

  canShowResetCostBasis() {
    return (
      this.props.detailsInfo &&
      !this.props.detailsInfo.linkType === false &&
      this.props.detailsInfo.costType === "manual" &&
      this.props.detailsInfo.taxDetails &&
      JSON.parse(this.props.detailsInfo.taxDetails).dcb
    );
  }

  render() {
    const { className, history, ...other } = this.props;
    const irrDetails = this.getIrrDetails();
    const irr = this.getIrr(irrDetails);
    const indexIrrs = this.getIndexIrrs(irrDetails);
    const hasComparisonIrrs = indexIrrs.length > 0 && !irr === false;
    const lasteUpdateDate = this.getNewestCostHistoryDate();
    const gridOptions = {
      getRowStyle: () => {
        return {
          height: "49px"
        };
      },
      getSectionStyle: () => {
        return {
          [`min-height`]: "50px"
        };
      }
    };

    return (
      <Container className={className}>
        <HeaderContainer>
          <HeaderFieldContainer
            onClick={e => {
              if (!irr === true) {
                return;
              }
              this.handleIrrSettingsButtonClick(e);
            }}
            isDisabled={!irr === true || this.props.isReadOnly === true}
          >
            <HeaderFieldTitleContainer>
              <HeaderFieldTitle>{i18n.t("irr")}</HeaderFieldTitle>
              {!irr === false && this.props.isReadOnly === false && <DropDownArrow />}
            </HeaderFieldTitleContainer>
            {!irr === true && <EmptyIrrValue>{"--"}</EmptyIrrValue>}
            {!irr === false && (
              <IrrValue
                value={irr}
                isUpdating={this.state.isCustodianUpdating}
                darkBackground={false}
                updated={true}
                preventAnimation
              />
            )}
          </HeaderFieldContainer>
          {hasComparisonIrrs === true && (
            <HeaderFieldContainer isDisabled={true}>
              <IndexVsIcon />
            </HeaderFieldContainer>
          )}
          {hasComparisonIrrs === true &&
            indexIrrs.map((indexIrr, index) => (
              <HeaderFieldContainer
                key={index}
                onClick={this.handleIrrSettingsButtonClick}
                isDisabled={this.props.isReadOnly === true}
              >
                <HeaderFieldTitleContainer>
                  <HeaderFieldTitle>{indexIrr.shortName}</HeaderFieldTitle>
                  {this.props.isReadOnly === false && <DropDownArrow />}
                </HeaderFieldTitleContainer>
                {!indexIrr.irr === true && <EmptyIrrValue>{"--"}</EmptyIrrValue>}
                {!indexIrr.irr === false && (
                  <IrrValue
                    value={sanitizeIrr(indexIrr.irr)}
                    isUpdating={this.state.isCustodianUpdating}
                    darkBackground={false}
                    updated={true}
                    preventAnimation
                  />
                )}
              </HeaderFieldContainer>
            ))}
        </HeaderContainer>
        <GridTitle>{i18n.t("costBasis")}</GridTitle>
        <GridContainer>
          <Grid
            gridData={this.state.gridData}
            getEmptyRow={this.getEmptyRow}
            onChange={this.handleChange.bind(this)}
            onRowUpdate={this.handleRowUpdate}
            onCellInvalidTickerAdded={this.handleCellInvalidTickerAdded}
            gridOptions={gridOptions}
          />
        </GridContainer>
        <ResetContainer>
          {!lasteUpdateDate === false && (
            <LastUpdate onClick={this.handleLastUpdateClick}>
              {i18n.t("lastUpdate") + ": " + lasteUpdateDate}
            </LastUpdate>
          )}
          {this.canShowResetCostBasis() && (
            <ManuallyOverrideContainer>
              &nbsp;
              <ManuallyOverrideDescription>{i18n.t("manuallyOverridden.description")}</ManuallyOverrideDescription>
              <Reset
                onClick={() => {
                  this.showCostBasisResetAlert();
                }}
              >
                {"Reset"}
              </Reset>
            </ManuallyOverrideContainer>
          )}
        </ResetContainer>

        {this.props.isReadOnly === false && (
          <EnterCashflowDescription>{i18n.t("enterCashflowDescription")}</EnterCashflowDescription>
        )}
        {this.props.isReadOnly === false && (
          <EnterCashflowButton onClick={this.handleEnterCashflowOnClick}>{i18n.t("enterCashflow")}</EnterCashflowButton>
        )}
        {this.state.showCostHistoryDialog === true && (
          <CustodianAssetCostComponent
            history={this.props.history}
            isHistoryUpdating={this.state.isCostHistoryUpdating}
            {...other}
            onDismiss={this.handleCostHistoyDialogOnDismiss}
          />
        )}
        {this.state.showSettingsDialog === true && (
          <IrrSettingsDialog
            irr={irr}
            irrDetails={irrDetails}
            supportedIndexes={this.props.supportedIndexes}
            supportedIndexesIrr={this.props.supportedIndexesIrr}
            selectedTimePeriod={this.state.selectedTimePeriod}
            custodianName={this.props.custodianName}
            selectedIndexes={this.state.selectedIndexes}
            onDismiss={this.handleIrrSettingsDialogOnDismiss}
          />
        )}
        {!this.state.showRateCellDialog === false && (
          <ExchangeRateChangeDialog
            rateData={this.state.rateCellDialogData}
            onRateChange={this.handleRateCellDialogOnChange}
            onDismiss={this.handleRateCellDialogOnDismiss}
          />
        )}
        <ResetAlert
          isOpen={this.state.showCostBasisManuallyOverriddenResetAlert}
          onDismiss={this.dismissCostBasisResetAlert}
          onReset={() => {
            this.handleResetManuallyOverridenCostbasis();
          }}
          title={i18n.t("costBasisManuallyOverriddenAlert.returns.title")}
          description={i18n.t("costBasisManuallyOverriddenAlert.returns.description")}
          primaryButtonTitle={i18n.t("costBasisManuallyOverriddenAlert.returns.primaryBtn")}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  supportedIndexes: supportedIndicesSelector(state),
  supportedIndexesIrr: supportedIndicesIrrSelector(state),
  irrComparisonIndexes: irrComparisonIndexesSelector(state),
  irrComparisonTimePeriod: irrComparisonTimePeriodSelector(state),
  custodianTaxType: getTaxableTypeForCustodian(state)
});

const mapDispatchToProps = {
  fetchDetailsHistory: fetchCustodianDetailsHistory,
  updateUserPreferences: updateUserPreferences,
  fetchTickerDetails: fetchTickerDetails,
  showToastTip: showToastTip,
  updateCustodianIrrType: updateCustodianIrrType,
  updateLocalCostHistory: updateCustodianHistoryAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CostbasisReturnsComponent);
