import createWhitelistFilter from "redux-persist-transform-filter";
import {
  FETCH_WL_DASHBOARD_PENDING,
  FETCH_WL_DASHBOARD_SUCCESS,
  FETCH_WL_DASHBOARD_ERROR,
  WL_ADD_CLIENT,
  WL_UPDATE_CLIENT,
  WL_UPDATE_DASHBOARD_CLIENT,
  WL_DELETE_CLIENT,
  WL_ADD_MANAGER,
  WL_UPDATE_MANAGER,
  WL_DELETE_MANAGER,
  WL_SET_CLIENT_CONTEXT,
  WL_ADD_SUBUSER,
  WL_DELETE_SUBUSER,
  WL_SET_ADMIN_LIST_CHARGES,
  wlUserStatus
} from "../actions/WhiteLabelActions";
import { userTypes } from "../actions/AuthActions";
import { userSelector } from "./AuthReducer";

const initialState = {
  dashboard: null,
  fetchDashboardPending: false,
  fetchDashboardError: null,
  managers: null,
  wlClientContext: null,
  adminListCharges: []
};

export const whiteLabelPersistTransform = () => {
  return createWhitelistFilter("white_label", ["dashboard", "managers", "wlClientContext"]);
};

export function whiteLabelReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WL_DASHBOARD_PENDING:
      return {
        ...state,
        fetchDashboardPending: action.isBackgroundRefresh === false,
        fetchDashboardError: null
      };
    case FETCH_WL_DASHBOARD_SUCCESS:
      return {
        ...state,
        fetchDashboardPending: false,
        dashboard: action.dashboard,
        managers: action.managers
      };
    case FETCH_WL_DASHBOARD_ERROR:
      return {
        ...state,
        fetchPortfolioPending: true,
        fetchDashboardError: action.error
      };
    case WL_ADD_CLIENT: {
      const newState = { ...state };
      const users = newState.dashboard.user || [];
      users.push({ tsCreated: Math.floor(Date.now() / 1000), ...action.client });
      newState.dashboard.user = users;
      return newState;
    }
    case WL_ADD_SUBUSER: {
      const newState = { ...state };
      const users = newState.dashboard.user || [];

      const clientIndex = users.findIndex(
        user => user.email === action.client.email || user.userId === action.client.userId
      );
      const subUserIndex = users[clientIndex].subUser.findIndex(
        user => user.email === action.subUser.email || user.userId === action.subUser.userId
      );

      if (subUserIndex > -1) {
        users[clientIndex].subUser[subUserIndex] = action.subUser;
      } else {
        users[clientIndex].subUser.push({ tsCreated: Math.floor(Date.now() / 1000), ...action.subUser });
      }

      newState.dashboard.user = users;
      return newState;
    }
    case WL_DELETE_SUBUSER: {
      const newState = { ...state };
      const users = newState.dashboard.user || [];

      const clientIndex = users.findIndex(
        user => user.email === action.client.email || user.userId === action.client.userId
      );
      const subUserIndex = users[clientIndex].subUser.findIndex(
        user => user.email === action.subUser.email || user.userId === action.subUser.userId
      );

      if (subUserIndex > -1) {
        users[clientIndex].subUser.splice(subUserIndex, 1);
      }

      newState.dashboard.user = users;
      return newState;
    }
    case WL_UPDATE_CLIENT: {
      const newState = { ...state };
      const users = newState.dashboard.user || [];
      const clientIndex = users.findIndex(
        user => user.email === action.client.email || user.userId === action.client.userId
      );

      if (clientIndex !== -1) {
        users[clientIndex] = action.client;
      }
      newState.dashboard.user = users;
      return newState;
    }
    case WL_DELETE_CLIENT: {
      const newState = { ...state };
      const users = newState.dashboard.user || [];

      newState.dashboard.user = users.filter(user => user.email !== action.client.email);
      return newState;
    }
    case WL_UPDATE_DASHBOARD_CLIENT: {
      const newState = { ...state };
      const users = newState.dashboard.user || [];
      const clientIndex = users.findIndex(
        user => user.email === action.client.email || user.userId === action.client.userId
      );
      users[clientIndex].paymentMethod = action.client.paymentMethod;
      users[clientIndex].recurringChargeSchedule = action.client.recurringChargeSchedule;
      users[clientIndex].tsLastUpdate = action.client.tsLastUpdate;
      newState.dashboard.user = users;
      return newState;
    }
    case WL_ADD_MANAGER: {
      const newState = { ...state };
      const managers = newState.managers || [];
      managers.push(action.manager);
      newState.managers = managers;
      return newState;
    }
    case WL_UPDATE_MANAGER: {
      const newState = { ...state };
      const managers = [...newState.managers] || [];
      const managerIndex = managers.findIndex(manager => manager.email === action.manager.email);

      if (managerIndex !== -1) {
        managers[managerIndex] = action.manager;
      }
      newState.managers = managers;
      return newState;
    }
    case WL_DELETE_MANAGER: {
      const newState = { ...state };
      const managers = newState.managers || [];

      newState.managers = managers.filter(manager => manager.email !== action.manager.email);
      return newState;
    }
    case WL_SET_CLIENT_CONTEXT:
      return {
        ...state,
        wlClientContext: action.client
      };
    case WL_SET_ADMIN_LIST_CHARGES:
      return {
        ...state,
        adminListCharges: action.charges
      };
    default:
      return state;
  }
}

export const wlAdminListChargesSelector = state => state.white_label.adminListCharges;

export const wlDashboardFetchPendingSelector = state => state.white_label.fetchDashboardPending;

export const wlDashboardFetchErrorSelector = state => state.white_label.fetchDashboardError;

export const wlDashboardSelector = state => state.white_label.dashboard;

export const wlClientsSelector = state => {
  if (state.white_label.dashboard) {
    return state.white_label.dashboard.user.map(user => {
      user.subUser = Array.isArray(user.subUser) ? user.subUser : [];

      return user;
    });
  }
  return [];
};

export const wlSubUsersSelector = state => {
  if (state.white_label.dashboard) {
    return state.white_label.dashboard.user.reduce((result, user) => {
      if (user.subUser);

      return [...result, ...user.subUser];
    }, []);
  }

  return null;
};

export const wlClientSelector = (state, userId) => {
  if (!userId === true) {
    return null;
  }
  return wlClientsSelector(state).find(item => item.userId === userId);
};

export const wlClientAndSubUserSelector = (state, userId) => {
  if (!userId === true) {
    return [];
  }
  const client = wlClientsSelector(state).find(
    item => item.userId === userId && !(item.email.indexOf("client-8efb") !== -1 && item.email.endsWith("@kubera.com"))
  );

  if (!client) {
    return [];
  }

  return [client, ...client.subUser];
};

export const wlOtherClientAndSubUserEmailMapSelector = (state, userId, addSubUserForUserId) => {
  const clientAdmSubUserMap = wlClientsSelector(state).reduce((result, item) => {
    if (item.userId !== userId) {
      result.set(item.email, item);
    }

    if (item.userId !== userId || item.userId === addSubUserForUserId) {
      item.subUser.forEach(subUser => {
        result.set(subUser.email, subUser);
      });
    }

    return result;
  }, new Map());

  return clientAdmSubUserMap;
};

export const wlClientEmailSelector = (state, email) => {
  if (!email === true) {
    return null;
  }
  return wlClientsSelector(state).find(item => item.email === email);
};

export const wlActiveClientsSelector = state => {
  const users = wlClientsSelector(state);
  return users.filter(
    user => (user.status === wlUserStatus.ACCEPTED || !user.userId === false) && !user.tsArchive === true
  );
};

export const wlInvitedClientsSelector = state => {
  const users = wlClientsSelector(state);
  return users.filter(
    user => user.status === wlUserStatus.INVITED && !user.userId === true && !user.tsArchive === true
  );
};

export const wlArchivedClientsSelector = state => {
  const users = wlClientsSelector(state);
  return users.filter(user => !user.tsArchive === false);
};

export const wlManagersSelector = state => {
  return state.white_label.managers || [];
};

export const wlOwnerSelector = state => {
  const managers = wlManagersSelector(state);
  return managers.find(item => item.type === userTypes.OWNER);
};

export const wlManagerSelector = (state, managerId, fallbackToOwner = false) => {
  const managers = wlManagersSelector(state);
  const manager = managers.find(item => item.userId === managerId);
  if (!manager === true && fallbackToOwner === true) {
    return wlOwnerSelector(state);
  }
  return manager;
};

export const wlClientContextSelector = state => {
  return state.white_label.wlClientContext;
};

export const wlPaymentMethodSelector = state => {
  const user = userSelector(state);
  if (user && user.wl && user.wl.paymentMethod) return user.wl.paymentMethod;
  return {};
};
