import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  currentPortfolioSelector,
  getRuleObject,
  portfolioCashOnHand,
  getRuleText,
  getRuleNodeKey,
  ruleNodeKeys,
  getRulesCashBreakdown,
  planningVariables
} from "@kubera/common";
import CurrencyLabel from "components/labels/CurrencyLabel";
import { ReactComponent as ExpandIcon } from "assets/images/expandable_indicator.svg";
import ChangeLabel from "components/labels/ChangeLabel";

const BreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BreakdownTable = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const BreakdownRow = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 22px 14px 28px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  border-top: ${props => (props.hideBorder === true ? "" : "1px solid rgba(0, 0, 0, 0.1)")};
  cursor: ${props => (props.isExpandable === true ? "pointer" : "auto")};
`;

const BreakdownChildRow = styled(BreakdownRow)`
  background: #f1f1f1;
`;

const TitleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  flex: 1;
`;

const Description = styled.div`
  margin-top: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.5);
`;

const Value = styled(CurrencyLabel)`
  margin-left: 20px;
  min-width: 100px;
  text-align: right;
`;

const ChangeValue = styled(ChangeLabel)`
  margin-left: 20px;
  min-width: 100px;
  text-align: right;
`;

const ExpandArrow = styled(ExpandIcon)`
  width: 10px;
  height: 10px;
  margin-right: 10px;
  margin-left: -20px;
  transform: ${props => (props.iscollapsed === 1 ? "rotate(-90deg)" : "")};
`;

const FooterRow = styled(BreakdownRow)`
  font-weight: 700;
`;

class CashBreakdownComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedKeys: []
    };

    this.handleRowClick = this.handleRowClick.bind(this);
  }

  handleRowClick(e, key) {
    var expandedKeys = this.state.expandedKeys;
    if (expandedKeys.includes(key)) {
      expandedKeys = expandedKeys.filter(item => item !== key);
    } else {
      expandedKeys.push(key);
    }
    this.setState({ expandedKeys });
  }

  getSelectedDataPoint() {
    return this.props.groupedData.data[this.props.dataIndex];
  }

  getRuleData(rule) {
    return this.props.dataForScenario.processedRules.find(item => item.id === rule.id).data;
  }

  getTotalChange() {
    const dataPoint = this.getSelectedDataPoint();
    const startTotal = this.props.portfolioCash;
    const endTotal = dataPoint.cashTotal;
    return endTotal - startTotal;
  }

  getRuleText(rule) {
    if (rule.id === planningVariables.TAX) return "Tax";
    var ruleText = getRuleText(rule.type, this.getRuleData(rule));
    if (rule.isNonCashRule === true) {
      return (ruleText += " (Cash)");
    }
    return ruleText;
  }

  getRuleDescription(rule) {
    if (!rule.overridingRules === false && rule.overridingRules.length > 0) {
      var overridenLabels = rule.overridingRules.map(overridingRule => {
        const ruleObject = getRuleObject(
          this.props.dataForScenario.processedRules.find(item => item.id === overridingRule.id)
        );
        return getRuleNodeKey(ruleObject);
      });
      return `${i18n.t("less")} ${overridenLabels.join(", ")}`;
    }
    return null;
  }

  getTableForBreakdown(breakdown) {
    const currency = this.props.dataForScenario.currency;
    const total = this.props.portfolioCash;
    const totalChange = this.getTotalChange();
    const nonEmptyKeys = Object.keys(breakdown).filter(
      key =>
        !breakdown[key].rules === true ||
        breakdown[key].rules.filter(rule => rule.changes.cumulativeDelta !== 0).length > 0
    );

    return (
      <BreakdownTable>
        <BreakdownRow key={"header"} hideBorder={true} isExpandable={false}>
          <TitleContainer>
            <Title>{`${ruleNodeKeys.CASH} ${i18n.t("asOfToday")}`}</Title>
          </TitleContainer>
          <Value currency={currency} value={Math.round(total)} roundDown={true} />
        </BreakdownRow>

        {nonEmptyKeys.map((key, index) => {
          const label = breakdown[key].label;
          const initialValue = breakdown[key].initialValue;
          const isCashflow = [
            ruleNodeKeys.INCOME,
            ruleNodeKeys.EXPENSE,
            ruleNodeKeys.CASH_INFLOW,
            ruleNodeKeys.CASH_OUTFLOW,
            ruleNodeKeys.ASSET_CASH_GROWTH
          ].includes(key);
          const isKeyExpanded = this.state.expandedKeys.includes(key);

          const totalChange = breakdown[key].rules.reduce((total, rule) => {
            return total + rule.changes.cumulativeDelta;
          }, 0);

          return (
            <div key={index}>
              <BreakdownRow
                hideBorder={false}
                isExpandable={true}
                onClick={e => {
                  this.handleRowClick(e, key);
                }}
              >
                <ExpandArrow iscollapsed={isKeyExpanded === false ? 1 : 0} />
                <TitleContainer>
                  <Title>{label}</Title>
                </TitleContainer>
                <ChangeValue
                  currency={currency}
                  disableShortFormat={true}
                  startValue={0}
                  endValue={Math.round(totalChange)}
                  preventAnimation={true}
                />
                {isCashflow === false && (
                  <Value currency={currency} value={Math.round(initialValue + totalChange)} roundDown={true} />
                )}
              </BreakdownRow>
              {isKeyExpanded === true && (
                <>
                  {breakdown[key].rules.map((rule, index) => {
                    if (rule.changes.cumulativeDelta === 0) {
                      return null;
                    }

                    const description = this.getRuleDescription(rule);
                    return (
                      <BreakdownChildRow key={index}>
                        <TitleContainer>
                          <Title>{this.getRuleText(rule, breakdown)}</Title>
                          {!description === false && <Description>{description}</Description>}
                        </TitleContainer>
                        <ChangeValue
                          currency={currency}
                          disableShortFormat={true}
                          startValue={0}
                          endValue={Math.round(rule.changes.cumulativeDelta)}
                          preventAnimation={true}
                        />
                      </BreakdownChildRow>
                    );
                  })}
                </>
              )}
            </div>
          );
        })}
        <FooterRow hideBorder={false}>
          <TitleContainer>
            <Title>{i18n.t("totalCash")}</Title>
          </TitleContainer>
          {!totalChange === false && (
            <ChangeValue
              currency={currency}
              disableShortFormat={true}
              startValue={0}
              endValue={Math.round(totalChange)}
              preventAnimation={true}
            />
          )}

          <Value currency={currency} value={Math.round(total + totalChange)} roundDown={true} />
        </FooterRow>
      </BreakdownTable>
    );
  }

  render() {
    const assetsBreakdown = getRulesCashBreakdown(
      this.props.dataForScenario.processedRules,
      this.getSelectedDataPoint()
    );

    return <BreakdownContainer>{this.getTableForBreakdown(assetsBreakdown, false)}</BreakdownContainer>;
  }
}

const mapStateToProps = (state, props) => ({
  portfolio: currentPortfolioSelector(state),
  portfolioCash: portfolioCashOnHand(state)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CashBreakdownComponent));
