import DeferredPromise from "utilities/DeferredPromise";

window.finicityLoadPromise = new DeferredPromise();
window.yodleeLoadPromise = new DeferredPromise();
window.plaidLoadPromise = new DeferredPromise();
window.trezorLoadPromise = new DeferredPromise();

function handleProviderOnload(providerPromiseProp) {
  window[providerPromiseProp].resolve(true);
}

function handleProviderOnerror(providerPromiseProp) {
  window[providerPromiseProp].reject("This provider is facing some issues please try connecting later");
}

document.getElementById("yodlee-script").addEventListener("load", () => {
  handleProviderOnload("yodleeLoadPromise");
});
document.getElementById("plaid-script").addEventListener("load", () => {
  handleProviderOnload("plaidLoadPromise");
});
document.getElementById("finicity-script").addEventListener("load", () => {
  handleProviderOnload("finicityLoadPromise");
});
document.getElementById("trezor-script").addEventListener("load", () => {
  handleProviderOnload("trezorLoadPromise");
});

document.getElementById("yodlee-script").addEventListener("error", () => {
  handleProviderOnerror("yodleeLoadPromise");
});
document.getElementById("plaid-script").addEventListener("error", () => {
  handleProviderOnerror("plaidLoadPromise");
});
document.getElementById("finicity-script").addEventListener("error", () => {
  handleProviderOnerror("finicityLoadPromise");
});
document.getElementById("trezor-script").addEventListener("error", () => {
  handleProviderOnerror("trezorLoadPromise");
});
