import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import { withRouter } from "@kubera/common";
import { hashParams } from "routes";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  userPhoneSelector,
  userSelector,
  userCountryCodeSelector,
  updateUser,
  verifyPhone,
  verifyPhoneCode,
  userPhoneSet,
  validatePhone
} from "@kubera/common";
import PhoneInputComponent from "components/beneficiary/PhoneInputComponent";
import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import TextInput from "components/inputs/TextInput";

const ModifyUserPhoneDialog = styled(Dialog)`
  display: flex;
  width: fit-content;
  min-height: 218px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 163px;
`;

const Container = styled.div`
  width: 315px;
  background-color: #ffffff;
  margin: auto;
  padding: 34px;
`;

const Title = styled.div`
  width: 100%;
  font-family: Inter;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.11px;
  padding-bottom: 11px;
  text-transform: uppercase;
`;

const ErrorMessage = styled.div`
  visibility: ${props => (props.isVisible === true ? "visible" : "hidden")}
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: 'ss01' on;
  color: ${props => props.theme.errorCLR};
  margin-top: 5px;
`;

const ActionBar = styled.div`
  display: flex;
  margin-top: 5px;
`;

const SaveButton = styled(PrimaryButton)`
  width: 150px;
  min-width: 140px;
  outline: 0;
`;

const BackButton = styled(SecondaryButton)`
  margin-left: 20px;
  width: 150px;
  min-width: 140px;
  outline: 0;
`;

const OtpField = styled(TextInput)`
  height: 50px;
  width: 100%;
  padding-left: 13px;
  padding-right: 13px;
  outline: 0;
  border: ${props => (props.inputError === true ? "1px solid rgba(255, 0, 0, 0.4)" : "1px solid rgba(0, 0, 0, 0.4)")};
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  color: black;

  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const Private = styled.span``;

const Resend = styled.label`
  color: ${props => props.theme.linkColor};
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
`;

class UserPhoneCompnent extends React.Component {
  static show = (history, location, type) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${type}`
    });
  };

  constructor(props) {
    super(props);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleOtpChange = this.handleOtpChange.bind(this);

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleResendOtp = this.handleResendOtp.bind(this);

    this.timer = null;

    let phone = this.props.userPhone;
    if (phone === undefined || phone == null) {
      phone = "";
    }
    this.state = {
      phone: phone,
      phoneCountryCode: null,
      phoneValid: true,
      isRequestPending: false,
      errorMessage: null,
      otp: null,
      isOtpSent: false,
      showResend: false
    };
  }

  componentDidMount() {
    let phone = this.props.userPhone;
    if (phone === undefined || phone == null) {
      phone = "";
    }
    this.setState({ phone: phone });
  }

  handlePhoneChange = (value, country) => {
    this.setState({ phone: value, phoneCountryCode: !country === false ? country.countryCode.toUpperCase() : null });
  };

  handleOtpChange = e => {
    this.setState({ otp: e.target.value.replace(/\s/g, "") });
  };

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleSaveButtonClick();
    }
    return false;
  };

  handleBackButtonClick() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
    this.timer = null;
    this.setState({ isOtpSent: false, showResend: false });
  }

  handleSaveButtonClick = async () => {
    if (this.state.isRequestPending) {
      return;
    }
    if (this.state.isOtpSent === false) {
      this.setState({ isRequestPending: true });
      const { valid: phoneValid } = await this.props.validatePhone(this.state.phone);
      this.setState({ phoneValid: phoneValid });

      if (phoneValid === true) {
        this.requestOtp(this.state.phone);
      } else {
        this.setState({ isRequestPending: false });
      }
    } else {
      const otp = this.state.otp;
      if (!otp === true || otp.trim().length === 0) {
        this.setState({ ...this.state, errorMessage: i18n.t("Required") });
      } else {
        this.submitOtp(this.state.phone, otp);
      }
    }
  };

  handleResendOtp() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
    this.timer = null;
    this.requestOtp(this.state.phone);
    this.setState({ showResend: false });
  }

  async requestOtp(phone) {
    this.setState({ ...this.state, isRequestPending: true, errorMessage: null });

    const verifyMobile = await verifyPhone(phone).catch(apiError => {
      this.setState({ ...this.state, isRequestPending: false, errorMessage: apiError.errorMessage });
    });

    if (verifyMobile) {
      this.setState({ ...this.state, isRequestPending: false, errorMessage: null, isOtpSent: true }, () => {
        this.timer = setTimeout(() => {
          this.setState({ showResend: true });
        }, 30000);
      });
    }
  }

  async submitOtp(phone, otp) {
    this.setState({ ...this.state, isRequestPending: true, errorMessage: null, showResend: false });

    if (this.timer !== null) {
      clearTimeout(this.timer);
    }

    const verifyMobileCode = await verifyPhoneCode(otp).catch(apiError => {
      if (apiError.errorCode === 1003) {
        apiError.errorMessage = i18n.t("invalidOtp");
      }
      this.setState({ ...this.state, isRequestPending: false, errorMessage: apiError.errorMessage });
    });

    if (verifyMobileCode) {
      this.props.userPhoneSet(phone);
      this.setState({ ...this.state, isRequestPending: false, errorMessage: null }, () => {
        const user = this.props.user;
        user.phone = this.state.phone;
        this.props.updateUser(user);
        this.dismiss();
      });
    }
  }

  handleOverlayDismiss = () => {
    this.dismiss();
  };

  dismiss = () => {
    if (this.props.hideUserPhone !== undefined) {
      this.props.hideUserPhone();
      return;
    }
    DialogOverlay.dismiss(this.props.history, this.props.location);
  };

  render() {
    let phoneInputBorder = "solid 1px rgba(0, 0, 0, 0.4)";
    if (this.state.phoneValid === false) {
      phoneInputBorder = "solid 1px rgba(255, 0, 0, 0.4)";
    }

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <ModifyUserPhoneDialog>
          <Container>
            {this.state.isOtpSent === false && <Title>{i18n.t("yourPhone")}</Title>}
            {this.state.isOtpSent === true && (
              <Title>
                <Private>{i18n.t("phoneOtpSentLine1")}</Private>
                <br />
                <Private>
                  {i18n.t("phoneOtpSentLine2")}
                  <span data-private>{" " + this.state.phone + "  "}</span>
                </Private>
                {this.state.showResend === true && <Resend onClick={this.handleResendOtp}>{i18n.t("resend")}</Resend>}
              </Title>
            )}
            {this.state.isOtpSent === false && (
              <Private data-private>
                <PhoneInputComponent
                  countryCode={this.props.userCountryCode.toLowerCase()}
                  phone={this.state.phone}
                  height={"15px"}
                  width={"293px"}
                  handlePhoneChange={this.handlePhoneChange}
                  handleKeyDown={this.handleKeyDown}
                  border={phoneInputBorder}
                  autofocus={true}
                />
              </Private>
            )}
            {this.state.isOtpSent === true && (
              <OtpField
                placeholder={i18n.t("verificationCodeHint")}
                inputError={!this.state.errorMessage === false}
                value={this.state.otp}
                onChange={this.handleOtpChange}
                onKeyDown={this.handleKeyDown}
                autoFocus={true}
                autoComplete="one-time-code"
              />
            )}
            <ErrorMessage isVisible={!this.state.errorMessage === false}>
              {!this.state.errorMessage === true ? "&nbsp" : this.state.errorMessage}
            </ErrorMessage>
            <ActionBar>
              <SaveButton
                data-cy="saveButtonPhone"
                onClick={this.handleSaveButtonClick}
                title={i18n.t("save")}
                isLoading={this.state.isRequestPending}
                isDisabled={this.props.userPhone === this.state.phone}
              />
              {this.state.isOtpSent === true && (
                <BackButton
                  data-cy="backButtonPhone"
                  onClick={this.handleBackButtonClick}
                  title={i18n.t("back")}
                  isLoading={false}
                  isDisabled={false}
                />
              )}
            </ActionBar>
          </Container>
        </ModifyUserPhoneDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  userPhone: userPhoneSelector(state),
  userCountryCode: userCountryCodeSelector(state),
  user: userSelector(state)
});

const mapDispatchToProps = {
  updateUser: updateUser,
  userPhoneSet,
  validatePhone
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserPhoneCompnent));
