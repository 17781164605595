import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  getTickerUsingId,
  searchPortfolioForText,
  currentPortfolioSelector,
  getTickerUsingShortName,
  getCustodianItems
} from "@kubera/common";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";
import { category } from "components/dashboard/DashboardComponent";
import CurrencyLabel from "components/labels/CurrencyLabel";
import PrimaryButton from "components/button/PrimaryButton";
import SearchInput from "components/inputs/SearchInput";
import { ReactComponent as LinkedIcon } from "assets/images/linked_account_icon.svg";
import { ReactComponent as ClearIcon } from "assets/images/close.svg";

const PickerDialog = styled(Dialog)`
  position: relative;
  width: 870px;
  min-height: 700px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 60px 60px 60px 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const SearchBox = styled(SearchInput)`
  height: 45px;
  margin-top: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  line-height: 17px;
  background-color: #ffffff;
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1px;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background: #ffffff;
`;

const Result = styled.div`
  display: flex;
  padding: 12px 18px 12px 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: ${props => (props.isFocused === true ? props.theme.focusBackgroundColor : "#ffffff")};
  cursor: pointer;
`;

const EmptyResult = styled(Result)`
  cursor: auto;
  font-size: 14px;
  padding-left: 10px;
`;

const ResultDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ResultNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ResultName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
`;

const ResultMeta = styled.div`
  margin-left: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const LinkedCustodianIcon = styled(LinkedIcon)`
  width: 12px;
  height: 12px;
  margin-left: 5px;
`;

const ResultDescription = styled.div`
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const ResultPath = styled.div`
  width: fit-content;
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const ResultValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ResultValue = styled(CurrencyLabel)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
`;

const ResultValueOriginalCurrency = styled(CurrencyLabel)`
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const SelectedItemsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 27px;
`;

const SelectedItemsList = styled.div`
  visibility: ${props => (props.isEmpty === true ? "hidden" : "visible")};
  box-shadow: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`;

const SelectedItem = styled(Result)`
  background: rgba(0, 0, 0, 0.05);
  align-items: center;
  cursor: auto;
`;

const SelectedItemValue = styled(ResultValue)`
  justify-content: center;
`;

const DeleteSelectedItemIcon = styled(ClearIcon)`
  margin-left: 35px;
  width: 8px;
  height: 8px;
  cursor: pointer;

  path {
    fill: #aaaaaa;
  }
`;

const DoneButton = styled(PrimaryButton)``;

class CustodiansPickerDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      searchResults: null,
      currentlySelectedResultIndex: null,
      selectedItems: this.getDefaultItems()
    };

    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleResultInteraction = this.handleResultInteraction.bind(this);
    this.handleRemoveSelectedItemClick = this.handleRemoveSelectedItemClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  getDefaultItems() {
    if (!this.props.data === true || !this.props.data.items === true) {
      return [];
    }
    return getCustodianItems(this.props.data.items);
  }

  componentDidMount() {
    addKeyboardEventListener(this.handleKeyDown);
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown, true);
  }

  handleKeyDown(e) {
    if (e.key === "ArrowUp") {
      this.selectPreviousResult();
      e.preventDefault();
      return true;
    } else if (e.key === "ArrowDown") {
      this.selectNextResult();
      e.preventDefault();
      return true;
    } else if (e.key === "Enter") {
      if (!this.state.searchResults || this.state.searchResults.length === 0) {
        if (!this.state.currentlySelectedResultIndex === false && !this.props.recentSearches === false) {
          this.handleRecentSearchResultClick(e, this.props.recentSearches[this.state.currentlySelectedResultIndex]);
        } else if (this.state.selectedItems?.length) {
          this.handleSaveClick(e);
        }
      } else {
        const result = this.state.searchResults[this.state.currentlySelectedResultIndex || 0];
        this.handleResultInteraction(result);
      }
      e.preventDefault();
      return true;
    }
    return false;
  }

  handleSearchInputChange(searchText) {
    this.setState({ searchText: searchText });

    if (!this.searchTimer === false) {
      clearTimeout(this.searchTimer);
    }

    if (!searchText === true) {
      this.setState({ searchText: searchText, searchResults: null });
      return;
    } else {
      this.setState({ searchText: searchText });
    }

    this.searchTimer = setTimeout(() => {
      this.props.searchPortfolioForText(searchText, this.props.category, data => {
        if (data.searchText !== searchText) {
          return;
        }
        const results =
          this.onlyCustodians() === true ? data.results.filter(item => item.isCustodian === true) : data.results;
        this.setState({ searchResults: results });
      });
      this.searchTimer = null;
    }, 300);
  }

  handleResultInteraction(result) {
    if (!this.state.selectedItems.find(item => item.id === result.id) === true) {
      var selectedItems = this.props.allowMultiple === true ? this.state.selectedItems : [];
      selectedItems.push(result);
      this.setState({ selectedItems: selectedItems });
    }

    this.handleSearchInputChange("");
  }

  handleRemoveSelectedItemClick(result) {
    var selectedItems = this.state.selectedItems;
    selectedItems = selectedItems.filter(item => item.id !== result.id);
    this.setState({ selectedItems: selectedItems });
  }

  handleSaveClick(e) {
    const items = this.state.selectedItems.map(item => {
      const entry = { id: item.id };
      if (item.isCustodian === true) {
        entry.isCustodian = true;
        entry.sectionId = item.sectionId;
        entry.sheetId = item.sheet.id;
        entry.valueTickerId = item.valueTickerId;
      } else if (item.isSection === true) {
        entry.isSection = true;
        entry.sheetId = item.sheetId;
        entry.sectionId = item.id;
      } else if (item.isSheet === true) {
        entry.isSheet = true;
      }
      return entry;
    });

    this.props.onVariableUpdate({ ...(this.props.data || {}), items: items });
    this.props.onDismiss();
  }

  getResultPath(result) {
    if (result.isCustodian === true) {
      if (!result.parentId === false && result.hidden === 1) {
        return `${this.getCategoryNameForCategory(result.sheet.category)} / ${result.sheet.name} / ${
          result.section.name
        } / ${result.parent.name}`;
      } else {
        return `${this.getCategoryNameForCategory(result.sheet.category)} / ${result.sheet.name} / ${
          result.section.name
        }`;
      }
    } else if (result.isSection === true) {
      return `${this.getCategoryNameForCategory(result.sheet.category)} / ${result.sheet.name}`;
    } else if (result.isSheet === true) {
      return `${this.getCategoryNameForCategory(result.category)}`;
    }
    return "";
  }

  getCategoryNameForCategory(categoryName) {
    if (categoryName === category.ASSET) {
      return i18n.t("assets");
    } else if (categoryName === category.DEBT) {
      return i18n.t("debts");
    } else if (categoryName === category.INSURANCE) {
      return i18n.t("insurance");
    }
    return null;
  }

  selectNextResult() {
    const currentIndex = this.state.currentlySelectedResultIndex;

    if (!this.state.searchResults === true) {
      if (currentIndex === this.props.recentSearches.length - 1) {
        return;
      }
    } else {
      if (currentIndex === this.state.searchResults.length - 1) {
        return;
      }
    }
    this.setState({ currentlySelectedResultIndex: currentIndex === null ? 0 : currentIndex + 1 });
  }

  selectPreviousResult() {
    const currentIndex = this.state.currentlySelectedResultIndex;
    if (currentIndex === null || currentIndex === 0) {
      return;
    }
    this.setState({ currentlySelectedResultIndex: currentIndex - 1 });
  }

  selectResult(index) {
    this.setState({ currentlySelectedResultIndex: index });
  }

  onlyCustodians() {
    return (
      !this.props.data === false && !this.props.data.props === false && this.props.data.props.onlyCustodians === true
    );
  }

  render() {
    const portfolioTicker = getTickerUsingShortName(this.props.currentPortfolio.currency);

    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <PickerDialog>
          <Container>
            <Title>
              {(this.onlyCustodians() === true
                ? i18n.t("custodiansOnlyPickerTitle")
                : i18n.t("custodiansPickerTitle")
              ).replace("%s1%", this.getCategoryNameForCategory(this.props.category).slice(0, -1))}
            </Title>
            <SearchBox
              autoFocus={true}
              value={this.state.searchText}
              onChange={this.handleSearchInputChange}
              placeholder={(this.onlyCustodians() === true
                ? i18n.t("custodiansOnlySearchPlaceholder")
                : i18n.t("custodiansSearchPlaceholder")
              ).replace("%s1%", this.getCategoryNameForCategory(this.props.category).slice(0, -1))}
            />
            {!this.state.searchResults === false && (
              <ResultsContainer>
                {this.state.searchResults.map((result, index) => {
                  return (
                    <div key={index}>
                      <Result
                        isFocused={this.state.currentlySelectedResultIndex === index}
                        onClick={e => this.handleResultInteraction(result)}
                        onMouseEnter={() => {
                          this.selectResult(index);
                        }}
                      >
                        <ResultDetailsContainer>
                          <ResultNameContainer>
                            <ResultName>{result.name}</ResultName>
                            {!result.linkType === false && <LinkedCustodianIcon />}
                            {result.isSection === true && <ResultMeta>{"(Section)"}</ResultMeta>}
                            {result.isSheet === true && <ResultMeta>{"(Sheet)"}</ResultMeta>}
                          </ResultNameContainer>
                          {!result.description === false && <ResultDescription>{result.description}</ResultDescription>}
                          <ResultPath>{this.getResultPath(result)}</ResultPath>
                        </ResultDetailsContainer>
                        <ResultValueContainer>
                          <ResultValue
                            currency={this.props.currentPortfolio.currency}
                            value={result.total}
                            abbreviate={false}
                            roundDown={true}
                          />
                          {result.isCustodian === true && portfolioTicker.id !== result.valueTickerId && (
                            <ResultValueOriginalCurrency
                              currency={getTickerUsingId(result.valueTickerId).shortName}
                              value={result.value}
                              abbreviate={false}
                              roundDown={true}
                              showCurrencyAsSymbol={false}
                            />
                          )}
                        </ResultValueContainer>
                      </Result>
                    </div>
                  );
                })}
                {this.state.searchResults.length === 0 && <EmptyResult>{i18n.t("assetNotFound")}</EmptyResult>}
              </ResultsContainer>
            )}

            {!this.state.searchText === true && (
              <SelectedItemsContainer>
                <SelectedItemsList isEmpty={this.state.selectedItems.length === 0}>
                  {this.state.selectedItems.map((result, index) => {
                    return (
                      <div key={index}>
                        <SelectedItem>
                          <ResultDetailsContainer>
                            <ResultNameContainer>
                              <ResultName>{result.name}</ResultName>
                              {!result.linkType === false && <LinkedCustodianIcon />}
                              {result.isSection === true && <ResultMeta>{"(Section)"}</ResultMeta>}
                              {result.isSheet === true && <ResultMeta>{"(Sheet)"}</ResultMeta>}
                            </ResultNameContainer>
                            {!result.description === false && (
                              <ResultDescription>{result.description}</ResultDescription>
                            )}
                            <ResultPath>{this.getResultPath(result)}</ResultPath>
                          </ResultDetailsContainer>
                          <ResultValueContainer>
                            <SelectedItemValue
                              currency={this.props.currentPortfolio.currency}
                              value={result.total}
                              abbreviate={false}
                              roundDown={true}
                            />
                            {result.isCustodian === true && portfolioTicker.id !== result.valueTickerId && (
                              <ResultValueOriginalCurrency
                                currency={getTickerUsingId(result.valueTickerId).shortName}
                                value={result.value}
                                abbreviate={false}
                                roundDown={true}
                                showCurrencyAsSymbol={false}
                              />
                            )}
                          </ResultValueContainer>
                          <DeleteSelectedItemIcon onClick={e => this.handleRemoveSelectedItemClick(result)} />
                        </SelectedItem>
                      </div>
                    );
                  })}
                </SelectedItemsList>
                <DoneButton title={i18n.t("done")} onClick={this.handleSaveClick} />
              </SelectedItemsContainer>
            )}
          </Container>
        </PickerDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentPortfolio: currentPortfolioSelector(state)
});

const mapDispatchToProps = {
  searchPortfolioForText: searchPortfolioForText
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustodiansPickerDialog);
