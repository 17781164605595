import { css } from "styled-components";

const ScrollBar = css`
  /* Track */
  ::-webkit-scrollbar-track {
    width: 50px;
    background: ${props => props.theme.desktopBackgroundScrollbarTrack};
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.desktopBackgroundScrollbarThumb};
    border-radius: 50px;
    border: 3px ${props => props.theme.desktopBackgroundScrollbarTrack} solid;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.desktopBackgroundScrollbarThumbHover};
  }
`;

const ScrollBarHorizontal = css`
  /* width */
  ::-webkit-scrollbar {
    width: 14px;
  }

  ${ScrollBar}
`;

const ScrollBarVertical = css`
  /* width */
  ::-webkit-scrollbar {
    height: 12px;
  }

  ${ScrollBar}
`;

export { ScrollBarHorizontal, ScrollBarVertical };
